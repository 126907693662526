import { ACTION, type Column, FormControlSizes, IconButton, IconName, type MarketCredential, Text } from '@talos/kyoko';
import type { ICellRendererParams, RowNode } from 'ag-grid-enterprise';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useRoleAuth } from '../../../hooks';
import { CredentialWrapper } from './styles';
import type { TradingControlsBlotterEntity } from './types';
import { useEquityMarginRatioLimits } from './useEquityMarginRatioLimits';

interface useColumnsProps {
  handleEdit: (marketAccount: TradingControlsBlotterEntity) => void;
  handleOpenCredential: (credentialID: number) => void;
}

export const useColumns = ({ handleEdit, handleOpenCredential }: useColumnsProps) => {
  const theme = useTheme();
  const { isAuthorized } = useRoleAuth();
  const { isEquityMarginRatioLimitCheckEnabled } = useEquityMarginRatioLimits();

  return useMemo<Column[]>(
    () =>
      compact([
        {
          type: 'market',
          field: 'Market',
          sortable: true,
        },
        {
          type: 'text',
          field: 'Name',
          title: 'Market Account',
          sortable: true,
        },
        {
          type: 'text',
          field: 'DisplayName',
          sortable: true,
        },
        {
          type: 'marketAccountTradingStatus',
          field: 'Status',
          title: 'Trading',
        },
        {
          type: 'connectionStatusWithText',
          field: 'ConnectionStatus',
          title: 'Status',
        },
        isEquityMarginRatioLimitCheckEnabled
          ? {
              id: 'equityMarginRatio',
              type: 'custom',
              title: 'Equity Margin Ratio Limit',
              params: {
                cellClass: 'ag-right-aligned-cell',
                valueGetter: ({ data }: ICellRendererParams<TradingControlsBlotterEntity>) =>
                  data?.EquityMarginRatio || data?.GlobalEquityMarginRatio,
                cellRenderer: ({ data }: ICellRendererParams<TradingControlsBlotterEntity>) => {
                  const displayValue = data?.EquityMarginRatio || data?.GlobalEquityMarginRatio;
                  return (
                    <Text color={data?.EquityMarginRatio ? 'colorTextImportant' : theme.colors.gray['070']}>
                      {displayValue}
                    </Text>
                  );
                },
              },
            }
          : null,
        {
          type: 'text',
          field: 'MarketType',
        },
        isAuthorized(ACTION.VIEW_CREDENTIALS)
          ? {
              field: 'MarketCredential',
              type: 'custom',
              title: 'Credential',
              width: 150,
              params: {
                comparator: (a, b) => {
                  return a?.Label.localeCompare(b?.Label);
                },
                cellRenderer: ({
                  value: marketCredential,
                }: ICellRendererParams<TradingControlsBlotterEntity, MarketCredential | undefined>) => {
                  if (!marketCredential) {
                    return null;
                  }
                  return (
                    <CredentialWrapper>
                      <Text
                        overflow="hidden"
                        textOverflow="ellipsis"
                        title={marketCredential.Label}
                        whiteSpace="nowrap"
                      >
                        {marketCredential.Label}
                      </Text>
                      <IconButton
                        icon={IconName.ExternalLink}
                        size={FormControlSizes.Small}
                        onClick={() => handleOpenCredential(marketCredential.CredentialID)}
                        title="View Credential"
                      />
                    </CredentialWrapper>
                  );
                },
              },
            }
          : null,
        {
          id: 'edit',
          type: 'iconButton',
          pinned: 'right',
          width: 60,
          suppressColumnsToolPanel: true,
          params: {
            onClick: ({ node }: { node: RowNode }) => handleEdit(node.data),
            icon: IconName.Pencil,
          },
        },
      ]),
    [theme, isAuthorized, isEquityMarginRatioLimitCheckEnabled, handleEdit, handleOpenCredential]
  );
};
