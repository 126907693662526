import { isEmpty, keys, uniq, values } from 'lodash';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useIntl } from '../../../../hooks';
import { OrdStatusEnum, type DecisionStatusEnum, type PricingModeEnum } from '../../../../types/types';
import { prettyName } from '../../../../utils/string';
import { IconName } from '../../../Icons';
import { getOrderStatusText } from '../../../Status/OrderStatus';
import { ORD_STATUS_MAPPING, ORD_TEXT_TO_STATUS_MAPPING } from '../../ToggleButtonFilter/constants';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  orderStatus: {
    defaultMessage: 'Order Status',
    id: 'Filters.FilterBuilder.filters.orderStatus',
  },
});

const orderStatusOptions = uniq([
  // At the top of the list, we have "All Open", etc.
  ...keys(ORD_STATUS_MAPPING),
  // The remaining items are sorted alphabetically
  ...[
    // All the different order status texts that we can show
    ...keys(ORD_TEXT_TO_STATUS_MAPPING),
    // Also, add in any other values from the Order Status Enum, that aren't already present in the above list
    ...values(OrdStatusEnum).filter(s => !keys(ORD_TEXT_TO_STATUS_MAPPING).includes(prettyName(s))),
  ].sort(),
]);

/**
 * This hook creates the filter property definition for Order Status properties.
 *
 * @returns Filter Property definition to use for Order Status filters
 */
export function useOrderStatusFilter() {
  const { formatMessage } = useIntl();

  return useMemo(
    () =>
      ({
        key: '_statuses',
        label: formatMessage(messages.orderStatus),
        icon: IconName.CheckCircle,
        options: orderStatusOptions,
        getOptionLabel: (option: string) => option,
      } as const satisfies FilterableProperty),
    [formatMessage]
  );
}

/**
 * This function will map a selection of order status filter builder values, to the required statuses for
 * backend filtering.  E.g. If the user has selected "All Open", this function will return all the order statuses
 * that are considered "Open".
 *
 * @param selectedFilterOrderStatuses The "raw" values selected by the user in the Order Status filter builder
 * @returns The corresponding order statuses to send to the backend for filtering
 */
export function orderStatusFilterTextToBackendStatus(selectedFilterOrderStatuses?: string[]): OrdStatusEnum[] {
  return uniq(
    selectedFilterOrderStatuses?.flatMap(s =>
      s in ORD_STATUS_MAPPING
        ? ORD_STATUS_MAPPING[s]
        : ORD_TEXT_TO_STATUS_MAPPING[s as keyof typeof ORD_TEXT_TO_STATUS_MAPPING] ?? s
    )
  );
}

/**
 * Match an order-like object against a filter builder selection on the client side.
 * Intended for use in our blotter tables.
 * Although we can pass a list of Order Statuses to the backend, that list will still potentially
 * include more orders than we care to see.
 * e.g. We display "Canceled" for both "Canceled" and "DoneForDay" orders in certain cases.
 * This function will match against both the display text, and the underlying property value.
 *
 * @param selectedOrderStatuses The order statuses the user has picked in the filter builder
 * @param data The Order like object to match against
 * @returns True if `data` matches the filter, false if `data` doesn't match the filter
 */
export function filterExcludesOrderStatus(
  selectedOrderStatuses: string[] | undefined,
  data:
    | {
        OrdStatus?: OrdStatusEnum | undefined;
        OrderQty?: string | undefined;
        CumQty?: string | undefined;
        DecisionStatus?: DecisionStatusEnum | undefined;
        PricingMode?: PricingModeEnum | undefined;
      }
    | undefined
) {
  if (!data) {
    return false;
  }
  if (selectedOrderStatuses && !isEmpty(selectedOrderStatuses)) {
    if (!data.OrdStatus) {
      return true;
    }
    const ordStatusText = getOrderStatusText({
      ordStatus: data.OrdStatus,
      orderQty: data.OrderQty ?? '0',
      cumQty: data.CumQty ?? '0',
      decisionStatus: data.DecisionStatus,
      pricingMode: data.PricingMode,
    });
    if (
      !selectedOrderStatuses.some(
        s =>
          s === ordStatusText || // Selected order status is a direct match against the display text
          (s in ORD_STATUS_MAPPING && ORD_STATUS_MAPPING[s].includes(data.OrdStatus)) || // Selected order status is e.g. "All Open", and matches against this order
          s === data.OrdStatus // Selected order status is a direct match against the order's status property
      )
    ) {
      // None of the selected order statuses match this order; this filter excludes this filter
      return true;
    }
  }
  return false;
}
