import {
  DateRangePicker,
  FormControlSizes,
  LookbackOption,
  TimePickerPrecision,
  useDateRangeFilter,
  useDynamicCallback,
  useObservableValue,
  type DateRangeFilter,
  type DateRangePickerProps,
  type ShortcutOption,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import type React from 'react';
import { useCallback } from 'react';
import { debounceTime, tap } from 'rxjs';
import { useChartDateRangeObs } from '../../providers/ChartMessagingProvider';
import { TreasuryManagementActionType } from '../TreasuryManagementReducer';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';

const dateRangePickerStyle: React.CSSProperties = { minWidth: '250px', width: '250px' };

const SHORTCUT_OPTIONS: ShortcutOption[] = [
  { value: LookbackOption.Today },
  { value: LookbackOption.Past24Hours },
  { value: LookbackOption.PastWeek },
  { value: LookbackOption.Past30Days },
  { value: LookbackOption.Past90Days },
  { value: LookbackOption.PastYear },
];

function getGroup(option: ShortcutOption): string | undefined {
  switch (option.value) {
    case LookbackOption.Today:
    case LookbackOption.Past24Hours:
    case LookbackOption.PastWeek:
    case LookbackOption.PastMonth:
    case LookbackOption.Past30Days:
    case LookbackOption.Past90Days:
    case LookbackOption.PastYear:
      return 'Rolling';
    default:
      return undefined;
  }
}

interface ChartHeaderDateRangePickerProps {
  onManualDateRangeChange: (newDateRange: DateRangeFilter) => void;
}

export function ChartHeaderDateRangePicker({ onManualDateRangeChange }: ChartHeaderDateRangePickerProps) {
  const {
    state: { chartRequestDateRange },
    dispatch,
  } = useTreasuryManagementContext();

  const chartDateRangeObs = useChartDateRangeObs();

  // TODO Convert this stuff to Lookbacks and pass that to the daterange component Olof
  const chartDateRange = useObservableValue<DateRangeFilter>(
    () => chartDateRangeObs,
    [chartDateRangeObs],
    chartRequestDateRange
  );

  // A notifier observable, which dispatches debounced date range changes to the top-level reducer
  useObservableValue(
    () =>
      chartDateRangeObs.pipe(
        debounceTime(200),
        tap(({ StartDate, EndDate }) => {
          dispatch({
            type: TreasuryManagementActionType.ChartRequestDateRangeChange,
            payload: {
              chartRequestDateRange: {
                StartDate: StartDate,
                EndDate: EndDate,
              },
            },
          });
        })
      ),
    [chartDateRangeObs, dispatch]
  );

  // This fires on user interaction with the DateRangePicker.
  const changeDateRangeFilter = useCallback(
    (action: React.SetStateAction<DateRangeFilter>) => {
      const priorDateRangeFilter = chartDateRange;
      const newDateRangeFilter = action instanceof Function ? action(chartDateRange) : action;
      if (!isEqual(priorDateRangeFilter, newDateRangeFilter)) {
        onManualDateRangeChange(newDateRangeFilter);
      }
    },
    [chartDateRange, onManualDateRangeChange]
  );

  const dateRangeFilter = useDateRangeFilter(chartDateRange, changeDateRangeFilter);

  const handleDateRangeChanged: DateRangePickerProps['onChange'] = useDynamicCallback(change => {
    dateRangeFilter.onDateRangeChanged?.(change);
  });

  if (!dateRangeFilter.dateRange) {
    return null;
  }

  return (
    <DateRangePicker
      value={dateRangeFilter.dateRange}
      onChange={handleDateRangeChanged}
      clearable={false}
      shortcuts={SHORTCUT_OPTIONS}
      maxTimePickerPrecision={TimePickerPrecision.MINUTE}
      style={dateRangePickerStyle}
      size={FormControlSizes.Small}
      getGroup={getGroup}
    />
  );
}
