import { ConnectionType, IconName, useMarketsContext, type FilterableProperty } from '@talos/kyoko';
import { useMemo } from 'react';
/**
 * This hook creates the filter property definition for Markets properties.
 *
 * @returns Filter Property definition to use for Markets filters
 */

export function useMarketsFilter() {
  const { marketsByName, marketsList, isMarketConfigured } = useMarketsContext();

  return useMemo(
    () => ({
      ...({
        key: 'Markets',
        label: 'Market',
        icon: IconName.PresentationChartLine,
        control: 'multi-select',
        options: marketsList
          .filter(
            m =>
              // We don't want to use `isMarketSupported` here, as that'd at worst show 50 different markets
              // even though the user might only be trading on a few
              isMarketConfigured(m, ConnectionType.Orders) || isMarketConfigured(m, ConnectionType.RFQ)
          )
          .map(m => m.Name),
        getOptionLabel: (option: string) => marketsByName.get(option)?.DisplayName || '',
      } as const satisfies FilterableProperty),
    }),
    [isMarketConfigured, marketsByName, marketsList]
  );
}
