import {
  FilterClauseType,
  IconName,
  TradeStatusEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type MarketTrade,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys, pick, values } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useIDFilter, useMarketsFilter, useSidesFilter } from '../../../hooks';
import { useMarketAccountsFilter } from '../../../hooks/filters/useMarketAccountsFilter';

export interface MarketTradeFilter extends DateRangeFilter {
  Symbols?: string[];
  Sides?: string[];
  MarketAccounts?: string[];
  Statuses?: TradeStatusEnum[];
  Markets?: string[];
  OrderID?: string;
}

export interface UseMarketTradeFilterParams<TData extends MarketTrade> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}

export function useMarketTradeFilter<TData extends MarketTrade>({
  persistedBlotterTable,
}: UseMarketTradeFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<MarketTradeFilter>((initialFilter as MarketTradeFilter) || {});

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<MarketTrade>>(
    row => {
      const data = row.data;
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Statuses', data, 'TradeStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Markets', data, 'Market')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const sidesFilter = useSidesFilter();
  const symbolsFilter = useSymbolsFilter();
  const marketsFilter = useMarketsFilter();
  const marketAccountsFilter = useMarketAccountsFilter();
  const idFilter = useIDFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      compact([
        symbolsFilter,
        sidesFilter,
        {
          key: 'Statuses',
          label: 'Status',
          icon: IconName.CheckCircle,
          options: values(TradeStatusEnum),
          getOptionLabel: (option: string) => option,
        },
        marketsFilter,
        marketAccountsFilter,
        { ...idFilter, key: 'OrderID', label: 'Order ID' },
      ]),
    [marketsFilter, sidesFilter, symbolsFilter, marketAccountsFilter, idFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      keys(filter).forEach(key => {
        if (key === '_start' || key === 'StartDate') {
          return;
        }
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key],
        });
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: MarketTradeFilter = removeEmptyFilters<MarketTradeFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies MarketTradeFilter),
          // below are exact search and only one value is supported by backend
          OrderID: filterClausesByPropertyKey.get('OrderID')?.selections?.[0],
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Symbol: 'Symbols',
  Side: 'Sides',
  MarketAccount: 'MarketAccounts',
  Market: 'Markets',
  TradeStatus: 'Statuses',
} satisfies Partial<Record<keyof MarketTrade, keyof MarketTradeFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof MarketTradeFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

export function onlyServerFilterKeys(filter?: MarketTradeFilter) {
  return filter == null ? filter : pick(filter, ['StartDate', 'EndDate', 'OrderID']);
}
