import {
  ExecTypeEnum,
  FilterClauseType,
  IconName,
  OrdTypeEnum,
  cleanupInitialFilterDateRange,
  filterExcludesOrderStatus,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useOrderStatusFilter,
  useSecuritiesContext,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type MarketExecutionReport,
  type OrdStatusEnum,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys, pick, values } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useIDFilter, useMarketsFilter } from '../../../hooks';

export interface MarketExecutionReportFilter extends DateRangeFilter {
  _statuses?: string[];
  Statuses?: OrdStatusEnum[];
  Users?: string[];
  SubAccounts?: string[];
  Markets?: string[];
  OrdTypes?: string[];
  ExecTypes?: string[];
  Strategies?: string[];
  Symbols?: string[];
  ParentOrderID?: string;
}

export const useMarketExecutionReportFilter = ({
  persistedBlotterTable,
  includeStatusFilter,
  includeIDFilter,
}: {
  persistedBlotterTable: UsePersistedBlotterTable<MarketExecutionReport>;
  includeStatusFilter: boolean;
  includeIDFilter: boolean; // Include IdFilter only if blotter table was not already filtered by some Id
}) => {
  const { securitiesList, securitiesBySymbol } = useSecuritiesContext();
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<MarketExecutionReportFilter>(
    (initialFilter as MarketExecutionReportFilter) || {}
  );

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<MarketExecutionReport>>(
    row => {
      const data = row.data;
      if (filterExistsAndExcludes(filter, 'OrdTypes', data, 'OrdType')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'ExecTypes', data, 'ExecType')) {
        return false;
      }
      if (filterExcludesOrderStatus(filter._statuses, data)) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Markets', data, 'Market')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const marketsFilter = useMarketsFilter();
  const orderStatusFilter = useOrderStatusFilter();
  const idFilter = useIDFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      compact([
        {
          key: 'Symbols',
          label: 'Symbol',
          icon: IconName.CurrencyDollar,
          options: securitiesList.map(sec => sec.Symbol),
          getOptionLabel: (option: string) => securitiesBySymbol.get(option)?.DisplaySymbol || '',
        },
        {
          key: 'ExecTypes',
          label: 'Exec Type',
          icon: IconName.CheckCircle,
          options: values(ExecTypeEnum),
          getOptionLabel: (option: string) => option,
        },
        marketsFilter,
        {
          key: 'OrdTypes',
          label: 'Order Type',
          icon: IconName.CheckCircle,
          options: values(OrdTypeEnum),
          getOptionLabel: (option: string) => option,
        },
        includeStatusFilter ? orderStatusFilter : null,
        includeIDFilter ? { ...idFilter, key: 'ParentOrderID', label: 'Parent Order ID' } : null,
      ]),
    [
      includeStatusFilter,
      marketsFilter,
      orderStatusFilter,
      securitiesBySymbol,
      securitiesList,
      idFilter,
      includeIDFilter,
    ]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      keys(filter).forEach(key => {
        if (key === '_start' || key === 'StartDate') {
          return;
        }
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key],
        });
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: MarketExecutionReportFilter = removeEmptyFilters<MarketExecutionReportFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies MarketExecutionReportFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
};

const colIDToFilterBuilderKeyMap = {
  ExecType: 'ExecTypes',
  Symbol: 'Symbols',
  OrdStatus: '_statuses',
  Market: 'Markets',
  ParentOrderID: 'ParentOrderID',
} satisfies Partial<Record<keyof MarketExecutionReport, keyof MarketExecutionReportFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof MarketExecutionReportFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

export function onlyServerFilterKeys(filter?: MarketExecutionReportFilter) {
  return filter == null ? filter : pick(filter, ['StartDate', 'EndDate', 'Statuses', 'Symbols', 'ParentOrderID']);
}
