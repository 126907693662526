import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ACTION,
  Button,
  ButtonVariants,
  CustomerTradingLimit,
  EMPTY_ARRAY,
  FormRowStatus,
  FormTable,
  IconName,
  LocalFilterInput,
  ModeEnum,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  UpdateActionEnum,
  promisesHaveResolved,
  stringColumnComparator,
  useFormTable,
  useGlobalToasts,
  type Asset,
  type Column,
  type FormRow,
} from '@talos/kyoko';

import type { RowNode } from 'ag-grid-community';
import { useFeatureFlag, useRoleAuth } from 'hooks';
import { useCustomerTradingLimits, useCustomersContext } from 'hooks/useCustomer';
import { compact } from 'lodash';
import { useBlotterState } from 'providers/AppConfigProvider';
import { useCustomerAccountColumn, useCustomersColumn, useSymbolOrCurrencyColumn } from '../../../utils/columns';

const SEARCH_KEYS = [
  'Counterparty',
  'Symbol',
  'Currency',
  'Description',
  'TradingLimitID',
  'ThresholdCurrency',
] satisfies (keyof CustomerTradingLimit)[];

export function CustomerTradingLimits() {
  const { add: addToast } = useGlobalToasts();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const { isAuthorized } = useRoleAuth();

  const customerService = useCustomersContext();
  const customerTradingLimits = useCustomerTradingLimits({ tag: 'CustomerTradingLimits' });

  const { filterValueCustomerTradingLimits, setFilterValueCustomerTradingLimits } = useBlotterState();

  const symbolOrCurrencyColumn = useSymbolOrCurrencyColumn({ comparator: customComparator });
  const customerAccountColumn = useCustomerAccountColumn();
  const customersColumn = useCustomersColumn();

  const { enableCustomerTradingLimitsPerAccount } = useFeatureFlag();

  const columns: Column[] = useMemo(
    () => [
      { field: 'Mode', type: 'mode', sortable: true, width: 80 },
      { field: 'Timestamp', type: 'date', sortable: true, hide: true },
      symbolOrCurrencyColumn,
      ...(enableCustomerTradingLimitsPerAccount ? [customerAccountColumn] : []),
      customersColumn,
      {
        field: 'WarnThreshold',
        type: 'size',
        width: 150,
        editable: true,
        params: { currencyField: 'ThresholdCurrency' },
      },
      {
        field: 'RejectThreshold',
        type: 'size',
        width: 150,
        editable: true,
        params: { currencyField: 'ThresholdCurrency' },
      },
      { field: 'ThresholdCurrency', type: 'currency', editable: true, width: 170 },
      { field: 'TradingLimitID', type: 'text', width: 140, params: { milliseconds: true }, hide: true },
      { field: 'UpdateAction', type: 'text', sortable: true, hide: true },
      { field: 'Description', type: 'text', width: 250, editable: true },
      { type: 'filler', id: 'filler' },
      { type: 'remove', id: 'remove' },
    ],
    [symbolOrCurrencyColumn, customerAccountColumn, customersColumn, enableCustomerTradingLimitsPerAccount]
  );

  // Since we use custom columns where id does not match `keyof CustomerTradingLimit`,
  // we need to use their column id for the quicksearch.
  const formTableOtherSearchKeys = useMemo(
    () => compact([customersColumn.id, enableCustomerTradingLimitsPerAccount ? customerAccountColumn.id : null]),
    [customersColumn.id, enableCustomerTradingLimitsPerAccount, customerAccountColumn.id]
  );

  const formTable = useFormTable<CustomerTradingLimit>({
    rowID: 'TradingLimitID',
    data: EMPTY_ARRAY,
    columns,
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
      otherSearchKeys: formTableOtherSearchKeys,
      filterText: filterValueCustomerTradingLimits,
    },
  });

  const createCustomerTradingLimit = useCallback(
    async (row: FormRow<CustomerTradingLimit>) => {
      return customerService
        .createCustomerTradingLimit(row.data)
        .then(({ data }) => {
          row.setData(data[0]);
          return data[0];
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: e.message || `Could not add customer trading limit.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, customerService]
  );

  const updateCustomerTradingLimit = useCallback(
    async (row: FormRow<CustomerTradingLimit>) => {
      return customerService
        .updateCustomerTradingLimit(row.data)
        .then(({ data }) => {
          row.setData(data[0]);
          return data[0];
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: e.message?.toString() || `Could not update customer trading limit.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, customerService]
  );

  const deleteCustomerTradingLimit = useCallback(
    async (row: FormRow<CustomerTradingLimit>) => {
      const { TradingLimitID } = row.data;
      if (TradingLimitID) {
        return customerService
          .deleteCustomerTradingLimit(TradingLimitID)
          .then(() => {
            row.remove(true);
            return row.data;
          })
          .catch((e: ErrorEvent) => {
            addToast({
              text: e.message?.toString() || `Could not delete customer trading limit.`,
              variant: NotificationVariants.Negative,
            });
          });
      }
    },
    [addToast, customerService]
  );

  const handleSave = useCallback(() => {
    setIsSaving(true);
    const rows = formTable.getRows();
    const requests: Promise<any>[] = [];
    for (const row of rows) {
      switch (row.status) {
        case FormRowStatus.Added:
          requests.push(createCustomerTradingLimit(row));
          break;
        case FormRowStatus.Updated:
          requests.push(updateCustomerTradingLimit(row));
          break;
        case FormRowStatus.Removed:
          requests.push(deleteCustomerTradingLimit(row));
          break;
        default:
          break;
      }
    }
    Promise.allSettled(requests).then(promises => {
      setIsSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: 'Customer Trading Limits saved.',
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Customer Trading Limits could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  }, [formTable, createCustomerTradingLimit, updateCustomerTradingLimit, deleteCustomerTradingLimit, addToast]);

  useEffect(() => {
    if (customerTradingLimits && initialLoad) {
      setInitialLoad(false);
      formTable.reset(customerTradingLimits);
    }
  }, [customerTradingLimits, formTable, initialLoad]);

  const handleAddTradingLimitButton = useCallback(() => {
    formTable.addRow(
      new CustomerTradingLimit({
        Mode: ModeEnum.Enabled,
        WarnThreshold: '',
        RejectThreshold: '',
        ThresholdCurrency: '',
        UpdateAction: UpdateActionEnum.Initial,
      })
    );
  }, [formTable]);

  const headerActions = (
    <PanelActions>
      <LocalFilterInput
        placeholder="Filter by Asset, Customer, or User"
        value={filterValueCustomerTradingLimits}
        onChange={setFilterValueCustomerTradingLimits}
      />
      <Button
        startIcon={IconName.Plus}
        onClick={handleAddTradingLimitButton}
        variant={ButtonVariants.Positive}
        disabled={!isAuthorized(ACTION.DEALER_TRADING)}
      >
        Add Trading Limit
      </Button>
      <Button
        variant={ButtonVariants.Primary}
        onClick={handleSave}
        disabled={isSaving || !formTable.isDirty || !isAuthorized(ACTION.DEALER_TRADING)}
      >
        Save
      </Button>
    </PanelActions>
  );

  return (
    <Panel>
      <PanelHeader>
        <h2>Customer Trading Limits</h2>
        {headerActions}
      </PanelHeader>
      <PanelContent>
        <FormTable {...formTable} />
      </PanelContent>
    </Panel>
  );
}

function customComparator(
  valueA: Asset | undefined,
  valueB: Asset | undefined,
  nodeA: RowNode<CustomerTradingLimit>,
  nodeB: RowNode<CustomerTradingLimit>
) {
  const symbolComparisonResult = stringColumnComparator(valueA?.Symbol, valueB?.Symbol);

  // If the two symbols are not the same, we return this sorting result
  if (symbolComparisonResult !== 0) {
    return symbolComparisonResult;
  }

  // If the Symbol is the same, sort by MarketAccount, CustomerUser, then Counterparty
  const { data: dataA } = nodeA;
  const { data: dataB } = nodeB;
  for (const key of ['MarketAccount', 'CustomerUser', 'Counterparty'] satisfies (keyof CustomerTradingLimit)[]) {
    const result = stringColumnComparator(dataA?.[key], dataB?.[key]);
    if (result !== 0) {
      return result;
    }
  }
  // These rows are the same
  return 0;
}
