import type { UseComboboxStateChange } from 'downshift';
import { useEffect, useMemo, useState, type RefObject } from 'react';
import { useDynamicCallback } from '../../../../hooks/useDynamicCallback';
import { useMultiSelectAutocomplete } from '../../../Form/MultiSelect';
import type { FilterableSelectProperty, PropertyRefs, UseFilterBuilderRefsOutput } from '../types';
export const MAX_VISIBLE_SELECTIONS_PER_PROPERTY = 4;

type UsePropertySelectionInputs = {
  property: FilterableSelectProperty;
  selections: string[];
  refs: PropertyRefs;
  onSelectionsChange: (newSelections: string[]) => void;
  inputRef?: RefObject<HTMLInputElement>;
} & Pick<UseFilterBuilderRefsOutput, 'updateSelectionRefs'>;

function defaultOptionSorter(getOptionLabel: (option: string) => string): (a: string, b: string) => -1 | 1 {
  return (a: string, b: string) => (getOptionLabel(a) < getOptionLabel(b) ? -1 : 1);
}

/**
 * This hook abstracts away a lot of the complexities native to the RHS of the FilterBuilder
 * and lets you implement your own RHS renderer component easily
 */
export const usePropertyMultiSelect = ({
  property,
  selections,
  refs,
  updateSelectionRefs,
  onSelectionsChange,
  inputRef,
}: UsePropertySelectionInputs) => {
  const { options, getOptionLabel, optionSorter: propertySorter } = property;

  const optionSorter = useMemo(() => {
    return propertySorter ?? defaultOptionSorter(getOptionLabel);
  }, [getOptionLabel, propertySorter]);

  // this reference element will be switched between the currently selected button. Its the dropdown's anchor point
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(refs.empty.current);

  // We remember what the latest selections were _before_ we started editing and mutating the "selections" prop.
  const [latestConfirmedSelections, setLatestConfirmedSelections] = useState<string[]>(selections);

  // We need to keep track of all additions that occur while editing internally and remember until editing is complete (dropdown closes)
  // These are seen as "unconfirmed" as their use is in contrast with the above "latestConfirmedSelections", which are set once we are done editing (dropdown closes)
  const [unconfirmedAdditions, setUnconfirmedAdditions] = useState<Set<string>>(new Set());

  const selectionsSet = useMemo(() => {
    return new Set(selections);
  }, [selections]);

  // Do sorting in separate memos for performance reasons
  const sortedLatestConfirmedSelections = useMemo(() => {
    // Note: Create new arr in order to not sort the original reference which is used elsewhere (unsorted)
    return [...latestConfirmedSelections].sort(optionSorter);
  }, [latestConfirmedSelections, optionSorter]);

  const sortedSelectableOptions = useMemo(() => {
    return options.sort(optionSorter);
  }, [optionSorter, options]);

  // We combine the latest confirmed selections with all additions we have observed so far in this "round" of editing.
  const combinedCurrentSelections = useMemo(() => {
    const combinedSet = new Set(latestConfirmedSelections);
    for (const addition of unconfirmedAdditions) {
      combinedSet.add(addition);
    }
    return [...combinedSet];
  }, [unconfirmedAdditions, latestConfirmedSelections]);

  const visibleSelections = useMemo(() => {
    return combinedCurrentSelections.slice(0, MAX_VISIBLE_SELECTIONS_PER_PROPERTY - 1);
  }, [combinedCurrentSelections]);

  const dropdownItems = useMemo(() => {
    const sortedLatestConfirmedSelectionsSet = new Set(sortedLatestConfirmedSelections);
    const selectableWithoutSelected = sortedSelectableOptions.filter(
      option => !sortedLatestConfirmedSelectionsSet.has(option)
    );
    return [...sortedLatestConfirmedSelectionsSet, ...selectableWithoutSelected];
  }, [sortedSelectableOptions, sortedLatestConfirmedSelections]);

  // whenever the visible selections change, we update the refs
  useEffect(() => {
    updateSelectionRefs(property.key, visibleSelections);
  }, [visibleSelections, updateSelectionRefs, property]);

  // this useeffect keeps the reference element set correctly (as in, the anchor point for the dropdown)
  useEffect(() => {
    if (selections.length === 0) {
      setReferenceElement(refs.empty.current);
    } else if (visibleSelections.length === refs.selections.size && referenceElement?.id === 'no-selections') {
      // the current anchor point is the noSelectionsRef, but we have >0 selections, so set anchor point to last selection.
      const lastSelectionRef =
        selections.length > visibleSelections.length
          ? refs.tail.current
          : refs.selections.get(selections[selections.length - 1])!.current;
      setReferenceElement(lastSelectionRef);
    }
  }, [visibleSelections, refs, selections, referenceElement]);

  const isSelectionDisabled = useDynamicCallback((selection: string) => {
    return !selectionsSet.has(selection);
  });

  const addUnconfirmedAdditions = useDynamicCallback((newSelections: string[]) => {
    const additions = newSelections.filter(newSelection => !selectionsSet.has(newSelection));
    if (additions.length > 0) {
      setUnconfirmedAdditions(curr => {
        for (const addition of additions) {
          curr.add(addition);
        }
        return new Set(curr);
      });
    }
  });

  // Whenever selections change and we are closed (or if we close the dropdown), we update the "latest confirmed selections set".
  const handleOpenChange = useDynamicCallback((change: UseComboboxStateChange<string>) => {
    if (change.isOpen != null && !change.isOpen && selections) {
      setLatestConfirmedSelections(selections);
      setUnconfirmedAdditions(new Set());
    }
  });

  const handleSelectionsChange = useDynamicCallback((newSelections: string[]) => {
    if (autocompleteOutput.isOpen) {
      addUnconfirmedAdditions(newSelections);
    } else {
      // its closed, so immediately set the latestConfirmedSelections to newSelections
      setLatestConfirmedSelections(newSelections);
    }
    onSelectionsChange(newSelections);
  });

  const { autocompleteOutput, multipleSelectionOutput } = useMultiSelectAutocomplete({
    initialIsOpen: selections.length === 0, // start open if we're brand new
    selections: selections,
    items: dropdownItems,
    initialSortByLabel: false,
    getLabel: getOptionLabel,
    getDescription: property.getOptionDescription,
    getGroup: property.getOptionGroup,
    groupSorter: property.groupSorter,
    onChange: handleSelectionsChange,
    onIsOpenChange: handleOpenChange,
    clearInputAfterSelection: false,
    removeItemOnInputBackspace: false,
    highlightInputTextAfterSelection: true,
    inputRef,
    matchThreshold: property.matchThreshold,
  });

  // Whenever the selections change, and we are closed, we need to reflect the changed selections
  // in the latestConfirmedSelections state
  // The side-effect of re-running this when isOpen changes is fine
  useEffect(() => {
    if (!autocompleteOutput.isOpen) {
      setLatestConfirmedSelections(selections);
    }
  }, [selections, autocompleteOutput.isOpen]);

  return {
    referenceElement,
    setReferenceElement,
    dropdownItems,
    visibleSelections,
    isSelectionDisabled,
    addUnconfirmedAdditions,
    tailLength: combinedCurrentSelections.length - visibleSelections.length,
    autocompleteOutput,
    multipleSelectionOutput,
  };
};
