import type { ReactNode } from 'react';
import type { IconName } from '../Icons';

export interface NoteProps {
  id: string;
  text: ReactNode;
  actions?: ReactNode;
  icon?: IconName;
  closable?: boolean;
  variant?: NoteVariant;
}

export enum NoteVariant {
  Default,
  Positive,
  Negative,
  Warning,
  Primary,
  Announcement,
}
