import type { ICellEditor, ICellEditorParams } from 'ag-grid-enterprise';
import { useDynamicCallback } from 'hooks/useDynamicCallback';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState, type Ref } from 'react';
import type { FormControlSizes } from '../Form/types';
import { AmountInput, type AmountInputProps } from '../Forms/AmountInput';

export interface AgGridAmountInputProps extends Omit<AmountInputProps, 'onChange' | 'value'> {
  currencyField: string;
  size?: FormControlSizes;
}

export const AgGridAmountInput = forwardRef(function AgGridAmountInput(
  {
    size,
    node,
    colDef,
    parseValue,
    currencyField,
    data,
    value: propsValue,
    ...props
  }: ICellEditorParams & AgGridAmountInputProps,
  ref: Ref<ICellEditor>
) {
  const [value, setValue] = useState(propsValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const { currenciesBySymbol } = props.context.current;

  useImperativeHandle(ref, () => {
    return {
      getValue: () => parseValue(value),
    };
  });

  const handleChange = useDynamicCallback((value: string | undefined) => {
    setValue(value);
    // Weird cursor behavior if user types the negative '-' sign
    // Similar to packages/kyoko/src/components/Form/NumberInput/useNumberInput.tsx
    // So we purposely move the cursor to the end
    if (value === '-') {
      setTimeout(() => inputRef.current?.setSelectionRange(100, 100), 0);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current != null) {
        inputRef.current.focus();
      }
    });
  }, []);

  const asset = currenciesBySymbol?.get(data?.[currencyField]);

  return (
    <AmountInput
      ref={inputRef}
      onChange={handleChange}
      value={value}
      currency={data?.[currencyField]}
      asset={asset}
      style={{
        width: '100%',
        height: `${node.rowHeight}px`,
        background: props.context.current.theme?.backgroundBody,
        ...props.style,
      }}
    />
  );
});
