export function createCookie(name: string, value: string, expires: number) {
  let date;
  let expiresAt = '';

  // no expiration date set
  if (expires == null) {
    // default to one day
    date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
  } else {
    date = new Date(expires);
  }
  expiresAt = '; expires=' + date.toGMTString();

  document.cookie = name + '=' + value + expiresAt + '; domain=' + window.location.hostname + '; path=/';
}

export function stringifyCookie(cookieData: any) {
  return btoa(JSON.stringify(cookieData));
}

export function parseCookie(cookieValue: string | null) {
  if (cookieValue == null || cookieValue.length === 0) {
    return null;
  }
  let json;
  try {
    json = atob(cookieValue);
  } catch (error) {
    // old cookies were not Base64 encoded
    return null;
  }
  return JSON.parse(json);
}

export function readCookie(name: string) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
}

export function eraseCookie(name: string) {
  createCookie(name, '', 0);
}
