import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../types';

export const selectIsBiometricallyVerified = createSelector(
  (state: RootState) => state.session.isBiometricallyVerified,
  isBiometricallyVerified => isBiometricallyVerified
);

export const selectArchivedOrderIDSet = createSelector(
  (state: RootState) => state.session.archivedOrderIDSet,
  archivedOrderIDSet => archivedOrderIDSet
);
