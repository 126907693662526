import type { ICellEditorParams } from 'ag-grid-enterprise';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Input } from '../Form/Input';
import type { FormControlProps } from '../Form/types';

export const AgGridInput = forwardRef(
  ({ size, node, colDef, parseValue, ...props }: ICellEditorParams & FormControlProps<HTMLInputElement>, ref) => {
    const [value, setValue] = useState(props.value);
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => {
      return {
        getValue: () => parseValue(value),
      };
    });

    useEffect(() => {
      setTimeout(() => {
        if (inputRef?.current != null) {
          inputRef.current.focus();
        }
      });
    }, []);

    const isRightAligned = ((colDef.cellClass ?? '') as string | string[]).includes('ag-right-aligned-cell'); // assumes we won't ever set the cellClass as a function ...

    return (
      <Input
        ref={inputRef}
        onChange={e => setValue(e.target.value)}
        value={value}
        size={size}
        style={{
          width: '100%',
          height: `${node.rowHeight}px`,
          background: props.context.current.theme?.backgroundBody,
        }}
        type={isRightAligned ? 'number' : 'text'}
      />
    );
  }
);
