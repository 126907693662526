import { Box, Button, ButtonVariants, Flex, IconButton } from '@talos/kyoko';
import styled, { createGlobalStyle, css } from 'styled-components';

export const DockViewStyles = createGlobalStyle`
  ${({ theme }) => css`
    .dockview-theme-dark {
      --dv-paneview-active-outline-color: var(--backgroundBody);
      --dv-tabs-and-actions-container-font-size: ${theme.fontSizeSmall}rem;
      --dv-tabs-and-actions-container-height: calc(${theme.baseSize * 1.5}px + 1px);
      --dv-tab-close-icon: var(--colorTextDefault);
      --dv-drag-over-background-color: var(--colors-blue-dim);
      --dv-drag-over-border-color: var(--colors-blue-lighten);
      --dv-tabs-container-scrollbar-color: var(--backgroundBody);
      --dv-group-view-background-color: var(--backgroundBody);
      --dv-tabs-and-actions-container-background-color: var(--backgroundFilledTabList);
      --dv-activegroup-visiblepanel-tab-background-color: var(--backgroundContent);
      --dv-activegroup-hiddenpanel-tab-background-color: var(--backgroundFilledTabList);
      --dv-inactivegroup-visiblepanel-tab-background-color: var(--backgroundContent);
      --dv-inactivegroup-hiddenpanel-tab-background-color: var(--backgroundFilledTabList);
      --dv-tab-divider-color: var(--backgroundBody);
      --dv-activegroup-visiblepanel-tab-color: var(--colorTextImportant);
      --dv-activegroup-hiddenpanel-tab-color: var(--colorTextSubtle);
      --dv-inactivegroup-visiblepanel-tab-color: var(--colorTextImportant);
      --dv-inactivegroup-hiddenpanel-tab-color: var(--colorTextSubtle);
      --dv-separator-border: var(--backgroundBody);
      --dv-paneview-header-border-color: var(--backgroundBody);
      --dv-icon-hover-background-color: var(--backgroundContentHover);
      --dv-floating-box-shadow: var(--backgroundShadow);
      --dv-active-sash-color: var(--backgroundContentHover);
      --dv-background-color: var(--backgroundBody);
      --dv-group-gap-size: ${theme.spacingLayout / 2}px;

      &.animating {
        .view {
          transition: width ease 0.2s, left ease 0.2s, height ease 0.2s, top ease 0.2s;
        }
      }

      .split-view-container.horizontal > .view-container > .view:not(:first-child)::before {
        width: 0;
      }

      .tabs-container {
        border-right: 1px solid var(--backgroundBody);
        scrollbar-width: none;
      }

      .tabs-and-actions-container {
        border-bottom: 1px solid var(--backgroundBody);

        .tabs-container .tab {
          min-width: 0;
        }
      }

      .tab {
        min-width: 0;

        .dv-default-tab-content {
          padding: 0;
        }

        .dv-default-tab-action {
          width: 0;
          padding: 0;
          transition: ease width 0.2s;
        }

        &:hover,
        &.active-tab {
          .dv-default-tab {
            gap: ${theme.spacingSmall}px;
          }

          .dv-default-tab-action {
            height: ${theme.baseSize}px;
            width: ${theme.baseSize}px;

            :hover .dockview-svg {
              color: var(--colorTextImportant);
            }
          }
        }

        &:focus-within,
        &:focus {
          &:after {
            outline: solid 1px var(--colorsWhiteDim) !important;
          }
        }
      }

      .dv-default-tab {
        min-width: 0;
      }

      .dockview-svg {
        color: var(--colorTextMuted);
        width: 8px;
        height: 8px;
      }

      .active-tab {
        .dockview-svg {
          color: var(--colorTextDefault);
        }
      }

      .dv-default-tab-content {
        padding: 0;
      }
    }
  `}
`;

export const GridLayoutWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex: 1 1 0;
`;

export const TabTypeButton = styled(Button)`
  justify-content: space-between;
`;

export const HeaderButton = styled(Button)<{ aligned: 'left' | 'right' }>`
  border-radius: 0;
  height: 100%;
  background: ${({ variant }) =>
    variant === ButtonVariants.Priority
      ? 'var(--backgroundDefaultButton)'
      : variant === ButtonVariants.Default
      ? 'transparent'
      : ''};
  &,
  &:hover,
  &:focus {
    ${({ aligned }) =>
      aligned === 'right'
        ? `border-left: 1px solid var(--backgroundBody);`
        : `border-right: 1px solid var(--backgroundBody);`}
  }
  &:focus,
  &:hover {
    background: ${({ variant }) =>
      variant === ButtonVariants.Priority
        ? 'var(--backgroundDefaultButton)'
        : variant === ButtonVariants.Default
        ? 'transparent'
        : ''};
  }
`;

export const TabIconButton = styled(IconButton)`
  border-radius: 0;
  margin-left: 1px;
  color: var(--colorTextDefault);

  &:hover {
    color: var(--colorTextImportant);
  }
`;

export const PageTitle = styled(Box)``;
PageTitle.defaultProps = {
  color: 'colorTextImportant',
  px: 'spacingDefault',
  fontSize: 'fontSizeSmall',
};
