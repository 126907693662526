import { useEffect } from 'react';
import { map } from 'rxjs/operators';
import { useCurrencyConversionContext } from '../contexts';
import type { CurrencyConversionRate } from '../types/CurrencyConversionRate';
import { useObservable, useObservableValue } from './useObservable';

export const useCurrencyConversionRate = (currency?: string, equivalentCurrency?: string) => {
  const { currencyRatesByCurrencyPair, registerSubscription, unregisterSubscription } = useCurrencyConversionContext();

  useEffect(() => {
    if (equivalentCurrency != null) {
      registerSubscription(currency, equivalentCurrency);
      return () => {
        unregisterSubscription(currency, equivalentCurrency);
      };
    }
  }, [currency, equivalentCurrency, registerSubscription, unregisterSubscription]);

  return useObservable<CurrencyConversionRate | undefined>(
    () =>
      currencyRatesByCurrencyPair?.pipe(
        map((rates: Map<string, CurrencyConversionRate>) => rates.get(`${currency}-${equivalentCurrency}`))
      ),
    [currencyRatesByCurrencyPair, currency, equivalentCurrency]
  );
};

export const useCurrencyConversionRateValue = (currency?: string, equivalentCurrency?: string) => {
  const obs = useCurrencyConversionRate(currency, equivalentCurrency);
  return useObservableValue(() => obs, [obs]);
};
