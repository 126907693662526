import { VStack } from '@talos/kyoko';
import { PortfolioControlHeader } from './PortfolioControlHeader';
import { PortfolioWorkspaceHeader } from './PortfolioWorkspaceHeader';

export const PortfolioDashboardHeader = () => {
  return (
    <VStack flex="none" gap="spacingTiny">
      <PortfolioWorkspaceHeader />
      <PortfolioControlHeader />
    </VStack>
  );
};
