import {
  Box,
  Button,
  ButtonVariants,
  CheckboxFuseAutocompleteDropdown,
  Flex,
  IconName,
  MultiSelectInput,
  SubAccountTypeEnum,
  useDropdownPopper,
  useMultiSelectAutocomplete,
  useRenderMultiSelectSelection,
  type SubAccount,
} from '@talos/kyoko';
import { useSubAccounts } from 'providers';
import { useCallback, useMemo, useRef, type Dispatch, type SetStateAction } from 'react';

const subAccountGetLabel = (subAccount: SubAccount) => subAccount.DisplayName;
const subAccountGetDescription = (subAccount: SubAccount) => subAccount.Type || '';

export function RollupMembersDropdown({
  rollupMembersToAdd,
  setRollupMembersToAdd,
  onAddRollupMembers,
  alreadySelected,
  subAccountType,
}: {
  rollupMembersToAdd: SubAccount[];
  setRollupMembersToAdd: Dispatch<SetStateAction<SubAccount[]>>;
  onAddRollupMembers: (SubAccounts: SubAccount[]) => void;
  alreadySelected: string[];
  subAccountType: SubAccountTypeEnum;
}) {
  const { allSubAccounts, allSubAccountRollups } = useSubAccounts();

  const allAccounts = useMemo(() => {
    if (allSubAccounts && allSubAccountRollups) {
      return (subAccountType === SubAccountTypeEnum.Rollup ? allSubAccounts : allSubAccountRollups).filter(
        account => !alreadySelected.includes(account.SubaccountID.toString())
      );
    }
    return [];
  }, [allSubAccountRollups, allSubAccounts, alreadySelected, subAccountType]);

  const inputRef = useRef<HTMLInputElement>(null);
  const { autocompleteOutput, multipleSelectionOutput } = useMultiSelectAutocomplete({
    selections: rollupMembersToAdd,
    items: allAccounts,
    getLabel: subAccountGetLabel,
    getDescription: subAccountGetDescription,
    initialSortByLabel: true,
    onChange: setRollupMembersToAdd,
    clearInputAfterSelection: false,
    removeItemOnInputBackspace: true,
    highlightInputTextAfterSelection: true,
    inputRef,
    disableFuzzyMatching: true,
  });

  const { isOpen, closeMenu, openMenu, getInputProps, getMenuProps } = autocompleteOutput;
  const { getDropdownProps, getSelectedItemProps, removeSelectedItem } = multipleSelectionOutput;
  const handleClickOutside = useCallback(() => closeMenu(), [closeMenu]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement: dropdownRef.current,
    dropdownWidth: '240px',
    dropdownPlacement: 'bottom-end',
    onClickOutside: handleClickOutside,
  });

  const renderMultiSelectItem = useRenderMultiSelectSelection({
    removeSelectedItem,
    getLabel: subAccountGetLabel,
    getSelectedItemProps,
  });

  const selectItems = useMemo(() => {
    return multipleSelectionOutput.selectedItems.map((item, index) => renderMultiSelectItem(item, index));
  }, [multipleSelectionOutput, renderMultiSelectItem]);

  return (
    <Flex gap="spacingSmall" w="100%">
      <Box flex={1}>
        <Box ref={dropdownRef} flex={1}>
          <MultiSelectInput
            {...getInputProps(getDropdownProps({ ref: inputRef }))}
            placeholder="Search SubAccount name..."
            onClick={openMenu}
            items={selectItems}
          />
        </Box>

        <div {...getMenuProps()}>
          <CheckboxFuseAutocompleteDropdown
            {...autocompleteOutput}
            {...dropdownPopper}
            selectedItems={multipleSelectionOutput.selectedItems}
            addSelectedItem={multipleSelectionOutput.addSelectedItem}
            removeSelectedItem={multipleSelectionOutput.removeSelectedItem}
            groupMaxHeight={400}
            maxHeight={400}
          />
        </div>
      </Box>
      <Button
        variant={ButtonVariants.Primary}
        startIcon={IconName.Plus}
        disabled={rollupMembersToAdd.length === 0}
        onClick={() => onAddRollupMembers(rollupMembersToAdd)}
      >
        Add
      </Button>
    </Flex>
  );
}
