import { ExecTypeEnum, IconName, MixpanelEvent } from '@talos/kyoko';
import { useUser } from 'hooks/useUser';
import { useExecutionReports } from 'providers/ExecutionReportsContext';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

const coinsSound = new Audio('/sounds/coins.mp3');
coinsSound.volume = 0.5;
const rejectedSound = new Audio('/sounds/rejected.mp3');
rejectedSound.volume = 0.6;
const canceledSound = new Audio('/sounds/canceled.mp3');
canceledSound.volume = 0.4;
const swipeSound = new Audio('/sounds/swipe.mp3');
swipeSound.volume = 0.3;

export interface SoundContextProps {
  playSound: (sound: SoundEnum, force?: boolean) => void;
}

export enum SoundEnum {
  Filled_Order = 'Coins',
  Rejected_Order = 'Rejected',
  Canceled_Order = 'Canceled',
  Archived_Order = 'Swipe',
}

export const INITIAL_SOUND_CONFIG = {
  [SoundEnum.Filled_Order]: true,
  [SoundEnum.Rejected_Order]: true,
  [SoundEnum.Canceled_Order]: true,
  [SoundEnum.Archived_Order]: true,
};

interface SoundSettingsContextProps {
  enableSoundEffects: boolean;
  enabledSounds: { [K in SoundEnum]: boolean };
  setEnableSoundEffects: (newValue: boolean) => void;
  setEnabledSounds: (newValue: { [K in SoundEnum]: boolean }) => void;
}
export const DefaultSoundSettingsConfig: SoundSettingsContextProps = {
  enableSoundEffects: false,
  enabledSounds: INITIAL_SOUND_CONFIG,
  setEnableSoundEffects: () => {},
  setEnabledSounds: () => {},
};

const SoundContext = createContext<SoundContextProps | null>(null);
SoundContext.displayName = 'SoundContext';
export const SoundSettingsContext = createContext<SoundSettingsContextProps>(DefaultSoundSettingsConfig);
SoundSettingsContext.displayName = 'SoundSettingsContext';
export const useSoundSettings = () => useContext(SoundSettingsContext);

export function useSounds() {
  const context = useContext(SoundContext);
  if (context == null) {
    throw new Error('Missing SoundContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const SOUND_OPTIONS = [
  {
    name: 'Order Filled',
    icon: IconName.VolumeUp,
    sound: SoundEnum.Filled_Order,
    mixpanelEvent: MixpanelEvent.EnableOrderFilledSound,
  },
  {
    name: 'Order Rejected',
    icon: IconName.VolumeUp,
    sound: SoundEnum.Rejected_Order,
    mixpanelEvent: MixpanelEvent.OrderRejectedSound,
  },
  {
    name: 'Order Canceled',
    icon: IconName.VolumeUp,
    sound: SoundEnum.Canceled_Order,
    mixpanelEvent: MixpanelEvent.OrderCanceledSound,
  },
  {
    name: 'Order Archived',
    icon: IconName.VolumeUp,
    sound: SoundEnum.Archived_Order,
    mixpanelEvent: MixpanelEvent.OrderArchivedSound,
  },
];

export const SoundProvider = function SoundProvider({ children }) {
  const { executionReportsByClOrdID } = useExecutionReports();
  const { Name: currUser } = useUser();

  const { enableSoundEffects, enabledSounds } = useSoundSettings();

  const playSound = useCallback(
    (sound: SoundEnum, force = false) => {
      // `Force` allows the provider to still play the sound from the settings page even if the sound is disabled
      if (force || (enableSoundEffects && enabledSounds[sound])) {
        switch (sound) {
          case SoundEnum.Filled_Order:
            return coinsSound.play();
          case SoundEnum.Rejected_Order:
            return rejectedSound.play();
          case SoundEnum.Canceled_Order:
            return canceledSound.play();
          case SoundEnum.Archived_Order:
            return swipeSound.play();
          default:
            return null;
        }
      }
    },
    [enableSoundEffects, enabledSounds]
  );

  const [processedIDs] = useState(new Set<string>());
  useEffect(() => {
    const subscription = executionReportsByClOrdID?.subscribe(data => {
      data.forEach(({ ExecType, ClOrdID, User }) => {
        if (processedIDs.has(ClOrdID) || currUser !== User) {
          return null;
        }
        processedIDs.add(ClOrdID);
        switch (ExecType) {
          case ExecTypeEnum.DoneForDay:
            return playSound(SoundEnum.Filled_Order);
          case ExecTypeEnum.Rejected:
            return playSound(SoundEnum.Rejected_Order);
          case ExecTypeEnum.Canceled:
            return playSound(SoundEnum.Canceled_Order);
          default:
            processedIDs.delete(ClOrdID);
            return null;
        }
      });
    });
    return () => subscription.unsubscribe();
  }, [currUser, executionReportsByClOrdID, playSound, processedIDs]);

  return <SoundContext.Provider value={{ playSound }}>{children}</SoundContext.Provider>;
};
