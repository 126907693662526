import { PositionUpdateSourceEnum, ProductTypeEnum } from '@talos/kyoko';
import type {
  DerivativesPositionsTabProps,
  PositionColumn,
  PositionsTableFilter,
  SpotPositionsTabProps,
  SubAccountPositionsTabProps,
  UnifiedPositionsTabProps,
} from './types';

export const defaultSpotBlotterColumns = [
  'reconWarning',
  { field: 'Asset', rowGroup: true, hide: true },
  'MarketAccount',
  'Amount',
  'AvgCost',
  'TotalPnL',
] satisfies PositionColumn[];

export const defaultSpotBlotterFilter: PositionsTableFilter = {
  AssetTypes: [ProductTypeEnum.Spot],
  Source: PositionUpdateSourceEnum.Internal,
};

export const defaultSpotBlotterTab: Omit<SpotPositionsTabProps, 'id' | 'label'> = {
  defaultColumns: defaultSpotBlotterColumns,
  defaultFilter: defaultSpotBlotterFilter,
  tabType: 'Spot',
  showZeroBalances: false,
  defaultRowGroupsOpened: {},
};

export const defaultDerivativesBlotterColumns = [
  'reconWarning',
  { id: 'underlying', rowGroup: true, hide: true },
  { id: 'instrument' },
  'MarketAccount',
  'Amount',
  'AvgCost',
  'MarkPrice',
  'UnderlyingPrice',
  'LiquidationPrice',
  'elpVsMp',
  'Delta',
  'Equivalent.Delta',
  'UnrealizedPnL',
  { field: 'AssetType', rowGroup: true, hide: true },
] satisfies PositionColumn[];

export const defaultDerivativesBlotterFilter: PositionsTableFilter = {
  Source: PositionUpdateSourceEnum.Gateway,
};

export const defaultDerivativesBlotterTab: Omit<DerivativesPositionsTabProps, 'id' | 'label'> = {
  defaultColumns: defaultDerivativesBlotterColumns,
  defaultFilter: defaultDerivativesBlotterFilter,
  tabType: 'Derivatives',
  showZeroBalances: false,
};

export const defaultUnifiedBlotterColumns = [
  'reconWarning',
  { id: 'MarketAccount', rowGroup: true, hide: true },
  'Asset',
  'Amount',
  'Equivalent.Amount',
  'AvgCost',
  'MarkPrice',
  'UnderlyingPrice',
  'LiquidationPrice',
  'elpVsMp',
  'UnrealizedPnL',
  'Equivalent.UnrealizedPnL',
] satisfies PositionColumn[];

export const defaultUnifiedBlotterFilter: PositionsTableFilter = {
  Source: PositionUpdateSourceEnum.Gateway,
};

export const defaultUnifiedBlotterTab: Omit<UnifiedPositionsTabProps, 'id' | 'label'> = {
  defaultColumns: defaultUnifiedBlotterColumns,
  defaultFilter: defaultUnifiedBlotterFilter,
  tabType: 'Unified',
  showZeroBalances: false,
  defaultRowGroupsOpened: {},
};

export const defaultSubAccountsBlotterColumns = [
  { field: 'SubAccount', id: 'SubAccount', rowGroup: true, hide: true },
  'Asset',
  'Amount',
  'Equivalent.Amount',
  'AvgCost',
  'MarkPrice',
  'Delta',
  'Equivalent.Delta',
  'Equivalent.CumFees',
  'Equivalent.UnrealizedPnL',
  'Equivalent.RealizedPnL',
  'TotalPnL',
] satisfies PositionColumn[];

export const defaultSubAccountsBlotterFilter: PositionsTableFilter = {};

export const defaultSubAccountsBlotterTab: Omit<SubAccountPositionsTabProps, 'id' | 'label'> = {
  defaultColumns: defaultSubAccountsBlotterColumns,
  defaultFilter: defaultSubAccountsBlotterFilter,
  tabType: 'Sub Accounts',
  showZeroBalances: false,
  defaultRowGroupsOpened: {},
};
