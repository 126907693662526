import styled, { keyframes } from 'styled-components';

import { modeColor } from '../../styles';
import { CardViewEnum } from '../../tokens';
import { SideEnum } from '../../types';
import { Flex } from '../Core/Flex';

import { CARD_HEIGHT, COLUMN_WIDTH, COMPACT_CARD_HEIGHT, EXPANDED_CARD_HEIGHT } from '../../styles/dimensions';
import { Box } from '../Core';

export const CardLabel = styled.div`
  background: ${({ theme }) => theme.colors.primary.default};
  color: ${({ theme }) => theme.colors.white.default};
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingSmall + theme.spacingTiny}px`};
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  margin: ${({ theme }) => -theme.spacingTiny}px;
`;

export const Header = styled.div<{ isDraggable: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray['100']};
  padding: ${({ theme }) => `${theme.spacingMedium}px ${theme.spacingMedium}px 0`};
  cursor: ${({ isDraggable }) => (isDraggable ? 'grab' : 'default')};

  &:active {
    cursor: ${({ isDraggable }) => (isDraggable ? 'grabbing' : 'default')};
  }
`;

export const SubHead = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacingMedium}px`};
`;

export const LeftSlot = styled(Flex)`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-wrap: wrap;
  margin-right: auto;
`;

export const CenterSlot = styled(Flex)`
  min-width: 1px;
  flex: 0 1 auto;
`;

export const RightSlot = styled(Flex)`
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacingSmall}px;
`;

export const Wrapper = styled(Box)<{
  expanded: boolean;
  view: string;
  isDragged?: boolean;
}>`
  position: relative;
  width: ${COLUMN_WIDTH}px;
  height: ${({ expanded, view }) =>
    view === CardViewEnum.COMPACT ? COMPACT_CARD_HEIGHT : expanded ? EXPANDED_CARD_HEIGHT : CARD_HEIGHT}px;
  display: flex;
  flex-direction: column;
  ${({ isDragged, theme }) =>
    isDragged ? `box-shadow: 0 5px 24px ${modeColor(theme, 'hsla(0, 0%, 0%, 0.1)', theme.colors.black.dim)};` : ''}
`;
Wrapper.defaultProps = {
  background: 'backgroundContent',
};

const placeholderAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PlaceholderWrapper = styled.button.attrs({
  type: 'button',
})`
  animation: ${placeholderAnimation} 300ms;
  position: relative;
  width: ${COLUMN_WIDTH}px;
  height: ${CARD_HEIGHT}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacingMedium}px;
  margin: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingSmall}px ${theme.spacingSmall}px 0`};
  background: none;
  border: 1px dashed ${({ theme }) => theme.colors.gray['050']};
  color: ${({ theme }) => theme.colors.gray['100']};
  cursor: pointer;
  transition: 150ms;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;

  svg {
    transition: 150ms;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.gray['090']};

    svg {
      transform: scale(1.1);
    }
  }

  &:focus {
    outline: none;
    border: 1px dashed ${({ theme }) => theme.colors.gray['080']};
  }
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${({ theme }) => theme.spacingSmall}px;
  margin-bottom: ${({ theme }) => theme.spacingDefault}px;
  padding: 0 ${({ theme }) => theme.spacingMedium}px;
`;

const summaryAnimation = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const Summary = styled(Box)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > div {
    animation: ${summaryAnimation} 150ms;
  }

  i {
    vertical-align: text-top;
  }
`;

export const Side = styled.span<{ side: string }>`
  color: ${({ side, theme }) => (side === SideEnum.Sell ? theme.colors.red.lighten : theme.colors.green.lighten)};
`;

export const FormWrapper = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacingMedium}px ${theme.spacingMedium}px ${theme.spacingDefault}px`};
  height: 100%;
`;

export const ButtonActions = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacingDefault}px ${theme.spacingMedium}px`};
  margin: ${({ theme }) => `0 -${theme.spacingMedium}px -${theme.spacingDefault}px`};
  border-top: ${({ theme }) => `solid 1px ${theme.colors.gray['040']}`};

  button {
    flex: 1;
  }

  button:first-child {
    margin-right: 10px;
  }
`;

export const ExpandDetails = styled.button.attrs(() => ({ type: 'button' }))`
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  left: ${({ theme }) => theme.spacingMedium}px;
  color: ${({ theme }) => theme.colors.gray['090']};
  gap: ${({ theme }) => theme.spacingSmall}px;
`;

export const DetailsWrapper = styled.div`
  padding: 0 ${({ theme }) => `${theme.spacingMedium}px ${theme.spacingMedium}px`};
  max-height: 191px;
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray['080']};
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
`;
