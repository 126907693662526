import { isNil } from 'lodash';
import type { RequiredProperties } from '../utils/types';
import type { Security } from './Security';
import { ProductTypeEnum } from './types';

export const isOptionSecurity = (security: Security): security is OptionSecurity =>
  security.ProductType === ProductTypeEnum.Option &&
  !isNil(security.Expiration) &&
  !isNil(security.StrikePrice) &&
  //!isNil(security.UnderlyingSymbol) &&
  // TODO(robertz): Add Security.UnderlyingQuoteCurrency
  //!isNil(security.UnderlyingQuoteCurrency) &&
  !isNil(security.QuoteCurrency) &&
  !isNil(security.BaseCurrency) &&
  !isNil(security.OptionType);

export type OptionSecurity = RequiredProperties<
  Security,
  'Expiration' | 'StrikePrice' | 'OptionType' /*| 'UnderlyingSymbol' /* | 'UnderlyingQuoteCurrency'*/
>;

export type RepresentativeOptionSecurity = Pick<OptionSecurity, 'SettlementCurrency'>;
