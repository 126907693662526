import type { CellEvent, ValueFormatterService, ValueService } from 'ag-grid-community';

interface AgGridGetCellValueParams<TData, TValue> {
  api: CellEvent<TData, TValue>['api'];
  column: CellEvent<TData, TValue>['column'] | null;
  columnApi: CellEvent<TData, TValue>['columnApi'] | null;
  node?: CellEvent<TData, TValue>['node'] | null | undefined;
  value: CellEvent<TData, TValue>['value'];
}

/**
 * This function provides a method to get the "display value" of an ag-grid cell, using the same method
 * that ag-grid uses internally.
 *
 * @param params AgGrid params object (e.g. from an event callback, etc.)
 * @returns Value as formatted for display in an ag-grid cell (e.g. using `valueFormatter`, etc.)
 */
export function getCellDisplayValue<TData = any, TValue = any>(params: AgGridGetCellValueParams<TData, TValue>) {
  const column = params.node?.rowGroupColumn ?? params.column;
  if (!column) {
    return params.value;
  }

  try {
    const valueFormatterService = params.api.__getContext().getBean('valueFormatterService') as ValueFormatterService;
    // Emulate ag-grid's method of calling the value formatter service
    const formattedValue = valueFormatterService.formatValue(column, params.node ?? null, params.value);
    return formattedValue ?? params.value; // This is the logic used internally by ag-grid
  } catch {
    return params.value;
  }
}

/**
 * This function provides a method to get the "filter value" of an ag-grid cell, using the same method
 * that ag-grid uses internally.
 *
 * @param params AgGrid params object (e.g. from an event callback, etc.)
 * @returns Value that ag-grid will use when filtering this cell (e.g. using `filterValueGetter`, etc.)
 */
export function getCellFilterValue<TData = any, TValue = any>(params: AgGridGetCellValueParams<TData, TValue>) {
  if (!params.column) {
    return params.value;
  }

  try {
    const valueService = params.api.__getContext().getBean('valueService') as ValueService;
    // Emulate ag-grid's method of calling the value service
    const filterValue = valueService.getValue(params.column, params.node ?? null, true);
    return filterValue;
  } catch {
    return params.value;
  }
}
