import { SubAccountReconMatch } from '@talos/kyoko';

export function buildReconMatchRows(reconMatch: SubAccountReconMatch): ReconMatchRow[] {
  // From the single recon match backend entity, we build two rows. One for the sub account "ledger event(s)", and one for the
  // market account "ledger event"
  const subAccountRow = new ReconMatchSubAccountRow(reconMatch);
  const marketAccountRow = new ReconMatchMarketAccountRow(reconMatch);

  return [subAccountRow, marketAccountRow];
}

export interface ReconMatchRow extends SubAccountReconMatch {
  marketAccount?: string;
  provider?: string;
  amount?: string;
  avgCost?: string;
  avgCostCurrency?: string;

  rowID: string;
}

export class ReconMatchSubAccountRow extends SubAccountReconMatch implements ReconMatchRow {
  get marketAccount() {
    return this.MarketAccountEvent?.MarketAccount;
  }

  get rowID() {
    return `${this.ID}-sub-account`;
  }

  get provider() {
    return 'Talos'; // sub account rows are hardcoded to this
  }

  get amount() {
    return this.SubAccountEvents?.Amount;
  }

  get avgCost() {
    return this.SubAccountEvents?.Events.at(0)?.AvgCost;
  }

  get avgCostCurrency() {
    return this.SubAccountEvents?.Events.at(0)?.AvgCostCurrency;
  }
}

export class ReconMatchMarketAccountRow extends SubAccountReconMatch implements ReconMatchRow {
  get rowID() {
    return `${this.ID}-market-account`;
  }

  get marketAccount() {
    return this.MarketAccountEvent?.MarketAccount;
  }

  get provider() {
    return this.marketAccount;
  }

  get amount() {
    return this.MarketAccountEvent?.Amount;
  }

  get avgCost() {
    return this.MarketAccountEvent?.AvgCost;
  }

  get avgCostCurrency() {
    return this.MarketAccountEvent?.AvgCostCurrency;
  }
}
