import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  primary: {
    mute: 'rgba(49, 74, 140, 0.1)',
    dim: 'rgba(49, 74, 140, 0.4)',
    default: '#314A8C',
    lighten: '#4B64A6',
  },

  black: {
    default: '#282828',
    dim: 'rgba(40, 40, 40, 0.4)',
    mute: 'rgba(40, 40, 40, 0.1)',
  },

  white: {
    default: '#eaeaea',
    dim: 'rgba(234, 234, 234, 0.4)',
    mute: 'rgba(234, 234, 234, 0.05)',
  },

  green: {
    default: '#2CEC9A',
    mute: 'rgba(44, 236, 154, 0.1)',
    dim: 'rgba(44, 236, 154, 0.4)',
    lighten: '#56f0ae',
  },

  red: {
    default: '#FF7171',
    mute: 'rgb(255, 113, 113, 0.1)',
    dim: 'rgb(255, 113, 113, 0.4)',
    lighten: '#ff8d8d',
  },

  blue: {
    default: '#2B6FD5',
    mute: 'rgb(43, 111, 213, 0.1)',
    dim: 'rgb(43, 111, 213, 0.4)',
    lighten: '#558cdd',
  },
  orange: {
    default: '#FF9426',
    mute: 'rgb(255, 148, 38, 0.1)',
    dim: 'rgb(255, 148, 38, 0.4)',
    lighten: '#ffa951',
  },

  yellow: {
    default: '#FF9426',
    mute: 'rgb(255, 148, 38, 0.1)',
    dim: 'rgb(255, 148, 38, 0.4)',
    lighten: '#ffa951',
  },

  gray: darkColors.gray,
  purple: darkColors.purple,
};

export const DarkBitpandaTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkBP',

  fontFamily: "'Inter', sans-serif",
  fontUrl: 'https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  backgroundHeader: colors.gray.main,
  borderColorHeader: 'transparent',

  backgroundSidebar: colors.gray.main,

  backgroundPrimaryButton: colors.white.default,
  backgroundPrimaryButtonHover: setAlpha(0.9, colors.white.default),
  backgroundPrimaryButtonActive: setAlpha(0.9, colors.white.default),
  backgroundPrimaryButtonFocus: setAlpha(0.8, colors.white.default),
  backgroundPrimaryButtonDisabled: setAlpha(0.6, colors.white.default),

  colorTextPrimary: '#FFFFFF',
  colorTextPrimaryButton: colors.black.default,
  colorTextPrimaryButtonHover: colors.black.default,
  colorTextPrimaryButtonFocus: colors.black.default,
  colorTextPrimaryButtonActive: colors.black.default,
  colorTextPrimaryButtonDisabled: colors.black.default,

  colorTextPositive: colors.green.default,
  colorTextPositiveButton: colors.black.default,
  colorTextPositiveButtonHover: colors.black.default,
  colorTextPositiveButtonFocus: colors.black.default,
  colorTextPositiveButtonActive: colors.black.default,
  colorTextPositiveButtonDisabled: colors.black.default,

  colorTextNegative: colors.red.default,
  colorTextNegativeButton: colors.black.default,
  colorTextNegativeButtonHover: colors.black.default,
  colorTextNegativeButtonFocus: colors.black.default,
  colorTextNegativeButtonActive: colors.black.default,
  colorTextNegativeButtonDisabled: colors.black.default,

  colorTextWarning: colors.orange.default,
  colorTextWarningButton: colors.black.default,
  colorTextWarningButtonHover: colors.black.default,
  colorTextWarningButtonFocus: colors.black.default,
  colorTextWarningButtonActive: colors.black.default,
  colorTextWarningButtonDisabled: colors.black.default,
});
