import {
  type Column,
  type ColumnDef,
  EMPTY_ARRAY,
  getAgGridColId,
  NumberVariants,
  toBigWithDefault,
  useDefaultColumns,
} from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';
import type { SubAccountReconOverviewRow } from './rows';

export type ReconOverviewBlotterColumnIDs = 'Asset' | 'Status';

export const useSubAccountReconOverviewBlotterColumns = (defaultColumns = EMPTY_ARRAY) => {
  const columnDefinitions: Map<string, Column> = useMemo(() => {
    const defsArr = [
      {
        type: 'asset',
        id: 'Asset' satisfies ReconOverviewBlotterColumnIDs,
        field: 'Asset',
        params: {
          colorful: true,
        },
      },
      { type: 'text', field: 'Status' satisfies ReconOverviewBlotterColumnIDs },
      {
        type: 'number',
        field: 'Breaks',
      },
      {
        type: 'size',
        field: 'SubAccountAmount',
        params: {
          currencyField: 'Asset',
        },
      },
      {
        type: 'size',
        field: 'MarketAccountAmount',
        params: {
          currencyField: 'Asset',
        },
      },
      {
        type: 'size',
        field: 'BreakAmount',
        params: {
          currencyField: 'Asset',
          getSentiment: (params: ICellRendererParams<SubAccountReconOverviewRow>) => {
            const data = params.node?.data;
            if (data != null && 'BreakAmount' in data) {
              const hasBreak = !toBigWithDefault(data.BreakAmount, 0).eq(0);
              return hasBreak ? NumberVariants.Warning : undefined;
            }

            return undefined;
          },
        },
      },
    ] satisfies ColumnDef<SubAccountReconOverviewRow>[];

    return new Map(defsArr.map(def => [getAgGridColId(def), def]));
  }, []);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};
