import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  EMPTY_OBJECT,
  Flex,
  FormControlSizes,
  IconButton,
  IconName,
  type RowGroupsOpenedState,
  useAccordionFilterBuilder,
  useBlotterTable,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  useTreeGroupColumnDef,
} from '@talos/kyoko';
import type { GridOptions } from 'ag-grid-community';
import { useMemo } from 'react';
import { RECON_OVERVIEW_BLOTTER_PORTAL_ID, RECON_OVERVIEW_BLOTTER_PORTAL_ID_MODAL } from '../tokens';
import type { ReconAssetRow, SubAccountReconOverviewRow } from './rows';
import {
  type ReconOverviewBlotterColumnIDs,
  useSubAccountReconOverviewBlotterColumns,
} from './useSubAccountReconOverviewBlotterColumns';
import { useSubAccountReconOverviewBlotterFilter } from './useSubAccountReconOverviewBlotterFilter';
import { useSubAccountReconOverviewBlotterMenu } from './useSubAccountReconOverviewBlotterMenu';
import { useSubAccountReconOverviewDataObs } from './useSubAccountReconOverviewDataObs';

interface SubAccountReconOverviewBlotterProps {
  /**
   * Called when the user clicks on the Deepdive/Details button of a ReconAssetRow.
   * Don't provide this if you don't want the blotter to render any of these buttons.
   */
  onViewCheckpointDetails?: (assetRow: ReconAssetRow) => void;
  blotterID: string;
  checkpointIDs?: string[];
  /** Whether or not were rendering this blotter in the modal. Does some different stuff */
  isInModal?: boolean;
}

function getDataPath(data: SubAccountReconOverviewRow) {
  return data.dataPath;
}

// Matching column ids
const SEARCH_KEYS: string[] = ['Asset', 'Status'] satisfies ReconOverviewBlotterColumnIDs[];

export const SubAccountReconOverviewBlotter = ({
  onViewCheckpointDetails,
  blotterID,
  checkpointIDs,
  isInModal,
}: SubAccountReconOverviewBlotterProps) => {
  const defaultColumns = useSubAccountReconOverviewBlotterColumns();
  const defaultFilter = EMPTY_OBJECT;
  const defaultRowGroupsOpened: RowGroupsOpenedState = EMPTY_OBJECT;

  const persistedBlotterTable = usePersistedBlotterTable<SubAccountReconOverviewRow>(blotterID, {
    columns: defaultColumns,
    filter: defaultFilter,
    sort: `+${AGGRID_AUTOCOLUMN_ID}`,
  });

  const { filter, initialFilterClauses, filterableProperties, onFilterClausesChanged } =
    useSubAccountReconOverviewBlotterFilter({ persistedBlotterTable });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps: {
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged,
    },
  });

  const { dataObservable } = useSubAccountReconOverviewDataObs({ filter, checkpointIDs });

  const autoGroupColumnDef = useTreeGroupColumnDef<SubAccountReconOverviewRow>({
    headerName: 'Sub Accounts - Asset - Market Accounts',
    width: 300,
  });

  const {
    columns: menuColumns,
    getContextMenuItems,
    dialogs,
  } = useSubAccountReconOverviewBlotterMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties,
    onDetailsClicked: onViewCheckpointDetails,
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...menuColumns],
    [persistedBlotterTable.columns, menuColumns]
  );

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'rowID' satisfies keyof SubAccountReconOverviewRow,
    ...persistedBlotterTable,
    columns: columnsWithMenu,
    sort: persistedBlotterTable.initialSort,
    rowSelection: 'multiple',
    ...persistedRowGroupsOpened.blotterTableProps,
    getContextMenuItems,
    groupDefaultExpanded: -1, // expand all rows by default
    quickSearchParams: {
      otherSearchKeys: SEARCH_KEYS,
    },
    ...({ autoGroupColumnDef, treeData: true, getDataPath: getDataPath } satisfies GridOptions),
  });

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  return (
    <Flex flexDirection="column" w="100%" h="100%" data-testid="sub-account-recon-overview-blotter">
      <BlotterTableFilters
        showFilterBuilder={false}
        {...filterBuilderAccordion}
        {...blotterTable.blotterTableFiltersProps}
        portalId={isInModal ? RECON_OVERVIEW_BLOTTER_PORTAL_ID_MODAL : RECON_OVERVIEW_BLOTTER_PORTAL_ID}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
      />
      <BlotterTable {...blotterTable} />
      {dialogs}
    </Flex>
  );
};
