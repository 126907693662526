import type { PickByType } from './types';

type AllowedKeyTypes = string | undefined;
export type IndexKeys<T> = keyof PickByType<T, AllowedKeyTypes>; // grab all keys of T, eg "market", which resolve to any of the AllowedKeyTypes: "market": string
export type EntryOrMap<T> = T | MapOfEntryOrMap<T>;
export type MapOfEntryOrMap<T> = Map<T[IndexKeys<T>], EntryOrMap<T>>;

interface NestedMapParams<T> {
  items: T[];
  indexKeys: IndexKeys<T>[];
}
/**
 * A NestedMap allows you to create maps with any level of nesting dynamically without the hastle of doing
 * Map<string, Map<string, Map<string ... etc into oblivion. Instead, simply pass your Data[] and a list of indexing keys[].
 * Entries are gotten by doing a .get(["ETH", "coinbase"]), for example
 */
export class NestedMap<T> {
  readonly map: MapOfEntryOrMap<T>;
  readonly indexKeys: IndexKeys<T>[];

  /**
   * @param items The items to place into the NestedMap
   * @param indexKeys The keys to use for placing items into the map
   */
  constructor({ items, indexKeys }: NestedMapParams<T>) {
    this.indexKeys = indexKeys;
    this.map = new Map();

    for (const item of items) {
      this.setEntry(item);
    }
  }

  clone(withData = true) {
    return new NestedMap<T>({
      items: withData ? this.deepValues() : [],
      indexKeys: this.indexKeys,
    });
  }

  /**
   * Clears the map entirely, just like the native Map.clear() method.
   */
  clear() {
    this.map.clear();
  }

  /**
   * Returns the size of the *first level* of the NestedMap. Does not return the amount of entries in the map.
   */
  shallowSize(): number {
    return this.map.size;
  }

  /**
   * Returns the amount of entries T found throughout the NestedMap.
   */
  deepSize(): number {
    return this.deepValues().length;
  }

  /**
   * Given the entryKeys passed, will traverse the tree and return the element that is arrived at,
   * either being a Map, a leaf node T, or undefined if nothing is found.
   * If you are looking for a traditional Map.get() operation, see NestedMap.getEntry()
   */
  getMapOrEntry(entryKeys: T[IndexKeys<T>][]): EntryOrMap<T> | undefined {
    let entryOrMap: EntryOrMap<T> = this.map;
    const layers = entryKeys.length;
    for (let layer = 0; layer < layers; layer++) {
      const entryKey = entryKeys[layer];
      const layerEntry: EntryOrMap<T> | undefined = entryOrMap.get(entryKey);

      if (layerEntry instanceof Map) {
        // We found another nested map here, go again!
        entryOrMap = layerEntry;
      } else {
        // This layer is no longer a map. It is either T or undefined.
        // Just return it, we either found it or we didnt
        return layerEntry;
      }
    }

    return entryOrMap;
  }

  /**
   * Equivalent to the .get operation on a traditional Map, either returns an entry T, or undefined.
   * If the entryKeys passed reach a non-leaf layer of the NestedMap, and the key(s) resolve to a Map,
   * undefined will be return instead of the map.
   */
  getEntry(entryKeys: T[IndexKeys<T>][]): T | undefined {
    const maybeEntry = this.getMapOrEntry(entryKeys);
    return maybeEntry instanceof Map ? undefined : maybeEntry;
  }

  setEntry(item: T) {
    const layers = this.indexKeys.length;

    let entryOrMap: EntryOrMap<T> = this.map;
    for (let layer = 0; layer < layers; layer++) {
      const typeKey = this.indexKeys[layer]; // eg "currency"
      const entryKey = item[typeKey]; // eg "ETH"

      const layerEntry: EntryOrMap<T> | undefined = entryOrMap.get(entryKey);
      if (layerEntry instanceof Map) {
        // We found another nested map here, go again!
        entryOrMap = layerEntry;
      } else {
        // We did **not** find another nested map here, so its either undefined or a <T> value
        // If we're on the last layer, simply insert our item
        // If we are not on the last layer, insert a new map and prime it to go on the next loop
        const isLastLayer = layer === layers - 1;

        if (isLastLayer) {
          entryOrMap.set(entryKey, item);
        } else {
          const newMap = new Map() as MapOfEntryOrMap<T>;
          entryOrMap.set(entryKey, newMap);
          entryOrMap = newMap;
        }
      }
    }
  }

  setEntryOrMap(itemOrMap: EntryOrMap<T>, keys: T[IndexKeys<T>][]) {
    // In this version of the set operation, we have the user pass in the set of keys, eg ["ETH"],
    // traverse to that destination, and then just naively set the element there
    const layers = keys.length;

    let entryOrMap: EntryOrMap<T> = this.map;
    for (let layer = 0; layer < layers; layer++) {
      const entryKey = keys[layer]; // key is for example "ETH"

      const layerEntry: EntryOrMap<T> | undefined = entryOrMap.get(entryKey);
      if (layerEntry instanceof Map) {
        // We found another nested map here, go again!
        entryOrMap = layerEntry;
      } else {
        // We did **not** find another nested map here, so its either undefined or a <T> value
        // If we're on the last layer, simply insert our item
        // If we are not on the last layer, insert a new map and prime it to go on the next loop
        const isLastLayer = layer === layers - 1;

        if (isLastLayer) {
          entryOrMap.set(entryKey, itemOrMap);
        } else {
          const newMap = new Map() as MapOfEntryOrMap<T>;
          entryOrMap.set(entryKey, newMap);
          entryOrMap = newMap;
        }
      }
    }
  }

  /**
   * Traverses the nested map and picks out all leaf nodes T, and then returns them in a flat list of T[]
   */
  deepValues(): T[] {
    return nestedMapValues(this.map);
  }

  /**
   *
   * @returns A list of all the keys found at this level of the NestedMap
   */
  shallowKeys(): T[IndexKeys<T>][] {
    return [...this.map.keys()];
  }

  /**
   * Executes a callback on each entry found at the first level of the NestedMap, and returns the results in a list
   * @param callback A function that takes the entry, and returns a value
   * @returns A list of the results of the callback
   */
  shallowMap<U>(callback: (entryOrMap: EntryOrMap<T>) => U): U[] {
    return this.shallowKeys().map(key => callback(this.map.get(key)!));
  }

  /**
   * Executes a callback on each entry of the NestedMap (at all levels), and returns the results in a list
   * @param callback A function that takes the entry, and returns a value
   * @returns A list of the results of the callback
   */
  deepMap<U>(callback: (entryOrMap: T) => U): U[] {
    return this.deepValues().map(callback);
  }
}

export function nestedMapValues<T>(entryOrMap: EntryOrMap<T>): T[] {
  if (entryOrMap instanceof Map) {
    const keys = [...entryOrMap.keys()];
    return keys.map(key => nestedMapValues(entryOrMap.get(key)!)).flat();
  } else {
    return [entryOrMap];
  }
}

export function nestedMapSum<T>(entryOrMap: EntryOrMap<T>, getValue: (t: T) => number, summation = 0): number {
  if (entryOrMap instanceof Map) {
    return [...entryOrMap.values()].reduce(
      (summation, childThingOrMap) => nestedMapSum(childThingOrMap, getValue, summation),
      summation
    );
  } else {
    // thingOrMap is a thing
    return getValue(entryOrMap) + summation;
  }
}

/**
 * Checks whether or not a map contains another layer of maps, or is a "leaf map"
 * @param map the map which either contains another layer of maps, or a layer of entries
 * @returns a boolean if items are found, undefined if the map's size is 0
 */
export function isLeafMap<T>(map: MapOfEntryOrMap<T>): boolean | undefined {
  if (map.size === 0) {
    return undefined;
  }

  const firstValue = map.values().next().value;
  return !(firstValue instanceof Map);
}
