import type { Leaves } from '@talos/kyoko';
import { MergedNestedMap } from '@talos/kyoko';
import Big from 'big.js';
import { get, set } from 'lodash';
import type { BalancesBlotterShowBy } from '../../Blotters/BalancesV2/types';
import { DEV_NOGROUP_GROUP } from './tokens';
import type { MergedBalance, MergedBalanceIndexKeys } from './types';
import { TreasuryBalance } from './types';

export function mergeBalancesByKeys(
  keys: MergedBalanceIndexKeys[],
  balances: TreasuryBalance[]
): MergedNestedMap<MergedBalance> {
  const mergedBalances = balances.flatMap(balance => (balance.Amount === '' ? [] : createMergedBalance(balance)));
  return new MergedNestedMap({
    items: mergedBalances,
    indexKeys: keys,
    merge: mergeDoubleBalances,
    allKeys: ['currency', 'group', 'marketAccountID'],
  });
}

export function mergeDoubleBalances(balance1: MergedBalance, balance2: MergedBalance): MergedBalance {
  const keys = Object.keys(balance1) as Array<keyof MergedBalance>;

  const mergedBalance = { ...balance1 };

  for (const key of keys) {
    if (key === 'netAmount' || key === 'netEquivalentAmount') {
      mergedBalance[key] = Big(balance1[key]).plus(Big(balance2[key])).toNumber();
    } else if (key === 'positive' || key === 'negative') {
      mergedBalance[key] = mergeBalances(balance1[key], balance2[key]);
    }
  }

  return mergedBalance;
}

const mergeKeys = [
  'Amount',
  'AvailableAmount',
  'OutstandingBuy',
  'OutstandingSell',
  'Equivalent.Amount',
  'Equivalent.AvailableAmount',
  'Equivalent.OutstandingBuy',
  'Equivalent.OutstandingSell',
] satisfies Leaves<TreasuryBalance>[];

/**
 * Merges the amounts of the two balances together.
 * The metadata on the balance objects will be taken from balance1
 * If two balances are being merged, the only aspects we care about are the amounts. So it doesnt matter that the metadata is inconsistent
 * @param balance1
 * @param balance2
 * @returns a brand new Balance object
 */
export function mergeBalances(balance1?: TreasuryBalance, balance2?: TreasuryBalance): TreasuryBalance {
  if (!balance1 || !balance2) {
    return (balance1 ? balance1 : balance2) as TreasuryBalance; // todo can we type it such that a MergedBalance must have either pos or neg property?
  }
  const mergedBalance = new TreasuryBalance(
    balance1,
    {
      Amount: '0',
      AvailableAmount: '0',
      OutstandingBuy: '0',
      OutstandingSell: '0',
      Currency: balance1.EquivalentCurrency,
    },
    balance1.EquivalentCurrency
  );

  for (const key of mergeKeys) {
    const mergedValue = Big(get(mergedBalance, key) || 0)
      .add(Big(balance2[key] || 0))
      .toFixed();
    set(mergedBalance, key, mergedValue);
  }

  return mergedBalance;
}

export function createMergedBalance(balance: TreasuryBalance): MergedBalance {
  const amount = Big(balance.Amount || '0');
  const equivalentAmount = Big(balance.Equivalent.Amount || '0');

  return {
    positive: amount.gt(0) ? balance : undefined,
    negative: amount.lt(0) ? balance : undefined,
    netAmount: amount.toNumber(),
    netEquivalentAmount: equivalentAmount.toNumber(),
    currency: balance.Currency,
    market: balance.Market,
    marketAccountID: balance.MarketAccountID.toString(),
    // In order to get the nested map to be able to group by an optional property "group", gotta set it to something if its undefined
    group: balance.marketAccountGroup ?? DEV_NOGROUP_GROUP,
  };
}

export function getUniqueMergedBalanceKey({ currency, market, marketAccountID }: MergedBalance): string {
  return `${currency}-${market}-${marketAccountID}`;
}

export function getTreasuryManagementPercentageString(total: number, part: number): string {
  if (total === 0 || part === 0) {
    return '';
  }
  const float = Math.abs(Math.floor((Math.abs(part) / Math.abs(total)) * 10000) / 100);
  if (float < 0.1) {
    return '<0.1%';
  } else {
    return float + '% ';
  }
}

// Simply returns the opposite of the passed-in showBy
export function showByOpposite(showBy: BalancesBlotterShowBy): BalancesBlotterShowBy {
  return showBy === 'asset' ? 'counterparty' : 'asset';
}

export function stringifyKey(key: string | number): string {
  return typeof key === 'string' ? key : key.toString();
}
