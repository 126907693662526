import { useMemo, type ReactNode } from 'react';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { getIntlKey } from '../../contexts';
import { useDynamicCallback, useIntl, useOrderIncrements } from '../../hooks';
import { parseColor } from '../../styles';
import { SideEnum, type CustomerOrder, type Order, type Security } from '../../types';
import { getPricingReference, isOrderComplete } from '../../utils';
import { InlineFormattedNumber } from '../FormattedNumber';
import { Icon, IconName } from '../Icons';
import { PriceInlineFormatted } from '../SmartSummary';
import { Text } from '../Text';
import { MinTd, OrderSide, Summary, Table, Tbody, Td } from './styles';

interface OrderCardSummaryProps
  extends Partial<
    Pick<
      Order,
      | 'PricingReference'
      | 'PricingMode'
      | 'Allocation'
      | 'OrderQty'
      | 'Currency'
      | 'OrdStatus'
      | 'Price'
      | 'CumQty'
      | 'AvgPxAllIn'
      | 'AvgPx'
    >
  > {
  OrdStatus: Order['OrdStatus'];
  Side: Order['Side'];
  security: Security;
  showFees: boolean;
  customerOrder?: CustomerOrder;
  showGroup?: boolean;

  tableDetails?: { id: string; label: ReactNode; value: ReactNode }[];

  subAccountDetails?: ReactNode;
}

const messages = defineMessages({
  filled: {
    id: 'OrderCard.filled',
    defaultMessage: 'Filled',
  },
  customerFilled: {
    id: 'OrderCard.customerFilled',
    defaultMessage: 'Customer Filled',
  },
  buy: {
    defaultMessage: 'Buy',
    id: 'OrderCard.buy',
  },
  sell: {
    defaultMessage: 'Sell',
    id: 'OrderCard.sell',
  },
  customer: {
    defaultMessage: 'Customer',
    id: 'OrderCard.customer',
  },
});

export const OrderCardSummary = (props: OrderCardSummaryProps) => {
  const {
    Side,
    OrderQty,
    Currency = '',
    OrdStatus,
    Price,
    CumQty,
    AvgPxAllIn,
    AvgPx,
    security,
    showFees,
    customerOrder,
    subAccountDetails,
    tableDetails,
  } = props;

  const { QuoteCurrency, PriceDisplaySpec, DefaultPriceIncrement } = security;
  const orderIncrements = useOrderIncrements({
    Currency,
    Symbol: security.Symbol,
  });

  const theme = useTheme();
  const inlineFormattedNumberBg = parseColor(theme, isOrderComplete(OrdStatus) ? 'gray.010' : 'gray.main');
  const { formatMessage } = useIntl();

  const renderedTableDetails = useMemo(() => {
    return tableDetails?.map(detail => (
      <tr key={detail.id}>
        <Td>{detail.label}</Td>
        <MinTd />
        <MinTd />
        <MinTd style={{ color: theme.colorTextImportant }}>{detail.value}</MinTd>
      </tr>
    ));
  }, [tableDetails, theme.colorTextImportant]);

  const getOrderSummaryRow = useDynamicCallback((isCustomerRow: boolean) => {
    const priceToDisplay = !isCustomerRow ? Price : customerOrder?.Price;
    return (
      <>
        <tr>
          <Td>
            <OrderSide isBuy={Side === SideEnum.Buy} isSell={Side === SideEnum.Sell}>
              {(isCustomerRow ? `${formatMessage(messages.customer)} ` : '') +
                (Side && formatMessage(messages[getIntlKey(Side)]))}
            </OrderSide>
          </Td>
          <MinTd>
            <InlineFormattedNumber
              number={!isCustomerRow ? OrderQty : customerOrder?.OrderQty}
              increment={orderIncrements.quantityIncrement}
              specification={PriceDisplaySpec}
              currency={Currency}
              background={inlineFormattedNumberBg}
            />
          </MinTd>
          <MinTd>
            <Text color="colorTextSubtle">
              <Icon icon={IconName.AtSymbol} />
            </Text>
          </MinTd>
          <MinTd>
            {priceToDisplay ? (
              <PriceInlineFormatted
                showPriceReference
                entity={{
                  PricingMode: props.PricingMode,
                  PricingReference: props.PricingReference,
                }}
                price={priceToDisplay}
                security={security}
              />
            ) : (
              <InlineFormattedNumber
                number={
                  getPricingReference({ PricingMode: props.PricingMode, PricingReference: props.PricingReference })
                    ?.value
                }
                currency={
                  getPricingReference({ PricingMode: props.PricingMode, PricingReference: props.PricingReference })
                    ?.currency
                }
                align="right"
              />
            )}
          </MinTd>
        </tr>
        <tr>
          <Td>{isCustomerRow ? formatMessage(messages.customerFilled) : formatMessage(messages.filled)}</Td>
          <MinTd>
            <InlineFormattedNumber
              number={!isCustomerRow ? CumQty : customerOrder?.CumQty}
              increment={orderIncrements.quantityIncrement}
              specification={PriceDisplaySpec}
              currency={Currency}
              background={inlineFormattedNumberBg}
            />
          </MinTd>
          <MinTd>
            <Text color="colorTextSubtle">
              <Icon icon={IconName.AtSymbol} />
            </Text>
          </MinTd>
          <MinTd>
            <InlineFormattedNumber
              number={!isCustomerRow ? (showFees ? AvgPxAllIn || AvgPx : AvgPx) : customerOrder?.AvgPx}
              increment={DefaultPriceIncrement}
              specification={PriceDisplaySpec}
              currency={QuoteCurrency}
              background={inlineFormattedNumberBg}
              startIcon={showFees ? IconName.Fees : undefined}
              align="right"
            />
          </MinTd>
        </tr>
      </>
    );
  });

  return (
    <Summary>
      <Table>
        <Tbody>
          {customerOrder && getOrderSummaryRow(true)}
          {getOrderSummaryRow(false)}
          {renderedTableDetails}
        </Tbody>
      </Table>
      <>{subAccountDetails}</>
    </Summary>
  );
};
