import { startOfDay, startOfMonth, startOfWeek, startOfYear, subDays, subHours, subMinutes, subMonths } from 'date-fns';
import { defineMessages } from 'react-intl';
import { FormattedMessage } from '../Intl';

export const dateRangeMessages = defineMessages({
  today: {
    defaultMessage: 'Today',
    id: 'DateRangePicker.today',
  },
  past5Min: {
    defaultMessage: 'Past 5 Min',
    id: 'DateRangePicker.past5Min',
  },
  pastHour: {
    defaultMessage: 'Past hour',
    id: 'DateRangePicker.pastHour',
  },
  past24Hours: {
    defaultMessage: 'Past 24 hours',
    id: 'DateRangePicker.past24Hours',
  },
  pastWeek: {
    defaultMessage: 'Past week',
    id: 'DateRangePicker.pastWeek',
  },
  past30Days: {
    defaultMessage: 'Past 30 days',
    id: 'DateRangePicker.past30Days',
  },
  pastMonth: {
    defaultMessage: 'Past month',
    id: 'DateRangePicker.pastMonth',
  },
  past90Days: {
    defaultMessage: 'Past 90 days',
    id: 'DateRangePicker.past90Days',
  },
  pastYear: {
    defaultMessage: 'Past year',
    id: 'DateRangePicker.pastYear',
  },
  weekToDate: {
    defaultMessage: 'Week to date',
    id: 'DateRangePicker.weekToDate',
  },
  monthToDate: {
    defaultMessage: 'Month to date',
    id: 'DateRangePicker.monthToDate',
  },
  yearToDate: {
    defaultMessage: 'Year to date',
    id: 'DateRangePicker.yearToDate',
  },
  lifeToDate: {
    defaultMessage: 'Life to date',
    id: 'DateRangePicker.lifeToDate',
  },
});

export interface DateRange {
  from: Date | null;
  to: Date | null;
}

export interface LookbackWindow {
  lookback: LookbackOption;
}

export enum LookbackOption {
  Today = 'Today',
  Past5Min = 'Past 5 Min',
  PastHour = 'Past hour',
  Past24Hours = 'Past 24 hours',
  PastWeek = 'Past week',
  Past30Days = 'Past 30 days',
  PastMonth = 'Past month',
  Past90Days = 'Past 90 days',
  PastYear = 'Past year',
  WeekToDate = 'Week to date',
  MonthToDate = 'Month to date',
  YearToDate = 'Year to date',
  LifeToDate = 'Life to date',
}

interface LookbackMetadata {
  getStartDate: () => Date;
  label: JSX.Element;
}
export const LOOKBACKS: { [key in LookbackOption]: LookbackMetadata } = {
  Today: {
    getStartDate: () => startOfDay(new Date()),
    label: <FormattedMessage {...dateRangeMessages.today} />,
  },
  'Past 5 Min': {
    getStartDate: () => subMinutes(new Date(), 5),
    label: <FormattedMessage {...dateRangeMessages.past5Min} />,
  },
  'Past hour': {
    getStartDate: () => subHours(new Date(), 1),
    label: <FormattedMessage {...dateRangeMessages.pastHour} />,
  },
  'Past 24 hours': {
    getStartDate: () => subHours(new Date(), 24),
    label: <FormattedMessage {...dateRangeMessages.past24Hours} />,
  },
  'Past week': {
    getStartDate: () => startOfDay(subDays(new Date(), 7)),
    label: <FormattedMessage {...dateRangeMessages.pastWeek} />,
  },
  'Past 30 days': {
    getStartDate: () => startOfDay(subDays(new Date(), 30)),
    label: <FormattedMessage {...dateRangeMessages.past30Days} />,
  },
  'Past month': {
    getStartDate: () => startOfDay(subMonths(new Date(), 1)),
    label: <FormattedMessage {...dateRangeMessages.pastMonth} />,
  },
  'Past 90 days': {
    getStartDate: () => startOfDay(subDays(new Date(), 90)),
    label: <FormattedMessage {...dateRangeMessages.past90Days} />,
  },
  'Past year': {
    getStartDate: () => subDays(new Date(), 365),
    label: <FormattedMessage {...dateRangeMessages.pastYear} />,
  },
  'Week to date': {
    getStartDate: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
    label: <FormattedMessage {...dateRangeMessages.weekToDate} />,
  },
  'Month to date': {
    getStartDate: () => startOfMonth(new Date()),
    label: <FormattedMessage {...dateRangeMessages.monthToDate} />,
  },
  'Year to date': {
    getStartDate: () => startOfYear(new Date()),
    label: <FormattedMessage {...dateRangeMessages.yearToDate} />,
  },
  'Life to date': {
    getStartDate: () => new Date(),
    label: <FormattedMessage {...dateRangeMessages.lifeToDate} />,
  },
};

export const DEFAULT_LOOKBACK_OPTIONS = [
  LookbackOption.Past5Min,
  LookbackOption.PastHour,
  LookbackOption.Today,
  LookbackOption.Past24Hours,
  LookbackOption.PastWeek,
  LookbackOption.Past30Days,
];

export function isDateRange(val?: DateRange | LookbackWindow): val is DateRange {
  if (!val) {
    return false;
  }
  return 'to' in val && 'from' in val;
}

export function lookbackOptionToDate(lookback: LookbackOption | null): Date {
  if (!lookback) {
    return startOfDay(new Date());
  }

  return LOOKBACKS[lookback].getStartDate();
}

export function isLookbackWindow(
  dateRangeOrLookback: DateRange | LookbackWindow
): dateRangeOrLookback is LookbackWindow {
  return 'lookback' in dateRangeOrLookback;
}
