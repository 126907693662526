import type { POSITION_SUB_ACCOUNT } from '@talos/kyoko';
import {
  RISK_SUB_ACCOUNT,
  useSubscription,
  type MinimalSubscriptionResponse,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { usePortfolioSettings } from 'providers/PortfolioSettingContext';
import { createContext, useContext, useMemo, useState, type PropsWithChildren } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from '../components/tokens';
import type { PortfolioRiskGridData } from '../types/PortfolioRiskGridData';
import { useRequestStateTagging } from '../useRequestStateTagging';
import { getPortfolioRiskCachePipe } from './getPortfolioRiskCachePipe';
import { useGetSubAccountNamesForRequest } from './pipes/useGetSubAccountNamesForRequest';
import { usePortfolioRiskGridDataConverterPipe } from './pipes/usePortfolioRiskGridDataConverterPipe';
import type { FromRiskObsValue } from './PortfolioManagementProvider.types';
import { useManagePortfolioViewStateStorage } from './useManagePortfolioViewStateStorage';

export function getNewTag(prefix: string) {
  return `${prefix}::${Date.now()}`;
}

export interface PortfolioManagementProviderRequest extends WebsocketRequest {
  name: typeof POSITION_SUB_ACCOUNT;
  ShowZeroBalances: boolean;
  EquivalentCurrency: string;
}

export interface PortfolioManagementContextProps {
  riskSubAccountObs: Observable<MinimalSubscriptionResponse<PortfolioRiskGridData>>;
  isDataLoading: boolean;
}

const PortfolioManagementContext = createContext<PortfolioManagementContextProps | undefined>(undefined);

// Build pipe to batch and Cache the Risk data
const batchedRiskCachePipe = getPortfolioRiskCachePipe();

export const PortfolioManagementProvider = ({ children }: PropsWithChildren) => {
  const { treatStablecoinsAsCash } = usePortfolioSettings();
  const { portfolioViewState } = useManagePortfolioViewStateStorage();
  const { selectedPortfolioId, showZeroBalances, showRollupHierarchy, includeCash } = portfolioViewState;
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { homeCurrency } = useDisplaySettings();

  const getSubAccountForRequest = useGetSubAccountNamesForRequest();

  // Tag the request and store in a map to guarantee the correct state is used in the processor
  // TODO: Future Changes to useSubscription to change with state would allow us to remove this
  const { request, tagMapRef } = useRequestStateTagging({
    request: {
      name: RISK_SUB_ACCOUNT,
      ShowZeroBalances: showZeroBalances,
      EquivalentCurrency: homeCurrency,
      Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
      SubAccounts: getSubAccountForRequest(selectedPortfolioId ?? -1),
    },
    extraState: {
      showRollupHierarchy,
      selectedPortfolioId,
      includeCash,
      treatStablecoinsAsCash,
    },
    getNewTag: () => getNewTag('PortfolioManagementProvider'),
  });
  const { data: subscription } = useSubscription<FromRiskObsValue>(selectedPortfolioId != null ? request : null, {
    replay: false,
    loadAll: true,
  });

  // STEP 1: Batch the messages and cache them for requery/pipe changes
  const subscriptionCache = useMemo(() => {
    return subscription.pipe(batchedRiskCachePipe);
  }, [subscription]);

  // STEP 2: Convert the batched messages into the grid data
  const batchProcessorPipe = usePortfolioRiskGridDataConverterPipe(tagMapRef);

  // STEP 3: Share the replayed data
  const batchObsUpdate = useMemo(() => {
    return subscriptionCache.pipe(
      batchProcessorPipe,
      map(response => {
        setIsDataLoading(false);
        return response;
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );
  }, [batchProcessorPipe, subscriptionCache]);

  const contextValue = useMemo<PortfolioManagementContextProps>(() => {
    return {
      riskSubAccountObs: batchObsUpdate,
      isDataLoading,
    };
  }, [batchObsUpdate, isDataLoading]);

  return <PortfolioManagementContext.Provider value={contextValue}>{children}</PortfolioManagementContext.Provider>;
};

export const usePortfolioManagementProvider = () => {
  const context = useContext(PortfolioManagementContext);
  if (context === undefined) {
    throw new Error('Missing PortfolioManagementContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
