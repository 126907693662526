import { FormControlSizes, Toggle, VStack } from '@talos/kyoko';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../stateManagement/portfolioViewLayoutSlice.hooks';

const { updateShowZeroBalances, updateIncludeCash } = getPortfolioViewActions();
export const CommonHeaderMenuItems = () => {
  const dispatch = useAppStateDispatch();
  const { showZeroBalances, includeCash } = usePortfolioViewStateSelector();
  return (
    <VStack gap="spacingDefault" alignItems="flex-start">
      <Toggle
        size={FormControlSizes.Small}
        checked={showZeroBalances}
        onChange={() => {
          dispatch(updateShowZeroBalances(!showZeroBalances));
        }}
        label="Show Zero Balances"
      />
      <Toggle
        size={FormControlSizes.Small}
        checked={includeCash}
        onChange={() => {
          dispatch(updateIncludeCash(!includeCash));
        }}
        label="Include Cash"
      />
    </VStack>
  );
};
