import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import { BlotterTableWrapper } from './styles';
import type { BlotterTableProps } from './types';

import { useBlotterSettings } from 'providers/BlotterSettingContext';
import { useMemo, useState } from 'react';
import { useIntlContext } from '../../hooks/useIntlContext';
import {
  AG_GRID_OPTION_GROUP_KEY,
  AG_GRID_OPTION_MARK_KEY,
  AG_GRID_OPTION_PRICE_QUOTE_KEY,
  AG_GRID_OPTION_STRIKE_PRICE_KEY,
  AgGridAmountInput,
  AgGridButton,
  AgGridCheckbox,
  AgGridCurrency,
  AgGridFormattedNumber,
  AgGridGroupToggleHeader,
  AgGridHamburgerMenu,
  AgGridIconButton,
  AgGridInput,
  AgGridLoadingOverlay,
  AgGridMeter,
  AgGridMultiSelectDropdown,
  AgGridNoRowsOverlay,
  AgGridOptionGroupCell,
  AgGridOptionMark,
  AgGridOptionPriceQuote,
  AgGridOptionStrikePrice,
  AgGridPrice,
  AgGridProcessStep,
  AgGridSearchSelectDropdown,
  AgGridSecurity,
  AgGridSize,
  AgGridToggle,
  AgGridTooltipHeader,
  AgGridWarning,
  AgGridWarningHeader,
} from '../AgGrid';
import { AG_GRID_FULL_WIDTH_GROUP_ROW, AgGridFullWidthGroupRow } from '../AgGrid/AgGridFullWidthGroupRow';
import { AgGridDateFilter } from './../AgGrid/AgGridDateFilter';

export function BlotterTable({ gridOptions, density, background, extraComponents }: BlotterTableProps) {
  const { agGridMessages } = useIntlContext();
  const [pinnedLeftOverlapping, setPinnedLeftOverlapping] = useState(false);
  const { alwaysShowColumnDividers } = useBlotterSettings();

  const memoizedGridOptions = useMemo(() => {
    return {
      ...gridOptions,
      suppressColumnVirtualisation:
        (import.meta.env.VITE_AVA_ENV === 'local' ||
          import.meta.env.VITE_AVA_ENV === 'test' ||
          import.meta.env.VITE_AVA_ENV === 'staging' ||
          import.meta.env.VITE_AVA_ENV === 'uat') &&
        !!(window as any).Cypress,
    };
  }, [gridOptions]);

  return (
    <>
      <BlotterTableWrapper
        background={background}
        className="ag-theme-balham-dark"
        density={density}
        pinnedLeftOverLapping={pinnedLeftOverlapping}
        alwaysShowColumnDividers={alwaysShowColumnDividers}
        data-testid="blotter-table-wrapper"
      >
        <AgGridReact
          localeText={agGridMessages}
          defaultColDef={{
            resizable: true,
            filterParams: {
              buttons: ['reset', 'apply'],
            },
          }}
          {...memoizedGridOptions}
          onBodyScroll={e => setPinnedLeftOverlapping(e.left > 0)}
          components={{
            // Apparently moving this list _outside_ of the render function into a constant so it can be exported and reused
            // makes it so that none of the components are registered. I have no idea why.
            buttonColumn: AgGridButton,
            checkbox: AgGridCheckbox,
            dateFilter: AgGridDateFilter,
            hamburgerMenuColumn: AgGridHamburgerMenu,
            iconButtonColumn: AgGridIconButton,
            loadingOverlay: AgGridLoadingOverlay,
            noRowsOverlay: AgGridNoRowsOverlay,
            meterColumn: AgGridMeter,
            priceColumn: AgGridPrice,
            formattedNumberColumn: AgGridFormattedNumber,
            processStepColumn: AgGridProcessStep,
            sizeColumn: AgGridSize,
            iconButton: AgGridIconButton,
            input: AgGridInput,
            amountInput: AgGridAmountInput,
            toggle: AgGridToggle,
            searchSelectDropdown: AgGridSearchSelectDropdown,
            multiSelectDropdown: AgGridMultiSelectDropdown,
            currencyColumn: AgGridCurrency,
            securityColumn: AgGridSecurity,
            warningColumn: AgGridWarning,
            warningHeader: AgGridWarningHeader,
            groupToggleHeader: AgGridGroupToggleHeader,
            [AG_GRID_OPTION_STRIKE_PRICE_KEY]: AgGridOptionStrikePrice,
            [AG_GRID_OPTION_PRICE_QUOTE_KEY]: AgGridOptionPriceQuote,
            [AG_GRID_OPTION_MARK_KEY]: AgGridOptionMark,
            [AG_GRID_OPTION_GROUP_KEY]: AgGridOptionGroupCell,
            [AG_GRID_FULL_WIDTH_GROUP_ROW]: AgGridFullWidthGroupRow,
            tooltipHeader: AgGridTooltipHeader,
            ...extraComponents,
          }}
          tooltipShowDelay={300}
          loadingOverlayComponent="loadingOverlay"
          noRowsOverlayComponent="noRowsOverlay"
          data-testid="blotter-table"
          suppressMultiSort={false}
          suppressColumnMoveAnimation={true}
        />
      </BlotterTableWrapper>
    </>
  );
}
