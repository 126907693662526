import {
  LookbackOption,
  ProductTypeEnum,
  formattedDateForSubscription,
  readableDate,
  readableDay,
  type DateRangeFilter,
  type PnlLookbackEnum,
} from '@talos/kyoko';
import {
  roundToNearestMinutes,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import type { SubAccountPositionsRequest } from '../../Blotters/PositionsV3/SubAccounts/useSubAccountPositionsBlotterRequest';

export interface PerformanceFilter {
  Symbols?: string[];
}

export type PnLUnit = 'amount' | 'percentage';

export type Product = 'spot' | 'derivatives' | 'all';

export interface PerformanceState {
  showing: 'Performance' | 'Positions';
  /** Currently applied filters */
  filter: PerformanceFilter;
  period: Period | CustomPeriod;
  chartRequestDateRange: DateRangeFilter;
  snapshotDate: string | null;
  pnlUnit: PnLUnit;
  showDelta: boolean;
  benchmarks: string[];
  pnlLookbacks: PnlLookbackEnum[];
  showInsights: boolean;
  subAccounts: string[];
  showZeroBalances: boolean;
  lifeToDateStartTime: string | null;
  product: Product;
}

// This defines what part of the PerformanceState is stored in the TabState. The "overlap" between the two.
export type PerformanceTabState = Pick<
  PerformanceState,
  | 'showing'
  | 'filter'
  | 'period'
  | 'pnlUnit'
  | 'snapshotDate'
  | 'showDelta'
  | 'benchmarks'
  | 'showInsights'
  | 'subAccounts'
  | 'showZeroBalances'
  | 'product'
>;

export type PerfSubAccountPositionsRequest = SubAccountPositionsRequest &
  PerformanceFilter & {
    SubAccounts?: string[];
    AssetTypes?: string[];
  };

export type Period =
  | 'LifeToDate'
  | 'YearToDate'
  | 'MonthToDate'
  | 'WeekToDate'
  | 'Intraday'
  | 'Yearly'
  | 'Monthly'
  | 'Weekly'
  | 'Daily';

export interface CustomPeriod {
  StartDate: string;
  EndDate?: string;
}

export function isPeriodCustomPeriod(period: Period | CustomPeriod): period is CustomPeriod {
  return typeof period === 'object';
}

export function periodToChartDateRange(period: Period | CustomPeriod) {
  return isPeriodCustomPeriod(period) ? period : PERIODS[period].getDateRangeFilter();
}

export function periodToLookbackEquivalent(period: Period | CustomPeriod) {
  return isPeriodCustomPeriod(period) ? undefined : PERIODS[period].lookbackEquivalent;
}

export function getPeriodLabel(period: Period | CustomPeriod) {
  if (isPeriodCustomPeriod(period)) {
    return `${readableDate(period.StartDate)} - ${period.EndDate ? readableDay(period.EndDate) : 'Now'}`;
  } else {
    return PERIODS[period].shortLabel;
  }
}

export interface PeriodMetadata {
  period: Period;
  shortLabel: string;
  label: string;
  getDateRangeFilter: () => DateRangeFilter;
  lookbackEquivalent: LookbackOption;
}

export const PERIODS: { [key in Period]: PeriodMetadata } = {
  LifeToDate: {
    period: 'LifeToDate',
    shortLabel: 'LTD',
    label: 'Life-To-Date (LTD)',
    getDateRangeFilter: () => ({}),
    lookbackEquivalent: LookbackOption.LifeToDate,
  },
  YearToDate: {
    period: 'YearToDate',
    shortLabel: 'YTD',
    label: 'Year-To-Date (YTD)',
    getDateRangeFilter: () => {
      return {
        StartDate: formattedDateForSubscription(startOfYear(new Date())),
      };
    },
    lookbackEquivalent: LookbackOption.YearToDate,
  },
  MonthToDate: {
    period: 'MonthToDate',
    shortLabel: 'MTD',
    label: 'Month-To-Date (MTD)',
    getDateRangeFilter: () => {
      return {
        StartDate: formattedDateForSubscription(startOfMonth(new Date())),
      };
    },
    lookbackEquivalent: LookbackOption.MonthToDate,
  },
  WeekToDate: {
    period: 'WeekToDate',
    shortLabel: 'WTD',
    label: 'Week-To-Date (WTD)',
    getDateRangeFilter: () => ({
      StartDate: formattedDateForSubscription(startOfWeek(new Date(), { weekStartsOn: 1 })),
    }),
    lookbackEquivalent: LookbackOption.WeekToDate,
  },
  Intraday: {
    period: 'Intraday',
    shortLabel: 'Intraday',
    label: 'Intraday',
    getDateRangeFilter: () => ({
      StartDate: formattedDateForSubscription(startOfDay(new Date())),
    }),
    lookbackEquivalent: LookbackOption.Today,
  },
  Yearly: {
    period: 'Yearly',
    shortLabel: 'Past Year',
    label: 'Past Year',
    getDateRangeFilter: () => ({
      // Take current date, subtract a year, take the start of that day.
      StartDate: formattedDateForSubscription(startOfDay(subYears(new Date(), 1))),
    }),
    lookbackEquivalent: LookbackOption.PastYear,
  },
  Monthly: {
    period: 'Monthly',
    shortLabel: 'Past Month',
    label: 'Past Month',
    getDateRangeFilter: () => ({
      StartDate: formattedDateForSubscription(startOfDay(subMonths(new Date(), 1))),
    }),
    lookbackEquivalent: LookbackOption.PastMonth,
  },
  Weekly: {
    period: 'Weekly',
    shortLabel: 'Past Week',
    label: 'Past Week',
    getDateRangeFilter: () => ({
      // Take current date, subtract a year, take the start of that day.
      StartDate: formattedDateForSubscription(startOfDay(subWeeks(new Date(), 1))),
    }),
    lookbackEquivalent: LookbackOption.PastWeek,
  },
  Daily: {
    period: 'Daily',
    shortLabel: 'Past 24 Hours',
    label: 'Past 24 Hours',
    getDateRangeFilter: () => ({
      // Take current date, subtract a year, take the start of that day.
      StartDate: formattedDateForSubscription(
        roundToNearestMinutes(subDays(new Date(), 1), { nearestTo: 15, roundingMethod: 'floor' })
      ),
    }),
    lookbackEquivalent: LookbackOption.Past24Hours,
  },
};

export interface ProductMetadata {
  productTypes: ProductTypeEnum[];
  label: string;
}

const SPOT_PRODUCT_TYPES: ProductTypeEnum[] = [ProductTypeEnum.Spot];
const DERIVATIVES_PRODUCT_TYPES: ProductTypeEnum[] = [
  ProductTypeEnum.PerpetualSwap,
  ProductTypeEnum.Future,
  ProductTypeEnum.Option,
  ProductTypeEnum.Basis,
  ProductTypeEnum.CFD,
  ProductTypeEnum.CalendarSpread,
];

const ALL_PRODUCT_TYPES: ProductTypeEnum[] = [...SPOT_PRODUCT_TYPES, ...DERIVATIVES_PRODUCT_TYPES];

export const PRODUCTS: { [key in Product]: ProductMetadata } = {
  spot: {
    productTypes: SPOT_PRODUCT_TYPES,
    label: 'Spot',
  },
  derivatives: {
    productTypes: DERIVATIVES_PRODUCT_TYPES,
    label: 'Derivatives',
  },
  all: {
    productTypes: ALL_PRODUCT_TYPES,
    label: 'All', // this is just spot + derivs for now
  },
};
