import { Input, useConstant } from '@talos/kyoko';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

export const AgGridInput = forwardRef(({ size, ...props }: { size: any; value: any }, ref) => {
  const [value, setValue] = useState(props.value);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
    };
  });

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus());
  }, []);

  const style = useConstant({ width: '100%' });

  return (
    <Input
      ref={inputRef}
      onChange={e => setValue(e.target.value)}
      value={value}
      size={size}
      style={style}
      onFocus={e => e.target.select()}
    />
  );
});
