import {
  POSITION_SUB_ACCOUNT,
  isPnLTagEnum,
  isPnlLookbackEnum,
  type Column,
  type PnlLookbackEnum,
  type WebsocketRequest,
} from '@talos/kyoko';
import { isEqual, uniq } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDisplaySettings, type CustomEOD } from '../../../../providers/DisplaySettingsProvider';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from '../tokens';

export interface SubAccountPositionsRequest extends WebsocketRequest {
  ShowZeroBalances: boolean;
  EquivalentCurrency: string;
  Tolerance: string;
  EndOfDay?: string;
  PnLLookbacks?: PnlLookbackEnum[];
  Tags?: string[];
  FlattenRollups?: boolean;
}

export interface UseSubAccountPositionsBlotterRequestParams {
  requestTag: string;
  showZeroBalances: boolean;
  initialColumns: Column[];
  /**
   * Pass true here when your blotter is in hierarchical mode. This will cause the request to set FlattenRollups: true.
   * When this is done, the backend will resolve all passed rollups in the filter to their underlying books and only return positions
   * for those resolved books.
   */
  hierarchical?: boolean;
}

/**
 * This hook encapsulates the logic of the SubAccountPositionsBlotter request management
 *
 * This request management is more complex in comparison to other blotters seeing as the request
 * is a function of the columns visible. As columns are changed, the request is (maybe) amended.
 *
 * Returns the request itself (to be used with `useConstant` in order to get an `initialRequest`),
 * together with an onColumnsChanged function which the implementer needs to call whenever the associated
 * BlotterTable's own onColumnsChanged runs.
 */
export const useSubAccountPositionsBlotterRequest = ({
  initialColumns,
  requestTag,
  showZeroBalances,
  hierarchical,
}: UseSubAccountPositionsBlotterRequestParams): {
  request: SubAccountPositionsRequest;
  onColumnsChanged: (columns: Column[]) => void;
} => {
  const { customEOD, homeCurrency } = useDisplaySettings();

  const [request, setRequest] = useState<SubAccountPositionsRequest>({
    name: POSITION_SUB_ACCOUNT,
    tag: requestTag,
    ShowZeroBalances: showZeroBalances,
    EquivalentCurrency: homeCurrency,
    Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
    EndOfDay: customEODToRequestEOD(customEOD),
    PnLLookbacks: columnsToRequestPnlLookbacks(initialColumns),
    Tags: columnsToRequestPnlTags(initialColumns),
    FlattenRollups: hierarchical,
  });

  // useEffect to react to changes in request dependencies, update whenever a change occurs
  useEffect(() => {
    setRequest(currentRequest => {
      const newRequest: SubAccountPositionsRequest = {
        ...currentRequest,
        EndOfDay: customEODToRequestEOD(customEOD),
        ShowZeroBalances: showZeroBalances,
        FlattenRollups: hierarchical,
      };
      if (isEqual(currentRequest, newRequest)) {
        return currentRequest;
      }

      return newRequest;
    });
  }, [customEOD, showZeroBalances, hierarchical]);

  const onColumnsChanged = useCallback((columns: Column[]) => {
    // Check the PnLLookbacks and see if we should amend the request based on the new visible columns
    setRequest(currentRequest => {
      const newRequest: SubAccountPositionsRequest = {
        ...currentRequest,
        PnLLookbacks: columnsToRequestPnlLookbacks(columns),
        Tags: columnsToRequestPnlTags(columns),
      };
      if (isEqual(currentRequest, newRequest)) {
        return currentRequest;
      }

      return newRequest;
    });
  }, []);

  return { request, onColumnsChanged };
};

/**
 * Convert an array of columns into an array of the necessary PnLLookbackEnums we need in order to populate these visible columns.
 *
 * For Lookbacks, each column (currently) corresponds to one PnLLookbackEnum. Eg one column is "24H", another "WTD", etc.
 */
export function columnsToRequestPnlLookbacks(columns: Column[]): PnlLookbackEnum[] {
  return columns
    .filter(column => column.field?.includes('PnLLookbacks.') && !column.hide)
    .map(lookbackField => lookbackField.field?.split('.')[1])
    .filter(isPnlLookbackEnum);
}

/**
 * Convert an array of columns into an array of the necessary PnLTagsEnum we need in order to populate these visible columns.
 *
 * For PnLTags, several columns can use the same tag (eg EndOfDay), so we wrap it with a uniq() call.
 */
export function columnsToRequestPnlTags(columns: Column[]): string[] {
  return uniq(
    columns
      .filter(column => column.field?.includes('PnLTags.') && !column.hide)
      .map(tagField => tagField.field?.split('.')[1])
      .filter(isPnLTagEnum)
  );
}

// Converts to UTC hours and format for request inclusion
export function customEODToRequestEOD(eod: CustomEOD): string {
  const customEODDate = new Date(2020, 1, 1, eod.hours, eod.minutes);
  const utcHours = customEODDate.getUTCHours();
  const utcMinutes = customEODDate.getUTCMinutes();
  return `${padTime(utcHours)}:${padTime(utcMinutes)}`;
}

function padTime(value: number) {
  return value < 10 ? `0${value.toString()}` : value.toString();
}
