import { isEqual, mapValues, parseInt } from 'lodash';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import type { MapTo, StyledComponentAllProps } from '../../utils/types';
import { Button } from '../Button';
import { Flex, VStack } from '../Core';
import { FormControlSizes, NumberInput } from '../Form';
import { FormattedMessage } from '../Intl';
import { parseDuration, type Duration } from './duration';
import { DurationWrapper, FormGroup, ShortcutButtons, ShortcutsHeader } from './styles';

const messages = defineMessages({
  shortcuts: {
    defaultMessage: 'Shortcuts',
    id: 'DateTimeDurationPicker.shortcuts',
  },
  days: {
    defaultMessage: 'Days',
    id: 'DateTimeDurationPicker.days',
  },
  hours: {
    defaultMessage: 'Hours',
    id: 'DateTimeDurationPicker.hours',
  },
  minutes: {
    defaultMessage: 'Minutes',
    id: 'DateTimeDurationPicker.minutes',
  },
  seconds: {
    defaultMessage: 'Seconds',
    id: 'DateTimeDurationPicker.seconds',
  },
});

export interface DurationPickerProps {
  /** Current value of the date / time picker */
  value: Duration;
  onChange: (duration: Duration) => void;
  closeAndSetValue: (duration: Duration) => void;

  /** Show the shortcuts section (5m/10m/...)? */
  showShortcuts?: boolean;
  /** Shortcuts to show
   *
   * @default {@link DEFAULT_DURATION_SHORTCUTS}
   */
  shortcuts?: { [key: string]: string };
}

export const DEFAULT_DURATION_SHORTCUTS = {
  '5m': '5m',
  '10m': '10m',
  '15m': '15m',
  '30m': '30m',
  '1h': '1h',
  '2h': '2h',
  '4h': '4h',
  '12h': '12h',
} as const;

type ForwardedProps = Omit<StyledComponentAllProps<typeof DurationWrapper>, 'onChange'>;

export function DurationPicker({
  value,
  shortcuts = DEFAULT_DURATION_SHORTCUTS,
  showShortcuts = true,
  onChange,
  closeAndSetValue,
  ...props
}: DurationPickerProps & ForwardedProps): JSX.Element {
  const duration = parseDuration(value);
  const maybeEmitChange = useCallback(
    (newDuration: Duration) => {
      if (!isEqual(duration, newDuration)) {
        onChange(newDuration);
      }
    },
    [duration, onChange]
  );

  const setValueFromShortcut = useCallback(
    (newValue: string) => {
      const newDuration = parseDuration(newValue);
      maybeEmitChange(newDuration);
      closeAndSetValue(newDuration);
    },
    [maybeEmitChange, closeAndSetValue]
  );
  const setPartOfValue = useCallback(
    (changed: MapTo<Partial<Duration>, string>) => {
      const newDuration = { ...duration, ...mapValues(changed, parseInt) };
      maybeEmitChange(newDuration);
    },
    [duration, maybeEmitChange]
  );

  const valueAsString = (value: number | undefined) => (value ? value.toString() : '');

  return (
    <DurationWrapper style={{ height: '100%', justifyContent: 'space-between' }} {...props}>
      <Flex gap="spacingSmall">
        <VStack gap="spacingSmall">
          <FormGroup
            label={
              <span>
                <FormattedMessage {...messages.days} />
              </span>
            }
          >
            <NumberInput
              value={valueAsString(duration.days)}
              onChange={days => setPartOfValue({ days })}
              defaultIncrement="1"
              placeholder="0"
            />
          </FormGroup>
          <FormGroup
            label={
              <span>
                <FormattedMessage {...messages.hours} />
              </span>
            }
          >
            <NumberInput
              value={valueAsString(duration.hours)}
              onChange={hours => setPartOfValue({ hours })}
              defaultIncrement="1"
              placeholder="0"
            />
          </FormGroup>
          <FormGroup
            label={
              <span>
                <FormattedMessage {...messages.minutes} />
              </span>
            }
          >
            <NumberInput
              value={valueAsString(duration.minutes)}
              onChange={minutes => setPartOfValue({ minutes })}
              defaultIncrement="1"
              placeholder="0"
            />
          </FormGroup>
          <FormGroup
            label={
              <span>
                <FormattedMessage {...messages.seconds} />
              </span>
            }
          >
            <NumberInput
              value={valueAsString(duration.seconds)}
              onChange={seconds => setPartOfValue({ seconds })}
              defaultIncrement="1"
              placeholder="0"
            />
          </FormGroup>
        </VStack>
      </Flex>
      {showShortcuts && shortcuts && (
        <>
          <ShortcutsHeader>
            <FormattedMessage {...messages.shortcuts} />
          </ShortcutsHeader>
          <ShortcutButtons>
            {Object.keys(shortcuts).map(shortcut => (
              <Button key={shortcut} onClick={() => setValueFromShortcut(shortcut)} size={FormControlSizes.Small}>
                {shortcuts[shortcut]}
              </Button>
            ))}
          </ShortcutButtons>
        </>
      )}
    </DurationWrapper>
  );
}
