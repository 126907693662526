import type { ICellRendererParams } from 'ag-grid-enterprise';
import { useMemo } from 'react';
import { useSecuritiesContext } from '../../contexts/SecuritiesContext';
import { Order } from '../../types/Order';
import type { Security } from '../../types/Security';
import { LegDirectionEnum, UnifiedLiquidityEnum } from '../../types/types';
import { getSymbolsAcrossMarkets, isMultileg } from '../../utils';
import { isRowHeightExpanded } from '../BlotterTable/utils';
import { HStack, VStack } from '../Core';
import { SecurityRenderer } from '../SecurityRenderer';
import { Text } from '../Text';
import { UnifiedLiquidityTooltip } from '../UnifiedLiquidity';
import { SecurityTooltipEnum, type AgGridSecurityProps } from './types';

export function AgGridSecurity({
  value,
  showIcon,
  showDescription,
  context,
  tooltip,
  data,
  colorful,
  node,
  api,
}: ICellRendererParams<any, string> & AgGridSecurityProps) {
  const security: Security | undefined = value ? context.current.securitiesBySymbol?.get(value) : undefined;

  const securityTooltip = useMemo(() => {
    if (
      tooltip === SecurityTooltipEnum.UNIFIED_LIQUIDITY &&
      data instanceof Order &&
      data.unifiedLiquidity === UnifiedLiquidityEnum.Enabled
    ) {
      const symbols = getSymbolsAcrossMarkets(data);
      if (symbols.length > 0) {
        return <UnifiedLiquidityTooltip security={security} symbols={symbols} />;
      }
    }
    return null;
  }, [security, tooltip, data]);

  const isRowExpanded = isRowHeightExpanded(node, api);

  return (
    <HStack w="100%" h="100%" justifyContent="flex-start">
      <VStack h="100%" w="100%" gap="spacingSmall" alignItems="flex-start">
        <SecurityRenderer
          symbol={value}
          security={security}
          currency={security?.BaseCurrency}
          showIcon={showIcon}
          showDescription={showDescription}
          theme={context.current.theme}
          securityTooltip={securityTooltip}
          colorful={colorful}
        />
        {isRowExpanded && security && isMultileg(security) && (
          <VStack alignItems="flex-start" gap="spacingSmall">
            <MultilegLegDirectionText security={security} legIndex={0} />
            <MultilegLegDirectionText security={security} legIndex={1} />
          </VStack>
        )}
      </VStack>
    </HStack>
  );
}

function MultilegLegDirectionText({ security, legIndex }: { security: Security; legIndex: 0 | 1 }) {
  const { securitiesBySymbol } = useSecuritiesContext();
  const leg = security.MultilegDetails?.Legs?.[legIndex];
  if (!leg) {
    return <></>;
  }

  const isDirectionSameAsParent = leg.Direction === LegDirectionEnum.Same;
  return (
    <Text color={isDirectionSameAsParent ? 'colorTextPositive' : 'colorTextNegative'}>
      {isDirectionSameAsParent ? '+1' : '-1'} {securitiesBySymbol.get(leg.Symbol)?.DisplaySymbol ?? leg.Symbol}
    </Text>
  );
}
