import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  FormControlSizes,
  IconButton,
  IconName,
  isColumnGroup,
  ToggleHorizontal,
  useBlotterTable,
  useObservable,
  usePersistedBlotterTable,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { shareReplay } from 'rxjs';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import type { PortfolioRiskGridData } from '../../types/PortfolioRiskGridData';
import type { ContextSelectionBlotterParams, RiskAggMode, RiskPivotType } from '../../types/types';
import { useGroupedDataExportContext } from '../RiskBlotterExportMenuItems';
import { RiskBlotterSuffixMenu } from '../RiskBlotterSuffixMenu';
import { useRollupTreeMenu } from '../useRollupTreeMenu';
import { usePivotColumnShowHide } from './usePivotColumnShowHide';
import { usePortfolioRiskColumns } from './usePortfolioRiskColumns';
import { useRollupTreeGridBuilders } from './useRollupTreeGridBuilders';

const riskPivotButtonBarOptions: Array<{ value: RiskPivotType; displayName: string }> = [
  {
    value: 'Tenor',
    displayName: 'By Tenor',
  },
  {
    value: 'Moneyness',
    displayName: 'By Moneyness',
  },
];

const riskAggModeButtonBarOptions: Array<{ value: RiskAggMode; displayName: string }> = [
  {
    value: 'Net',
    displayName: 'Net',
  },
  {
    value: 'Gross',
    displayName: 'Gross',
  },
];

const { updateRiskPivotAggMode, updateRiskPivotType } = getPortfolioViewActions();

/**
 * PortfolioRiskBlotter display the underliers and assets of the selected subAccount (Rollup or Book) in a hierarchical grid.
 */
export function PortfolioRiskBlotter({ blotterID, wrapperContext, subAccountId }: ContextSelectionBlotterParams) {
  const { riskSubAccountObs } = usePortfolioManagementProvider();
  const { riskPivotAggMode, riskPivotType } = usePortfolioViewStateSelector();
  const dispatch = useAppStateDispatch();
  const sharedObs = useObservable(() => riskSubAccountObs.pipe(shareReplay(1)), [riskSubAccountObs]);

  const { defaultColumns, defaultColumnsFlat } = usePortfolioRiskColumns();
  const persistedBlotterTable: UsePersistedBlotterTable<PortfolioRiskGridData> = usePersistedBlotterTable(blotterID, {
    columns: defaultColumnsFlat,
    sort: `+${AGGRID_AUTOCOLUMN_ID}`,
  });

  const { blotterParams } = useRollupTreeGridBuilders();

  const { getContextMenuItems, dialogs } = useRollupTreeMenu();

  const customColumnUpdate = usePivotColumnShowHide({
    riskPivotType,
    riskAggMode: riskPivotAggMode,
    sort: persistedBlotterTable.initialSort,
  });

  const fullColumns = useMemo(() => {
    const pivotColumnGroups = defaultColumns.filter(isColumnGroup);
    return [...persistedBlotterTable.columns, ...pivotColumnGroups];
  }, [defaultColumns, persistedBlotterTable.columns]);

  const groupedDataContext = useGroupedDataExportContext();
  const blotterTable = useBlotterTable<PortfolioRiskGridData>({
    dataObservable: sharedObs,
    rowID: 'rowID',
    rowSelection: 'single',
    context: groupedDataContext,
    getContextMenuItems,
    quickSearchParams: {
      entitySearchKeys: ['Asset', 'SubAccount'],
      filterOnAutoGroupLevels: true,
    },
    groupDefaultExpanded: -1,
    supportColumnColDefGroups: true,
    customColumnUpdate,
    ...blotterParams,
    ...persistedBlotterTable,
    sort: persistedBlotterTable.initialSort,
    groupableColumns: fullColumns,
  });

  useEffect(() => {
    if (blotterTable.gridApi) {
      if (subAccountId) {
        blotterTable.gridApi.expandAll();
      } else {
        blotterTable.gridApi.collapseAll();
      }
    }
  }, [subAccountId, blotterTable.gridApi]);

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  const onRiskPivotAggModeChange = useCallback(
    (val: RiskAggMode) => {
      dispatch(updateRiskPivotAggMode(val));
    },
    [dispatch]
  );

  const onRiskPivotTypeChange = useCallback(
    (val: RiskPivotType) => {
      dispatch(updateRiskPivotType(val));
    },
    [dispatch]
  );

  return (
    <>
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        showFilterBuilder={false}
        showQuickFilterOnly={false}
        quickFilterPrefix={
          <>
            <ToggleHorizontal<RiskAggMode>
              value={riskPivotAggMode}
              options={riskAggModeButtonBarOptions}
              onChange={onRiskPivotAggModeChange}
              data-testid="risk-agg-mode-toggle"
            />
            <ToggleHorizontal<RiskPivotType>
              value={riskPivotType}
              options={riskPivotButtonBarOptions}
              onChange={onRiskPivotTypeChange}
              data-testid="risk-pivot-type-toggle"
            />
          </>
        }
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
        suffix={
          <RiskBlotterSuffixMenu
            riskObs={sharedObs}
            exportDataAsCSV={blotterTable.exportDataAsCSV}
            exportDataAsExcel={blotterTable.exportDataAsExcel}
            label={wrapperContext.label}
          />
        }
      />
      <BlotterTable {...blotterTable} />
      {dialogs}
    </>
  );
}
