import { compact, uniq } from 'lodash';
import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { toBigWithDefault } from '../utils';
import { EMPTY_ARRAY } from '../utils/empty';
import {
  QuoteStatusEnum,
  type IAllocation,
  type IOMSQuote,
  type IOMSQuote4401Aggregations,
  type OrdRejReasonEnum,
  type PricingModeEnum,
  type SideEnum,
} from './types';

export interface QuoteMarket {
  BidAmt: string;
  BidPx: string;
  FirstTransactTime: string;
  LegIndexes: { LegIndex: number }[];
  Market: string;
  MarketAccount: string;
  OfferAmt: string;
  OfferPx: string;
  QuoteStatus: string;
  TradedAmt?: string;
  TradedPx?: string;
  TradedQty?: string;
  TradedSide?: SideEnum;
  TransactTime: string;
  Timestamp: string;
  Text?: string;
  ValidUntilTime: string;
  LimitBidPx?: string;
  LimitOfferPx?: string;
  QID?: string;
}

// OMSQuote4401
export class Quote {
  static readonly rowID = 'RFQID';
  static readonly defaultColumns = [
    'SubmitTime',
    'RFQID',
    'Side',
    'Symbol',
    'OrderQty',
    'Markets',
    'SubAccount',
    'User',
    'QuoteStatus',
    'ParentRFQID',
    'QuoteID',
  ] satisfies (keyof Quote)[];

  Timestamp!: string;

  MessageID?: string;

  User!: string;

  Symbol!: string;

  Currency!: string;

  RFQID!: string;

  QuoteReqID!: string;

  QuoteStatus!: QuoteStatusEnum;

  QuoteID!: string;

  QuoteRequestRejectReason?: OrdRejReasonEnum;

  SubmitTime?: string;

  OrderQty?: string;

  AmountCurrency?: string;

  FirstTransactTime?: string;

  EndTime?: string;

  SubAccount?: string;

  Side?: SideEnum;

  TradedPx?: string;

  TradedQty?: string;

  TradedAmt?: string;

  TradedSide?: SideEnum;

  ParentRFQID?: string;

  TransactTime?: string;

  EventIndicator?: string;

  Markets!: QuoteMarket[];

  Legs?: QuoteLeg[];

  Text?: string;

  Group?: string;

  Parameters?: { [key: string]: string };

  Allocation?: IAllocation;

  OrgID?: string;

  Comments?: string;

  Aggregations?: IOMSQuote4401Aggregations[];

  PricingMode?: PricingModeEnum;

  PricingReference?: string;

  FixingDetails?: {
    Index?: string;
    Fixing?: string;
  };

  get isTerminal() {
    return [
      QuoteStatusEnum.Canceled,
      QuoteStatusEnum.Rejected,
      QuoteStatusEnum.Filled,
      QuoteStatusEnum.PendingFix,
    ].includes(this.QuoteStatus);
  }

  get isPending() {
    return [QuoteStatusEnum.PendingCancel, QuoteStatusEnum.PendingNew, QuoteStatusEnum.PendingFill].includes(
      this.QuoteStatus
    );
  }

  get tradedMarkets(): string[] {
    return uniq(compact(this.Markets?.filter(m => toBigWithDefault(m.TradedQty, 0).gt(0)).map(m => m.Market)));
  }

  constructor(data: IOMSQuote) {
    Object.assign(this, data);
  }
}

interface UseQuoteColumns {
  defaultColumns?: (keyof Quote | Partial<Column>)[];
}

export function useQuoteColumns({ defaultColumns = EMPTY_ARRAY }: UseQuoteColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'SubmitTime',
              type: 'date',
              sortable: true,
              sort: '-',
            },
            {
              field: 'RFQID',
              type: 'id',
            },
            {
              field: 'Side',
              type: 'quoteSide',
            },
            {
              field: 'Symbol',
              type: 'security',
            },
            {
              field: 'OrderQty',
              type: 'size',
              params: { currencyField: 'Currency' },
            },
            {
              field: 'Markets',
              type: 'quoteMarkets',
            },
            {
              field: 'SubAccount',
              type: 'text',
            },
            {
              field: 'User',
              type: 'user',
            },
            {
              field: 'QuoteStatus',
              type: 'quoteStatus',
            },
            {
              field: 'ParentRFQID',
              type: 'id',
            },
            {
              field: 'QuoteID',
              type: 'id',
            },
            { field: 'PricingMode', type: 'text', title: 'Pricing Mode' },
          ] satisfies ColumnDef<Quote>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          {
            field: 'Timestamp',
            type: 'date',
          },
          {
            field: 'MessageID',
            type: 'text',
          },
          {
            field: 'Currency',
            type: 'currency',
          },
          {
            field: 'QuoteReqID',
            type: 'id',
          },
          {
            field: 'QuoteRequestRejectReason',
            type: 'text',
          },
          {
            field: 'AmountCurrency',
            type: 'currency',
          },
          {
            field: 'FirstTransactTime',
            type: 'date',
          },
          {
            field: 'EndTime',
            type: 'date',
          },
          {
            field: 'TradedPx',
            type: 'price',
            params: { assetField: 'Symbol' },
          },
          {
            field: 'TradedQty',
            type: 'size',
            params: { currencyField: 'Currency' },
          },
          {
            field: 'TradedAmt',
            type: 'size',
            params: { currencyField: 'AmountCurrency' },
          },
          {
            field: 'TradedSide',
            type: 'side',
          },
          { field: 'tradedMarkets', type: 'markets', title: 'Traded Markets', sortable: true },
          {
            field: 'TransactTime',
            type: 'date',
          },
          {
            field: 'EventIndicator',
            type: 'text',
          },
          {
            field: 'Legs',
            type: 'text',
          },
          {
            field: 'Text',
            type: 'text',
          },
          {
            field: 'Group',
            type: 'text',
          },
          {
            field: 'Parameters',
            type: 'text',
          },
          {
            field: 'Allocation',
            type: 'text',
          },
          {
            field: 'PricingReference',
            type: 'price',
            hide: true,
            params: {
              isReferencePrice: true,
            },
          },
          {
            field: 'FixingDetails.Index',
            title: 'Fixing Index',
            type: 'text',
          },
          {
            field: 'FixingDetails.Fixing',
            title: 'Fixing Price',
            type: 'price',
            params: {
              // TODO fhqvst this should probably be `FixingDetails.Index`
              assetField: 'Symbol',
            },
          },
        ] satisfies (false | ColumnDef<Quote>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);
  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [...defaultVisibleColumns.values(), ...defaultHiddenColumns.values()] satisfies ColumnDef<Quote>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}

export interface QuoteLeg {
  Market: string;
  Symbol: string;
  BidQty: string;
  OfferQty: string;
  Currency: string;
  AmountCurrency: string;
  QuoteStatus: string;
  QuoteRequestRejectReason: string;
  MarketAccount: string;
  Text: string;
}
