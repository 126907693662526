import { REPORTS_TABS_ID } from '../../containers/Analytics/Reports/Providers/tokens';

export function enableSmartLabelledAnalyticsTabs(config: any) {
  let tabs = config.tabs?.[REPORTS_TABS_ID]?.items ?? [];
  for (const tab of tabs) {
    if (tab.usingSmartLabel === undefined) {
      tab.usingSmartLabel = true;
    }
  }
  tabs = tabs.filter((tab: any) => tab.type !== 'dailyOverview');
  if (config.tabs?.[REPORTS_TABS_ID]?.items != null) {
    config.tabs[REPORTS_TABS_ID].items = tabs;
  }
}
