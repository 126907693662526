import { compact } from 'lodash';
import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import type { ExpectTrue } from '../tests';
import { EMPTY_ARRAY } from '../utils/empty';
import type { CustomerDealSummary } from './CustomerOrderSummary';
import {
  PricingModeEnum,
  QuoteStatusEnum,
  type CustomerRiskApprovalStatusEnum,
  type CustomerWorkflowEnum,
  type ICustomerQuote,
  type SideEnum,
} from './types';

export const isCustomerQuote = (entity: any): entity is CustomerQuote => {
  return entity instanceof CustomerQuote;
};

export class CustomerQuote {
  static readonly rowID = 'RFQID';
  static readonly defaultColumns = [
    'Side',
    'Symbol',
    'OrderQty',
    'User',
    'Counterparty',
    'QuoteStatus',
    'RFQID',
  ] satisfies (keyof CustomerQuote)[];

  AmountCurrency!: string;

  BidAmt?: string;

  BidPx?: string;

  Counterparty!: string;

  Currency!: string;

  CustomerUser!: string;

  EndTime!: string;

  OrderQty!: string;

  OfferAmt?: string;

  OfferPx?: string;

  Parameters!: { [key: string]: string | string[] | boolean | undefined };

  MarketAccount!: string;

  QuoteID!: string;

  QuoteReqID!: string;

  QuoteStatus!: QuoteStatusEnum;

  RFQID!: string;

  Side?: SideEnum;

  SubmitTime!: string;

  Symbol!: string;

  Text?: string;

  Timestamp!: string;

  TradedAmt?: string;

  TradedPx?: string;

  TradedQty?: string;

  TradedSide?: SideEnum;

  User?: string;

  ValidUntilTime?: string;

  ExpectedHedgeOfferPx?: string;

  ExpectedHedgeBidPx?: string;

  EffectiveBidSpread?: string;

  EffectiveOfferSpread?: string;

  OrigQuoteReqID!: string;

  TransactTime!: string;
  InternalText?: string | undefined;
  InternalQuoteRequestRejectReason?: string | undefined;
  QuoteRequestRejectReason?: string | undefined;
  SubAccount?: string | undefined;
  MessageID?: unknown;
  ReferenceQuoteID?: unknown;
  Group?: string | undefined;
  Comments?: string | undefined;
  InternalMarketErrors?: unknown;
  EventIndicator?: unknown;

  PricingMode?: PricingModeEnum;

  PricingReference?: string | undefined;

  summary?: CustomerDealSummary;

  Workflow?: CustomerWorkflowEnum;

  BuySideApprovalStatus?: CustomerRiskApprovalStatusEnum;
  SellSideApprovalStatus?: CustomerRiskApprovalStatusEnum;

  FixingDetails?: {
    Index?: string;
    Fixing?: string;
  };

  constructor(data: CustomerQuote) {
    Object.assign(this, data);
  }
}

interface UseCustomerQuoteColumns {
  defaultColumns?: (keyof CustomerQuote | Partial<Column>)[];
  source?: 'monitoring' | 'dealer-monitoring';
  enableMakerMarketplaceETFRFQFlow?: boolean;
}

export function useCustomerQuoteColumns({
  defaultColumns = EMPTY_ARRAY,
  source = 'dealer-monitoring',
  enableMakerMarketplaceETFRFQFlow = false,
}: UseCustomerQuoteColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'SubmitTime',
              type: 'date',
              sortable: true,
              checkboxSelection: (params: any) => params.data.QuoteStatus === QuoteStatusEnum.PendingFix,
              headerCheckboxSelection: enableMakerMarketplaceETFRFQFlow,
            },
            { field: 'Counterparty', type: 'customer', title: 'Customer' },
            { field: 'MarketAccount', type: 'text', title: 'Account', id: 'marketAccount' },
            {
              field: 'MarketAccount',
              type: 'marketAccountSourceAccountID',
              id: 'customerAccountID',
              hide: true,
            },
            { field: 'RFQID', type: 'id' },
            { field: 'Side', type: 'quoteSide' },
            { field: 'Symbol', type: 'security' },
            { field: 'OrderQty', type: 'size', params: { currencyField: 'Currency' } },
            { field: 'TradedPx', type: 'price', params: { assetField: 'Symbol' } },
            { field: 'TradedAmt', type: 'size', params: { currencyField: 'AmountCurrency' } },
            { field: 'QuoteStatus', type: 'quoteStatus' },
            { field: 'QuoteID', type: 'id' },
          ] satisfies ColumnDef<CustomerQuote>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    [enableMakerMarketplaceETFRFQFlow]
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        compact([
          { field: 'User', type: 'user' },
          source === 'monitoring' && { field: 'SubAccount', type: 'subAccount' },
          { field: 'Timestamp', type: 'date' },
          source === 'monitoring' && { field: 'MessageID', type: 'text' },
          { field: 'QuoteReqID', type: 'id' },
          source === 'monitoring' && { field: 'QuoteRequestRejectReason', type: 'text' },
          { field: 'EndTime', type: 'date' },
          { field: 'TradedQty', type: 'size', params: { currencyField: 'Currency' } },
          { field: 'TradedSide', type: 'side' },
          source === 'monitoring' && { field: 'Group', type: 'text' },
          { field: 'Text', type: 'text' },
          { field: 'Parameters', type: 'text' },
          source === 'monitoring' && { field: 'Comments', type: 'text' },
          {
            field: 'BidPx',
            type: 'price',
            params: {
              assetField: 'Symbol',
              isReferencePrice: (data: CustomerQuote) => data.PricingMode === PricingModeEnum.SpreadToFixing,
            },
          },
          { field: 'BidAmt', type: 'size', params: { currencyField: 'AmountCurrency' } },
          {
            field: 'OfferPx',
            type: 'price',
            params: {
              assetField: 'Symbol',
              isReferencePrice: (data: CustomerQuote) => data.PricingMode === PricingModeEnum.SpreadToFixing,
            },
          },
          { field: 'OfferAmt', type: 'size', params: { currencyField: 'AmountCurrency' } },
          { field: 'ValidUntilTime', type: 'date' },
          source === 'monitoring' && { field: 'ReferenceQuoteID', type: 'text' },
          source === 'monitoring' && { field: 'InternalQuoteRequestRejectReason', type: 'text' },
          { field: 'CustomerUser', type: 'text' },
          { field: 'ExpectedHedgeBidPx', type: 'price', params: { assetField: 'Symbol' } },
          { field: 'ExpectedHedgeOfferPx', type: 'price', params: { assetField: 'Symbol' } },
          { field: 'EffectiveBidSpread', type: 'text' },
          enableMakerMarketplaceETFRFQFlow ? { field: 'PricingMode', type: 'text', title: 'Pricing Mode' } : undefined,
          { field: 'EffectiveOfferSpread', type: 'text' },
          { title: 'Allowed Slippage', type: 'text', id: 'allowedSlippage' },
          { field: 'OrigQuoteReqID', type: 'id' },
          { field: 'InternalText', type: 'text' },
          enableMakerMarketplaceETFRFQFlow
            ? {
                field: 'PricingReference',
                type: 'price',
                params: {
                  isReferencePrice: true,
                },
              }
            : undefined,
          enableMakerMarketplaceETFRFQFlow
            ? {
                field: 'FixingDetails.Index',
                title: 'Fixing Index',
                type: 'text',
              }
            : undefined,
          enableMakerMarketplaceETFRFQFlow
            ? {
                field: 'FixingDetails.Fixing',
                title: 'Fixing Price',
                type: 'price',
                params: {
                  // TODO fhqvst this should probably be `FixingDetails.Index`
                  assetField: 'Symbol',
                },
              }
            : undefined,
        ]) satisfies (false | ColumnDef<CustomerQuote>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, [source, enableMakerMarketplaceETFRFQFlow]);

  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<CustomerQuote>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);

  return useDefaultColumns(defaultColumns, columnDefinitions);
}

// TYPE LEVEL TESTS
type _Expect_CustomerQuote_To_Only_Have_Keys_From_ICustomerQuote = ExpectTrue<
  {
    [K in keyof CustomerQuote & string]: K extends keyof ICustomerQuote ? true : K;
  }[Exclude<
    keyof CustomerQuote,
    'summary' | 'TransactTime' | 'FixingDetails' /* TODO Remove TransactTime from this type, it shouldn't be here */
  >]
>;
type _Expect_All_Keys_In_ICustomerQuote_To_Be_In_CustomerQuote = ExpectTrue<
  {
    [K in keyof ICustomerQuote & string]: K extends keyof CustomerQuote ? true : K;
  }[Exclude<keyof ICustomerQuote, never>]
>;
