import type { Colors, DefaultTheme } from 'styled-components';

import { Direction } from '../../types/Direction';
import { setAlpha } from '../utils';
import { base } from './BaseTheme';
import { ThemeTypes } from './types';

export const darkColors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#181A22',

    '000': '#01030C',
    '010': '#10121A',
    '020': '#20222A',
    '030': '#272930',
    '040': '#2F3038',
    '050': '#37383F',
    '060': '#414249',
    '070': '#53545A',
    '080': '#808186',
    '090': '#A3A4A7',
    '100': '#E5E5E6',
  },

  primary: {
    mute: 'rgba(49, 74, 140, 0.1)',
    dim: 'rgba(49, 74, 140, 0.4)',
    default: '#314A8C',
    lighten: '#4B64A6',
  },

  green: {
    mute: 'rgba(51, 126, 90, 0.1)',
    dim: 'rgba(51, 126, 90, 0.4)',
    default: '#2E7E57',
    lighten: '#56AE84',
  },

  red: {
    mute: 'rgba(146, 46, 67, 0.1)',
    dim: 'rgba(146, 46, 67, 0.4)',
    default: '#A63240',
    lighten: '#F3455A',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    default: '#338EEB',
    lighten: '#4AA4FF',
    dim: 'rgba(51, 136, 224, 0.4)',
    mute: 'rgba(51, 136, 224, 0.1)',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: {
    default: '#8B4ED9',
    lighten: '#A45FFF',
  },
};

export const DarkTheme: DefaultTheme = mapDarkTheme(darkColors, {});

export function mapDarkTheme(colors: Colors, customMappings: Partial<DefaultTheme>): DefaultTheme {
  return {
    ...base,
    type: ThemeTypes.dark,
    name: 'Dark',

    colors,

    backgroundBody: colors.gray['000'],
    backgroundShadow: colors.gray['000'],
    backgroundLayoutWrapper: 'transparent',

    backgroundContent: colors.gray.main,
    backgroundContentHover: colors.gray['020'],
    backgroundDrawer: colors.gray['020'],
    backdropFilterDrawer: 'none',
    backgroundPopover: colors.gray['030'],
    backgroundPopoverHighlight: colors.gray['040'],
    backgroundModal: colors.gray['020'],
    borderColorModal: colors.gray['020'],
    borderColorPopover: setAlpha(0.08, colors.gray['100']),
    backgroundCard: setAlpha(0.04, colors.gray['100']),

    backgroundOverlay: setAlpha(0.8, colors.gray['000']),

    backgroundSidebar: colors.gray['020'],
    borderColorSidebar: colors.gray['020'],
    backgroundSidebarItemActive: colors.gray['040'],
    backgroundSidebarItemHover: colors.gray['030'],
    backdropFilterSidebar: 'none',

    colorTextSidebarItem: colors.gray['090'],
    colorTextSidebarItemActive: colors.gray['100'],
    colorTextSidebarItemHover: colors.gray['100'],

    boxShadowContent: 'none',
    boxShadowHeader: 'none',
    backdropFilterContent: 'none',
    backdropFilterHeader: 'none',

    colorBlotterTableHeader: colors.gray['090'],

    backgroundBlotterTableRow: colors.gray.main,
    backgroundBlotterTableRowSelected: colors.gray['030'],
    backgroundBlotterTableRowHover: colors.gray['020'],
    borderColorBlotterTable: setAlpha(0.56, colors.gray['000']),

    backgroundScrollbar: 'transparent',
    colorScrollbar: setAlpha(0.5, colors.gray['030']),
    colorScrollbarHover: setAlpha(0.5, colors.gray['080']),

    borderColorContent: 'transparent',
    backgroundHeader: colors.gray['020'],
    borderColorHeader: colors.gray['020'],

    backgroundDefaultButton: setAlpha(0.08, colors.gray['100']),
    backgroundDefaultButtonHover: setAlpha(0.12, colors.gray['100']),
    backgroundDefaultButtonFocus: setAlpha(0.12, colors.gray['100']),
    backgroundDefaultButtonActive: setAlpha(0.08, colors.gray['100']),
    backgroundDefaultButtonDisabled: setAlpha(0.04, colors.gray['100']),

    backgroundPriorityButton: setAlpha(0.24, colors.gray['100']),
    backgroundPriorityButtonHover: setAlpha(0.32, colors.gray['100']),
    backgroundPriorityButtonFocus: setAlpha(0.32, colors.gray['100']),
    backgroundPriorityButtonActive: setAlpha(0.24, colors.gray['100']),
    backgroundPriorityButtonDisabled: setAlpha(0.12, colors.gray['100']),

    backgroundPrimaryButton: colors.primary.default,
    backgroundPrimaryButtonHover: colors.primary.lighten,
    backgroundPrimaryButtonFocus: colors.primary.lighten,
    backgroundPrimaryButtonActive: colors.primary.default,
    backgroundPrimaryButtonDisabled: setAlpha(0.5, colors.primary.default),

    backgroundPositiveButton: colors.green.default,
    backgroundPositiveButtonHover: colors.green.lighten,
    backgroundPositiveButtonFocus: colors.green.lighten,
    backgroundPositiveButtonActive: colors.green.default,
    backgroundPositiveButtonDisabled: setAlpha(0.5, colors.green.default),

    backgroundNegativeButton: colors.red.default,
    backgroundNegativeButtonHover: colors.red.lighten,
    backgroundNegativeButtonFocus: colors.red.lighten,
    backgroundNegativeButtonActive: colors.red.default,
    backgroundNegativeButtonDisabled: setAlpha(0.5, colors.red.default),

    backgroundWarningButton: colors.yellow.default,
    backgroundWarningButtonHover: colors.yellow.lighten,
    backgroundWarningButtonFocus: colors.yellow.lighten,
    backgroundWarningButtonActive: colors.yellow.default,
    backgroundWarningButtonDisabled: setAlpha(0.5, colors.yellow.default),

    boxShadowPrimaryButton: 'none',
    boxShadowPrimaryButtonHover: 'none',
    boxShadowPrimaryButtonFocus: 'none',
    boxShadowPrimaryButtonActive: 'none',
    boxShadowPrimaryButtonDisabled: 'none',

    boxShadowPositiveButton: 'none',
    boxShadowPositiveButtonHover: 'none',
    boxShadowPositiveButtonFocus: 'none',
    boxShadowPositiveButtonActive: 'none',
    boxShadowPositiveButtonDisabled: 'none',

    boxShadowNegativeButton: 'none',
    boxShadowNegativeButtonHover: 'none',
    boxShadowNegativeButtonFocus: 'none',
    boxShadowNegativeButtonActive: 'none',
    boxShadowNegativeButtonDisabled: 'none',

    boxShadowPriorityButton: 'none',
    boxShadowPriorityButtonHover: 'none',
    boxShadowPriorityButtonFocus: 'none',
    boxShadowPriorityButtonActive: 'none',
    boxShadowPriorityButtonDisabled: 'none',

    boxShadowDefaultButton: 'none',
    boxShadowDefaultButtonHover: 'none',
    boxShadowDefaultButtonFocus: 'none',
    boxShadowDefaultButtonActive: 'none',
    boxShadowDefaultButtonDisabled: 'none',

    boxShadowInput: 'none',
    boxShadowInputHover: 'none',
    boxShadowInputFocus: 'none',
    boxShadowInputInvalid: 'none',
    boxShadowInputDisabled: 'none',
    boxShadowInputReadOnly: 'none',
    boxShadowInputInvalidFocus: 'none',

    backgroundPrimary: colors.primary.default,
    backgroundPositive: colors.green.default,
    backgroundNegative: colors.red.default,
    backgroundPrimarySubtle: colors.primary.dim,
    backgroundPositiveSubtle: colors.green.dim,
    backgroundNegativeSubtle: colors.red.dim,
    backgroundPrimaryMuted: colors.primary.mute,
    backgroundPositiveMuted: colors.green.mute,
    backgroundNegativeMuted: colors.red.mute,

    backgroundInput: setAlpha(0.2, colors.gray['000']),
    backgroundInputDisabled: setAlpha(0.16, colors.gray['000']),
    backgroundInputReadOnly: setAlpha(0.2, colors.gray['000']),
    backgroundInputInvalid: 'transparent',

    backgroundSelect: setAlpha(0.08, colors.gray['100']),
    backgroundSelectHover: setAlpha(0.12, colors.gray['100']),
    backgroundSelectFocus: setAlpha(0.12, colors.gray['100']),
    backgroundSelectDisabled: setAlpha(0.12, colors.gray['100']),
    backgroundSelectReadOnly: setAlpha(0.08, colors.gray['100']),
    backgroundSelectInvalid: setAlpha(0.08, colors.gray['100']),

    backgroundSelectDropdown: colors.gray['010'],

    backgroundToggle: setAlpha(0.52, colors.gray['000']),
    backgroundToggleChecked: colors.primary.default,
    backgroundToggleSwitch: colors.gray['090'],
    backgroundToggleSwitchChecked: colors.white.default,

    backgroundToggleSwitchButton: setAlpha(0.32, colors.gray['000']),
    backgroundToggleSwitchButtonActive: setAlpha(0.12, colors.gray.main),

    backgroundCheckbox: setAlpha(0.08, colors.gray['100']),
    backgroundCheckboxHover: setAlpha(0.12, colors.gray['100']),
    backgroundCheckboxChecked: colors.primary.default,
    backgroundCheckboxCheckedHover: colors.primary.lighten,

    colorCheckboxCheck: colors.white.default,

    borderColorCheckbox: setAlpha(0.08, colors.gray['100']),
    borderColorCheckboxFocus: colors.blue.lighten,
    borderColorCheckboxHover: setAlpha(0.16, colors.gray['100']),
    borderColorCheckboxChecked: colors.primary.default,
    borderColorCheckboxCheckedFocus: colors.blue.lighten,

    backgroundRadiobutton: setAlpha(0.08, colors.gray['100']),
    backgroundRadiobuttonHover: setAlpha(0.12, colors.gray['100']),
    backgroundRadiobuttonChecked: colors.primary.default,
    backgroundRadiobuttonCheckedHover: colors.primary.lighten,

    colorRadiobuttonDot: colors.white.default,

    borderColorRadiobutton: setAlpha(0.08, colors.gray['100']),
    borderColorRadiobuttonFocus: colors.blue.lighten,
    borderColorRadiobuttonHover: setAlpha(0.16, colors.gray['100']),
    borderColorRadiobuttonChecked: colors.primary.default,
    borderColorRadiobuttonCheckedFocus: colors.blue.lighten,

    backgroundProgressBar: setAlpha(0.12, colors.gray['100']),
    backgroundProgressBarIndeterminate: colors.gray['050'],
    backgroundProgressBarMeter: colors.primary.default,

    colorTextMuted: colors.gray['070'],
    colorTextSubtle: colors.gray['080'],
    colorTextDefault: colors.gray['090'],
    colorTextImportant: colors.gray['100'],
    colorTextAttention: colors.white.default,

    colorTextDefaultButton: colors.gray['100'],
    colorTextDefaultButtonHover: colors.gray['100'],
    colorTextDefaultButtonFocus: colors.gray['100'],
    colorTextDefaultButtonActive: colors.gray['100'],
    colorTextDefaultButtonDisabled: setAlpha(0.5, colors.gray['100']),

    colorTextDefaultDimButton: colors.gray['090'],

    colorTextPriorityButton: colors.gray['100'],
    colorTextPriorityButtonHover: colors.gray['100'],
    colorTextPriorityButtonFocus: colors.gray['100'],
    colorTextPriorityButtonActive: colors.gray['100'],
    colorTextPriorityButtonDisabled: setAlpha(0.5, colors.gray['100']),

    colorTextPriorityDimButton: colors.gray['090'],

    colorTextPositiveButton: colors.white.default,
    colorTextPositiveButtonHover: colors.white.default,
    colorTextPositiveButtonFocus: colors.white.default,
    colorTextPositiveButtonActive: colors.white.default,
    colorTextPositiveButtonDisabled: setAlpha(0.5, colors.white.default),

    colorTextNegativeButton: colors.white.default,
    colorTextNegativeButtonHover: colors.white.default,
    colorTextNegativeButtonFocus: colors.white.default,
    colorTextNegativeButtonActive: colors.white.default,
    colorTextNegativeButtonDisabled: setAlpha(0.5, colors.white.default),

    colorTextWarningButton: colors.white.default,
    colorTextWarningButtonHover: colors.white.default,
    colorTextWarningButtonFocus: colors.white.default,
    colorTextWarningButtonActive: colors.white.default,
    colorTextWarningButtonDisabled: setAlpha(0.5, colors.white.default),

    colorTextPrimaryButton: colors.white.default,
    colorTextPrimaryButtonHover: colors.white.default,
    colorTextPrimaryButtonFocus: colors.white.default,
    colorTextPrimaryButtonActive: colors.white.default,
    colorTextPrimaryButtonDisabled: setAlpha(0.5, colors.white.default),

    colorTextPrimary: colors.primary.lighten,
    colorTextPrimarySubtle: colors.primary.default,
    colorTextPrimaryMuted: colors.primary.dim,
    colorTextPositive: colors.green.lighten,
    colorTextPositiveSubtle: colors.green.default,
    colorTextPositiveMuted: colors.green.dim,
    colorTextNegative: colors.red.lighten,
    colorTextNegativeSubtle: colors.red.default,
    colorTextNegativeMuted: colors.red.dim,
    colorTextWarning: colors.yellow.lighten,
    colorTextWarningSubtle: colors.yellow.default,
    colorTextWarningMuted: colors.yellow.mute,

    colorOrderStatusRejected: colors.yellow.lighten,
    colorOrderStatusFilled: colors.green.lighten,

    colorDataBlue: '#2C59D0',

    borderColorInput: setAlpha(0.08, colors.gray['100']),
    borderColorInputHover: setAlpha(0.2, colors.gray['100']),
    borderColorInputFocus: setAlpha(0.56, colors.gray['100']),

    borderColorInputInvalid: colors.red.lighten,
    borderColorInputInvalidFocus: colors.red.lighten,
    borderColorInputDisabled: setAlpha(0.04, colors.gray['100']),
    borderColorInputReadOnly: setAlpha(0.04, colors.gray['100']),

    borderColorSelect: 'transparent',
    borderColorSelectHover: 'transparent',
    borderColorSelectFocus: setAlpha(0.16, colors.gray['100']),
    borderColorSelectInvalid: colors.red.lighten,
    borderColorSelectDisabled: 'transparent',
    borderColorSelectReadOnly: 'transparent',

    borderColorDefaultButton: 'transparent',
    borderColorDefaultButtonHover: 'transparent',
    borderColorDefaultButtonFocus: 'transparent',
    borderColorDefaultButtonActive: 'transparent',
    borderColorDefaultButtonDisabled: 'transparent',

    borderColorPriorityButton: 'transparent',
    borderColorPriorityButtonHover: 'transparent',
    borderColorPriorityButtonFocus: 'transparent',
    borderColorPriorityButtonActive: 'transparent',
    borderColorPriorityButtonDisabled: 'transparent',

    borderColorPositiveButton: 'transparent',
    borderColorPositiveButtonHover: 'transparent',
    borderColorPositiveButtonFocus: 'transparent',
    borderColorPositiveButtonActive: 'transparent',
    borderColorPositiveButtonDisabled: 'transparent',

    borderColorNegativeButton: 'transparent',
    borderColorNegativeButtonHover: 'transparent',
    borderColorNegativeButtonFocus: 'transparent',
    borderColorNegativeButtonActive: 'transparent',
    borderColorNegativeButtonDisabled: 'transparent',

    borderColorPrimaryButton: 'transparent',
    borderColorPrimaryButtonHover: 'transparent',
    borderColorPrimaryButtonFocus: 'transparent',
    borderColorPrimaryButtonActive: 'transparent',
    borderColorPrimaryButtonDisabled: 'transparent',

    borderColorWarningButton: 'transparent',
    borderColorWarningButtonHover: 'transparent',
    borderColorWarningButtonFocus: 'transparent',
    borderColorWarningButtonActive: 'transparent',
    borderColorWarningButtonDisabled: 'transparent',

    borderColorChartAxis: colors.gray['040'],
    borderColorChartGridLine: colors.gray['040'],
    borderColorChartCrosshair: colors.gray['070'],
    backgroundChartUpColor: colors.green.lighten,
    backgroundChartDownColor: colors.red.lighten,
    borderColorChartUpColor: colors.green.lighten,
    borderColorChartDownColor: colors.red.lighten,
    backgroundChart: colors.gray.main,

    borderColorDivider: colors.gray['050'],
    backgroundDivider: setAlpha(0.56, colors.gray['000']),

    borderColorTimeline: colors.gray['070'],
    backgroundTimelineContent: colors.gray['030'],
    backgroundTimelineOverlay: `linear-gradient(to top, ${setAlpha(1, colors.gray['020'])}, ${setAlpha(
      0,
      colors.gray['020']
    )})`,

    borderColorSelectionTable: colors.gray.main,
    borderColorSelectionTableActive: colors.gray['030'],

    colorTab: colors.gray['090'],
    colorTabHover: colors.gray['100'],
    colorTabActive: colors.gray['090'],
    colorTabSelected: colors.gray['100'],
    borderColorTab: setAlpha(0.56, colors.gray['000']),
    backgroundFilledTab: colors.gray['030'],
    backgroundFilledTabList: colors.gray['010'],
    borderColorTabSelected: colors.gray['100'],

    boxShadowTab: 'none',
    boxShadowTabActive: 'none',

    backgroundResizeHandle: colors.gray['030'],
    backgroundResizeHandleHover: colors.gray['040'],

    backgroundLoader: colors.gray['100'],

    borderColorDrawer: colors.gray['020'],

    backgroundTabInput: colors.primary.dim,
    colorTabInputButton: colors.gray['080'],
    colorTabInputButtonHover: colors.gray['080'],
    colorTabInputButtonActive: colors.gray['100'],

    colorStepIconOpen: colors.gray['070'],
    colorStepIconActive: colors.primary.lighten,
    colorStepIconDone: colors.primary.lighten,
    colorStepConnectionOpen: colors.gray['070'],
    colorStepConnectionDone: colors.primary.lighten,

    backgroundAccordionContent: colors.gray['020'],

    colorTextVolumeLadderSpread: colors.gray['100'],
    backgroundVolumeLadderBidSpread: colors.primary.dim,
    backgroundVolumeLadderOfferSpread: colors.primary.dim,
    backgroundVolumeLadderTransparencyDirection: Direction.Asc,

    backgroundDropdownResults: colors.gray['040'],
    backgroundDropdownInput: colors.gray['030'],
    backgroundDropdownSelected: colors.gray['060'],
    backgroundDropdownHovered: colors.gray['050'],
    backgroundDropdownGroupHeader: colors.gray['050'],
    boxShadowDropdown: '0px 24px 40px 0px #00000066',

    backgroundModalHeader: colors.gray['050'],

    colorModeCurrencyIcon: 'monochrome',
    colorCurrencyIcon: colors.gray.main,

    ...customMappings,
  };
}
