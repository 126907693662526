import { defineMessages } from 'react-intl';

export const fieldsMessages = defineMessages({
  dataNameIsRequired: {
    id: 'fields.dataNameIsRequired',
    defaultMessage: '{dataName} is required',
  },
  fieldNameMustBePositive: {
    id: 'fields.fieldNameMustBePositive',
    defaultMessage: '{fieldName} must be positive',
  },
  fieldNameExceedsMaximumDpsOfScale: {
    id: 'fields.fieldNameExceedsMaximumDpsOfScale',
    defaultMessage: '{fieldName} exceeds maximum dps of {scale}',
  },
  minQuantityIsMinSize: {
    id: 'fields.minQuantityIsMinSize',
    defaultMessage: 'Min quantity is {minSize}',
  },
});
