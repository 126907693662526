import { HStack, Icon, IconName, Text } from '@talos/kyoko';
import type { D3ChartCenterProps } from './types';

type ChartCenterLabelProps<T> = Pick<D3ChartCenterProps<T>, 'highlightedInfo' | 'renderData'> & {
  labelSize: number;
};

const MIN_LABEL_SIZE_REM = 0.8;

export const ChartCenterLabel = <T,>({ renderData, highlightedInfo, labelSize }: ChartCenterLabelProps<T>) => {
  const centerLabels = highlightedInfo?.centerLabels ?? renderData.metadata.centerLabels;
  const controlledLabelSize = Math.max(MIN_LABEL_SIZE_REM, labelSize);

  return (
    <HStack fontSize={`${controlledLabelSize}rem`} gap="spacingSmall" w="100%">
      <HStack flexWrap="wrap" gap="spacingSmall" minWidth="0px">
        {centerLabels.map((label, i) => {
          const isLastLabel = i === centerLabels.length - 1;
          return (
            <HStack key={i} gap="spacingSmall" overflow="hidden">
              <Text
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                minWidth="0px"
                title={label}
                data-testid="d3-chart-center-label-text"
              >
                {label}
              </Text>
              {!isLastLabel && <Icon icon={IconName.ChevronRight} />}
            </HStack>
          );
        })}
      </HStack>
    </HStack>
  );
};
