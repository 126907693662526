import Big from 'big.js';
import { ParameterKeysEnum } from 'tokens/orderStrategy';

import {
  ACTION,
  AllocationValueTypeEnum,
  DecisionStatusEnum,
  ErrorActionEnum,
  OrdStatusEnum,
  ParameterTypeEnum,
  TimeInForceEnum,
  absoluteDateValue,
  calcDateFromDuration,
  convertReceivedAllocationForUI,
  formattedDateForSubscription,
  isDuration,
  isOrderComplete,
  isOrderPending,
  logger,
  parseDate,
  type ExecutionReport,
  type IAllocation,
  type Order,
  type OrderStrategy,
  type Parameter,
  type Security,
  type UpperCased,
} from '@talos/kyoko';
import { upperFirst } from 'lodash';
import type { OMSForm, OMSFormOrderParameters } from 'providers/OMSContext.types';
import type { FullOrderRequest } from 'providers/orders.types';
import { useCallback } from 'react';
import { useOrderAccess, type OrderAccessEntity } from '../hooks/useOrderAccess';
import { useRoleAuth } from '../hooks/useRoleAuth';
import type { AvaAcceptQuoteRequest } from '../providers/quotes.types';

interface FormatParameterArgs<TArgType> {
  parameters: UpperCased<OMSFormOrderParameters>;
  key: TArgType;
  param: Parameter | undefined;
}

const capitalizeParameters = ({
  parameters,
}: {
  parameters: OMSFormOrderParameters;
}): UpperCased<OMSFormOrderParameters> => {
  Object.keys(parameters || {}).forEach(key => {
    const value = parameters[key];
    delete parameters[key];
    parameters[upperFirst(key)] = value;
  });
  return parameters;
};

const removeUnusedParameters = ({
  parameters,
  selectedParameters,
}: {
  parameters: UpperCased<OMSFormOrderParameters>;
  selectedParameters?: Parameter[];
}) => {
  for (const key in parameters) {
    if (parameters[key] === '' || !selectedParameters?.map(p => p.Name)?.includes(key)) {
      delete parameters[key];
    }
  }
  return parameters;
};

const dividePercentParameter = ({ parameters, key, param }: FormatParameterArgs<string>) => {
  if (param?.Type === ParameterTypeEnum.Percent) {
    const percentValue = parameters[key] as string;
    parameters[key] = Big(percentValue).div(100).toFixed();
  }
};

const formatDateParameter = ({
  parameters,
  key,
  param,
}: FormatParameterArgs<ParameterKeysEnum.StartTime | ParameterKeysEnum.EndTime | string>) => {
  if (key === ParameterKeysEnum.StartTime || key === ParameterKeysEnum.EndTime) {
    const rawParamValue = parameters[key];
    switch (typeof rawParamValue) {
      case 'string':
        parameters[key] = formattedDateForSubscription(rawParamValue);
        break;
      case 'undefined':
        break;
      default:
        // could be a date or duration object, need to convert to a date we can send to the server
        if (!rawParamValue) {
          break;
        }
        if (isDuration(rawParamValue)) {
          const relativeTo =
            key === ParameterKeysEnum.EndTime
              ? absoluteDateValue(parameters[ParameterKeysEnum.StartTime], parseDate())
              : parseDate();
          parameters[key] = formattedDateForSubscription(calcDateFromDuration(rawParamValue, relativeTo));
        } else {
          parameters[key] = formattedDateForSubscription(rawParamValue ?? undefined);
        }
    }
  }
  if (param?.Type === ParameterTypeEnum.Date) {
    return formattedDateForSubscription(parameters[key] as string | undefined);
  }
};

const formatEnumParameter = ({ parameters, key, param }: FormatParameterArgs<string | number>) => {
  if (param?.Type === ParameterTypeEnum.Enum) {
    if (typeof parameters[key] === 'string') {
      parameters[key] = parseInt(parameters[key] as string);
    }
  }
};

export const cleanParametersForOrder = ({
  parameters: currParameters,
  selectedStrategy,
}: {
  parameters?: OMSFormOrderParameters;
  selectedStrategy?: OrderStrategy;
}): UpperCased<OMSFormOrderParameters> & { ErrorAction: ErrorActionEnum } => {
  // Don't modify original parameters obj
  // Capitalize Parameters
  const parameters = capitalizeParameters({ parameters: { ...currParameters } });

  const selectedParameters = selectedStrategy?.Parameters;

  // Delete Incorrect and Unused Strategy Parameters
  removeUnusedParameters({ parameters, selectedParameters });

  Object.keys(parameters).forEach(key => {
    const param = selectedParameters?.find(p => p.Name === key);

    // Fix Percentage Parameters From 0-100 to Value Between 0-1
    dividePercentParameter({ parameters, key, param });
    formatDateParameter({ parameters, key, param });
    formatEnumParameter({ parameters, key, param });
  });

  // [ch23616] UI to send ErrorAction=Pause on all orders
  // This will later be removed and set from the orderStrategy subscription
  return { ...parameters, ErrorAction: ErrorActionEnum.Pause };
};

export const prepareAllocationOrderForUI = <T extends { Allocation?: IAllocation }>(
  data: T
): T & Pick<OMSForm, 'subAccountAllocations' | 'allocationValueType'> => {
  const order = { ...data };
  const allocation = order?.Allocation;

  if (!allocation?.ValueType || !allocation.Allocations) {
    return {
      ...order,
      subAccountAllocations: [],
      allocationValueType: AllocationValueTypeEnum.Percentage,
    };
  }

  const { subAccountAllocations, allocationValueType } = convertReceivedAllocationForUI(allocation);
  return { ...order, subAccountAllocations, allocationValueType };
};

export function ensureFloat(n?: number | string): number {
  if (n == null) {
    return 0.0;
  }
  if (typeof n === 'number') {
    return n;
  }
  return parseFloat(n);
}

export function assertOrderCurrency(order: FullOrderRequest | AvaAcceptQuoteRequest, security: Security): boolean {
  const isValid = order.Currency
    ? order.Currency === security.BaseCurrency || order.Currency === security.QuoteCurrency
    : true;
  if (!isValid) {
    logger.error(new Error(`Wrong currency ${order.Currency} in order for symbol ${order.Symbol}`));
  }
  return isValid;
}

export function useAssertOrderModifyable() {
  const { isAuthorized } = useRoleAuth();
  const checkWriteAccessToOrder = useOrderAccess();

  return useCallback(
    (executionReportLike: Pick<ExecutionReport, 'OrdStatus'> & OrderAccessEntity) => {
      if (!isAuthorized(ACTION.SUBMIT_ORDER)) {
        return false;
      }
      if (isOrderPending(executionReportLike.OrdStatus)) {
        return false;
      }
      if (!checkWriteAccessToOrder(executionReportLike)) {
        return false;
      }
      return true;
    },
    [checkWriteAccessToOrder, isAuthorized]
  );
}

export function useAssertOrderCancelable() {
  const { isAuthorized } = useRoleAuth();
  const checkWriteAccessToOrder = useOrderAccess();

  return useCallback(
    (order: Order | ExecutionReport) => {
      if (!isAuthorized(ACTION.SUBMIT_ORDER)) {
        return false;
      }
      if (order.OrdStatus === OrdStatusEnum.PendingCancel) {
        return false;
      }
      if (order.TimeInForce !== TimeInForceEnum.GoodTillCancel) {
        return false;
      }
      if (!checkWriteAccessToOrder(order)) {
        return false;
      }
      return true;
    },
    [checkWriteAccessToOrder, isAuthorized]
  );
}

export function useAssertOrderPausable() {
  const checkWriteAccessToOrder = useOrderAccess();

  return useCallback(
    (executionReportLike: Pick<ExecutionReport, 'OrdStatus' | 'DecisionStatus'> & OrderAccessEntity) => {
      if (isOrderComplete(executionReportLike.OrdStatus)) {
        return false;
      }
      // DecisionStatusEnum.SystemPaused is included here as these orders can still be "Paused".
      if (
        ![
          DecisionStatusEnum.Active,
          DecisionStatusEnum.PendingResume,
          DecisionStatusEnum.Staged,
          DecisionStatusEnum.SystemPaused,
          DecisionStatusEnum.WaitingForStartTime,
          DecisionStatusEnum.WaitingForTrigger,
        ].includes(executionReportLike.DecisionStatus)
      ) {
        return false;
      }
      if (!checkWriteAccessToOrder(executionReportLike)) {
        return false;
      }

      return true;
    },
    [checkWriteAccessToOrder]
  );
}

export function useAssertOrderResumable() {
  const checkWriteAccessToOrder = useOrderAccess();

  return useCallback(
    (executionReportLike: Pick<ExecutionReport, 'OrdStatus' | 'DecisionStatus'> & OrderAccessEntity) => {
      if (isOrderComplete(executionReportLike.OrdStatus)) {
        return false;
      }
      if (
        ![
          DecisionStatusEnum.Paused,
          DecisionStatusEnum.PendingPause,
          DecisionStatusEnum.Staged,
          DecisionStatusEnum.SystemPaused,
        ].includes(executionReportLike.DecisionStatus)
      ) {
        return false;
      }
      if (!checkWriteAccessToOrder(executionReportLike)) {
        return false;
      }

      return true;
    },
    [checkWriteAccessToOrder]
  );
}
