import { useCallback, useEffect, useState } from 'react';
import { map, scan, shareReplay } from 'rxjs/operators';

import { MarketAccountsContext, type MarketAccount } from '../contexts/MarketAccountsContext';
import { useObservable, useObservableValue, useStaticSubscription } from '../hooks';
import { wsScanToDoubleMap } from '../pipes';
import { wsScanToMap } from '../pipes/wsScanToMap';
import { MARKET_ACCOUNT } from '../tokens';
import type { SubscriptionResponse } from '../types/SubscriptionResponse';
import {
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  OrderMarketStatusEnum,
  type IOMSExecutionReport4203Markets,
} from '../types/types';

export const MarketAccountsProvider = function MarketAccountsProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: subscription } = useStaticSubscription<MarketAccount>({
    name: MARKET_ACCOUNT,
    tag: 'MarketAccountsProvider',
  });

  const filteredSubscription = useObservable<SubscriptionResponse<MarketAccount>>(
    () =>
      subscription.pipe(
        map(res => ({
          ...res,
          data: res.data.filter((d: MarketAccount) => {
            // [sc-33537] Ignore Type=Unknown Market Accounts
            if (d.Type === MarketAccountTypeEnum.Unknown) {
              return false;
            } else {
              return true;
            }
          }),
        }))
      ),
    [subscription]
  );

  const marketAccountsByIDObservable = useObservable(
    () =>
      filteredSubscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.MarketAccountID, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  // MarketAccount.Name is a unique key
  const marketAccountsByNameObservable = useObservable(
    () =>
      filteredSubscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.Name, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  const marketAccountsByCredentialIDByNameObservable = useObservable(
    () =>
      subscription.pipe(
        wsScanToDoubleMap({
          getKey1: ma => ma.CredentialID,
          getKey2: ma => ma.Name,
          newOuterMapEachUpdate: true,
          newInnerMapsEachUpdate: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const marketAccountsBySourceAccountIDObservable = useObservable<Map<string, MarketAccount>>(
    () =>
      filteredSubscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.SourceAccountID, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  const marketAccountsByMarketObservable = useObservable<Map<string, MarketAccount[]>>(
    () =>
      filteredSubscription.pipe(
        scan((memo, json) => {
          if (json.initial) {
            memo.clear();
          }
          for (const d of json.data) {
            const marketAccounts = memo.get(d.Market) || [];
            const idx = marketAccounts.findIndex(ma => ma.Name === d.Name);
            if (idx !== -1) {
              marketAccounts[idx] = d;
            } else {
              marketAccounts.push(d);
            }
            memo.set(d.Market, marketAccounts);
          }
          return memo;
        }, new Map<string, MarketAccount[]>()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  const marketAccountsByCounterpartyObservable = useObservable<Map<string, MarketAccount[]>>(
    () =>
      filteredSubscription.pipe(
        scan((memo, json) => {
          if (json.initial) {
            memo.clear();
          }
          for (const d of json.data) {
            const marketAccounts = memo.get(d.Counterparty) || [];
            const idx = marketAccounts.findIndex(ma => ma.Name === d.Name);
            if (idx !== -1) {
              marketAccounts[idx] = d;
            } else {
              marketAccounts.push(d);
            }
            memo.set(d.Counterparty, marketAccounts);
          }
          return memo;
        }, new Map<string, MarketAccount[]>()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  const listMarketAccountsObservable = useObservable(
    () =>
      marketAccountsByIDObservable.pipe(
        map(memo => [...memo.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketAccountsByIDObservable]
  );

  const activeMarketAccountsObservable = useObservable(
    () =>
      listMarketAccountsObservable.pipe(
        map(memo => memo.filter(marketAccount => marketAccount.Status === MarketAccountStatusEnum.Active))
      ),
    [listMarketAccountsObservable]
  );

  const activeCustomerMarketAccountsObservable = useObservable(
    () =>
      activeMarketAccountsObservable.pipe(
        map(memo => memo.filter(marketAccount => marketAccount.Type === MarketAccountTypeEnum.Customer))
      ),
    [activeMarketAccountsObservable]
  );

  // This is an iteration made on top of the iterations that filter down from
  // known market accounts => active market accounts => customer market account types.
  // If we get to a place where we're iterating over collections that are too large,
  // and this becomes a drain on performance, we can rework the internals of this
  // to apply the status and type filters in addition to the Map building all
  // on a single pass through the iterable. Downstream consumers won't need to
  // be affected as long as they're referencing this named export.
  const activeCustomerMarketAccountsByCounterpartyAndNameObservable = useObservable(
    () =>
      activeCustomerMarketAccountsObservable.pipe(
        scan((memo, marketAccounts) => {
          return marketAccounts.reduce((acc, marketAccount) => {
            if (!acc.has(marketAccount.Counterparty)) {
              acc.set(marketAccount.Counterparty, new Map());
            }
            acc.get(marketAccount.Counterparty)!.set(marketAccount.Name, marketAccount);
            return acc;
          }, memo);
        }, new Map<string, Map<string, MarketAccount>>()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [activeCustomerMarketAccountsObservable]
  );

  const marketAccountsByID = useObservableValue(() => marketAccountsByIDObservable, [marketAccountsByIDObservable]);
  const marketAccountsByName = useObservableValue(
    () => marketAccountsByNameObservable,
    [marketAccountsByNameObservable]
  );
  const marketAccountsByCredentialIDByName = useObservableValue(
    () => marketAccountsByCredentialIDByNameObservable,
    [marketAccountsByCredentialIDByNameObservable]
  );
  const marketAccountsBySourceAccountID = useObservableValue(
    () => marketAccountsBySourceAccountIDObservable,
    [marketAccountsBySourceAccountIDObservable]
  );
  const marketAccountsByMarket = useObservableValue(
    () => marketAccountsByMarketObservable,
    [marketAccountsByMarketObservable]
  );
  const marketAccountsByCounterparty = useObservableValue(
    () => marketAccountsByCounterpartyObservable,
    [marketAccountsByCounterpartyObservable]
  );
  const marketAccountsList = useObservableValue(() => listMarketAccountsObservable, [listMarketAccountsObservable]);
  const activeMarketAccountsList = useObservableValue(
    () => activeMarketAccountsObservable,
    [activeMarketAccountsObservable]
  );
  const activeCustomerMarketAccountsList = useObservableValue(
    () => activeCustomerMarketAccountsObservable,
    [activeCustomerMarketAccountsObservable]
  );
  const activeCustomerMarketAccountsByCounterpartyAndName = useObservableValue(
    () => activeCustomerMarketAccountsByCounterpartyAndNameObservable,
    [activeCustomerMarketAccountsByCounterpartyAndNameObservable]
  );

  const marketAccountDisplayNameByName = useObservableValue(
    () =>
      marketAccountsByNameObservable.pipe(
        scan((prevMap, marketAccountMap) => {
          let emitNewValue = false;
          const newMap = new Map<string, string>();
          marketAccountMap.forEach((value, key) => {
            if (prevMap.get(key) !== value.DisplayName) {
              emitNewValue = true;
            }
            newMap.set(key, value.DisplayName);
          });
          return emitNewValue ? newMap : prevMap;
        }, new Map<string, string>()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketAccountsByNameObservable],
    new Map<string, string>()
  );

  useEffect(() => {
    if (!isLoaded) {
      const hasLoadedNow = [
        marketAccountsByID,
        marketAccountsByName,
        marketAccountsByCredentialIDByName,
        marketAccountsBySourceAccountID,
        marketAccountsByMarket,
        marketAccountsByCounterparty,
        marketAccountsList,
        activeMarketAccountsList,
        activeCustomerMarketAccountsList,
        activeCustomerMarketAccountsByCounterpartyAndName,
        marketAccountDisplayNameByName,
      ].every(dataset => dataset != null);
      if (hasLoadedNow) {
        setIsLoaded(hasLoadedNow);
      }
    }
  }, [
    isLoaded,
    marketAccountsByID,
    marketAccountsByName,
    marketAccountsByCredentialIDByName,
    marketAccountsBySourceAccountID,
    marketAccountsByMarket,
    marketAccountsByCounterparty,
    marketAccountsList,
    activeMarketAccountsList,
    activeCustomerMarketAccountsList,
    activeCustomerMarketAccountsByCounterpartyAndName,
    marketAccountDisplayNameByName,
  ]);

  const isMarketAccountActive = useCallback((ma?: MarketAccount) => ma?.Status === MarketAccountStatusEnum.Active, []);
  const isMarketStatusError = useCallback(
    (m: Partial<IOMSExecutionReport4203Markets>) =>
      m.MarketStatus !== OrderMarketStatusEnum.Online && m.MarketStatus !== OrderMarketStatusEnum.Disabled,
    []
  );

  return (
    <MarketAccountsContext.Provider
      value={{
        isLoaded,
        marketAccountsByID: marketAccountsByID!,
        marketAccountsByName: marketAccountsByName!,
        marketAccountsByMarket: marketAccountsByMarket!,
        marketAccountsByCounterparty: marketAccountsByCounterparty!,
        marketAccountsList: marketAccountsList!,
        marketAccountsByCredentialIDByName: marketAccountsByCredentialIDByName!,
        marketAccountsBySourceAccountID: marketAccountsBySourceAccountID!,
        activeMarketAccountsList: activeMarketAccountsList!,
        activeCustomerMarketAccountsList: activeCustomerMarketAccountsList!,
        activeCustomerMarketAccountsByCounterpartyAndName: activeCustomerMarketAccountsByCounterpartyAndName!,
        isMarketAccountActive,
        isMarketStatusError,
        marketAccountDisplayNameByName,
      }}
    >
      {children}
    </MarketAccountsContext.Provider>
  );
};
