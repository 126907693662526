import {
  type Allocation,
  AllocationValueTypeEnum,
  Button,
  ButtonVariants,
  Divider,
  Drawer,
  DrawerFooter,
  type DrawerProps,
  EMPTY_ARRAY,
  Flex,
  FormGroup,
  HStack,
  Input,
  LedgerUpdateTypeEnum,
  NotificationVariants,
  readableDate,
  SearchSelect,
  type SubAccountReconMatch,
  Text,
  useGlobalToasts,
  VStack,
} from '@talos/kyoko';
import { isEmpty, keys, noop } from 'lodash';
import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AllocationsSelector } from '../../../../../components/AllocationsSelector';
import { useSubAccountReconRequests } from '../../useSubAccountReconRequests';
import { ReconMatchSummary } from './ReconMatchSummary';
import type { ResolveBreakForm } from './types';
import { useResolutionValidation } from './useResolutionValidation';

type BreakResolutionDrawerProps = DrawerProps & {
  match: SubAccountReconMatch | undefined;
};

const updateTypeOptions: LedgerUpdateTypeEnum[] = [LedgerUpdateTypeEnum.BalanceDelta];

function getUpdateTypeLabel(updateType: LedgerUpdateTypeEnum): string {
  switch (updateType) {
    case LedgerUpdateTypeEnum.BalanceDelta:
      return 'Adjust Sub Account(s) by';
    default:
      return '';
  }
}

export const BreakResolutionDrawer = ({ match, ...drawerProps }: BreakResolutionDrawerProps) => {
  const { close: closeDrawer } = drawerProps;
  const { add: addToast } = useGlobalToasts();
  const { resolveBreak } = useSubAccountReconRequests();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<ResolveBreakForm>({});
  const { touched, setTouched, setAllTouched, errors } = useResolutionValidation(form, match?.breakAmount);

  useEffect(() => {
    if (drawerProps.isOpen) {
      setForm({
        updateType: LedgerUpdateTypeEnum.BalanceDelta,
        subAccountAllocations: match?.suggestedAllocationAmendments,
        asset: match?.Asset,
        comments: '',
      });
    }
  }, [drawerProps.isOpen, match]);

  useEffect(() => {
    // When the drawer is closed, we clear the form.
    if (!drawerProps.isOpen) {
      setForm({});
      setTouched({});
    }
  }, [drawerProps.isOpen, setTouched]);

  const updateForm = useCallback(
    (update: Partial<ResolveBreakForm>) => {
      setForm(prev => ({ ...prev, ...update }));

      // Set touched after updates run to not hit any weird annoying timing ux issues (just put at back of queue)
      setTimeout(() => {
        setTouched(prev => {
          // Map each update to just true, then apply to the touched state
          const update = {};
          keys(update).forEach(key => {
            update[key] = true;
          });
          return { ...prev, ...update };
        });
      }, 0);
    },
    [setTouched]
  );

  const handleChangeUpdateType = useCallback(
    (updateType: LedgerUpdateTypeEnum | undefined) => {
      updateForm({ updateType });
    },
    [updateForm]
  );

  const subAccountOptions = match?.subAccounts ?? EMPTY_ARRAY;

  const handleAllocationsChange = useCallback(
    (newAllocations: Allocation[]) => {
      updateForm({ subAccountAllocations: newAllocations });
    },
    [updateForm]
  );

  const handleChangeComments = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updateForm({ comments: event.target.value });
    },
    [updateForm]
  );

  const anyError = !isEmpty(errors);

  const handleResolve = useCallback(() => {
    const allocs = form.subAccountAllocations;
    const canMakeRequest = match?.ID != null && allocs != null;
    if (!canMakeRequest) {
      return;
    }
    setIsLoading(true);
    resolveBreak({ CheckpointID: match.CheckpointID, MatchID: match.ID, Allocation: allocs, Comments: form.comments })
      .then(() => {
        addToast({
          variant: NotificationVariants.Positive,
          text: 'Successfully resolved break.',
        });
        closeDrawer();
      })
      .catch((e: Error) => {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Failed to resolve break: ${e.message}`,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [addToast, match, form, resolveBreak, closeDrawer]);

  return (
    <Drawer {...drawerProps}>
      {match != null && (
        <VStack h="100%" w="100%" justifyContent="space-between">
          <Flex flexDirection="column" h="100%" w="100%" justifyContent="flex-start">
            <VStack gap="spacingDefault" p="spacingMedium">
              <HStack fontSize="fontSizeSm" w="100%" gap="spacingDefault" justifyContent="space-between">
                <Text>{readableDate(match.TransactTime, true)}</Text>
                <Text color="colorTextImportant">{match.Status}</Text>
              </HStack>
              <ReconMatchSummary match={match} />
            </VStack>

            <Divider />

            <Flex flexDirection="column" p="spacingMedium">
              <FormGroup
                label="Update Type"
                error={touched.updateType && errors.updateType ? errors.updateType : undefined}
              >
                <SearchSelect
                  selection={form.updateType}
                  options={updateTypeOptions}
                  onChange={handleChangeUpdateType}
                  getLabel={getUpdateTypeLabel}
                  disabled={updateTypeOptions.length < 2}
                  data-testid="resolve-break-update-type"
                />
              </FormGroup>

              <AllocationsSelector
                subAccountAllocations={form.subAccountAllocations ?? EMPTY_ARRAY}
                allocationValueType={AllocationValueTypeEnum.Quantity}
                subAccountOptions={subAccountOptions}
                touched={touched}
                errors={errors}
                onAllocationsChange={handleAllocationsChange}
                onAllocationsValueTypeChange={noop} // not allowed...
                hideTypeToggleButtons // ...because this is disabled and we hard-code to Qty
                quantityCurrency={match.Asset}
                allowEditingSingleAllocationValue
                useAllocations
              />

              <FormGroup
                mb="spacingMedium"
                label="Comments"
                error={touched.comments && errors.comments ? errors.comments : undefined}
              >
                <Input
                  value={form.comments ?? ''}
                  autoComplete="off"
                  onChange={handleChangeComments}
                  data-testid="resolve-break-comments-input"
                />
              </FormGroup>
            </Flex>
          </Flex>

          <DrawerFooter w="100%">
            <Button onClick={() => drawerProps.close()}>Cancel</Button>
            <Button
              variant={ButtonVariants.Primary}
              disabled={anyError || isLoading}
              onMouseOver={setAllTouched}
              loading={isLoading}
              onClick={handleResolve}
              data-testid="resolve-break-resolve-button"
            >
              Resolve
            </Button>
          </DrawerFooter>
        </VStack>
      )}
    </Drawer>
  );
};
