import type { ValueFormatterParams } from 'ag-grid-community';
import Big from 'big.js';
import { get, isNil } from 'lodash';
import type { AmountObject } from '../../../utils/notional';

export function dateStringComparator(a: string | undefined, b: string | undefined) {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return -1;
  } else if (!b) {
    return 1;
  }
  return a.localeCompare(b);
}

export function amountObjectComparator(a: AmountObject | undefined, b: AmountObject | undefined) {
  return numericColumnComparator(a?.value, b?.value);
}

export function amountObjectValueFormatter({ value }: ValueFormatterParams<unknown, AmountObject>): string {
  return value.value;
}

/**
 * Compares to numeric values. Regards a nullish value to be less than a non-nullish value.
 */
export function numericColumnComparator(a: any, b: any) {
  try {
    return Big(a).cmp(b);
  } catch {
    if (isNil(a) && isNil(b)) {
      return 0;
    }
    if (isNil(a)) {
      return -1;
    }
    if (isNil(b)) {
      return 1;
    }
    return a - b;
  }
}

/**
 * A basic string comparator where a null value is regarded as smaller than a non-null value.
 */
export function stringColumnComparator(a?: string, b?: string) {
  if (a == null && b == null) {
    return 0;
  }

  if (a == null) {
    return -1;
  }

  if (b == null) {
    return 1;
  }

  return a.localeCompare(b);
}

/**
 * Given a list of comparison keys to compare entities of type T on,
 * returns a comparator function, comparing on string values
 *
 * For example passing in comparisonKeys: ["MarketAccount", "Currency", "Destination"] will provide a sorting
 * function which sorts items T by first MarketAccount, secondly Currency and thirdly Destination.
 */
export function stringColumnComparatorMultiColumn<T>(comparisonKeys: (keyof T)[]) {
  return (a: T, b: T) => {
    const keyWithDiff = comparisonKeys.find(key => get(a, key) !== get(b, key));
    return keyWithDiff ? stringColumnComparator(get(a, keyWithDiff), get(b, keyWithDiff)) : 0;
  };
}
