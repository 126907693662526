import type { IAggFuncParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

type RowCountValue = number;

/**
 * This column just counts the rows and aggregates them.
 *
 * This column will not show the row count on non-group (non-aggregated) rows (which always would be 1).
 */
export const rowCount: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  exportable: false,
  valueGetter: (params: ValueGetterParams<unknown>): RowCountValue => {
    return 1;
  },
  aggFunc: column.aggregate
    ? ({ values }: IAggFuncParams<unknown, RowCountValue>) => {
        return values.reduce((total, value) => total + value, 0); // just sum
      }
    : undefined,
  valueFormatter: (params: ValueFormatterParams<unknown, RowCountValue>) => {
    if (!params.node?.group) {
      return '';
    }

    return params.value.toString();
  },
});
