import styled from 'styled-components';

import {
  Box,
  Button,
  Icon,
  IconButton,
  Notification,
  PopoverStyles,
  Text,
  getStatusColor,
  parseColor,
  type OrderStatusText,
} from '@talos/kyoko';

export const CARD_BOX_SHADOW_INSET_HEIGHT = '2px';

export const CardNotification = styled(Notification)`
  margin: ${({ theme }) => `${theme.spacingSmall}px 0 ${theme.spacingDefault}px`};
  word-break: break-word;
`;

const minWidth = '260px';

export const OMSCardWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  overflow: hidden;
`;

export const VisibilityWrapper = styled.div<{ isVisible?: boolean }>`
  min-width: ${minWidth};

  ${props =>
    !props.isVisible &&
    `
      min-height: 200px;
  `};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Wrapper = styled(Box)<{ status?: OrderStatusText; isComplete?: boolean }>`
  min-width: ${minWidth};
  background: ${({ theme }) => theme.colors.gray.main};
  color: ${({ theme }) => theme.colors.gray['100']};
  box-shadow: inset 0 ${CARD_BOX_SHADOW_INSET_HEIGHT} 0 0 ${({ status, theme }) => getStatusColor(status, theme)};

  ${({ isComplete, theme }) => isComplete && `background: ${parseColor(theme, 'gray.010')};`};
`;

export const Header = styled.div`
  display: grid;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacingDefault}px;

  > div {
    &:first-child {
      padding-bottom: ${({ theme }) => theme.spacingSmall}px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    * div {
      display: flex;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  color: ${({ theme }) => theme.colors.gray['100']};
`;

export const SubTitle = styled(Text)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorTextSubtle};
  padding: 0px ${({ theme }) => theme.spacingSmall}px;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  vertical-align: middle;
`;

export const Time = styled.div`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  color: ${({ theme }) => theme.colors.gray['100']};
`;

export const Detail = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacingDefault}px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray['050']}`};
`;

export const Summary = styled.div`
  padding: ${({ theme }) => `${theme.spacingDefault}px 0 ${theme.spacingSmall}px`};
`;

export const OrderSide = styled.div<{ isBuy: boolean; isSell: boolean }>`
  color: ${props =>
    props.isBuy
      ? props.theme.colors.green.lighten
      : props.isSell
      ? props.theme.colors.red.lighten
      : props.theme.colors.gray['060']};
`;

export const OrderNumber = styled.span`
  color: ${({ theme }) => theme.colors.gray['100']};
`;

export const DetailToggle = styled.button.attrs(() => ({ type: 'button' }))`
  width: 100%;
  background: none;
  border: none;
  display: inline-flex;
  cursor: pointer;
  outline: none;
  color: ${({ theme }) => theme.colors.gray['100']};
  text-transform: uppercase;
  padding: 0 0 ${({ theme }) => theme.spacingSmall}px;
  margin-top: ${({ theme }) => theme.spacingSmall}px;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;

  ${Icon}:last-child {
    margin-left: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-bottom: ${({ theme }) => theme.spacingSmall}px;
`;

export const Tbody = styled.tbody``;
export const Tr = styled.tr``;

export const Th = styled.th`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  font-weight: ${({ theme }) => theme.fontWeightRegular};
  color: ${({ theme }) => theme.colors.gray['060']};
  text-align: right;
  padding: 2px 5px;

  &:last-child {
    padding-right: 0;
  }
`;

export const Td = styled.td<{ isSelected?: boolean }>`
  padding: 1px;

  &:first-child {
    padding-left: 0;
    padding-right: 1px;
  }

  &:last-child {
    padding-right: 0;
  }

  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.gray['090'] : theme.colors.gray['080'])};
`;

export const MinTd = styled(Td)`
  width: 1px;
  white-space: nowrap;
  text-align: right;
`;

export const DetailTable = styled.table`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  word-break: break-word;

  td {
    padding: 1px 0;
  }
  tr:nth-child(odd) td {
    color: ${({ theme }) => theme.colors.gray['080']};
    padding-top: ${({ theme }) => theme.spacingDefault}px;
  }
`;

export const MarketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.gray['090']};
  padding: ${({ theme }) => `${theme.spacingDefault}px 0 ${theme.spacingTiny}px`};

  ${Icon} {
    margin-left: 4px;
    vertical-align: top;
  }

  ${PopoverStyles.Content} {
    max-width: 255px;
    color: ${({ theme }) => theme.colors.gray['100']};
  }
`;

export const MarketInfo = styled.tr`
  color: ${({ theme }) => theme.colors.gray['080']};
`;

export const MarketSummary = styled.tbody<{ isDimmed: boolean }>`
  ${({ isDimmed }) =>
    isDimmed &&
    `
    ${MarketInfo} > td {
      opacity: 0.4;
    }
    ${MarketHeader} > td {
      opacity: 1;
      color: ${({ theme }) => theme.colors.gray['080']};
    }
  `}
`;

export const MarketsTable = styled.table`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  width: 100%;
  border-spacing: 0;
  margin-top: ${({ theme }) => theme.spacingSmall}px;

  td {
    padding: 1px 0;
    white-space: nowrap;
  }
  td:nth-child(1) {
    width: 100%;
  }
  td:nth-child(3) {
    width: 1%;
    padding: 1px ${({ theme }) => theme.spacingSmall}px;
  }
  td:nth-child(2),
  td:nth-child(4) {
    text-align: right;
  }

  ${MarketSummary} > ${MarketHeader} > td {
      padding-top: 10px;
    }
  }

  ${MarketSummary}:first-child > ${MarketHeader} > td {
    padding-top: 0px;
  }
`;

export const QuotesTable = styled.table`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  width: 100%;
  border-spacing: 0;
  margin-top: ${({ theme }) => theme.spacingSmall}px;
  color: ${({ theme }) => theme.colors.gray['060']};

  th {
    font-weight: normal;
    text-align: right;
  }
  td {
    padding: 2px ${({ theme }) => theme.spacingSmall}px;
    white-space: nowrap;
    text-align: right;
  }
  td:first-child {
    width: 100%;
    padding-left: 0;
    text-align: left;
  }
`;

export const Offer = styled(MinTd)<{ isBest?: boolean; isSelected?: boolean }>`
  padding: 2px ${({ theme }) => theme.spacingSmall}px;
  ${({ isSelected }) =>
    isSelected &&
    `
    color: ${({ theme }) => theme.colors.gray['090']};
  `};
  ${({ isBest, theme }) =>
    isBest &&
    `
    color: ${theme.colors.green.lighten};
  `};
\`;
`;

export const Bid = styled(MinTd)<{ isBest?: boolean; isSelected?: boolean }>`
  padding: 2px ${({ theme }) => theme.spacingSmall}px;
  ${({ isSelected }) =>
    isSelected &&
    `
    color: ${({ theme }) => theme.colors.gray['090']};
  `};
  ${({ isBest, theme }) =>
    isBest &&
    `
    color: ${theme.colors.red.lighten};
  `};
`;

export const ButtonActions = styled.div`
  display: flex;
  flex: 0;
  align-items: flex-end;
  margin-top: ${({ theme }) => theme.spacingSmall}px;
  gap: ${({ theme }) => theme.spacingDefault}px;

  ${Button} {
    flex: 1 1 0;
  }

  ${IconButton} {
    flex: 0 0 auto;
  }
`;

export const QuoteSummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const SpreadWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingSmall}px;
  display: flex;
  gap: ${({ theme }) => theme.spacingTiny}px;
`;

export const ProfitWrapper = styled.div`
  justify-content: right;
  gap: ${({ theme }) => theme.spacingSmall}px;
`;

export const PnLSummaryWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacingTiny}px;
`;
