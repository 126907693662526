import {
  Box,
  DateTimePickerSelect,
  MixpanelEvent,
  Text,
  VStack,
  useMixpanel,
  type DateTimePickerSelectProps,
} from '@talos/kyoko';
import { usePortfolioPositions } from 'containers/Portfolio/providers';
import { useCallback } from 'react';
import { useTheme } from 'styled-components';

const MINUTES_INTERVAL = 60;
const shortcuts: DateTimePickerSelectProps['shortcuts'] = {
  Now: 'Now',
};

function customShortcutResolver(shortcut: string) {
  // We only have one shortcut "Now" and that is effectively clearing the selection
  return null;
}

export const SettleTime = () => {
  const mixpanel = useMixpanel();
  const { colorTextSubtle, colorTextAttention } = useTheme();
  const { endDate, setEndDate } = usePortfolioPositions();

  const handleDateTimeChange = useCallback(
    (value: Date | null) => {
      mixpanel.track(MixpanelEvent.ChangeSettlementTime);
      setEndDate(value);
    },
    [setEndDate, mixpanel]
  );

  return (
    <VStack gap="spacingDefault" alignItems="flex-start">
      <Text color={colorTextSubtle}>Select Time</Text>
      <Box maxWidth="250px">
        <DateTimePickerSelect
          value={endDate}
          onChange={handleDateTimeChange}
          timePickerVariant="selector"
          shortcuts={shortcuts}
          placeholder="Now"
          placeholderColor={colorTextAttention}
          timeSelectorIntervalMinutes={MINUTES_INTERVAL}
          customShortcutResolver={customShortcutResolver}
        />
      </Box>
    </VStack>
  );
};
