import type { UseAccordionFilterBuilderOutput } from '@talos/kyoko';
import { createContext, useContext, useMemo, useState, type Dispatch, type SetStateAction } from 'react';

export const TreasuryManagementInteractionsContext = createContext<
  TreasuryManagementInteractionsContextProps | undefined
>(undefined);

type GoToRowFunc = (key: string) => void;

export type TreasuryManagementInteractionsContextProps = {
  goToGroupRow: GoToRowFunc | undefined;
  setGoToGroupRow: Dispatch<SetStateAction<GoToRowFunc | undefined>>;

  /** Open a filter builder clause, and create it if it doesn't exist */
  openClause: UseAccordionFilterBuilderOutput['openClause'] | undefined;
  setOpenClause: Dispatch<SetStateAction<UseAccordionFilterBuilderOutput['openClause'] | undefined>>;
};

export function useTreasuryManagementInteractions() {
  const context = useContext(TreasuryManagementInteractionsContext);
  if (context === undefined) {
    throw new Error(
      'Missing TreasuryManagementInteractionsContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

// This provider holds a few functions at the top of the page which any child can use in order to interact with different elements of the page
export const TreasuryManagementInteractionsProvider = function TreasuryManagementInteractionsProvider({ children }) {
  const [openClause, setOpenClause] = useState<TreasuryManagementInteractionsContextProps['openClause']>(undefined);
  const [goToGroupRow, setGoToGroupRow] =
    useState<TreasuryManagementInteractionsContextProps['goToGroupRow']>(undefined);

  const value = useMemo(() => {
    return {
      openClause,
      setOpenClause,
      goToGroupRow,
      setGoToGroupRow,
    };
  }, [openClause, setOpenClause, goToGroupRow, setGoToGroupRow]);

  return (
    <TreasuryManagementInteractionsContext.Provider value={value}>
      {children}
    </TreasuryManagementInteractionsContext.Provider>
  );
};
