import {
  AgGridLoadingOverlay,
  AgGridSearchSelectDropdown,
  BlotterTableWrapper,
  FormControlSizes,
  useDynamicCallback,
} from '@talos/kyoko';
import type { GridReadyEvent, GridSizeChangedEvent } from 'ag-grid-community';
import { AgGridReact, type AgGridReactProps } from 'ag-grid-react';
import { AgGridFormCheckbox } from 'components/AgGrid/AgGridFormCheckbox';
import { AgGridIconButton } from 'components/AgGrid/AgGridIconButton';
import { AgGridInput } from 'components/AgGrid/AgGridInput';
import { useCallback, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

const DEFAULT_COLUMN_DEFINITION = {
  suppressMovable: true,
  resizable: false,
  cellClassRules: {
    editable: params => params.colDef.editable,
  },
};

export function FormTable({
  onGridSizeChanged,
  onGridReady,
  ...props
}: { onGridSizeChanged?: any } & AgGridReactProps) {
  // The Popup Ref is used to determine where the popup element attaches to.
  const popupRef = useRef(document.body);
  const theme = useTheme();

  const [gridReady, setGridReady] = useState(false);

  const handleGridSizeChanged = useCallback(
    (params: GridSizeChangedEvent) => {
      if (params.api == null || !gridReady) {
        return;
      }

      params.api.sizeColumnsToFit();
      onGridSizeChanged && onGridSizeChanged(params);
    },
    [onGridSizeChanged, gridReady]
  );

  const handleGridReady = useDynamicCallback((event: GridReadyEvent) => {
    setGridReady(true);
    onGridReady?.(event);
  });

  return (
    <BlotterTableWrapper className="ag-theme-balham-dark">
      <AgGridReact
        rowModelType="clientSide"
        singleClickEdit={true}
        sortingOrder={['desc', 'asc']}
        loadingOverlayComponent="loadingOverlay"
        defaultColDef={DEFAULT_COLUMN_DEFINITION}
        components={{
          formCheckbox: AgGridFormCheckbox,
          iconButton: AgGridIconButton,
          input: AgGridInput,
          loadingOverlay: AgGridLoadingOverlay,
          searchSelectDropdown: AgGridSearchSelectDropdown,
        }}
        onGridSizeChanged={handleGridSizeChanged}
        popupParent={popupRef.current}
        tooltipShowDelay={300}
        rowHeight={theme.baseSize * FormControlSizes.Default + theme.spacingTiny}
        stopEditingWhenCellsLoseFocus={true}
        onGridReady={handleGridReady}
        {...props}
      />
    </BlotterTableWrapper>
  );
}
