import { sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { columnToColumnState, useBlotterTableStorage } from './BlotterTableStorage';
import { getAgGridColId } from './columns/getAgGridColId';
import type { Column } from './columns/types';
import type { BlotterTableFilter, UseBlotterTableProps } from './types';

export type UsePersistedBlotterTable<R> = Pick<UseBlotterTableProps<R>, 'columns'> &
  Required<Pick<UseBlotterTableProps<R>, 'onFilterChanged' | 'onSortChanged'>> & {
    initialFilter?: UseBlotterTableProps<R>['filter'];
    initialSort?: UseBlotterTableProps<R>['sort'];
    onColumnsChanged: (columns: Column[]) => void;
  };

type PersistanceProps = {
  persistSort?: boolean;
  persistFilter?: boolean;
  persistColumns?: boolean;
};

/**
 * Store blotter state in a persistent storage. Returns an initial state that is re-read only when `blotterID` changes.
 * @param blotterID Key to use in the persistent storage.
 * @param columns Default list of columns to use if persisted value is missing or. If a persisted state exists this list is used to construct proper Columns.
 * @param sort Default sorting to use if persisted value is missing.
 * @param filter Default filters to use if persisted value is missing.
 * @param persistSort Specify if Sort should be persisted, defaults to true
 * @param persistFilter Specify if Filter should be persisted, defaults to true
 * @param persistColumns Specify if Columns should be persisted, defaults to true
 *
 */
export function usePersistedBlotterTable<R>(
  blotterID: string | null,
  // TODO rename to defaultColumns, defaultSort, defaultFilter
  {
    persistSort = true,
    persistFilter = true,
    persistColumns = true,
    columns,
    sort,
    filter,
  }: Pick<UseBlotterTableProps<R>, 'columns' | 'filter' | 'sort'> & PersistanceProps
): UsePersistedBlotterTable<R> {
  const { getState, setColumnState, setSortState, setFilterState } = useBlotterTableStorage();

  const onColumnsChanged = useCallback(
    (columns: Column<R>[]) => {
      const persistedColumns = columns.map(columnToColumnState);
      if (blotterID != null && persistColumns) {
        setColumnState(blotterID, persistedColumns);
      }
    },
    [blotterID, setColumnState, persistColumns]
  );

  // TODO figure out if filters should be persisted through a useEffect or by calling onFilterChanged...
  const onFilterChanged = useCallback(
    (filter?: BlotterTableFilter) => {
      if (blotterID != null && persistFilter) {
        setFilterState(blotterID, filter);
      }
    },
    [blotterID, setFilterState, persistFilter]
  );
  const onSortChanged = useCallback(
    sort => {
      if (blotterID != null && persistSort) {
        setSortState(blotterID, sort);
      }
    },
    [blotterID, setSortState, persistSort]
  );

  const initialState = useMemo(() => {
    if (blotterID == null) {
      return {
        columns,
        initialSort: sort,
        initialFilter: filter,
      };
    }
    const persisted = getState(blotterID);

    if (persisted == null || columns == null) {
      return {
        columns,
        initialSort: sort,
        initialFilter: filter,
      };
    }

    const allColumns: Map<string, Column> = new Map();
    // 1. Add specified columns
    for (const specifiedCol of columns) {
      allColumns.set(getAgGridColId(specifiedCol), specifiedCol);
    }

    // 2. Overwrite width, visibility etc. with persisted state
    const persistedColumns = !persistColumns ? [] : persisted.columns ?? [];
    for (const persistedCol of persistedColumns) {
      const specifiedCol = allColumns.get(getAgGridColId(persistedCol));
      if (specifiedCol != null) {
        allColumns.set(getAgGridColId(specifiedCol), {
          ...specifiedCol,
          hide: persistedCol.hide,
          width: persistedCol.width,
          rowGroup: persistedCol.rowGroup,
          rowGroupIndex: persistedCol.rowGroupIndex,
        });
      }
    }

    // 3. Sort columns according to persisted state, or fallback to what's specified
    const persistedOrder = new Map(persistedColumns.map((column, index) => [getAgGridColId(column), index]));
    const specifiedOrder = new Map(columns.map((column, index) => [getAgGridColId(column), index]));
    const sortByCol = column =>
      persistedOrder.get(getAgGridColId(column)) ?? specifiedOrder.get(getAgGridColId(column));

    // Return merged and sorted columns
    return {
      columns: sortBy([...allColumns.values()], sortByCol),
      initialFilter: !persistFilter ? filter : persisted.filter ?? filter,
      initialSort: (!persistSort ? sort : persisted.sort ?? sort) as typeof sort,
    };
  }, [getState, blotterID, columns, filter, sort, persistColumns, persistSort, persistFilter]);

  return {
    ...initialState,
    onColumnsChanged,
    onFilterChanged,
    onSortChanged,
  };
}
