import type { CSSProperties } from 'react';
import { Input, type FormControlSizes } from '../Form';
import { Icon, IconName } from '../Icons';

export interface LocalFilterInputProps {
  placeholder?: string;
  value: string | undefined;
  onChange: (val: string) => void;
  width?: CSSProperties['width'];
  size?: FormControlSizes;
}

export const LocalFilterInput = ({ placeholder, value, onChange, width = '275px', size }: LocalFilterInputProps) => {
  return (
    <Input
      placeholder={placeholder}
      value={value || ''}
      onChange={e => onChange(e.target.value)}
      clearable={true}
      prefix={<Icon icon={IconName.Search} />}
      width={width}
      size={size}
    />
  );
};
