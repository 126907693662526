import { debounce, inRange, type DebounceSettings } from 'lodash';
import { useCallback, useEffect, useRef, useState, type RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { useDynamicCallback } from './useDynamicCallback';

export interface UseElementSizeParams {
  /** The debounce time in milliseconds */
  debounceWait?: number;
  /** Lodash's DebounceSettings */
  debounceOptions?: DebounceSettings;
  /** When provided, the caller will only receive updates for the given thresholds passed in.
   * For example, if the caller passes in `["offsetWidth", [1000]]`, the caller will only ever receive size updates
   * when the offsetWidth crosses 1000.
   */
  thresholds?: [keyof SizeProps, number[]][];
}

export function useElementSize<T extends HTMLElement>(props?: UseElementSizeParams) {
  const elementRef = useRef<T | null>(null);
  const { size } = useElementSizeOfRef({ ref: elementRef, ...props });
  return { elementRef, size };
}

interface UseElementSizeOfRefParams<T extends HTMLElement> extends UseElementSizeParams {
  ref: RefObject<T>;
}

export function useElementSizeOfRef<T extends HTMLElement>({
  ref,
  thresholds,
  debounceWait,
  debounceOptions,
}: UseElementSizeOfRefParams<T>) {
  const requestedFrame = useRef(0);
  const [size, setSize] = useState<SizeProps>({});
  const [observer] = useState(() => {
    if (debounceWait != null) {
      return new ResizeObserver(
        debounce(([entry]: ResizeObserverEntry[]) => checkSize(entry), debounceWait, debounceOptions)
      );
    } else {
      return new ResizeObserver(([entry]: ResizeObserverEntry[]) => checkSize(entry));
    }
  });

  const checkSizeImpl = useCallback(
    (target: Element | undefined) => {
      if (!ref.current) {
        return;
      }

      setSize(prev => {
        if (!(target instanceof HTMLElement)) {
          return prev;
        }

        if (thresholds != null && isSizePropsInstantiated(prev)) {
          const someThresholdCrossed = thresholds.some(([property, levels]) => {
            // We have crossed some threshold if there is any "level" between prev property and current property values
            const prevValue = prev[property] ?? 0;
            const newValue = target[property] ?? 0;
            return levels.some(level => inRange(level, Math.min(prevValue, newValue), Math.max(prevValue, newValue)));
          });

          if (!someThresholdCrossed) {
            return prev;
          }
        }

        return {
          left: target.offsetLeft,
          top: target.offsetTop,
          scrollWidth: target.scrollWidth,
          scrollHeight: target.scrollHeight,
          offsetWidth: target.offsetWidth,
          offsetHeight: target.offsetHeight,
          clientWidth: target.clientWidth,
          clientHeight: target.clientHeight,
        };
      });
    },
    [ref, thresholds]
  );

  const checkSize = useDynamicCallback((entry: ResizeObserverEntry) => {
    // react-use also cancels their requestAnimationFrames (https://github.com/streamich/react-use/blob/master/src/useMeasureDirty.ts)
    window.cancelAnimationFrame(requestedFrame.current);
    // https://stackoverflow.com/a/58701523
    requestedFrame.current = window.requestAnimationFrame(() => {
      checkSizeImpl(entry?.target);
    });
  });

  useEffect(() => {
    if (ref.current != null && ref.current instanceof HTMLElement) {
      observer.observe(ref.current);
      checkSizeImpl(ref.current);
      return () => observer.disconnect();
    }
  }, [observer, ref, checkSizeImpl]);

  return { size };
}

// If any value here is not null, eg offsetWidth, then we should be instantiated
function isSizePropsInstantiated(sizeProps: SizeProps) {
  return sizeProps.offsetWidth != null;
}

export type SizeProps = {
  left?: number;
  top?: number;
  offsetHeight?: number;
  offsetWidth?: number;
  scrollHeight?: number;
  scrollWidth?: number;
  clientHeight?: number;
  clientWidth?: number;
};
