import {
  ROLLUP_MEMBERSHIP,
  request,
  useDynamicCallback,
  useObservableValue,
  useStaticSubscription,
  useUserContext,
  wsScanToDoubleMap,
  type RollupMembership,
  type SubAccount,
} from '@talos/kyoko';
import { createContext, memo, useCallback, useContext, useMemo } from 'react';

export interface SubAccountRollupMembershipsContextProps {
  /** A mapping of parent -> children. Pass in a parent and get a map of all children for that parent. */
  rollupMembershipsByParentChild: Map<number, Map<number, RollupMembership>>;
  /** A mapping of child -> parents. Pass in a child and get a map of all parents for that child. */
  rollupMembershipsByChildParent: Map<number, Map<number, RollupMembership>>;
  /** A list of all sub accounts which we have found to be assigned to more than one rollup. */
  subAccountIDsAssignedToMoreThanOneRollup: number[];
  /**
   * Tells you if the rollup hierarchy is deemed to be a tree or not. A tree is a graph where every child has at most one parent.
   * If there are no memberships to check, true will be returned.
   */
  isRollupHierarchyTree: boolean;
  addRollupMemberships: (
    subAccountID: SubAccount['SubaccountID'],
    memberships: SubAccount['SubaccountID'][]
  ) => Promise<any>;
  deleteRollupMemberships: (
    subAccountID: SubAccount['SubaccountID'],
    memberships: SubAccount['SubaccountID'][]
  ) => Promise<any>;
}

const SubAccountRollupMembershipsContext = createContext<SubAccountRollupMembershipsContextProps | null>(null);
SubAccountRollupMembershipsContext.displayName = 'SubAccountRollupMembershipsContext';

export function useSubAccountRollupMemberships() {
  const context = useContext(SubAccountRollupMembershipsContext);
  if (context == null) {
    throw new Error(
      'Missing SubAccountRollupMembershipsContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

export const SubAccountRollupMembershipsProvider = memo(function SubAccountRollupMembershipsProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const { data: rollupMembershipsObs, push } = useStaticSubscription<RollupMembership>({
    name: ROLLUP_MEMBERSHIP,
    tag: 'SubAccountRollupMembershipsProvider',
  });

  const handleSuccessfulCRUDOperation = useDynamicCallback((result: RollupMembership[]) => {
    push({
      type: '',
      ts: '',
      data: result,
    });
  });

  const { addRollupMemberships, deleteRollupMemberships } = useSubAccountRollupMembershipRequests({
    onSuccess: handleSuccessfulCRUDOperation,
  });

  const rollupMembershipsByParentChild: Map<number, Map<number, RollupMembership>> = useObservableValue(
    () =>
      rollupMembershipsObs.pipe(
        wsScanToDoubleMap<RollupMembership, number, number>({
          getKey1: item => item.ParentID,
          getKey2: item => item.ChildID,
          newOuterMapEachUpdate: true,
          newInnerMapsEachUpdate: true,
        })
      ),
    [rollupMembershipsObs],
    new Map<number, Map<number, RollupMembership>>()
  );

  const rollupMembershipsByChildParent: Map<number, Map<number, RollupMembership>> = useObservableValue(
    () =>
      rollupMembershipsObs.pipe(
        wsScanToDoubleMap<RollupMembership, number, number>({
          getKey1: item => item.ChildID,
          getKey2: item => item.ParentID,
          newOuterMapEachUpdate: true,
          newInnerMapsEachUpdate: true,
        })
      ),
    [rollupMembershipsObs],
    new Map<number, Map<number, RollupMembership>>()
  );

  const subAccountIDsAssignedToMoreThanOneRollup = useMemo(() => {
    return [...rollupMembershipsByChildParent.entries()]
      .filter(([_, relationships]) => relationships.size > 1)
      .map(([childID]) => childID);
  }, [rollupMembershipsByChildParent]);

  const isRollupHierarchyTree: boolean = useMemo(() => {
    // If any child has more than one relationship to a parent, we are by definition not a tree anymore - we are a graph.
    return subAccountIDsAssignedToMoreThanOneRollup.length === 0;
  }, [subAccountIDsAssignedToMoreThanOneRollup]);

  return (
    <SubAccountRollupMembershipsContext.Provider
      value={{
        rollupMembershipsByParentChild,
        rollupMembershipsByChildParent,
        subAccountIDsAssignedToMoreThanOneRollup,
        isRollupHierarchyTree,
        addRollupMemberships,
        deleteRollupMemberships,
      }}
    >
      {children}
    </SubAccountRollupMembershipsContext.Provider>
  );
});

const useSubAccountRollupMembershipRequests = ({ onSuccess }: { onSuccess: (data: RollupMembership[]) => void }) => {
  const { orgApiEndpoint } = useUserContext();

  const addRollupMemberships = useCallback(
    (subAccountID: SubAccount['SubaccountID'], memberships: SubAccount['SubaccountID'][]) => {
      return orgApiEndpoint
        ? request<{ data: RollupMembership[] }>(
            'PATCH',
            `${orgApiEndpoint}/subaccounts/rollups/memberships/${subAccountID}`,
            memberships
          ).then(res => {
            onSuccess(res.data);
            return res.data;
          })
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint, onSuccess]
  );

  const deleteRollupMemberships = useCallback(
    (subAccountID: SubAccount['SubaccountID'], memberships: SubAccount['SubaccountID'][]) => {
      return orgApiEndpoint
        ? request<{ data: RollupMembership[] }>(
            'DELETE',
            `${orgApiEndpoint}/subaccounts/rollups/memberships/${subAccountID}`,
            memberships
          ).then(res => {
            return res.data;
          })
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint]
  );

  return {
    addRollupMemberships,
    deleteRollupMemberships,
  };
};
