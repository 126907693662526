import { getAgGridColId, type Column, type ColumnDef } from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useFeatureFlag } from '../../../hooks';
import { useDisplaySettings } from '../../../providers/DisplaySettingsProvider';
import type { Balance } from '../../../types';
import { useEquityMarginRatioLimits } from '../../Settings/TradingControls/useEquityMarginRatioLimits';
import { EquityMarginRatio } from './EquityMarginRatio';

export function useBalancesColumnDefinitions(): Map<string, Column> {
  const { homeCurrency } = useDisplaySettings();
  const { showPositionsBlotterWarningColumn } = useFeatureFlag();
  const { isEquityMarginRatioLimitCheckEnabled } = useEquityMarginRatioLimits();

  return useMemo(() => {
    const arr = compact([
      showPositionsBlotterWarningColumn
        ? {
            id: 'reconWarning',
            field: 'reconWarning',
            type: 'reconWarning',
            aggregate: true,
            sortable: true,
            exportable: false,
          }
        : undefined,
      {
        type: 'text',
        field: 'marketAccountGroup',
        id: 'marketAccountGroup',
        title: 'Group',
        hide: true,
        sortable: true,
        params: { color: 'colorTextImportant' },
      },
      {
        type: 'account',
        field: 'marketAccountName',
        id: 'marketAccountName',
        sortable: true,
        params: { color: 'colorTextImportant', prefixCustodianMarketAccounts: true },
      },
      {
        type: 'currency',
        field: 'Currency',
        id: 'Currency',
        title: 'Asset',
        sortable: true,
        params: { colorful: true },
      },
      {
        type: 'market',
        field: 'Market',
        title: 'Market',
        hide: true,
        sortable: true,
      },
      {
        type: 'size',
        field: 'Amount',
        title: 'Quantity',
        params: { currencyField: 'Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'Equivalent.Amount',
        title: `${homeCurrency} value`,
        params: { currencyField: 'Equivalent.Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'AvailableAmount',
        title: 'Available Amount',
        params: { currencyField: 'Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'Equivalent.AvailableAmount',
        title: `Available Amount (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'OutstandingBuy',
        title: 'Outstanding Buy',
        params: { currencyField: 'Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'OutstandingSell',
        title: 'Outstanding Sell',
        params: { currencyField: 'Currency', highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'Equivalent.OutstandingBuy',
        title: `Outstanding Buy (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency', highlightNegative: true },
        sortable: true,
        hide: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'Equivalent.OutstandingSell',
        title: `Outstanding Sell (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency', highlightNegative: true },
        sortable: true,
        hide: true,
        aggregate: true,
      },
      isEquityMarginRatioLimitCheckEnabled
        ? {
            id: 'equityMarginRatio',
            type: 'custom',
            title: 'Equity Margin Ratio v Limit',
            sortable: false,
            params: {
              cellRendererParams: ({ data }: ICellRendererParams<Balance>) => {
                return {
                  marketAccount: data?.marketAccountName,
                  equity: data?.Equity,
                  maintenanceMargin: data?.MaintenanceMargin,
                };
              },
              cellRenderer: ({
                marketAccount,
                equity,
                maintenanceMargin,
              }: {
                marketAccount: string;
                equity: string;
                maintenanceMargin: string;
              }) => (
                <EquityMarginRatio
                  marketAccount={marketAccount}
                  equity={equity}
                  maintenanceMargin={maintenanceMargin}
                />
              ),
            },
          }
        : null,
      {
        type: 'size',
        field: 'Equity',
        params: { currencyField: 'Currency', highlightNegative: true },
        sortable: true,
        hide: true,
      },
      {
        type: 'size',
        field: 'InitialMargin',
        params: { currencyField: 'Currency', highlightNegative: true },
        sortable: true,
        hide: true,
      },
      {
        type: 'size',
        field: 'MaintenanceMargin',
        params: { currencyField: 'Currency', highlightNegative: true },
        sortable: true,
        hide: true,
      },
    ]) satisfies ColumnDef<Balance>[];

    return new Map<string, Column>(arr.map(c => [getAgGridColId(c), c]));
  }, [homeCurrency, showPositionsBlotterWarningColumn, isEquityMarginRatioLimitCheckEnabled]);
}
