import { AccordionGroup, HStack, LookbackOption, VStack } from '@talos/kyoko';
import { useMemo } from 'react';
import { FilteredTransfersWrapper, type TransfersTabProps } from './FilteredTransfersWrapper';
import { TRANSFERS_BLOTTER_PREFIX } from './tokens';
import { TransfersFilterRow } from './TransfersFilterRow';
import { useTransfersColumns } from './useTransfersColumns';

const TransfersBlotterSettings = {
  label: 'Recent',
  id: 'recent-transfers-pms',
};

/** Simplified version of the Transfers components that excludes the Inner Tabs */
export const RecentTransfers = () => {
  const defaultColumns = useTransfersColumns();

  const newTabDefaults = useMemo(() => {
    return {
      defaultFilter: {
        _start: LookbackOption.Past30Days,
      },
      defaultColumns,
    } satisfies TransfersTabProps;
  }, [defaultColumns]);

  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <HStack h="40px" w="100%" justifyContent="right" gap="spacingComfortable" background="backgroundContent">
        <TransfersFilterRow showNewTransfer={true} />
      </HStack>
      <AccordionGroup key={TransfersBlotterSettings.id}>
        <FilteredTransfersWrapper
          key={TransfersBlotterSettings.id}
          tabId={TransfersBlotterSettings.id}
          blotterID={`${TRANSFERS_BLOTTER_PREFIX}/${TransfersBlotterSettings.id}`}
          defaultColumns={newTabDefaults.defaultColumns}
          defaultFilter={newTabDefaults.defaultFilter}
          onCloneTab={() => ({})}
          initialIsOpen={false}
        />
      </AccordionGroup>
    </VStack>
  );
};
