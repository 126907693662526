import { MixpanelEvent, MixpanelEventSource } from '@talos/kyoko';
import type { LayoutOptionView } from '../portfolioDashboard.types';
import type { PortfolioViewType } from '../portfolioDashboardLayouts';
import { getOperationsLayout } from './dockviewWorkspaceConfigs/operations/operations.defaultLayout';
import { getPortfolioManagementLayout } from './dockviewWorkspaceConfigs/portfolioManagement/portfolioManagement.defaultLayout';

export function getPortfolioLayoutOptions(): LayoutOptionView<PortfolioViewType>[] {
  const result: LayoutOptionView<PortfolioViewType>[] = [
    {
      label: 'Portfolio Management',
      route: '/portfolio-management',
      value: 'portfolio-management',
      tabs: [
        {
          label: 'Overview',
          route: '/overview',
          layoutType: 'fixed',
          content: 'PM-Overview',
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioManagementOverview,
          mixpanelEventSource: MixpanelEventSource.PortfolioManagementOverview,
        },
        {
          label: 'Performance',
          route: '/performance',
          layoutType: 'fixed',
          content: 'PM-Performance',
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioManagementPerformance,
          mixpanelEventSource: MixpanelEventSource.PortfolioManagementPerformance,
        },
        {
          label: 'Transactions',
          route: '/transactions',
          layoutType: 'flexible',
          getDefaultLayout: () => getPortfolioManagementLayout(),
          availablePanels: 'pm-transactions',
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioManagementTransactions,
          mixpanelEventSource: MixpanelEventSource.PortfolioManagementTransactions,
        },
        {
          label: 'Reports',
          route: '/reports',
          layoutType: 'fixed',
          content: 'PM-Reports',
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioManagementReports,
          mixpanelEventSource: MixpanelEventSource.PortfolioManagementReports,
        },
      ],
    },
    {
      label: 'Risk Management',
      route: '/risk-management',
      value: 'risk',
      tabs: [
        {
          label: 'Risk Exposures',
          route: '/exposures',
          layoutType: 'fixed',
          content: 'Risk-Exposures',
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioRiskExposures,
          mixpanelEventSource: MixpanelEventSource.PortfolioRiskExposures,
        },
      ],
    },
    {
      label: 'Operations',
      route: '/operations',
      value: 'operations',
      tabs: [
        {
          label: 'Overview',
          route: '/overview',
          layoutType: 'fixed',
          content: 'Operations-Overview',
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsOverview,
          mixpanelEventSource: MixpanelEventSource.PortfolioOperationsOverview,
        },
        {
          label: 'Transactions',
          route: '/transactions',
          layoutType: 'flexible',
          getDefaultLayout: () => getOperationsLayout(),
          availablePanels: 'operations-transactions',
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsTransactions,
          mixpanelEventSource: MixpanelEventSource.PortfolioOperationsTransactions,
        },
        {
          label: 'Settlement',
          route: '/settlement',
          layoutType: 'fixed',
          content: 'Operations-Settlement',
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsSettlement,
          mixpanelEventSource: MixpanelEventSource.PortfolioOperationsSettlement,
        },
        {
          label: 'Reconciliation',
          route: '/reconciliation',
          layoutType: 'fixed',
          content: 'Operations-Reconciliation',
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsReconciliation,
          mixpanelEventSource: MixpanelEventSource.PortfolioOperationsReconciliation,
        },
        /** Hiding until Ops Reports are separately working from Ops Overview */
        // {
        //   label: 'Reports',
        //   route: '/reports',
        //   layoutType: 'fixed',
        //   content: 'Operations-Reports',
        //   mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsReports,
        //   mixpanelEventSource: MixpanelEventSource.PortfolioOperationsReports,
        // },
      ],
    },
  ];
  return result;
}
