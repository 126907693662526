import { useCallback, useEffect, useRef, useState, type MutableRefObject } from 'react';
import { useTheme } from 'styled-components';

import {
  ACTION,
  Box,
  HStack,
  Icon,
  IconName,
  LoaderSizes,
  ModeEnum,
  Text,
  Tooltip,
  bpsToPercent,
  isTalosUser,
  percentToBps,
  type AgGridSearchSelectDropdownProps,
  type Aggregation,
  type BlotterTableContextProps,
  type Customer,
  type CustomerSecurity,
  type PricingRule,
  type SubAccount,
} from '@talos/kyoko';

import { AgGridFormCheckbox } from 'components/AgGrid/AgGridFormCheckbox';
import { Loader, LoaderWrapper } from 'components/Loader';
import { FormTable } from './FormTable';

import type { ColDef, ICellEditorParams } from 'ag-grid-community';
import { useRoleAuth, useUser } from 'hooks';
import { useCustomersByName } from 'hooks/useCustomer';
import { identity } from 'lodash';
import { useCustomerSecurities, useSubAccounts } from 'providers';
import { usePrevious } from 'react-use';
import { DEFAULT_CUSTOMER_CREDIT_CURRENCIES } from '../CustomerCredit';
import { BlotterWrapper } from '../styles';
import { DEALER_FEE_MODE_OPTIONS } from '../utils';

const suppressKeyboardEvent = () => true;

const getDefaultRenderOptions = (key: string, globalDefaultRef: MutableRefObject<PricingRule>): Partial<ColDef> => {
  return {
    field: key,
    valueGetter: ({ data, node }) => {
      if (!data) {
        return '';
      }
      const usesOwnData = data[key] != null && data[key] !== '';
      const hasParentData = node?.parent?.data && node.parent.data[key];
      if (usesOwnData) {
        return data[key];
      } else if (hasParentData) {
        return node.parent?.data[key];
      }
      return globalDefaultRef.current[key] || '';
    },
    cellRenderer: ({ data, value, valueFormatted, context }) => {
      if (!data) {
        return '';
      }
      const usesOwnData = data[key] != null && data[key] !== '';
      // Aggregations column uses the result from its valueFormatter
      const effectiveValue = valueFormatted ?? value;
      return (
        <Text color={usesOwnData ? 'colorTextDefault' : 'colorTextMuted'} theme={context.current.theme}>
          {effectiveValue}
        </Text>
      );
    },
  };
};

// fallbackField used for BidSpread and OfferSpread to Inherit Spread before Global
const getSpreadColumn = (field?: any, globalDefaultRef?: any, fallbackField?: any) => {
  return {
    field: field,
    width: 150,
    cellEditor: 'input',
    editable: true,
    cellRenderer: ({ data, value, context }) => {
      if (!data || !value) {
        return '';
      }
      const usesOwnData = data[field] != null && data[field] !== '';
      return (
        <Text
          color={usesOwnData ? 'colorTextDefault' : 'colorTextMuted'}
          theme={context.current.theme}
        >{`${value} BPS`}</Text>
      );
    },
    valueGetter: ({ data, node }) => {
      if (!data) {
        return '';
      }
      const ownData = data[field] ?? data[fallbackField];
      const parentData = node.parent?.data && (node.parent.data[field] ?? node.parent.data[fallbackField]);
      if (ownData) {
        return percentToBps(ownData);
      } else if (parentData) {
        return percentToBps(parentData);
      }
      return percentToBps(globalDefaultRef.current?.[field] || '');
    },
    valueSetter: ({ data, newValue }) => {
      data[field] = bpsToPercent(newValue);
      return true;
    },
  };
};

const createPricingRulesColumns = (
  aggregations: Aggregation[],
  counterparties: Map<string, Customer>,
  subAccounts: SubAccount[],
  customerSecuritiesMapRef: MutableRefObject<Map<string, CustomerSecurity>>,
  globalDefaultRef: MutableRefObject<PricingRule>,
  onCounterpartyEdit: (cp: string) => void,
  onCounterpartyDelete: (cp: string) => void,
  isTalosUser: boolean,
  isAuthorizedForDealerTrading: boolean
) => {
  function displayName(counterparty: string) {
    return counterparties.get(counterparty)?.DisplayName || counterparty;
  }

  const columns: ColDef[] = [
    {
      field: 'Counterparty',
      valueFormatter: ({ value }: { value: string }) => displayName(value),
      rowGroup: true,
      comparator: (a, b) => {
        return displayName(b)?.localeCompare(displayName(a));
      },
      hide: true,
    },
    {
      headerName: '',
      width: 100,
      field: 'Mode',
      colId: 'Mode',
      suppressColumnsToolPanel: true,
      cellRenderer: props => {
        return (
          <HStack gap="spacingDefault" justifyContent="flex-start">
            <AgGridFormCheckbox {...props} />
            {!!props.isGloballyDisabled && (
              <Tooltip
                tooltip={
                  <Box textAlign="center">
                    Symbol is currently disabled
                    <br />
                    under Security Master
                  </Box>
                }
                usePortal={true}
              >
                <Icon icon={IconName.ExclamationCircleSolid} color="colors.yellow.lighten" />
              </Tooltip>
            )}
          </HStack>
        );
      },
      cellRendererParams: ({ data }) => {
        return {
          checked: data?.Mode === ModeEnum.Enabled,
          isGloballyDisabled:
            data?.Mode === ModeEnum.Enabled &&
            customerSecuritiesMapRef.current.get(data?.Symbol)?.Mode === ModeEnum.Disabled,
          onChange: ({ value, setValue }) => {
            const mode = value === ModeEnum.Enabled ? ModeEnum.Disabled : ModeEnum.Enabled;
            setValue(mode);
          },
        };
      },
    },
    {
      field: 'Symbol',
      valueGetter: ({ data }) => data?.Symbol || '*',
      comparator: (a, b) => b?.localeCompare(a),
      sort: 'desc',
    },
    {
      ...getSpreadColumn('Spread', globalDefaultRef),
      headerTooltip: 'Spread to be applied as a ratio.',
      hide: true,
    },
    {
      ...getSpreadColumn('BidSpread', globalDefaultRef, 'Spread'),
      headerTooltip: 'Spread to be applied to the Bid prices as a ratio.',
    },
    {
      ...getSpreadColumn('OfferSpread', globalDefaultRef, 'Spread'),
      headerTooltip: 'Spread to be applied to the Offer prices as a ratio.',
    },
    {
      ...getSpreadColumn('SalesCommission', globalDefaultRef),
      headerName: 'Commission',
      hide: true,
    },
    {
      ...getDefaultRenderOptions('FeeMode', globalDefaultRef),
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent,
      cellEditorPopup: true,
      cellEditorParams: (params: ICellEditorParams) => {
        return {
          ...params,
          showClear: true,
          useSearchSelectParams: { items: DEALER_FEE_MODE_OPTIONS, getLabel: identity },
        } satisfies AgGridSearchSelectDropdownProps<string>;
      },
      width: 175,
      editable: true,
      hide: true,
    },
    {
      ...getDefaultRenderOptions('FeeCurrency', globalDefaultRef),
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent,
      cellEditorPopup: true,
      cellEditorParams: (params: ICellEditorParams) => {
        const items = [...DEFAULT_CUSTOMER_CREDIT_CURRENCIES.keys()];
        return {
          ...params,
          showClear: true,
          useSearchSelectParams: { items, getLabel: currency => currency },
        } satisfies AgGridSearchSelectDropdownProps<string>;
      },
      width: 125,
      editable: true,
      hide: true,
    },
    {
      ...getSpreadColumn('Fee', globalDefaultRef),
      headerName: 'Fee',
      hide: true,
      headerTooltip: 'Post trade fee to apply',
    },
    {
      ...getDefaultRenderOptions('PriceImprovementRatio', globalDefaultRef),
      cellEditor: 'input',
      editable: true,
      hide: true,
      headerName: 'RFQ Price Improvement Ratio',
    },
    {
      ...getDefaultRenderOptions('OrderPriceImprovementRatio', globalDefaultRef),
      cellEditor: 'input',
      editable: true,
      hide: true,
      headerName: 'Order Price Improvement Ratio',
    },
    {
      ...getDefaultRenderOptions('PricesTimeout', globalDefaultRef),
      cellEditor: 'input',
      editable: true,
      headerTooltip: 'Duration to wait for prices from the liquidity providers before returning a quote price.',
      hide: true,
    },
    {
      ...getSpreadColumn('AcceptPriceLeniency', globalDefaultRef),
      headerTooltip:
        'The maximum amount of slippage (BPS) that the dealer is willing to take from a previously quoted price.',
      headerName: 'Price Leniency',
    },
    {
      ...getDefaultRenderOptions('AcceptTimeLeniency', globalDefaultRef),
      cellEditor: 'input',
      editable: true,
      headerTooltip: 'Time leniency to apply on top of the QuoteTTL.',
      hide: true,
    },
    {
      ...getDefaultRenderOptions('PricingAggregation', globalDefaultRef),
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent,
      cellEditorPopup: true,
      cellEditorParams: (params: ICellEditorParams) => {
        const items = aggregations.map(agg => agg.Name);
        const aggregationsByName = new Map(aggregations.map(agg => [agg.Name, agg]));

        return {
          ...params,
          showClear: true,
          useSearchSelectParams: {
            items,
            getLabel: aggName => aggregationsByName.get(aggName)?.DisplayName ?? aggName,
          },
        } satisfies AgGridSearchSelectDropdownProps<string>;
      },
      editable: true,
      valueFormatter: ({ value }) => aggregations.find(aggregation => aggregation.Name === value)?.DisplayName ?? value,
      headerTooltip: 'Aggregation of markets to use when providing prices for customer orders and quotes.',
    },
    {
      ...getDefaultRenderOptions('QuoteTTL', globalDefaultRef),
      width: 120,
      headerName: 'Quote TTL',
      cellEditor: 'input',
      editable: true,
      headerTooltip: 'Duration between price updates.',
    },
    {
      ...getDefaultRenderOptions('RFQTTL', globalDefaultRef),
      width: 120,
      headerName: 'RFQ TTL',
      cellEditor: 'input',
      editable: true,
      headerTooltip:
        'Duration of the quote request. The request will automatically close after this time if not otherwise traded on or cancelled.',
    },
    {
      ...getDefaultRenderOptions('TradeSubAccount', globalDefaultRef),
      hide: true,
      headerName: 'Trade SubAccount',
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent,
      cellEditorPopup: true,
      editable: true,
      headerTooltip: 'Trade SubAccount is the subaccount where the dealer-side of your customer trades will be booked.',
      cellEditorParams: (params: ICellEditorParams) =>
        ({
          ...params,
          showClear: true,
          useSearchSelectParams: {
            items: subAccounts.map(s => s.Name),
            getLabel: identity,
          },
        } satisfies AgGridSearchSelectDropdownProps<string>),
    },
    {
      ...getDefaultRenderOptions('HedgeSubAccount', globalDefaultRef),
      hide: true,
      headerName: 'Hedge SubAccount',
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent,
      cellEditorPopup: true,
      editable: true,
      headerTooltip:
        'Hedge SubAccount is the subaccount where the hedge orders and trades executed against the market will be booked.',
      cellEditorParams: (params: ICellEditorParams) =>
        ({
          ...params,
          showClear: true,
          useSearchSelectParams: {
            items: subAccounts.map(s => s.Name),
            getLabel: identity,
          },
        } satisfies AgGridSearchSelectDropdownProps<string>),
    },
    {
      field: 'TakeProfitInQuoteCurrency',
      headerName: 'TP in Quote Ccy',
      colId: 'TakeProfitInQuoteCurrency',
      cellRenderer: 'formCheckbox',
      hide: true,
      valueGetter: ({ data, node }) => {
        if (!data) {
          return false;
        }
        const key = 'TakeProfitInQuoteCurrency';
        const usesOwnData = data[key] != null;
        const hasParentData = node?.parent?.data != null && node.parent.data[key] != null;

        if (usesOwnData) {
          return data[key];
        } else if (hasParentData) {
          return node.parent?.data[key];
        }
        return globalDefaultRef.current[key] || false;
      },
      cellRendererParams: ({ value }) => {
        return {
          checked: value,
          onChange: ({ value, setValue }) => {
            setValue(!value);
          },
        };
      },
      headerTooltip:
        'When Take Profit in Quote Currency is enabled, profits will always be taken in the quote currency on counter-currency requests. When disabled, profit will be taken in the base currency on counter-currency requests',
    },
  ];

  if (isAuthorizedForDealerTrading) {
    columns.push({
      colId: 'Edit',
      cellRenderer: 'iconButton',
      minWidth: 50,
      maxWidth: 50,
      suppressColumnsToolPanel: true,
      cellRendererParams: ({ data }) => {
        const isGroupRow = data?.Counterparty && !data?.Symbol;
        // We want the edit option only for group rows
        if (!isGroupRow) {
          return {
            onClick: () => {},
          };
        }
        return {
          icon: IconName.Pencil,
          onClick: onCounterpartyEdit,
        };
      },
    });
  }

  if (isTalosUser) {
    columns.push({
      colId: 'Remove',
      cellRenderer: 'iconButton',
      minWidth: 50,
      maxWidth: 50,
      suppressColumnsToolPanel: true,
      cellRendererParams: ({ data }) => {
        const isGroupRow = data?.Counterparty && !data?.Symbol;
        // We want the delete option only for group rows
        if (!isGroupRow) {
          return {
            onClick: () => {},
          };
        }
        return {
          icon: IconName.Trash,
          onClick: onCounterpartyDelete,
        };
      },
    });
  }

  return columns;
};

export const CustomerPricingTable = ({
  globalDefault,
  aggregations,
  customers,
  onEditCounterparty,
  onDeleteCounterparty,
  ...props
}) => {
  const getRowId = useCallback(({ data }) => `${data.Counterparty}${data.Symbol}`, []);
  const { allSubAccountBooks } = useSubAccounts();
  const [columns, setColumns] = useState<ColDef[] | undefined>();
  const globalDefaultRef = useRef(globalDefault);
  const customersMap = useCustomersByName();

  const providersLoaded =
    customers != null && customersMap != null && aggregations != null && allSubAccountBooks != null;

  useEffect(() => {
    globalDefaultRef.current = globalDefault;
  }, [globalDefault]);

  const { isAuthorized } = useRoleAuth();
  const user = useUser();

  const theme = useTheme();
  const customerSecurities = useCustomerSecurities();
  const customerSecuritiesMapRef = useRef(new Map<string, CustomerSecurity>());

  useEffect(() => {
    if (customerSecurities) {
      customerSecuritiesMapRef.current = customerSecurities.reduce((acc, customerSecurity) => {
        acc.set(customerSecurity.Symbol, customerSecurity);
        return acc;
      }, new Map<string, CustomerSecurity>());
    }
  }, [customerSecurities]);

  const prevCustomerMap = usePrevious(customersMap);

  useEffect(() => {
    if (providersLoaded && globalDefault && onEditCounterparty) {
      setColumns(prev =>
        prev == null || prevCustomerMap !== customersMap
          ? createPricingRulesColumns(
              aggregations,
              customersMap,
              allSubAccountBooks,
              customerSecuritiesMapRef,
              globalDefaultRef,
              onEditCounterparty,
              onDeleteCounterparty,
              isTalosUser(user),
              isAuthorized(ACTION.DEALER_TRADING)
            )
          : prev
      );
    }
  }, [
    providersLoaded,
    globalDefault,
    onEditCounterparty,
    onDeleteCounterparty,
    aggregations,
    prevCustomerMap,
    customersMap,
    isAuthorized,
    user,
    allSubAccountBooks,
  ]);

  const [blotterKey, setBlotterKey] = useState(0);
  useEffect(() => {
    // We update the blotter key to re-render the table when counterparties or aggregations update
    // Otherwise, edits between browsers and the edit button from the grid doesn't update to the latest group names.
    if (providersLoaded) {
      setBlotterKey(prev => prev + 1);
    }
  }, [providersLoaded]);

  // @ts-expect-error If we aren't specifying all properties, ideally we only allow certain properties to be used ...
  const context = useRef<BlotterTableContextProps>({ theme });
  useEffect(() => {
    // @ts-expect-error If we aren't specifying all properties, ideally we only allow certain properties to be used ...
    context.current = { theme };
  }, [theme]);

  if (!providersLoaded && !columns) {
    return (
      <LoaderWrapper style={{ height: 150 }}>
        <Loader size={LoaderSizes.SMALL} />
      </LoaderWrapper>
    );
  }

  return (
    <BlotterWrapper key={blotterKey}>
      <FormTable
        columnDefs={columns}
        getRowId={getRowId}
        context={context}
        autoGroupColumnDef={{
          minWidth: 180,
          headerName: 'Customer',
          cellRendererParams: { suppressCount: true },
          sort: 'desc',
        }}
        enableCellChangeFlash={true}
        enableGroupEdit={true}
        // groupHideOpenParents is what hides the group row's child, as it's always the first child.
        // Important with this tactic that we do not allow sorting of columns for this blotter.
        groupHideOpenParents={true}
        {...props}
      />
    </BlotterWrapper>
  );
};
