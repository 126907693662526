import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  CUSTOMER_ORDER,
  CUSTOMER_ORDER_SUMMARY,
  CustomerOrder,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  Toggle,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByColumnMainMenuItems,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useMixpanel,
  usePersistedBlotterTable,
  useSubscription,
  useWsBlotterTable,
  wsStitchWith,
  type BlotterTableFilter,
  type Column,
  type ColumnState,
  type CompositePipeFunction,
  type ICustomerOrderSummary,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { WebsocketRequest } from '@talos/kyoko';
import type { GetMainMenuItemsParams } from 'ag-grid-community';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { pipe } from 'rxjs';
import { useFeatureFlag } from '../../../hooks';
import { useBlotterState } from '../../../providers/BlottersContext';
import { useCustomerOrdersColumns } from './useCustomerOrdersColumns';
import {
  colIDToFilterBuilderKey,
  onlyServerFilterKeys,
  useCustomerOrdersFilter,
  type CustomerOrdersTableFilter,
} from './useCustomerOrdersFilter';
import { useCustomerOrdersMenu } from './useCustomerOrdersMenu';

const ENTITY_SEARCH_KEYS: (keyof CustomerOrder)[] = [
  'SubmitTime',
  'Counterparty',
  'Symbol',
  'Side',
  'OrderQty',
  'Price',
  'Strategy',
  'CumQty',
  'AvgPx',
  'OrdStatus',
  'User',
  'MarketAccount',
  'OrderID',
  'ClOrdID',
];

export interface FilteredCustomerOrderParams {
  blotterID: string;
  tabLabel?: string;
  defaultColumns?: Column[];
  defaultFilter?: CustomerOrdersTableFilter;
  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: ColumnState[]) => void;
  onDoubleClickRow?: (data: CustomerOrder) => void;
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
}

export function CustomerOrdersBlotterTable({
  blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  onCloneTab,
  onDoubleClickRow,
  generateOrderDetailsRoute,
}: FilteredCustomerOrderParams) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();
  const { enableCustomerOrderSummaryColumns, setEnableCustomerOrderSummaryColumns } = useBlotterState();
  const columnDefinitions = useCustomerOrdersColumns({ defaultColumns, enableCustomerOrderSummaryColumns });
  const persistedBlotterTable = usePersistedBlotterTable<CustomerOrder>(blotterID, {
    columns: columnDefinitions,
    filter: defaultFilter,
    sort: '-SubmitTime',
  });

  const filterResults = useCustomerOrdersFilter({
    persistedBlotterTable,
  });
  const {
    initialFilter,
    clientSideFilter: clientLocalFilter,
    blotterTableFilterProps,
    filterBuilderProps,
  } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const CustomerOrdersMenu = useCustomerOrdersMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
    generateOrderDetailsRoute,
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...CustomerOrdersMenu.columns],
    [CustomerOrdersMenu.columns, persistedBlotterTable.columns]
  );

  const { showBlotterPauseButton } = useFeatureFlag();

  /**
   * We need to keep the customerOrder sub and the customerOrderSummary sub aligned, we do this since they
   * should always be requesting the same data and support the same filters.
   */
  const [customerOrderSummaryRequest, setCustomerOrderSummaryRequest] = useState<WebsocketRequest | null>(null);
  const handleUpdatedCustomerOrderRequest = useCallback((request: WebsocketRequest | null) => {
    if (request === null) {
      setCustomerOrderSummaryRequest(null);
      return;
    }
    setCustomerOrderSummaryRequest({
      ...request,
      name: CUSTOMER_ORDER_SUMMARY,
      tag: 'CUSTOMER_RECENT_ORDER_SUMMARIES',
    });
  }, []);

  const { data: customerOrderSummariesObs } = useSubscription<ICustomerOrderSummary>(
    enableCustomerOrderSummaryColumns ? customerOrderSummaryRequest : null
  );

  const enrichWithCustomerOrderSummaryFieldsPipe = useMemo(
    () =>
      pipe(
        wsStitchWith({
          getPrimaryTypeKey: customerOrder => customerOrder.OrderID,
          getSecondaryTypeKey: customerOrderSummary => customerOrderSummary.OrderID,
          secondarySource: customerOrderSummariesObs,
          stitch: (customerOrder, customerOrderSummary) => {
            customerOrder.enrichOrderWithCustomerSummary?.(customerOrderSummary);
            return customerOrder;
          },
        })
      ),
    [customerOrderSummariesObs]
  ) satisfies CompositePipeFunction<CustomerOrder>;

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      tag: blotterID,
      name: CUSTOMER_ORDER,
      sort_by: '-SubmitTime',
    },
    onInnerRequestChanged: handleUpdatedCustomerOrderRequest,
    rowID: CustomerOrder.rowID satisfies keyof CustomerOrder,
    onDoubleClickRow,
    rowSelection: 'multiple',
    initialFilter,
    clientLocalFilter,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: columnsWithMenu,
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    getContextMenuItems: CustomerOrdersMenu.getContextMenuItems,
    getExtraMainMenuItems,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    quickSearchParams: {
      entitySearchKeys: ENTITY_SEARCH_KEYS,
    },
    pipe: enrichWithCustomerOrderSummaryFieldsPipe,
    pauseParams: {
      showPauseButton: showBlotterPauseButton,
    },
  });
  const { onFilterChanged } = blotterTable;

  /**
   * When the configured filter changes tell WSBlotterTable about
   * the server keys of the filter but not any locally evaluated keys as they might break the backend.
   */
  useEffect(() => {
    if (filterResults.filter) {
      const serverFilter = { ...onlyServerFilterKeys(filterResults.filter) };
      if (!isEqual(blotterTable.filter, serverFilter)) {
        onFilterChanged(serverFilter);
      }
    }
  }, [blotterTable.filter, filterResults.filter, onFilterChanged]);

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'CustomerOrders',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Toggle
              label="Summary Columns"
              size={FormControlSizes.Small}
              tooltip="Include Summary Columns in the table"
              checked={enableCustomerOrderSummaryColumns}
              onChange={setEnableCustomerOrderSummaryColumns}
            />

            <Button startIcon={IconName.DocumentUpload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>

            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.SubmitTime} />
      <BlotterTable {...blotterTable} />
      {CustomerOrdersMenu.dialogs}
    </>
  );
}
