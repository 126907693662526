import { IconName, wait } from '@talos/kyoko';
import { useCallback, useRef, useState } from 'react';
import { PLACE_ORDER_THROTTLE_INTERVAL } from '../components/OMS/OrderImport';
import { OrgConfigurationKey, useOrders, useOrgConfiguration } from '../providers';
import type { SendOrderArgs } from '../providers/orders.types';

export const BulkOrdStatusEnum = {
  PendingSubmission: 'PendingSubmission',
  Submitted: 'Submitted',
  SubmissionAborted: 'SubmissionAborted',
} as const;

/** Representing keyof typeof BulkOrdStatusEnum */
export type BulkOrdStatusEnumValues = keyof typeof BulkOrdStatusEnum;

export const bulkOrdStatusTextMapping: { [key in BulkOrdStatusEnumValues]: string } = {
  [BulkOrdStatusEnum.PendingSubmission]: 'Pending Submission',
  [BulkOrdStatusEnum.Submitted]: 'Submitted',
  [BulkOrdStatusEnum.SubmissionAborted]: 'Submission Aborted',
};

export const bulkOrdStatusIconMapping: { [key in BulkOrdStatusEnumValues]: IconName } = {
  [BulkOrdStatusEnum.PendingSubmission]: IconName.Clock,
  [BulkOrdStatusEnum.Submitted]: IconName.ChevronDoubleRight,
  [BulkOrdStatusEnum.SubmissionAborted]: IconName.ClearCircleSolid,
};

/**
 * This hook provides an API for bulk submitting orders. You can start and stop order submission via this hook. See
 * the return type documentation for more details.
 */
export const useBulkOrderSubmissionSending = () => {
  // Stop is a global variable we can flip in order to stop ongoing bulk sending. This is a ref such that we don't have to wait one render cycle before we get the new state.
  const stopRef = useRef(false);
  const [isSending, setIsSending] = useState(false);

  /** The ClOrdIdState mapping maps from each order currently being worked on to their BulkOrdStatusEnum. PendingSubmission, Submitted, or Aborted. */
  const [clOrdIdsStates, setClOrdIdsStates] = useState<Map<string, BulkOrdStatusEnumValues>>(new Map());

  const updateClOrdIdState = useCallback((clOrdId: string, state: BulkOrdStatusEnumValues) => {
    setClOrdIdsStates(current => {
      const newMap = new Map(current);
      newMap.set(clOrdId, state);
      return newMap;
    });
  }, []);

  const ordersService = useOrders();
  const { getConfig } = useOrgConfiguration();

  const reset = useCallback((orders: SendOrderArgs[]) => {
    setClOrdIdsStates(new Map(orders.map(order => [order.clOrdID, BulkOrdStatusEnum.PendingSubmission])));
    setIsSending(true);
    stopRef.current = false;
  }, []);

  const sendOrders = useCallback(
    async (orders: SendOrderArgs[]) => {
      reset(orders);

      // Work
      for (const order of orders) {
        if (stopRef.current) {
          break;
        }
        ordersService.send(order);
        updateClOrdIdState(order.clOrdID, BulkOrdStatusEnum.Submitted);
        await wait(getConfig(OrgConfigurationKey.PlaceOrderThrottleMillis, PLACE_ORDER_THROTTLE_INTERVAL));
      }

      setIsSending(false);
    },
    [reset, stopRef, ordersService, updateClOrdIdState, getConfig]
  );

  const stopSendingOrders = useCallback(() => {
    stopRef.current = true;
    setClOrdIdsStates(current => {
      // For each clordid which hasnt been submitted, we move that to submission aborted status
      return new Map(
        [...current.entries()].map(([clOrdId, status]) => [
          clOrdId,
          // When we stop sending orders, we set any orders that were still in PendingSubmission to SubmissionAborted
          status === BulkOrdStatusEnum.PendingSubmission ? BulkOrdStatusEnum.SubmissionAborted : status,
        ])
      );
    });
  }, []);

  return {
    /** The function to call to start sending orders */
    sendOrders,
    /** Function to call to stop the current orders being sent */
    stopSendingOrders,
    /** Whether or not the hook is currently in the middle of sending. Kind of like a loading state. */
    isSending,
    /** A set of states for all the orders undergoing work. State will be one of BulkOrdStatusEnum. */
    clOrdIdsStates,
  };
};
