import { AccordionGroup, VStack } from '@talos/kyoko';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import type { BaseBlotterProps } from '../types';
import { PortfolioBreakdownBlotter } from './index';

export function PortfolioBreakdownBlotterWrapper() {
  const wrapperContext: BaseBlotterProps = {
    id: `portfolio-breakdown`,
    label: 'Portfolio Breakdown Blotter',
    tag: `PortfolioManagement-PortfolioBreakdownBlotter`,
  };

  const { selectedPortfolioId } = usePortfolioViewStateSelector();

  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <AccordionGroup key={wrapperContext.id}>
        <PortfolioBreakdownBlotter
          blotterID={wrapperContext.tag}
          wrapperContext={wrapperContext}
          subAccountId={selectedPortfolioId}
        />
      </AccordionGroup>
    </VStack>
  );
}
