import type { UseMultipleSelectionGetSelectedItemPropsOptions } from 'downshift';
import { useCallback } from 'react';
import { IconButton } from '../../Button';
import { IconName } from '../../Icons';
import { TagSizes } from '../../Tag';
import { getLabelWrapper } from '../Autocomplete/useAutocomplete';
import { FormControlSizes } from '../types';
import { Selection } from './styles';

export function useRenderMultiSelectSelection<T>({
  disabled = false,
  getLabel,
  getSelectedItemProps,
  isSelectionLocked,
  removeSelectedItem,
}: {
  getSelectedItemProps: (options: UseMultipleSelectionGetSelectedItemPropsOptions<T>) => any;
  removeSelectedItem: (item: T) => void;
  disabled?: boolean;
  getLabel?: (obj: T) => string;
  isSelectionLocked?: (obj: T) => boolean;
}) {
  return useCallback(
    (selectedItem: T, index: number, formControlSize: FormControlSizes = FormControlSizes.Default) => {
      const isLocked = isSelectionLocked ? isSelectionLocked(selectedItem) : false;
      return (
        <Selection
          key={`selected-item-${index}`}
          disabled={disabled}
          size={TagSizes.Tiny}
          formControlSize={formControlSize}
          {...getSelectedItemProps({ selectedItem, index })}
          suffix={
            !isLocked && (
              <IconButton
                ghost
                disabled={disabled}
                icon={IconName.Clear}
                size={formControlSize - 0.5}
                style={{ position: 'absolute', right: '2px' }}
                onClick={e => {
                  e.stopPropagation();
                  removeSelectedItem(selectedItem);
                }}
              />
            )
          }
        >
          {getLabelWrapper(getLabel, selectedItem)}
        </Selection>
      );
    },
    [disabled, getLabel, getSelectedItemProps, isSelectionLocked, removeSelectedItem]
  );
}
