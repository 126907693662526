/* eslint @typescript-eslint/explicit-module-boundary-types: ["warn"] */

import { add } from 'date-fns';
import { isDate, isEqual, isNil, parseInt } from 'lodash';
import { parseDate, type DateFnInput } from '../../utils/date';
import type { Nullish } from '../../utils/types';

/**
 * Represents a duration of time
 */
export interface Duration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

// Constructed this way since javascript regexes don't allow "extended" form,
// so this is the closest we can get
const parseDurationRegex = new RegExp(
  [
    /^/,
    /\s*(?:(?<days>\d+)\s*(?:days?|d))?/,
    /\s*(?:(?<hours>\d+)\s*(?:hours?|hrs?|h))?/,
    /\s*(?:(?<minutes>\d+)\s*(?:minutes?|mins?|m))?/,
    /\s*(?:(?<seconds>\d+)\s*(?:seconds?|secs?|s))?/,
    /\s*$/,
  ]
    .map(r => r.source)
    .join(''),
  'i' // Case insensitive matching
);

export function canParseAsDuration(input: string): boolean {
  return parseDurationRegex.test(input);
}

/**
 * The "empty" / default duration object
 */
export const emptyDuration: Duration = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

/**
 * Ensure that a duration input is in a consistent format.
 *
 * If passed a string, that string will be parsed and
 *
 * @param input A string description of a duration, or a Duration object, or null
 * @returns A Duration object matching the input
 */
export function parseDuration(input: string | Partial<Duration> | null): Duration {
  if (typeof input === 'string') {
    const components = input.match(parseDurationRegex);
    if (components?.length) {
      // Can't currently use the named groups here (`components.groups['days']` etc.)
      // because current browserlist configuration transpiles the regexes
      // to an older format that doesn't support named capture groups
      const [, days, hours, minutes, seconds] = components;
      return {
        days: days ? parseInt(days) : 0,
        hours: hours ? parseInt(hours) : 0,
        minutes: minutes ? parseInt(minutes) : 0,
        seconds: seconds ? parseInt(seconds) : 0,
      } as Duration;
    }
    return { ...emptyDuration };
  }
  return {
    days: input?.days ?? 0,
    hours: input?.hours ?? 0,
    minutes: input?.minutes ?? 0,
    seconds: input?.seconds ?? 0,
  };
}

/**
 *  Returns a short, pretty representation of a Duration
 */
export function formatDuration(duration: Duration | string | null): string {
  if (typeof duration === 'string') {
    return duration; // return the duration as it was inputted
  }
  if (!duration) {
    return '';
  }
  const parts = Object.entries(duration)
    .filter(([key, value]) => !!value)
    .map(([key, value]) => `${value}${key.charAt(0)}`);

  return parts.join(' ');
}

export function isDuration(value: Nullish<string | Duration | Date>): value is Duration {
  if (isNil(value)) {
    return false;
  }
  if (typeof value === 'string') {
    return false;
  }
  if (isDate(value)) {
    return false;
  }
  return true;
}

/*
 * Check if duration is empty, all set to 0
 */
export function isEmptyDuration(value: Nullish<string | Duration | Date>): boolean {
  return isEqual(value, emptyDuration);
}

/**
 * Calculate a date based on a duration and a base date.
 *
 * @param duration Duration object to apply
 * @param relativeTo Calculate relative to this date
 * @returns New date object with a value of `relativeTo` + `duration`
 */
export function calcDateFromDuration(duration: Duration, relativeTo: DateFnInput): Date {
  let date = parseDate(relativeTo);
  date = add(date, {
    days: duration.days,
    hours: duration.hours,
    minutes: duration.minutes,
    seconds: duration.seconds,
  });
  return date;
}
