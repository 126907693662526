import {
  filterByCellValueMenuItem,
  IconName,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  type Column,
  type ColumnDef,
  type FilterableProperty,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { compact } from 'lodash';
import { useMemo, type ReactNode } from 'react';
import { ReconAssetRow, type SubAccountReconOverviewRow } from './rows';
import { colIDToFilterBuilderKey } from './useSubAccountReconOverviewBlotterFilter';

export function useSubAccountReconOverviewBlotterMenu({
  openClause,
  filterableProperties,
  onDetailsClicked,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
  onDetailsClicked?: (assetRow: ReconAssetRow) => void;
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const mixpanel = useMixpanel();
  const { handleClickJson, jsonModal } = useJsonModal();

  const defaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const data = params?.node?.data;
    const items = compact([
      ...filterByCellValueMenuItem({ params, filterableProperties, openClause, colIDToFilterBuilderKey, mixpanel }),
      ...(data
        ? [
            {
              name: `Show JSON`,
              action: () => handleClickJson(data),
            },
            'separator',
          ]
        : []),
      ...defaultContextMenuItems(params),
    ]);
    return items;
  });

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  const columns = useMemo(
    () =>
      compact([
        onDetailsClicked && {
          type: 'iconButton',
          id: 'rowMenu',
          frozen: true,
          pinned: 'right',
          width: 45,
          params: {
            icon: IconName.Deepdive,
            hide: params => {
              const data: SubAccountReconOverviewRow | undefined = params.node.data;
              return !(data instanceof ReconAssetRow);
            },
            onClick: params => {
              const data: ReconAssetRow | undefined = params.node.data;
              if (data) {
                onDetailsClicked(data);
              }
            },
          },
        },
      ]) satisfies ColumnDef<SubAccountReconOverviewRow>[],
    [onDetailsClicked]
  );

  return {
    columns,
    getContextMenuItems,
    dialogs,
  };
}
