import {
  Box,
  MixpanelEvent,
  MixpanelEventProperty,
  useDynamicCallback,
  useMixpanel,
  type MixpanelEventSource,
} from '@talos/kyoko';
import { TransferFormComponent } from '../containers/Transfers/TransferForm';
import type { TransferForm } from '../containers/Transfers/types';
import { AppwideDrawerContentType, useAppwideDrawerContext } from '../providers/AppwideDrawerProvider';

export const useTransfersDrawer = () => {
  const mixpanel = useMixpanel();

  const { openDrawer } = useAppwideDrawerContext();

  const openTransfersDrawer = useDynamicCallback(
    ({ initialTransfer, source }: { initialTransfer?: TransferForm; source: MixpanelEventSource }) => {
      mixpanel.track(MixpanelEvent.OpenTransfersDrawer, { [MixpanelEventProperty.Source]: source });

      openDrawer({
        type: AppwideDrawerContentType.TransferForm,
        title: 'Transfer Form',
        renderContent: () => (
          <Box p="spacingMedium">
            <TransferFormComponent initialForm={initialTransfer} portalizeDropdowns={false} />
          </Box>
        ),
      });
    }
  );

  return {
    openTransfersDrawer,
  };
};
