import { ACTION, MixpanelEventSource, ProductTypeEnum, useDynamicCallback } from '@talos/kyoko';
import { useMemo } from 'react';
import { useRoleAuth, useTransfersDrawer } from '../../../../hooks';
import { useTransfers } from '../../../../providers';
import type { Balance } from '../../../../types';
import { useTradePositionColumn } from '../../../../utils/columns/useTradePositionColumn';
import { useBalancesColumnDefinitions } from '../../../Blotters/BalancesV2/useBalancesV2Columns';
import type { TransferForm } from '../../../Transfers/types';

// Create a simple type here to give us an extra layer of type safety around these parts
export type ExtraTreasuryColumns = 'buy' | 'sell' | 'withdraw' | 'deposit';

export const useTreasuryColumnDefinitions = () => {
  const balancesColumnDefinitions = useBalancesColumnDefinitions();

  const { getPrimeableTransfer } = useTransfers();
  const { openTransfersDrawer } = useTransfersDrawer();

  const handleOpenTransfersDrawer = useDynamicCallback((primedTransfer: TransferForm) => {
    openTransfersDrawer({ initialTransfer: primedTransfer, source: MixpanelEventSource.TreasuryBlotter });
  });

  const { isAuthorized } = useRoleAuth();
  const authorizedToTrade = isAuthorized(ACTION.SUBMIT_ORDER);
  const authorizedToTransfer = isAuthorized(ACTION.SUBMIT_TRANSFER);

  const buyColumn = useTradePositionColumn<Balance>({
    assetField: 'Currency',
    marketAccountField: 'marketAccountName',
    constrainSecuritiesToMarket: true,
    action: 'Buy',
    columnId: 'buy' satisfies ExtraTreasuryColumns,
    getAssetType: ProductTypeEnum.Spot,
    showTitle: true,
  });

  const sellColumn = useTradePositionColumn<Balance>({
    assetField: 'Currency',
    marketAccountField: 'marketAccountName',
    constrainSecuritiesToMarket: true,
    action: 'Sell',
    columnId: 'sell' satisfies ExtraTreasuryColumns,
    getAssetType: ProductTypeEnum.Spot,
    showTitle: true,
  });

  const columnDefinitions = useMemo(() => {
    const map = new Map(balancesColumnDefinitions);
    if (authorizedToTrade) {
      map.set('buy', buyColumn);
      map.set('sell', sellColumn);
    }

    if (authorizedToTransfer) {
      map.set('withdraw', {
        type: 'transferButton',
        id: 'withdraw' satisfies ExtraTreasuryColumns,
        params: { getPrimeableTransfer, type: 'withdraw', onClick: handleOpenTransfersDrawer },
      });
      map.set('deposit', {
        type: 'transferButton',
        id: 'deposit' satisfies ExtraTreasuryColumns,
        params: { getPrimeableTransfer, type: 'deposit', onClick: handleOpenTransfersDrawer },
      });
    }

    return map;
  }, [
    balancesColumnDefinitions,
    authorizedToTrade,
    authorizedToTransfer,
    getPrimeableTransfer,
    handleOpenTransfersDrawer,
    buyColumn,
    sellColumn,
  ]);

  return columnDefinitions;
};
