import type Popper from '@popperjs/core';
import { detectOverflow } from '@popperjs/core';
import { animated, easings, useTransition } from '@react-spring/web';
import type React from 'react';
import { Children, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { usePopper, type Modifier } from 'react-popper';
import { useTheme } from 'styled-components';

import { noop } from 'lodash';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useOverridePopoverProps } from '../../providers/OverridePopoverPropsProvider';
import { Z_INDEX } from '../../styles/layout';
import { EMPTY_ARRAY } from '../../utils/empty';
import { useDropdownContext } from '../Form/Dropdown/DropdownContext';
import { useModalContext } from '../Modal';
import { Portal, useTopLevelPortalElement } from '../Portal';
import { Content, Target, Wrapper } from './styles';
import type { PopoverContentProps, PopoverProps, PopoverState, PopoverStateProps } from './types';

export * as PopoverStyles from './styles';
export * from './types';

const TRANSITION_SMALL = {
  overflow: 'hidden',
  from: { opacity: 0 as React.CSSProperties['opacity'], transform: 'translateY(8px)' },
  enter: { opacity: 1 as React.CSSProperties['opacity'], transform: 'translateY(0)' },
  leave: { opacity: 0 as React.CSSProperties['opacity'], transform: 'translateY(8px)' },
  config: {
    duration: 300,
    easing: easings.easeOutBack,
  },
  unique: true,
} satisfies Parameters<typeof useTransition>[1];

const TRANSITION = {
  overflow: 'hidden',
  from: { opacity: 0 as React.CSSProperties['opacity'], transform: 'translateY(8px)' },
  enter: { opacity: 1 as React.CSSProperties['opacity'], transform: 'translateY(0)' },
  leave: { opacity: 0 as React.CSSProperties['opacity'], transform: 'translateY(8px)' },
  config: {
    duration: 300,
    easing: easings.easeOutBack,
  },
  unique: true,
} satisfies Parameters<typeof useTransition>[1];

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  (
    {
      style,
      placement,
      variant,
      transitionStyle,
      isSmall,
      children,
      overflow,
      overflowY,
      noPaddingAndBorder,

      ...props
    },
    ref
  ) => {
    const modalContext = useModalContext();
    const dropdownContext = useDropdownContext();

    // Popovers are most often renders absolutely due to available space and position reasons. They can be rendered absolutely but within a modal or a dropdown, which themselves
    // are usually rendered absolutely. In these cases, we need to modify the base zIndex we render the tooltip at to ensure that the tooltip is on top of the context we're being rendered within.
    // The dropdown has presedence over modals.
    const baseZIndex = dropdownContext ? Z_INDEX.dropdown : modalContext ? Z_INDEX.modal : Z_INDEX.popover;

    return (
      <div style={{ ...style, zIndex: baseZIndex }} ref={ref}>
        <Wrapper placement={placement} isSmall={isSmall} {...props}>
          <animated.div style={transitionStyle}>
            <Content
              variant={variant}
              role="tooltip"
              placement={placement}
              overflow={overflow}
              overflowY={overflowY}
              isSmall={isSmall}
              aria-busy={props['aria-busy']}
              noPaddingAndBorder={noPaddingAndBorder}
            >
              {children}
            </Content>
          </animated.div>
        </Wrapper>
      </div>
    );
  }
);

export const PopoverTarget = forwardRef<HTMLSpanElement, any>(({ children, ...props }, ref) => (
  <Target {...props} ref={ref}>
    {children}
  </Target>
));

export function Popover({
  children,
  isOpen,
  onMouseEnterTarget,
  onMouseLeave,
  onFocusTarget,
  onBlurTarget,
  onClickTarget,
  setContentRef,
  setTargetRef,
  isSmall,
  usePortal,
  tabIndex = 0,
  styles,
  attributes,
  state,
  spacing,
  overflow,
  overflowY = 'auto',
  preventOverflow,
  targetStyle,
  variant,
  noPaddingAndBorder,
}: PopoverProps) {
  useTopLevelPortalElement('popover');

  const [targetChild, contentChild] = Children.toArray(children);

  const transitions = useTransition(isOpen, isSmall ? TRANSITION_SMALL : TRANSITION);

  if (contentChild == null) {
    return <>{targetChild}</>;
  }

  const maybeMaxWidth = preventOverflow ? `calc(100% - ${spacing * 2}px)` : '';

  const popperContent = transitions(
    (transitionStyle, item, t) =>
      item && (
        <PopoverContent
          variant={variant}
          style={{ ...styles.popper, maxWidth: maybeMaxWidth }} // safeguard from overflowing by applying maxwidth
          ref={setContentRef}
          key={t.key}
          placement={(state && state.placement) ?? 'auto'}
          transitionStyle={transitionStyle}
          onMouseLeave={onMouseLeave}
          isSmall={isSmall}
          overflow={overflow}
          overflowY={overflowY}
          aria-busy={!t.ctrl.idle}
          noPaddingAndBorder={noPaddingAndBorder}
          data-testid="popover"
          {...attributes.popper}
        >
          {contentChild}
        </PopoverContent>
      )
  );

  return (
    <>
      <PopoverTarget
        onMouseEnter={onMouseEnterTarget}
        onMouseLeave={onMouseLeave}
        onFocus={onFocusTarget}
        onBlur={onBlurTarget}
        onClick={onClickTarget}
        ref={setTargetRef}
        tabIndex={tabIndex}
        style={targetStyle}
        data-testid="popover-target"
      >
        {targetChild}
      </PopoverTarget>
      {usePortal ? <Portal portalId="popover">{popperContent}</Portal> : popperContent}
    </>
  );
}

export function usePopoverState({
  closeOnClickOutside = false,
  onClickOutside = noop,
  trigger = 'click',
  isSmall = false,
  usePortal = false,
  onOpen = noop,
  onClose = noop,
  placement,

  modifiers = EMPTY_ARRAY,
  delay,
  strategy = 'absolute',
  preventOverflow = true,
  noPaddingAndBorder = false,
}: PopoverStateProps): PopoverState {
  const { spacingSmall } = useTheme();
  const [targetRef, setTargetRef] = useState<HTMLSpanElement | null>(null);
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);

  const overridePopoverProps = useOverridePopoverProps();
  // If the context overrides the trigger, use that instead.
  trigger = overridePopoverProps.trigger ?? trigger;

  const [isOpen, setOpen] = useState(false);
  const [overflow, setOverflow] = useState<Popper.SideObject>({} as any);

  const overflowModifier: Modifier<string, object> = useMemo(
    () => ({
      name: 'maxHeight',
      enabled: true,
      phase: 'main',
      requiresIfExists: ['offset'],
      fn({ state }) {
        setOverflow(detectOverflow(state));
      },
    }),
    []
  );

  const popper = usePopper(targetRef, contentRef, {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: spacingSmall,
        },
      },
      overflowModifier,
      ...modifiers,
    ],
    placement,
    strategy,
  });
  const { styles, attributes, state, update } = popper;

  const onClickTarget = () => {
    if (trigger === 'click') {
      toggle();
    }
  };

  const toggle = () => (isOpen ? close() : open());

  const close = useCallback(() => {
    onClose && onClose();
    setOpen(false);
  }, [onClose]);

  const open = useCallback(() => {
    onOpen && onOpen();
    setOpen(true);
  }, [onOpen]);

  // e.target contains the element that is being leaved
  const isLeavingContent = e => e.target === window || !contentRef || contentRef.contains(e.target);

  // if leaving target, close unless the next element is the content
  const isLeavingPopover = e =>
    e.relatedTarget === window ||
    e.target === window ||
    !targetRef ||
    !contentRef ||
    (targetRef.contains(e.target) && !contentRef.contains(e.relatedTarget));

  const isClickingOutsidePopover = useCallback(
    e => !targetRef || !contentRef || (!targetRef.contains(e.target) && !contentRef.contains(e.target)),
    [contentRef, targetRef]
  );

  let openTimer;
  let closeTimer;
  const onMouseEnterTarget = () => {
    if (trigger === 'hover') {
      closeTimer && clearTimeout(closeTimer);
      delay
        ? (openTimer = setTimeout(() => {
            open();
            openTimer = null;
          }, delay))
        : open();
    }
  };

  const onMouseLeave = e => {
    if (trigger === 'hover') {
      if (isLeavingContent(e) || isLeavingPopover(e)) {
        openTimer && clearTimeout(openTimer);
        delay
          ? (closeTimer = setTimeout(() => {
              close();
              closeTimer = null;
            }, 150))
          : close();
      }
    }
  };

  const onFocusTarget = () => {
    if (trigger === 'hover') {
      open();
    }
  };
  const onBlurTarget = () => {
    if (trigger === 'hover') {
      close();
    }
  };

  const isTouchScreen = useDeviceType() === 'mobile';
  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = e => {
        if (contentRef && isClickingOutsidePopover(e)) {
          if (closeOnClickOutside) {
            close();
          }

          onClickOutside && onClickOutside(e);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      if (isTouchScreen) {
        document.addEventListener('touchstart', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        if (isTouchScreen) {
          document.removeEventListener('touchstart', handleClickOutside);
        }
      };
    }
  }, [isTouchScreen, isOpen, close, closeOnClickOutside, contentRef, isClickingOutsidePopover, onClickOutside]);

  return {
    onClickTarget,
    onMouseEnterTarget,
    onMouseLeave,
    onFocusTarget,
    onBlurTarget,
    targetRef,
    contentRef,
    setTargetRef,
    setContentRef,
    isOpen,
    close,
    open,
    toggle,
    placement,
    isSmall,
    usePortal,
    styles: { popper: styles.popper },
    attributes,
    state,
    update,
    spacing: spacingSmall,
    overflow,
    preventOverflow,
    noPaddingAndBorder,
  };
}
