import {
  ACTION,
  BlotterTable,
  Flex,
  IconName,
  LoaderSizes,
  LocalFilterInput,
  PanelActions,
  useBlotterTable,
  useDisclosure,
  useObservable,
  useUserContext,
  type Column,
  type MarketCredential,
  type User,
} from '@talos/kyoko';
import type { RowNode } from 'ag-grid-enterprise';
import { compact, keys, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { map, of } from 'rxjs';
import { Loader, LoaderWrapper } from '../../../../components/Loader';
import { useFeatureFlag, useRoleAuth } from '../../../../hooks';
import type { IEditUserDrawerTab } from '../types';
import { useUserSettings } from '../useUserSettings';
import { EditMarketCredentialsDialog } from './EditMarketCredentialsDialog';

const SEARCH_KEYS: (keyof MarketCredential)[] = ['Name', 'Market', 'Label'];

interface EditMarketCredentialTabProps {
  columns: Column[];
  selected: MarketCredential | undefined;
  user: User;
  clearSelected: () => void;
}

function EditMarketCredentialsTab({ columns, selected, user, clearSelected }: EditMarketCredentialTabProps) {
  const { listMarketCredentials } = useUserContext();
  const [allowedKeys, setAllowedKeys] = useState<Map<string, string[]>>(new Map());
  const [marketCredentials, setMarketCredentials] = useState<MarketCredential[] | undefined>(undefined);
  const [quickFilter, setQuickFilter] = useState<string>('');
  const { listAllowedUserCredentialsKeys } = useUserSettings();
  const editMarketCredentialsDialog = useDisclosure({
    onClose: clearSelected,
  });
  const { open } = editMarketCredentialsDialog;

  useEffect(() => {
    Promise.all([listAllowedUserCredentialsKeys(), listMarketCredentials()]).then(
      ([allowedCredentials, credentials]) => {
        const allowed = allowedCredentials?.data ?? [];
        const allowedMarkets = keys(allowed?.[0] ?? {});
        const sortedCreds = sortBy(
          credentials.filter(credential => allowedMarkets.includes(credential.Market)), // Show only Markets that can have some keys assigned
          item => item.Label
        );
        setMarketCredentials(sortedCreds);
        setAllowedKeys(allowed);
      }
    );
  }, [listAllowedUserCredentialsKeys, listMarketCredentials]);

  useEffect(() => {
    if (selected) {
      open();
    }
  }, [selected, open]);

  const dataObservable = useObservable(() => {
    return of(marketCredentials ?? []).pipe(
      map(data => ({
        data,
        initial: true,
        type: 'MarketCredential',
      }))
    );
  }, [marketCredentials]);

  const blotterTable = useBlotterTable<MarketCredential>({
    dataObservable,
    rowID: 'CredentialID',
    columns,
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
      filterText: quickFilter,
    },
  });

  if (marketCredentials == null) {
    return (
      <LoaderWrapper>
        <Loader size={LoaderSizes.SMALL} />
      </LoaderWrapper>
    );
  }

  return (
    <Flex flexDirection="column" gap="spacingMedium" w="100%" h="100%">
      <PanelActions>
        <LocalFilterInput value={quickFilter} onChange={setQuickFilter} width="200px" />
      </PanelActions>
      <BlotterTable {...blotterTable} background="backgroundDrawer" />
      <EditMarketCredentialsDialog
        selected={selected}
        allowedKeys={allowedKeys}
        user={user}
        {...editMarketCredentialsDialog}
      />
    </Flex>
  );
}

export function useEditMarketCredentialsTab({ user }: { user: User }): IEditUserDrawerTab {
  const [selected, setSelected] = useState<MarketCredential | undefined>(undefined);

  const handleEditCredential = useCallback(
    (marketCredential: MarketCredential) => {
      setSelected({ ...marketCredential });
    },
    [setSelected]
  );

  const clearSelected = useCallback(() => {
    setSelected(undefined);
  }, [setSelected]);

  const columns = useMemo<Column[]>(
    () =>
      compact([
        {
          type: 'text',
          field: 'Label',
          width: 150,
        },
        {
          type: 'text',
          field: 'Market',
          width: 150,
        },
        {
          type: 'text',
          field: 'ConnectionType',
          width: 150,
        },
        {
          id: 'edit',
          type: 'iconButton',
          pinned: 'right',
          width: 60,
          suppressColumnsToolPanel: true,
          params: {
            onClick: ({ node }: { node: RowNode }) => handleEditCredential(node.data),
            icon: IconName.Pencil,
          },
        },
      ]),
    [handleEditCredential]
  );

  const component = useMemo(() => {
    return <EditMarketCredentialsTab columns={columns} selected={selected} user={user} clearSelected={clearSelected} />;
  }, [columns, selected, user, clearSelected]);

  const { enableMarketCredentials } = useFeatureFlag();
  const { isAuthorized } = useRoleAuth();
  const canEditMarketCredentials = enableMarketCredentials && isAuthorized(ACTION.EDIT_MARKET_CREDENTIALS);

  return {
    name: 'Market Credentials',
    component,
    viewable: canEditMarketCredentials,
    save: undefined,
    isDirty: false,
    renderReasonInput: false,
  };
}
