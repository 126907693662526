import styled from 'styled-components';
import { parseColor } from '../../styles';

export const MeterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BarWrapper = styled.div<{
  background?: string;
  roundLeftEdge: boolean;
  roundRightEdge: boolean;
  height?: number;
}>`
  flex: 1;
  height: ${({ theme, height }) => height ?? theme.baseSize / 2}px;
  border-top-left-radius: ${({ theme, roundLeftEdge }) => (roundLeftEdge ? theme.baseSize / 2 / 2 : 0)}px;
  border-bottom-left-radius: ${({ theme, roundLeftEdge }) => (roundLeftEdge ? theme.baseSize / 2 / 2 : 0)}px;
  border-top-right-radius: ${({ theme, roundRightEdge }) => (roundRightEdge ? theme.baseSize / 2 / 2 : 0)}px;
  border-bottom-right-radius: ${({ theme, roundRightEdge }) => (roundRightEdge ? theme.baseSize / 2 / 2 : 0)}px;

  background: ${({ theme, background }) => (background ? parseColor(theme, background) : theme.backgroundProgressBar)};
  overflow: hidden;
`;

export const Bar = styled.div.attrs<{
  width: number;
  align: 'left' | 'right';
  animate: boolean;
}>(({ width, align, animate }) => ({
  style: { transform: `translateX(${align === 'left' ? -(100 - width) : 100 - width}%)` },
}))<{
  width: number;
  color?: string;
  align: 'left' | 'right';
  animate: boolean;
}>`
  height: 100%;
  width: 100%;
  min-width: ${({ theme }) => theme.baseSize / 2}px;
  background: ${({ theme, color }) => (color ? parseColor(theme, color) : theme.backgroundProgressBarMeter)};
  transition: transform ${({ animate }) => (animate ? '200ms' : '0ms')} ease-out, background 200ms ease;
  transform-origin: center ${({ align }) => align};
`;

export const Label = styled.div`
  min-width: ${({ theme }) => 3 * theme.baseSize}px;
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  color: ${({ theme }) => theme.colorTextSubtle};
`;
