import {
  Box,
  ChartTooltipValue,
  ChartTooltipValueType,
  HStack,
  LineChart,
  Portal,
  Text,
  VStack,
  renderToHTML,
  useDynamicCallback,
  useHighchartsRef,
  type BoxProps,
  type Currency,
  type DateFormatter,
  type DateRangeFilter,
} from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';

import { useHomeCurrency } from 'hooks';
import { useTheme } from 'styled-components';
import { useChartPlotline } from '../../PortfolioChart/useChartPlotline';
import { AS_OF_PLOTLINE_ID, DYNAMIC_CHART_Z_INDEXES } from '../../PortfolioChart/utils';
import { RESOLUTION_METADATA, dateRangeFilterToResolution } from '../../SelectableResolution';
import { BlueMarkerBox } from '../../components/BlueMarkerBox';
import { ChartMessageType, ChartMessagingProvider, useChartMessaging } from '../../providers/ChartMessagingProvider';
import { TreasuryManagementActionType } from '../TreasuryManagementReducer';
import { CHART_HEADER_SUFFIX_PORTAL_ID } from '../types';
import { ChartHeaderDateRangePicker } from './ChartHeaderDateRangePicker';
import { BALANCES_SERIES_NAME } from './tokens';
import { useHistoricalValueData } from './useHistoricalValueData';

// Overscroll applied to the chart x axis
const OVERSCROLL = 0;

interface HistoricalValueChartProps extends BoxProps {}

function InnerHistoricalValueChart({ ...boxProps }: HistoricalValueChartProps) {
  const theme = useTheme();
  const homeCurrencyInfo = useHomeCurrency();
  const { chartRef, isLoaded, setChartObject } = useHighchartsRef();
  const { dataLoading, dataResetting } = useHistoricalValueData({ isLoaded, chartRef });

  const { messenger } = useChartMessaging();

  const {
    state: { chartRequestDateRange, snapshotDate },
    dispatch,
  } = useTreasuryManagementContext();

  const requestResolution = useMemo(() => {
    return dateRangeFilterToResolution(chartRequestDateRange);
  }, [chartRequestDateRange]);

  const { initialPlotline } = useChartPlotline({ snapshotDate, isLoaded, chartRef, plotlineID: AS_OF_PLOTLINE_ID });

  const handlePointClick = useDynamicCallback((point: Highcharts.Point) => {
    if (!isLoaded) {
      return;
    }

    const clickedExistingPlotLinePoint = snapshotDate != null && new Date(snapshotDate).getTime() === point.x;

    dispatch({
      type: TreasuryManagementActionType.SnapshotDateChange,
      payload: {
        // Delete plotline (setting page to show "Now") if you clicked a point which already had a plotline
        snapshotDate: clickedExistingPlotLinePoint ? null : new Date(point.x),
      },
    });
  });

  const getTooltip = useDynamicCallback(
    (data: Highcharts.TooltipFormatterContextObject, tooltip: Highcharts.Tooltip) => {
      return renderToHTML(
        <MyTooltip
          data={data}
          homeCurrencyInfo={homeCurrencyInfo}
          dateFormatter={RESOLUTION_METADATA[requestResolution].dateFormatter}
          plotLineX={snapshotDate ? new Date(snapshotDate).getTime() : undefined}
        />,
        theme
      );
    }
  );

  const options = useMemo(() => {
    return {
      chart: {
        animation: false,
        zooming: {
          mouseWheel: {
            enabled: true,
          },
        },
        events: {
          click: function (event) {
            if (this.hoverPoint) {
              handlePointClick(this.hoverPoint);
            }
          },
        },
      },
      plotOptions: {
        series: {
          point: {
            events: {
              click: function () {
                handlePointClick(this);
              },
            },
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function (tooltip: Highcharts.Tooltip) {
          return getTooltip(this, tooltip);
        },
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        ordinal: false,
        minRange: 1000 * 3600 * 4, // 4 hours in milliseconds
        plotLines: initialPlotline,
        overscroll: OVERSCROLL, // defines the right-padding so to speak of the line series data points
      },
      yAxis: {
        title: {
          text: `${homeCurrencyInfo?.Symbol} Value`,
          style: {
            color: theme.colorTextDefault,
          },
        },
      },
      navigator: {
        enabled: true,
        xAxis: {
          labels: {
            format: '{value:%e %b %y}',
          },
          plotLines: initialPlotline,
          overscroll: OVERSCROLL,
        },
      },
      series: [
        {
          type: 'line',
          data: [],
          animation: false,
          name: BALANCES_SERIES_NAME,
          color: theme.colorDataBlue,
          navigatorOptions: {
            color: theme.colors.gray['070'],
          },
          lineWidth: 2,
          zIndex: DYNAMIC_CHART_Z_INDEXES.series,
          states: {
            hover: {
              lineWidth: 2,
            },
          },
          marker: {
            enabled: false,
          },
        },
      ],
    } satisfies Highcharts.Options;
    // Important that these dependencies are stable
  }, [initialPlotline, theme, handlePointClick, getTooltip, homeCurrencyInfo]);

  const handleManualDateRangeChange = useCallback(
    (newDateRange: DateRangeFilter) => {
      if (!isLoaded) {
        return;
      }
      const start = newDateRange.StartDate ? new Date(newDateRange.StartDate) : undefined;

      // There can either be some newDateRangeFilter.EndDate, or there might not. If there is, use it, otherwise: use the series dataMax (highest x value of the series)
      const maybeSeriesMax = getLastDataPoint(chartRef.current?.series[0])?.x;
      const seriesMaxOrUndefined = maybeSeriesMax ? maybeSeriesMax + OVERSCROLL : undefined;
      const endTime = newDateRange.EndDate ? new Date(newDateRange.EndDate).getTime() : seriesMaxOrUndefined;

      // Instead of setting the date range here, we set the extremes of the chart, which then in turn sends an event to change the date range in this component
      chartRef.current?.xAxis[0].setExtremes(start?.getTime(), endTime, true, false);
    },
    [chartRef, isLoaded]
  );

  // Whenever the xAxis extremes change, we send a message to our neighbors that this has occurred
  const handleXAxisExtremesChange = useCallback(
    (axis: Highcharts.Axis, event: Highcharts.AxisSetExtremesEventObject) => {
      // If neither of these are defined there's no data in the chart. Special logic in this case.
      if (event.dataMax == null && event.dataMin == null) {
        // If there is no event.min then its some kind of initial event, which we decide to ignore
        if (event.min == null) {
          return true;
        }

        messenger.next({
          type: ChartMessageType.VisibleAreaChanged,
          payload: {
            min: event.min,
            max: undefined,
          },
        });
      } else {
        // There is data in the chart:
        // If the xAxis max is equal to the data max (right-most data point), then there is no EndDate set so to speak, we are going up to "now" in other words
        const maxOrUndefined = event.max >= event.dataMax ? undefined : event.max;
        messenger.next({
          type: ChartMessageType.VisibleAreaChanged,
          payload: {
            min: event.min,
            max: maxOrUndefined,
          },
        });
      }
    },
    [messenger]
  );

  // When data is resetting, we want to render a big blocking overlay over the entire chart area
  const showLoading = dataResetting;
  // If data is not resetting, just loading more data, show the loading corner icon
  const showLoadingCornerIcon = dataLoading && !showLoading;

  return (
    <>
      <Portal portalId={CHART_HEADER_SUFFIX_PORTAL_ID}>
        <ChartHeaderDateRangePicker onManualDateRangeChange={handleManualDateRangeChange} />
      </Portal>
      <Box w="100%" h="100%" position="relative" {...boxProps}>
        <LineChart
          throttleResizing={0}
          onChartCreated={setChartObject}
          showLoadingCornerIcon={showLoadingCornerIcon}
          showOverlayWhenLoading
          isLoading={showLoading}
          options={options}
          onXAxisAfterSetExtremes={handleXAxisExtremesChange}
          showNoData
          customNoDataText="No data found. Try changing the time frame."
        />
      </Box>
    </>
  );
}

export function HistoricalValueChart({ ...props }: HistoricalValueChartProps) {
  return (
    <ChartMessagingProvider>
      <InnerHistoricalValueChart {...props} />
    </ChartMessagingProvider>
  );
}

function getLastDataPoint(series: Highcharts.Series | undefined): Highcharts.Point | undefined {
  // Safety checks
  if (series == null || series.data == null || series.data.length === 0) {
    return undefined;
  }

  return series.data[series.data.length - 1];
}

interface MyTooltipProps {
  data: Highcharts.TooltipFormatterContextObject;
  homeCurrencyInfo: Currency | undefined;
  dateFormatter: DateFormatter;
  plotLineX: number | undefined;
}

function MyTooltip({ data, homeCurrencyInfo, dateFormatter, plotLineX }: MyTooltipProps) {
  const showPlotlineDescription = plotLineX === data.x;
  return (
    <VStack gap="spacingDefault" alignItems="flex-start" fontSize="fontSizeMd">
      {data.x != null && (
        <Text weight="fontWeightMedium" color="colorTextAttention">
          {dateFormatter(new Date(data.x))}
        </Text>
      )}
      <ChartTooltipValue data={data.y} valueType={ChartTooltipValueType.currency} currency={homeCurrencyInfo} />
      {showPlotlineDescription && (
        <HStack gap="spacingSmall">
          <BlueMarkerBox />
          <Text color="colorTextSubtle">Date in balances chart and blotter</Text>
        </HStack>
      )}
    </VStack>
  );
}
