import {
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  Flex,
  HStack,
  Text,
  ToggleHorizontal,
  usePortal,
  VStack,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { useAppStateDispatch } from '../../../../../providers/AppStateProvider';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import { LongShortStackedChart } from '../LongShortStackedChart';
import { PortfolioBreakdownBlotterWrapper } from '../PortfolioBreakdownBlotter/PortfolioBreakdownBlotterWrapper';

type ChartDisplayType = 'byUnderlier' | 'byInstrument';
const chartDisplayOptions: Array<{ value: ChartDisplayType; displayName: string }> = [
  {
    value: 'byInstrument',
    displayName: 'Instrument',
  },
  {
    value: 'byUnderlier',
    displayName: 'Underlier',
  },
];

const { updateOverviewChartDisplayType } = getPortfolioViewActions();
export const PortfolioOverview = () => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  const { overviewChartDisplayType } = usePortfolioViewStateSelector();
  const dispatch = useAppStateDispatch();

  const onDisplayTypeChange = useCallback(
    (displayType: ChartDisplayType) => {
      dispatch(updateOverviewChartDisplayType(displayType));
    },
    [dispatch]
  );

  return (
    <Box w="100%" h="100%" data-testid="portfolio-management-overview-wrapper">
      <VStack gap="spacingTiny" w="100%" h="100%">
        <HStack px="8px" justifyContent="space-between" w="100%" background="backgroundContent">
          <HStack>
            <Text py="12px" fontSize="14px">
              Portfolio Breakdown
            </Text>
          </HStack>
          <HStack>
            <Box pr="spacingSmall">Chart By: </Box>
            <ToggleHorizontal<ChartDisplayType>
              value={overviewChartDisplayType}
              options={chartDisplayOptions}
              onChange={onDisplayTypeChange}
            />
            <Box ref={filtersContainerRef} px="spacingDefault" />
          </HStack>
        </HStack>
        <Flex flex="none" w="100%" h="220px" maxHeight="220px" data-testid="portfolio-overview-chart">
          <LongShortStackedChart />
        </Flex>
        <Flex justifyContent="stretch" flex="auto" w="100%" data-testid="portfolio-overview-grid">
          <PortfolioBreakdownBlotterWrapper />
        </Flex>
      </VStack>
    </Box>
  );
};
