import {
  SideEnum,
  getOppositeSide,
  getPositionAmount,
  isSpot,
  logger,
  toBigWithDefault,
  type Security,
} from '@talos/kyoko';
import type { TradePositionButtonAction } from '../../utils/getPositionSecurities';

/**
 * This function converts a set of parameters representing user intention to an order form prime instruction.
 *
 * `positionAmount` is the amount of `asset` to be acted upon, `action` represents what to do (eg buy, sell) with this `positionAmount` of `asset`, and `securityToTradeThrough`
 * is the instrument using which this action should be performed.
 *
 * Assumptions: this function assumes that Buy and Sell Spot actions are _never_ done through inverse instruments (eg Buy ETH through BTC-ETH spot pair).
 * If the function observes a Buy or Sell action through an inverse instrument, it will return an effectively empty result and raise a logging error.
 *
 * The function does support Closing through inverse instruments (Eg: Close (sell) a positive ETH position through a BTC-ETH Buy Order (sell ETH for BTC))
 */
export function getPrimeOrderFormInstructions({
  positionAmount,
  action,
  asset,
  securityToTradeThrough,
}: {
  positionAmount: string;
  action: TradePositionButtonAction;
  asset: string;
  securityToTradeThrough: Security;
}) {
  const closeSide = toBigWithDefault(positionAmount, 0).gt(0) ? SideEnum.Sell : SideEnum.Buy;
  const side = action === 'Buy' ? SideEnum.Buy : action === 'Sell' ? SideEnum.Sell : closeSide;

  // Different logic for spot vs non-spot. For spot, you can close through a large amount of spot trading pairs.
  // For derivs, there's only one option: your deriv security your position is in.
  // Due to this difference, we split the two cases up

  const isInverseInstrument = asset === securityToTradeThrough.QuoteCurrency;

  if (isSpot(securityToTradeThrough)) {
    if ((action === 'Buy' || action === 'Sell') && isInverseInstrument) {
      // We don't support inverse instruments for buy and sell actions.
      logger.error(new Error(`Trade Button: trying to ${action} through an inverse instrument (unsupported)`), {
        extra: {
          action,
          asset,
          securityToTradeThrough,
        },
      });

      return { currency: '', amount: '', side };
    }

    const amountBig = toBigWithDefault(positionAmount, 0);
    const amountAbs = amountBig.abs().toFixed(); // always prime with a positive amount
    const amountIsGt0 = amountBig.gt(0);
    const amountIsLt0 = amountBig.lt(0);

    if (action === 'Buy') {
      return { currency: asset, amount: amountIsLt0 ? amountAbs : '', side };
    } else if (action === 'Sell') {
      return { currency: asset, amount: amountIsGt0 ? amountAbs : '', side };
    } else {
      // action = "Close"
      // If we are closing through an inverse instrument, defined as our asset we are trying to close being the Quote Currency of the selected trading pair,
      // we need to flip the side of the order. If you want to close an ETH position, for example BTC-ETH would be an inverse instrument.
      return {
        amount: amountAbs,
        currency: asset,
        side: isInverseInstrument ? getOppositeSide(side) : side,
      };
    }
  } else {
    // Non-spot
    const { value, currency } = getPositionAmount(positionAmount, securityToTradeThrough);
    return { amount: value, currency, side };
  }
}
