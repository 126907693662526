import { animated } from '@react-spring/web';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { isColor } from '../../styles';
import { Direction } from '../../types/Direction';
import { Box } from '../Core';
import { Highlight, NumberWrapper } from '../FormattedNumber/styles';

const HEADER_HEIGHT = 4;
export const MARKET_DATA_CARD_LADDER_INPUT_WIDTH = 52;

export const VolumeLadderWrapper = styled.div<{ height?: number; width?: number }>`
  color: ${({ theme }) => theme.colorTextDefault};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Labels = styled(Box)`
  display: flex;
  align-items: center;
  line-height: 1;
`;
Labels.defaultProps = {
  px: 'spacingMedium',
};

export const LadderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
LadderWrapper.defaultProps = {
  flex: 2,
};

export const Header = styled(Box)`
  display: flex;
  line-height: 1;
`;
Header.defaultProps = {
  p: 'spacingDefault',
};

export const Ladder = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;
`;
Ladder.defaultProps = {
  borderColor: 'borderColorDivider',
  borderTop: 'solid 1px',
  borderBottom: 'solid 1px',
};

export const LadderStepSettings = styled(Ladder)`
  padding: ${({ theme }) => `0 ${theme.spacingMedium}px`};
  overflow-y: auto;
`;

export const Footer = styled.div`
  display: flex;
  line-height: 1;
  padding: ${({ theme }) => `${theme.spacingDefault}px`};
`;

const label = css`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  color: ${({ theme }) => theme.colorTextDefault};
`;

export const BidLabel = styled(Box)`
  ${label};
  color: ${({ theme }) => theme.colorTextPositive};
`;
BidLabel.defaultProps = {
  textAlign: 'left',
  flex: '1',
};

export const SectionLabel = styled.div`
  ${label};
  color: ${({ theme }) => theme.colorTextImportant};
  text-align: center;
  margin: 0 auto;
`;

export const OfferLabel = styled(Box)`
  ${label}
  color: ${({ theme }) => theme.colorTextNegative};
`;
OfferLabel.defaultProps = {
  textAlign: 'right',
  flex: '1',
};

export const LeftLabel = styled(Box)`
  ${label};
  padding-right: ${({ theme }) => `${theme.spacingDefault}px`};
`;
LeftLabel.defaultProps = {
  w: '25%',
  flex: '1 1 25%',
  textAlign: 'left',
};

export const SpreadLabel = styled.div`
  ${label};
  width: 14%;
  text-align: center;
`;

export const RightLabel = styled(Box)`
  ${label};
  padding-left: ${({ theme }) => `${theme.spacingDefault}px`};
`;
RightLabel.defaultProps = {
  w: '25%',
  flex: '1 1 25%',
  textAlign: 'right',
};

export const PriceValue = styled.div`
  width: 25%;
  flex: 1 1 25%;
  justify-content: flex-end;
  padding: ${({ theme }) => `${theme.spacingSmall}px`};
  display: flex;
  align-items: center;
`;

export const SizeValue = styled.div`
  width: 15%;
  flex: 0 1 15%;
  padding: ${({ theme }) => `${theme.spacingSmall}px`};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SpreadValue = styled.div`
  white-space: nowrap;
  width: 14%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingDefault}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colorTextVolumeLadderSpread};
`;

export const Values = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `0 ${theme.spacingSmall}px`};

  & > ${SizeValue}:nth-child(1),
  & > ${PriceValue}:nth-child(2) {
    justify-content: flex-start;
  }
`;

export const TruncatedBpsSpread = styled.div``;

export const DisplayBps = styled.div`
  position: relative;
  > ${NumberWrapper} {
    position: absolute;
    left: -20px;
    right: -20px;
    top: 0;
    bottom: 0;
  }
  &:not(:hover) {
    > ${NumberWrapper} {
      display: none;
    }
  }
`;

export const RowWrapper = styled.div<{ width?: number }>`
  flex: 1 0 0;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: background-color 200ms ease;

  ${({ theme }) => `
    cursor: pointer;

    &:hover {
      color: ${theme.colorTextAttention};
      background: ${theme.backgroundContentHover};

      ${Highlight} {
        color: ${theme.colorTextAttention};
      }

      ${DisplayBps} {
        color: ${theme.colorTextVolumeLadderSpread};
        ${Highlight} {
          color: ${theme.colorTextVolumeLadderSpread};
        }
        > ${TruncatedBpsSpread} {
          visibility: hidden;
        }
        > span {
          display: block;
        }
      }
    }`}
`;

export const SpreadRowWrapper = styled(RowWrapper)`
  border-top: solid 1px ${({ theme }) => theme.borderColorDivider};
  border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
  justify-content: space-between;
  color: ${({ theme }) => theme.colorTextSubtle};
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  min-height: 32px;
`;

export const TopPrice = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingMedium}px`};
`;

export const Spread = styled.div`
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;

  > * {
    padding: 0 ${({ theme }) => theme.spacingSmall}px;
  }
`;
const MAX_ROWS = 18;
const MIN_OPACITY = 0.1;
export const SpreadBars = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  > div {
    width: 50%;
    display: flex;
  }
`;
export const BidSpreadBar = styled.div.attrs<{ width?: string; index: number }>(({ width }) => ({
  style: {
    transform: `scaleX(${width}%) scaleY(1.2)`,
  },
}))<{ width?: string; index: number }>`
  width: 100%;
  transform-origin: right center;
  transition: transform 200ms ease;
  height: 100%;
  background: ${({ theme, index }) => {
    return isColor(theme.backgroundVolumeLadderBidSpread)
      ? transparentize(
          theme.backgroundVolumeLadderTransparencyDirection === Direction.Asc
            ? index / MAX_ROWS
            : 1 - MIN_OPACITY - index / MAX_ROWS,
          theme.backgroundVolumeLadderBidSpread
        )
      : theme.backgroundVolumeLadderBidSpread;
  }};
  margin-left: auto;
`;

export const OfferSpreadBar = styled.div.attrs<{ width?: string; index: number }>(({ width }) => ({
  style: {
    transform: `scaleX(${width}%) scaleY(1.2)`,
  },
}))<{ width?: string; index: number }>`
  width: 100%;
  transform-origin: left center;
  transition: transform 200ms ease;
  height: 100%;
  background: ${({ theme, index }) =>
    isColor(theme.backgroundVolumeLadderOfferSpread)
      ? transparentize(
          theme.backgroundVolumeLadderTransparencyDirection === Direction.Asc
            ? index / MAX_ROWS
            : 1 - MIN_OPACITY - index / MAX_ROWS,
          theme.backgroundVolumeLadderOfferSpread
        )
      : theme.backgroundVolumeLadderOfferSpread};
`;

export const VolumeHeaderRow = styled(Box)`
  border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
  display: flex;
  z-index: 2;
`;
VolumeHeaderRow.defaultProps = {
  p: 'spacingMedium',
  h: `${HEADER_HEIGHT}rem`,
  justifyContent: 'space-between',
};

export const MarketViewWrapper = styled(Box)`
  position: relative;
  flex: 1;
  max-height: 200px;
  min-height: 100px;
`;

export const TopOfBookWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  align-items: center;
  position: relative;
`;

export const PriceButton = styled(Box).attrs({ as: 'button' })`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  background-color: transparent;
  border: none;
  appearance: none;
  z-index: 1;

  &:hover {
    background: ${({ theme }) =>
      isColor(theme.backgroundContentHover)
        ? transparentize(0.5, theme.backgroundContentHover)
        : theme.backgroundContentHover};
  }
`;
PriceButton.defaultProps = {
  p: 'spacingMedium',
};

export const SpreadDisplay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  z-index: 1;
`;

export const SparklineWrapper = styled(Box)`
  position: absolute;

  line {
    transition: stroke-opacity 200ms ease;
  }
  text {
    transition: opacity 200ms ease;
    fill: ${({ theme }) => theme.colorTextSubtle};
    opacity: 0;
  }

  &:hover {
    line,
    text {
      opacity: 1;
    }
  }
`;
SparklineWrapper.defaultProps = {
  left: 0,
  right: 0,
  top: 32,
  bottom: 0,
};

export const SettingsWrapper = styled(animated(Box))`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) =>
    isColor(theme.backgroundContent) ? transparentize(0.25, theme.backgroundContent) : theme.backgroundContent};
  transition: background 5000ms ease;
  z-index: 2;
  backdrop-filter: blur(5px);
`;
SettingsWrapper.defaultProps = {
  top: `${HEADER_HEIGHT}rem`,
};

export const SettingsContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const SparklineSettings = styled(Box)`
  max-height: 200px;
  min-height: 100px;
  border-bottom: solid 8px transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
SparklineSettings.defaultProps = {
  py: 'spacingMedium',
  px: 'spacingMedium',
  flex: '1',
};

export const LadderSettings = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${Ladder} {
    border: none;
  }
`;

export const BucketLabel = styled.div`
  ${label};
  text-align: center;
`;

export const Bucket = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: ${({ theme }) => theme.spacingDefault}px;
`;

export const SettingsHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: 100%;
  }
`;
SettingsHeader.defaultProps = {
  h: '3.75rem',
  pt: 'spacingMedium',
  px: 'spacingDefault',
  pb: 'spacingDefault',
};

export const SettingsFooter = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacingMedium}px;

  button {
    width: 100%;
  }
`;
SettingsFooter.defaultProps = {
  py: 'spacingMedium',
  px: 'spacingMedium',
  h: '4rem',
};
