import {
  getCurrencyColor,
  useCurrenciesContext,
  useObservableValue,
  useSecuritiesContext,
  wsScanToMap,
} from '@talos/kyoko';

import { ChartMessagingProvider } from 'containers/Portfolio/providers/ChartMessagingProvider';
import { useMemo } from 'react';
import { map } from 'rxjs';
import styled from 'styled-components';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import { InnerStackedChart, type StackedChartDataItem } from './InnerStackedChart';
import { getWsIntervalOnChangedTag } from './getWsIntervalOnChangedTag';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacingDefault}px;
  background: ${({ theme }) => theme.backgroundContent};
`;

// workaround until we can confirm if highcharts options can be data-only updated properly
const wsIntervalOnChangedTag = getWsIntervalOnChangedTag(2500);

export function LongShortStackedChart() {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { overviewChartDisplayType } = usePortfolioViewStateSelector();
  const { riskSubAccountObs, isDataLoading } = usePortfolioManagementProvider();

  const positionData = useObservableValue(
    () =>
      riskSubAccountObs.pipe(
        wsIntervalOnChangedTag,
        wsScanToMap({
          getUniqueKey: ({ rowID }) => rowID,
          newMapEachUpdate: false,
        }),
        map(m => [...m.values()])
      ),
    [riskSubAccountObs]
  );

  const dataToChart = useMemo(() => {
    const assetsToDisplay = (positionData ?? []).filter(item => !item.skipAggregation);
    type DirectionalAsset = `${'+' | '-'}${string}`;
    const aggregatedAssets = assetsToDisplay.reduce(
      (result, next) => {
        const groupingAsset = overviewChartDisplayType === 'byUnderlier' ? next.ExposureGroupAsset : next.Asset;
        const position = next.Equivalent.Position ? parseFloat(next.Equivalent.Position) : 0;
        const colorField = next.ExposureGroupAsset;

        const direction = position > 0 ? '+' : '-';
        const key: DirectionalAsset = `${direction}${groupingAsset}`;
        const current = result.get(key);
        result.set(key, {
          direction,
          asset: groupingAsset,
          position: current ? current.position + position : position,
          colorField,
        });
        return result;
      },
      new Map<
        DirectionalAsset,
        {
          direction: '+' | '-';
          asset: string;
          position: number;
          colorField: string;
        }
      >()
    );

    const longShortAssets: StackedChartDataItem[] = [...aggregatedAssets.values()].map(p => {
      const currency = currenciesBySymbol.get(p.asset);
      const security = securitiesBySymbol.get(p.asset);
      const asset = security?.DisplaySymbol ?? currency?.Symbol ?? p.asset;
      const assetDescription = security?.Description ?? currency?.Description ?? '';
      const color = getCurrencyColor(p.colorField ?? p.asset);

      return {
        asset,
        assetDescription,
        value: p.position,
        color: color,
      };
    });
    return longShortAssets;
  }, [currenciesBySymbol, overviewChartDisplayType, positionData, securitiesBySymbol]);

  return (
    <Wrapper>
      <ChartMessagingProvider>
        <InnerStackedChart isDataLoading={isDataLoading} data={dataToChart} />
      </ChartMessagingProvider>
    </Wrapper>
  );
}
