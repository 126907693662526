import { createContext, useContext, type Dispatch, type SetStateAction } from 'react';

export interface BlotterSettingContextValue {
  alwaysShowColumnDividers: boolean;
  setAlwaysShowColumnDividers: Dispatch<SetStateAction<boolean>>;
}

export const DefaultBlotterSettingsConfig: BlotterSettingContextValue = {
  alwaysShowColumnDividers: false,
  setAlwaysShowColumnDividers: () => {},
};

const BlotterSettingsContext = createContext<BlotterSettingContextValue | undefined>(undefined);
BlotterSettingsContext.displayName = 'BlotterSettingsContext';

export function useBlotterSettings() {
  const context = useContext(BlotterSettingsContext);
  if (context === undefined) {
    throw new Error('Missing BlotterSettingsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export function BlotterSettingsProvider({
  children,
  value = DefaultBlotterSettingsConfig,
}: {
  children: React.ReactNode;
  value?: BlotterSettingContextValue;
}) {
  return <BlotterSettingsContext.Provider value={value}>{children}</BlotterSettingsContext.Provider>;
}
