import {
  scanToNestedMap,
  useObservable,
  wsSubscriptionCache,
  type MinimalSubscriptionResponse,
  type NestedMap,
} from '@talos/kyoko';
import { createContext, useContext, useMemo } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { getBalanceKey, type Balance } from '../../../../types';
import { KEYS_BY_ASSET, KEYS_BY_COUNTERPARTY } from '../TreasuryManagementReducer';
import { useBalancesSub } from '../hooks/useBalancesSub';
import { useHistoricalPositionsSub } from '../hooks/useHistoricalPositionsSub';
import { TreasuryBalance, type MergedBalance } from '../types';
import { createMergedBalance } from '../utils';
import { useTreasuryManagementContext } from './TreasuryManagementStateAndTabsProvider';

export const TreasuryManagementBalancesContext = createContext<TreasuryManagementBalancesContextProps | undefined>(
  undefined
);

export type TreasuryManagementBalancesContextProps = {
  balancesDeltaObs: Observable<MinimalSubscriptionResponse<Balance>>;
  balancesByCounterpartyObs: Observable<NestedMap<MergedBalance>>;
  balancesByAssetObs: Observable<NestedMap<MergedBalance>>;
};

export function useTreasuryManagementBalances() {
  const context = useContext(TreasuryManagementBalancesContext);
  if (context === undefined) {
    throw new Error(
      'Missing TreasuryManagementBalancesContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

export const TreasuryManagementBalancesProvider = function TreasuryManagementBalancesProvider({ children }) {
  const { homeCurrency } = useDisplaySettings();

  const {
    state: { snapshotDate },
  } = useTreasuryManagementContext();

  const balancesSub = useBalancesSub();
  const historicalPositionsSub = useHistoricalPositionsSub({ tag: 'TreasuryManagementBalancesProvider' });

  // We also cache each subscription independently
  const cachedBalancesSub = useMemo(() => {
    return balancesSub.pipe(wsSubscriptionCache(getBalanceKey));
  }, [balancesSub]);
  const cachedHistoricalPositionsSub = useMemo(() => {
    return historicalPositionsSub.pipe(wsSubscriptionCache(getBalanceKey));
  }, [historicalPositionsSub]);

  // Choose which observable to subscribe to for updates using the set snapshotDate parameter
  const balancesDeltaObs = snapshotDate == null ? cachedBalancesSub : cachedHistoricalPositionsSub;

  const mergedBalancesDeltaObs = useObservable(
    () =>
      balancesDeltaObs.pipe(
        map(enrichedJson => {
          // Apply some additional treasury-mgmt specific constraints here on the incoming data stream
          // Converting from Balance instances to TreasuryBalances where Equivalent must be defined
          const filteredData: TreasuryBalance[] = [];
          for (const balance of enrichedJson.data) {
            if (balance.Equivalent != null) {
              const filteredBalance = new TreasuryBalance(balance, balance.Equivalent, homeCurrency);
              filteredData.push(filteredBalance);
            }
          }
          const newJson = { ...enrichedJson, data: filteredData };
          return newJson as MinimalSubscriptionResponse<TreasuryBalance>;
        }),
        // Create merged balances which can be funneled into nested maps below
        map(json => ({ ...json, data: json.data.map(createMergedBalance) }))
      ),
    [balancesDeltaObs, homeCurrency]
  );

  const balancesByCounterpartyObs = useObservable(
    () =>
      mergedBalancesDeltaObs.pipe(
        scanToNestedMap(KEYS_BY_COUNTERPARTY),
        shareReplay({ bufferSize: 1, refCount: false })
      ), // keep hot and emit one replay msg
    [mergedBalancesDeltaObs]
  );

  const balancesByAssetObs = useObservable(
    () => mergedBalancesDeltaObs.pipe(scanToNestedMap(KEYS_BY_ASSET), shareReplay({ bufferSize: 1, refCount: false })), // keep hot and emit one replay msg
    [mergedBalancesDeltaObs]
  );

  const value = useMemo(() => {
    return {
      balancesDeltaObs,
      balancesByAssetObs,
      balancesByCounterpartyObs,
    };
  }, [balancesDeltaObs, balancesByAssetObs, balancesByCounterpartyObs]);

  return (
    <TreasuryManagementBalancesContext.Provider value={value}>{children}</TreasuryManagementBalancesContext.Provider>
  );
};
