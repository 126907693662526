import type { ICellRendererParams } from 'ag-grid-enterprise';
import { IconButton } from '../Button';
import type { AgGridIconButtonProps } from './types';

export function AgGridIconButton({
  hide,
  onClick,
  icon,
  node,
  api,
  columnApi,
  disabled,
  color,
  title,
  data,
}: ICellRendererParams & AgGridIconButtonProps) {
  if (!data || node.rowPinned) {
    return null;
  }

  if (hide?.({ node, api, columnApi })) {
    return null;
  }

  return (
    <IconButton
      ghost={true}
      icon={icon}
      disabled={disabled}
      // Adding an onClick directly to this Button triggers the onRowSelectionChanged event of the AgGrid
      ref={ref => {
        if (ref != null) {
          ref.onclick = e => {
            e.stopPropagation();
            onClick && onClick({ node, api, columnApi });
          };
        }
      }}
      color={color}
      title={title}
      data-testid="ag-grid-icon-button"
    />
  );
}
