import {
  EMPTY_OBJECT,
  FilterClauseType,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useCurrenciesFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type FilterClause,
  type FilterableProperty,
  type IHedgePositionStatus,
  type Leaves,
} from '@talos/kyoko';
import { isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useIDFilter } from '../../../hooks';
import { useRollupsFilter } from '../../../hooks/filters/useRollupsFilter';
import { useSubAccounts } from '../../../providers';
import type { IHedgePositionStatusRow } from '../../../providers/HedgePositionStatusProvider';
import type { HedgePositionStatusFilter } from './types';

export const useHedgePositionStatusFilter = ({
  initialFilter = EMPTY_OBJECT,
  saveFilter,
}: {
  initialFilter?: HedgePositionStatusFilter;
  saveFilter: (newFilter: any) => void;
}) => {
  const [filter, setFilter] = useState(initialFilter);

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const { subAccountsByID } = useSubAccounts();

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<IHedgePositionStatus>>(
    row => {
      const data = row.data;

      if (filterExistsAndExcludes(filter, 'HedgeControlStatus', data, 'HedgeControlStatus')) {
        return false;
      }

      if (filter.AccountID && data) {
        const subAccountName = subAccountsByID.get(data.AccountID)?.Name;
        if (
          subAccountName &&
          filterExistsAndExcludes(filter, 'AccountID', { AccountID: subAccountName }, 'AccountID')
        ) {
          return false;
        }
      }
      if (
        filterExistsAndExcludes(filter, 'Asset', data, 'Asset') &&
        filterExistsAndExcludes(filter, 'Asset', data, 'TargetAsset')
      ) {
        return false;
      }

      if (filterExistsAndExcludes(filter, 'HedgeRuleID', data, 'HedgeRuleID')) {
        return false;
      }

      return true;
    },
    [filter, subAccountsByID]
  );

  const currencyFilter = useCurrenciesFilter();
  const rollupsFilter = useRollupsFilter();
  const idFilter = useIDFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () => [
      { ...currencyFilter, key: 'Asset', label: 'Asset' },
      { ...rollupsFilter, key: 'AccountID', label: 'Position Rollup' },
      { ...idFilter, key: 'HedgeRuleID', label: 'Hedge Rule ID' },
    ],
    [currencyFilter, rollupsFilter, idFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      keys(filter).forEach(key => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key],
        });
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters({
          ...curr,
          // Ensure that the HedgeControlStatus is always a 1d array.
          // This is to clear out potential configs that have it not be that way
          // If this comment is more than a release old, we can remove this flattening.
          HedgeControlStatus: initialFilter.HedgeControlStatus
            ? [initialFilter.HedgeControlStatus].flat(Infinity)
            : undefined,
          HedgeRuleID: filterClausesByPropertyKey.get('HedgeRuleID')?.selections,
          AccountID: filterClausesByPropertyKey.get('AccountID')?.selections,
          Asset: filterClausesByPropertyKey.get('Asset')?.selections,
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter, initialFilter.HedgeControlStatus]
  );

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    changeFilter,
    clientSideFilter,
    handleFilterClausesChanged,
  };
};

export const POSITION_AUTO_HEDGE_SEARCH_KEYS: (keyof IHedgePositionStatusRow)[] = [
  'AccountID',
  'Asset',
  'HedgeControlStatus',
  'HedgeSymbol',
  'TargetAsset',
  'HedgeOrderID',
];
export const POSITION_HEDGE_ORDER_SEARCH_KEYS: Leaves<Pick<IHedgePositionStatusRow, 'Order'>>[] = [
  'Order.Side',
  'Order.Symbol',
  'Order.Currency',
  'Order.Strategy',
];
