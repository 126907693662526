import {
  ACTION,
  API_PERMISSION_WILDCARD,
  Flex,
  FormRowStatus,
  FormTable,
  NotificationVariants,
  ROLE,
  Text,
  UpdateActionEnum,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  type Column,
  type UseFormTable,
  type User,
} from '@talos/kyoko';
import { useFeatureFlag, useGetMarketAccountsDisplayNameBySubAccount, useRoleAuth } from 'hooks';
import { cloneDeep, compact, get } from 'lodash';
import { useSubAccountPermissionFilters, useSubAccounts, type UserSubAccountPermissionRow } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { prettifyRole } from 'tokens/roles';
import { v4 as uuid } from 'uuid';
import { getApiPermissionActionFromUser, useApiPermissionActionColumn } from '../../../../utils/columns';
import type { IEditUserDrawerTab } from '../types';
import { SubAccountsDropdown } from './SubAccountsDropdown';

const ALL_SUB_ACCOUNTS_LABEL = 'All Sub Accounts';

interface EditUserSubAccountsProps {
  formTable: UseFormTable<UserSubAccountPermissionRow>;
  onAddSubAccount: (newSubAccounts: string[]) => void;
  setSubAccountSelections: React.Dispatch<React.SetStateAction<string[]>>;
  subAccountSelections: string[];
}

export function EditUserSubAccounts({
  formTable,
  onAddSubAccount,
  setSubAccountSelections,
  subAccountSelections,
}: EditUserSubAccountsProps) {
  return (
    <Flex flexDirection="column" gap="spacingMedium" w="100%" h="100%">
      <Flex flexDirection="column" gap="spacingMedium">
        <Text>Selected Sub Accounts</Text>
        <SubAccountsDropdown
          onAddSubAccount={onAddSubAccount}
          setSubAccountsToAdd={setSubAccountSelections}
          subAccountsToAdd={subAccountSelections}
          includeRollups={false}
        />
      </Flex>
      <FormTable promptIfUnsavedChanges={false} background="transparent" {...formTable} />
    </Flex>
  );
}

export function useEditUserSubAccounts({ user }: { user: User }): IEditUserDrawerTab {
  const { add: addToast } = useGlobalToasts();
  const { subAccountsByName } = useSubAccounts();
  const { isAuthorized } = useRoleAuth();
  const canEditFilterPermissions = isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS);

  const getMarketAccountDisplayNames = useGetMarketAccountsDisplayNameBySubAccount();
  const userNotTrader = !user.Roles?.includes(ROLE.TRADER);

  const permissionActionColumn = useApiPermissionActionColumn(
    'User',
    userNotTrader ? `Only users with the "${prettifyRole(ROLE.TRADER)}" role can trade into sub accounts` : undefined
  );
  const getLabel = useCallback(
    (subAccountName: string) =>
      subAccountName === API_PERMISSION_WILDCARD
        ? ALL_SUB_ACCOUNTS_LABEL
        : subAccountsByName?.get(subAccountName)?.DisplayName || subAccountName,
    [subAccountsByName]
  );
  const columns = useMemo<Column[]>(
    () =>
      compact([
        {
          title: 'Sub Account Name',
          id: 'subAccounts',
          sort: '+',
          minWidth: 140,
          width: 140,
          suppressMenu: true,
          sortIndex: 0,
          type: 'custom',
          params: {
            valueGetter: ({ data }) => get(data, 'FilterPermission.Filter.SubAccount'),
            valueFormatter: ({ value }) => getLabel(value),
          },
        },
        {
          title: 'Market Accounts',
          id: 'marketAccounts',
          minWidth: 400,
          width: 400,
          suppressMenu: true,
          type: 'custom',
          params: {
            valueGetter: ({ data }) => {
              const subAccountName = get(data, 'FilterPermission.Filter.SubAccount');
              return getMarketAccountDisplayNames(subAccountName).join(', ');
            },
          },
        },
        permissionActionColumn,
        canEditFilterPermissions
          ? { type: 'remove', frozen: true, id: 'remove', pinned: 'right', title: '', suppressMenu: true }
          : null,
      ]),
    [permissionActionColumn, canEditFilterPermissions, getLabel, getMarketAccountDisplayNames]
  );

  const { filterIDsByUserName, subAccountPermissionFiltersByFilterID, updateSubAccountPermissionFilters } =
    useSubAccountPermissionFilters();

  const userSubAccountsPermissionRows: UserSubAccountPermissionRow[] = useMemo(
    () =>
      compact(
        Array.from(filterIDsByUserName?.get(user.Name) || []).map(filterID => {
          const FilterPermission = cloneDeep(subAccountPermissionFiltersByFilterID?.get(filterID));
          if (FilterPermission) {
            return { FilterPermission, User: user };
          }
          return null;
        })
      ),
    [filterIDsByUserName, subAccountPermissionFiltersByFilterID, user]
  );

  const formTable = useFormTable<UserSubAccountPermissionRow>({
    rowID: 'FilterPermission.FilterID',
    data: userSubAccountsPermissionRows,
    columns,
  });

  const resetTable = useDynamicCallback(formTable.reset);

  useEffect(() => {
    if (!formTable.isDirty) {
      resetTable(userSubAccountsPermissionRows);
    }
  }, [formTable.isDirty, resetTable, userSubAccountsPermissionRows]);

  const [subAccountSelections, setSubAccountSelections] = useState<string[]>([]);

  const allChecked = useMemo(() => subAccountSelections.includes(API_PERMISSION_WILDCARD), [subAccountSelections]);

  const onAddSubAccount = useCallback(
    (newSubAccounts: string[]) => {
      const rows = formTable.getRows();
      const subAccountsInRowsMap = new Map(
        rows.map(row => [row.data.FilterPermission.Filter.SubAccount, row.data.FilterPermission.FilterID])
      );

      // Is there a subaccount: '*' permission in the list?
      const existsImplicitEntry = !!formTable
        .getRows()
        .find(d => d.data.FilterPermission.Filter.SubAccount === API_PERMISSION_WILDCARD);

      for (const subAccount of newSubAccounts) {
        const rowId = subAccountsInRowsMap.get(subAccount);

        // If current addition is '*', and there is an implicityEntry in list already, then
        // it is an duplicate (i.e. two '*' would be in the list)
        const isImplicitDuplicate = subAccount === API_PERMISSION_WILDCARD && existsImplicitEntry;

        if (rowId || (isImplicitDuplicate && rowId)) {
          // Already existing entry
          const row = formTable.gridApi?.getRowNode(rowId);
          // Instead of adding a duplicate, highlight the already existing row and toast
          if (row) {
            formTable.gridApi?.flashCells({ rowNodes: [row] });
            addToast({
              text: `There is already an existing row for SubAccount: "${getLabel(
                subAccount
              )}" in the table. Update the existing one or remove it.`,
              variant: NotificationVariants.Primary,
            });
          }
        } else {
          // new entry
          formTable.addRow({
            FilterPermission: {
              Action: getApiPermissionActionFromUser(user),
              Subject: { User: user.Name, SubAccount: undefined },
              FilterID: uuid(),
              Filter: { SubAccount: allChecked ? API_PERMISSION_WILDCARD : subAccount, MarketAccount: undefined },
              UpdateAction: UpdateActionEnum.Initial,
            },
            User: user,
          });
        }
      }

      setSubAccountSelections([]);
    },
    [addToast, allChecked, formTable, getLabel, user]
  );

  const handleSaveChanges = useDynamicCallback((reason?: string) => {
    if (!formTable.isDirty) {
      return;
    }

    const rows = formTable.getRows();
    const updates = compact(
      rows.map(row => {
        if (row.status === FormRowStatus.Added) {
          return { ...row.data.FilterPermission, UpdateAction: UpdateActionEnum.Initial };
        }
        if (row.status === FormRowStatus.Removed) {
          return { ...row.data.FilterPermission, UpdateAction: UpdateActionEnum.Remove };
        }
        if (row.status === FormRowStatus.Updated) {
          return { ...row.data.FilterPermission, UpdateAction: UpdateActionEnum.Update };
        }
        return null;
      })
    );

    return updateSubAccountPermissionFilters(updates, reason)
      .catch((e: Error) => {
        addToast({
          text: `Failed to update Sub Account Permissions: ${e.message}`,
          variant: NotificationVariants.Negative,
        });
      })
      .finally(() => resetTable());
  });

  const component = useMemo(() => {
    return (
      <EditUserSubAccounts
        formTable={formTable}
        onAddSubAccount={onAddSubAccount}
        setSubAccountSelections={setSubAccountSelections}
        subAccountSelections={subAccountSelections}
      />
    );
  }, [formTable, onAddSubAccount, setSubAccountSelections, subAccountSelections]);

  const { enableAccountSegregation, enableAccountSegregationConfiguration } = useFeatureFlag();
  const canViewAccountSegregation =
    ((enableAccountSegregation || enableAccountSegregationConfiguration) &&
      isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS)) ||
    isAuthorized(ACTION.VIEW_FILTER_PERMISSIONS_FEATURE_DISABLED);

  return {
    name: 'Sub Accounts',
    component,
    viewable: canViewAccountSegregation,
    badgeContent: userSubAccountsPermissionRows.length.toString(),
    isDirty: formTable.isDirty,
    save: handleSaveChanges,
    renderReasonInput: true,
  };
}
