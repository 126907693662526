import {
  AgGridCurrency,
  AgGridSecurity,
  assetIsSecurity,
  getAssetDisplaySymbol,
  stringColumnComparator,
  useAssetsContext,
  useCurrenciesContext,
  useDynamicCallback,
  useSecuritiesContext,
  type AgGridSearchSelectDropdownProps,
  type Asset,
  type Column,
} from '@talos/kyoko';
import type { ColDef, ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

type AssetAutocompleteItem = {
  label: string;
  value: Asset | undefined;
  description?: string;
};

interface SymbolOrCurrencyType {
  Symbol?: string;
  Currency?: string;
}

interface UseSymbolOrCurrencyColumnParams<T extends SymbolOrCurrencyType> {
  comparator?: ColDef<T>['comparator'];
}

/**
 * Defines a column for managing data entries which have _either_ a Symbol or Currency property.
 * These are regarded to be an "Asset", which in frontend world is just typed as Currency | Security.
 */
export const useSymbolOrCurrencyColumn = <T extends SymbolOrCurrencyType>({
  comparator,
}: UseSymbolOrCurrencyColumnParams<T>): Column => {
  const { assetsList } = useAssetsContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { currenciesBySymbol } = useCurrenciesContext();

  const autocompleteItems = useMemo(() => {
    const items: AssetAutocompleteItem[] = assetsList.map(asset => ({
      value: asset,
      label: getAssetDisplaySymbol(asset),
      description: asset.Description,
    }));

    items.unshift({
      label: '*',
      value: undefined,
      description: 'All assets',
    });

    return items;
  }, [assetsList]);

  const cellEditorParams = useDynamicCallback((params: ICellEditorParams) => {
    return {
      ...params,
      useSearchSelectParams: {
        items: autocompleteItems,
        getLabel: (s: AssetAutocompleteItem) => s.label,
        getDescription: (s: AssetAutocompleteItem) => s.description ?? '',
      },
      searchPlaceholder: 'Symbol or currency',
    } satisfies AgGridSearchSelectDropdownProps<AssetAutocompleteItem>;
  });

  const valueGetter = useDynamicCallback(({ data }: { data: T }) => {
    const security = data.Symbol;
    const currency = data.Currency;
    if (security != null) {
      return securitiesBySymbol.get(security);
    } else if (currency != null) {
      return currenciesBySymbol.get(currency);
    }

    return undefined;
  });

  const valueSetter = useDynamicCallback(({ newValue, data }: { newValue: AssetAutocompleteItem; data: any }) => {
    delete data.Symbol;
    delete data.Currency;

    if (newValue == null || newValue.value == null) {
      return;
    }

    if (assetIsSecurity(newValue.value)) {
      data.Symbol = newValue.value.Symbol;
    } else {
      data.Currency = newValue.value?.Symbol;
    }
  });

  const valueFormatter = useDynamicCallback((params: { value?: Asset }) => {
    if (!params.value) {
      return '*';
    }
    return getAssetDisplaySymbol(params.value);
  });

  const cellRenderer = useDynamicCallback((params: ICellRendererParams<T>) => {
    return params.data?.Symbol ? (
      <AgGridSecurity {...params} value={params.data.Symbol} showDescription />
    ) : params.data?.Currency ? (
      <AgGridCurrency {...params} value={params.data.Currency} showDescription />
    ) : (
      <>*</>
    );
  });

  return useMemo(
    () => ({
      type: 'custom',
      id: 'asset',
      sortable: true,
      sort: '+',
      params: {
        comparator: comparator ?? defaultSymbolComparator,
        headerName: 'Asset',
        headerTooltip:
          'Enforce the limit for a specific symbol or currency, or leave empty (*) to enforce the limit for all assets.',
        colId: 'asset',
        field: 'asset',
        width: 175,
        editable: true,
        suppressKeyboardEvent: () => true,
        cellEditor: 'searchSelectDropdown',
        cellEditorPopup: true,
        cellEditorParams,
        valueSetter,
        valueGetter,
        valueFormatter,
        cellRenderer,
      },
    }),
    [cellEditorParams, cellRenderer, valueFormatter, valueGetter, valueSetter, comparator]
  );
};

function defaultSymbolComparator(valueA: Asset | undefined, valueB: Asset | undefined) {
  return stringColumnComparator(valueA?.Symbol, valueB?.Symbol);
}
