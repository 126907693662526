import {
  AllocationValueTypeEnum,
  Dialog,
  Patch,
  logger,
  prepareAllocationsForRequest,
  useDynamicCallback,
  useUserContext,
  type Allocation,
  type DialogProps,
  type Order,
  type Trade,
  type UseDisclosureReturn,
} from '@talos/kyoko';
import { useState } from 'react';
import { FormError } from '../../components/Form';
import { OMSSubAccounts, type OMSSubAccountsProps } from '../../components/OMS/OMSSubAccounts';
import { useModifyDialogValidation } from '../../hooks/useModifyDialogValidation';
import { useSubAccounts } from '../../providers';
import { useTradingSettings } from '../../providers/TradingSettingsContext';
import { CancelDialogWrapper } from './styles';

type ModifyMultipleEntitySubAccountsDialogProps = DialogProps &
  UseDisclosureReturn &
  (
    | {
        selectedEntities: Order[];
        entityType: 'order';
      }
    | {
        selectedEntities: Trade[];
        entityType: 'trade';
      }
  );

interface MultipleSubAccountDialogForm {
  allocationValueType: AllocationValueTypeEnum;
  subAccountAllocations: Allocation[];
}

const DEFAULT_FORM = {
  subAccountAllocations: [{ subAccount: '', value: '100' }],
  allocationValueType: AllocationValueTypeEnum.Percentage,
};

/**
 * This dialog allows you to modify the sub accounts of multiple orders or trades
 * There is a large overlap in how this form should work so for now they just share on dialog
 *
 * In the future if we find the needs of the two entities start divering, we should just create two dialogs
 * out of this one again
 */
export const ModifyMultipleEntitySubAccountsDialog = ({
  selectedEntities,
  entityType,
  ...props
}: ModifyMultipleEntitySubAccountsDialogProps) => {
  const [modifySubAccountError, setModifySubAccountError] = useState<string | undefined>();
  const [touched, setTouched] = useState(false);
  const { useTradeAllocations } = useTradingSettings();
  const { orgApiEndpoint } = useUserContext();
  const { tradableSubAccounts } = useSubAccounts();
  const [form, setForm] = useState<MultipleSubAccountDialogForm>(DEFAULT_FORM);
  const { open, close } = props;

  const handleAllocationUpdate: OMSSubAccountsProps['onUpdate'] = useDynamicCallback(changes => {
    setForm(prev => ({ ...prev, ...changes }));
  });

  const handleConfirm = useDynamicCallback(() => {
    if (!orgApiEndpoint) {
      return;
    }

    form?.allocationValueType == null &&
      logger.error(new Error(`allocationValueType is null while preparing allocations`));
    form?.subAccountAllocations == null &&
      logger.error(new Error(`subAccountAllocations is null while preparing allocations`));

    const preparedAllocations = prepareAllocationsForRequest({
      subAccountAllocations: form.subAccountAllocations,
      subAccounts: tradableSubAccounts,
      allocationValueType: form.allocationValueType,
      quantity: undefined, // for a multi-entity sub account modification, we only use percentages
    });

    const route = entityType === 'order' ? `orders-all?OrderIDs=` : 'trades-all?TradeIDs=';
    const ids =
      entityType === 'order'
        ? selectedEntities.map(order => order.OrderID)
        : selectedEntities.map(trade => trade.TradeID);

    Patch(orgApiEndpoint, `/${route}${ids.join(',')}`, {
      Allocation: preparedAllocations,
    })
      .then(() => {
        close();
        setForm(DEFAULT_FORM);
        setModifySubAccountError(undefined);
        setTouched(false);
      })
      .catch((e: ErrorEvent) => {
        setModifySubAccountError(e?.message || 'Something went wrong.');
        open();
      });
  });

  const { errors } = useModifyDialogValidation({
    orderOrTrade: form,
    mode: entityType,
  });

  return (
    <Dialog
      {...props}
      onConfirm={handleConfirm}
      onConfirmMouseOver={() => setTouched(true)}
      confirmLabel="Update"
      cancelLabel="Cancel"
      confirmDisabled={errors?.subAccountAllocations != null}
      width={300}
    >
      {form && (
        <CancelDialogWrapper>
          <h3 style={{ margin: 0, textAlign: 'left' }}>
            <b>
              {entityType === 'order' ? 'Orders' : 'Trades'} ({selectedEntities.length})
            </b>
          </h3>

          {/* Sub Accounts */}
          <OMSSubAccounts
            subAccountAllocations={form.subAccountAllocations}
            onUpdate={handleAllocationUpdate}
            allocationValueType={form.allocationValueType}
            useAllocations={useTradeAllocations}
            errors={errors}
            touched={{ subAccountAllocations: touched }}
            hideTypeToggleButtons
            updateDefaultSubAccountOnChange={false}
          />
          {modifySubAccountError && (
            <FormError style={{ justifyContent: 'flex-end' }}>{modifySubAccountError}</FormError>
          )}
        </CancelDialogWrapper>
      )}
    </Dialog>
  );
};
