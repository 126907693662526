import { useCombobox } from 'downshift';
import { useEffect } from 'react';
import type { UseAutocompleteOutput } from '../Autocomplete/types';
import { getLabelWrapper, useAutocomplete } from '../Autocomplete/useAutocomplete';
import type { UseSearchSelectProps } from './types';

/**
 * A wrapper of useAutocomplete which select-ifies the behavior.
 */
export function useSearchSelect<T>(props: UseSearchSelectProps<T>): UseAutocompleteOutput<T> {
  const {
    getLabel,
    inputRef,
    selection,
    onChange,
    closeDropdownOnItemSelection = true,
    clearInputOnSelection = false,
    enableTabSelect = true,
    scrollSelectionIntoViewIndex,
  } = props;

  const { onIsOpenChange, ...restOfProps } = props;

  const autocomplete = useAutocomplete({
    stateReducer: (state, action) => {
      switch (action.type) {
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...action.changes,
            // For backward compatibility with earlier combobox behavior,
            // keep the highlighted index and open state when the input is clicked
            highlightedIndex: state.highlightedIndex,
            isOpen: state.isOpen,
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        case useCombobox.stateChangeTypes.ItemClick: {
          const isProgrammaticChange = action.type === useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem;

          return {
            ...action.changes,
            // If programmatic changes, keep state, else close if we are configured to close on item selection
            isOpen: isProgrammaticChange ? state.isOpen : closeDropdownOnItemSelection ? false : true,
            highlightedIndex: closeDropdownOnItemSelection ? action.changes.highlightedIndex : state.highlightedIndex, // if we're not closing the dropdown, leave the highlighted index as is!
            inputValue: clearInputOnSelection ? '' : action.changes.inputValue ?? state.inputValue, // if action inputvalue is undefined, go with the state's inputValue
          };
        }
        case useCombobox.stateChangeTypes.FunctionOpenMenu: {
          const nextState = {
            ...action.changes,
            inputValue: '',
          };
          if (scrollSelectionIntoViewIndex) {
            nextState.highlightedIndex = state.selectedItem
              ? scrollSelectionIntoViewIndex(props.items, state.selectedItem)
              : 0;
          }
          return nextState;
        }
        case useCombobox.stateChangeTypes.InputChange: {
          return {
            ...action.changes,
            highlightedIndex: 0, // reset the highlightedIndex every time the input value changes
          };
        }
        case useCombobox.stateChangeTypes.MenuMouseLeave: {
          return {
            ...action.changes,
            highlightedIndex: state.highlightedIndex, // Do not scroll to top if mouse leaves input
          };
        }
        case useCombobox.stateChangeTypes.FunctionSetInputValue: {
          // This action seems to be called when downshift tries to programmatically set the inputValue to be the label of the selectedItem.
          // In our searchselects, we never want to prime the input field value to be that of the selected item.
          return state;
        }
        default:
          return action.changes;
      }
    },
    selectedItem: selection ?? null, // this component is strictly controlled, we do not allow selectedItem to be undefined.
    itemToString: item => (item ? `${getLabelWrapper(getLabel, item)}` : ''),
    onSelectedItemChange: ({ selectedItem, highlightedIndex, type }) => {
      const haveSelectedItem = selectedItem != null;
      const selectedItemIsDisabled = haveSelectedItem && props.isItemDisabled?.(selectedItem);
      const shouldReturn = !haveSelectedItem || selectedItemIsDisabled;
      if (shouldReturn) {
        return;
      }
      // When we press tab downshift automatically triggers onSelectItemChange, the only caveat is that highlightedIndex is -1
      // So we simply need to ignore the highlightedIndex for this specific case (not early return) and proceed on selecting the item
      if (type !== useCombobox.stateChangeTypes.ItemClick) {
        // Lets only do this when we are 100% sure the change was not caused by a click
        // https://talostrading.atlassian.net/browse/DEAL-4207 - outlines a bug where if the
        // first item in a list is disabled, and user clicks any item in list, it would not register.
        const shouldIgnoreTabSelect = !enableTabSelect && highlightedIndex === -1;
        const isTabSelectInvalid =
          enableTabSelect && highlightedIndex === -1 && type !== useCombobox.stateChangeTypes.InputBlur;
        if (shouldIgnoreTabSelect || isTabSelectInvalid) {
          return;
        }
      }

      onChange(selectedItem);
      if (closeDropdownOnItemSelection) {
        // Also blur the input on item selection to push the tab index forward to next form element
        inputRef.current?.blur();
      }
    },
    onIsOpenChange: changes => {
      // Whenever isopen changes, if we're changing to opened, we always clear the input field and focus it.
      if (changes.isOpen) {
        setInputValue('');
        setTimeout(() => inputRef.current?.focus(), 0);
      }
      onIsOpenChange?.(changes);
    },
    ...restOfProps,
  });

  const { setInputValue } = autocomplete;

  useEffect(() => {
    if (props.selection == null) {
      setInputValue('');
    }
  }, [props.selection, setInputValue]);

  return autocomplete;
}
