import { Box, Flex, HStack, Text, type RequiredProperties } from '@talos/kyoko';
import { arc, pie, scaleLinear } from 'd3';
import { useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';
import { portfolioAbbreviation } from '../../../portfolioAbbreviation';
import type { DrillKey } from '../../types';
import { stringifyKey } from '../../utils';
import { D3ChartCenter } from './D3ChartCenter';
import { D3ChartG, D3ChartPath } from './styles';
import type { ChartDataPoint, ChartPortion, ChartSlice, D3ChartProps } from './types';

const DEFAULT_SLICE_THICKNESS = 42;
const HOVER_ADDITION = 8;
const SLICE_STROKE_WIDTH = '2px';
const OUTER_PIE_THICKNESS = 12;
const POLYLINE_LABEL_ARC_EXTENSION_FACTOR = 1.12;
const POLYLINE_LABEL_LINE_EXTENSION = 8;
const LABEL_MIN_WIDTH = 70;
const MAX_RADIUS = 280;
const MIN_RADIUS = 80;
const MINIMAL_PIE_SHARE_TO_SHOW_LABEL = 0.035;
const Y_LABEL_PADDING = 32;
const POLYLINE_ORIGIN_DOT_RADIUS = 4;

const directionMinShare = Math.PI / 6;
const counterClockwiseMaxEndAngle = directionMinShare;
const counterClockwiseMinEndAngle = 2 * Math.PI - directionMinShare;

export const D3Chart = <T,>({
  size,
  renderData,
  drilled,
  onSliceClick,
  onBackClick,
  highlightedSlice,
  onSliceHighlighted,
  highlightedSlicePart,
  showOuterRingOnSliceHighlighted = true,
  maxRadius,
}: D3ChartProps<T>) => {
  const { colors, colorDataBlue } = useTheme();

  const svgRef = useRef(null);
  const width = size.clientWidth || 300;
  const height = size.clientHeight || 300;

  const halfWidth = width / 2;
  const halfHeight = height / 2;

  const widthLeftAfterAccountingForLabels = halfWidth * (1 / POLYLINE_LABEL_ARC_EXTENSION_FACTOR) - LABEL_MIN_WIDTH;
  const heightLeftAfterAccountForLabels = halfHeight * (1 / POLYLINE_LABEL_ARC_EXTENSION_FACTOR) - Y_LABEL_PADDING;

  const spaceLeftAfterAccountingForLabels = Math.min(
    widthLeftAfterAccountingForLabels,
    heightLeftAfterAccountForLabels
  );

  const maxRadiusToUse = maxRadius ? maxRadius : MAX_RADIUS;
  const radius = Math.max(MIN_RADIUS, Math.min(spaceLeftAfterAccountingForLabels, maxRadiusToUse));
  const scale = radius / MAX_RADIUS;

  // For the sizing of the chart we use the width we're given by our container be the source of truth
  // The biggest problem as it comes to sizing the svg is the labels. As we know they extend either to the left or right -- not up or down.
  // So remove the label extension (2x because left + right extension), and then add a little bit of padding

  const wantedHeight = 2 * radius * POLYLINE_LABEL_ARC_EXTENSION_FACTOR + 2 * Y_LABEL_PADDING;

  const midPointX = width / 2;
  const midPointY = wantedHeight / 2;

  const sliceThickness = DEFAULT_SLICE_THICKNESS * scale;
  const defaultInnerRadius = radius - sliceThickness;

  const highlightedInfo = useMemo(() => {
    if (!renderData || !highlightedSlice) {
      return undefined;
    }

    const clockwiseHighlightedDataPoint = renderData.clockwise.data.find(d => d.key === highlightedSlice);
    const counterClockwiseHighlightedDataPoint = renderData.counterClockwise.data.find(d => d.key === highlightedSlice);

    const highlightedSum =
      (clockwiseHighlightedDataPoint?.value || 0) + (counterClockwiseHighlightedDataPoint?.value || 0);

    // highlighted can be either clockwise or c-clockwise, get displayName || key from whichever one it is
    const datapoint = clockwiseHighlightedDataPoint ?? counterClockwiseHighlightedDataPoint!;

    const highlightedDisplayNames: string[] = datapoint.drillToDisplayNames ?? [
      datapoint.displayName ?? JSON.stringify(datapoint.key),
    ];

    return {
      sum: highlightedSum,
      key: highlightedSlice,
      centerLabels: highlightedDisplayNames,
    };
  }, [renderData, highlightedSlice]);

  if (!renderData) {
    return null;
  }

  const portionPie = pie<ChartPortion<T>>()
    .value(d => Math.abs(d.netSum))
    .sort(null)([renderData.clockwise, renderData.counterClockwise]);

  const [clockwiseSpec, counterClockwiseSpec] = portionPie;

  if (renderData.counterClockwise.netSum !== 0 && renderData.clockwise.netSum !== 0) {
    // we have some counter clockwise data to show
    // lets always give the reds at least 25% of the chart

    counterClockwiseSpec.endAngle = counterClockwiseSpec.startAngle;
    // Counter clockwise always starts at one full "lap", then grows "backwards" around the clock
    counterClockwiseSpec.startAngle = 2 * Math.PI;
    // Counter clockwise must be between our defined range
    counterClockwiseSpec.endAngle = Math.max(
      Math.min(counterClockwiseSpec.endAngle, counterClockwiseMinEndAngle),
      counterClockwiseMaxEndAngle
    );
    // The two opposing sides should of course meet at the same place
    clockwiseSpec.endAngle = counterClockwiseSpec.endAngle;
  }

  const clockwiseColor = colorDataBlue;
  const counterClockwiseColor = colors.red.lighten;
  const opacityRange = [1, 0.4];

  const clockwisePieGen = pie<ChartSlice<T>>()
    .value(d => d.renderValue)
    .sort((a, b) => b.value - a.value)
    .startAngle(clockwiseSpec.startAngle)
    .endAngle(clockwiseSpec.endAngle);
  const clockwiseInstructions = clockwisePieGen(renderData.clockwise.data);
  const clockwiseOpacities = scaleLinear([0, renderData.clockwise.data.length - 1], opacityRange);

  const counterClockwisePieGen = pie<ChartSlice<T>>()
    .value(d => d.renderValue)
    .sort((a, b) => a.value - b.value)
    .startAngle(counterClockwiseSpec.startAngle)
    .endAngle(counterClockwiseSpec.endAngle);
  const counterClockwiseInstructions = counterClockwisePieGen(renderData.counterClockwise.data);
  const counterClockwiseOpacities = scaleLinear([0, renderData.counterClockwise.data.length - 1], opacityRange);

  return (
    <svg ref={svgRef} width="100%" height={wantedHeight} overflow="visible">
      <g transform={`translate(${midPointX} ${midPointY})`} width="100%" height="100%">
        {clockwiseInstructions?.map((instruction, i) => {
          const key = renderData.clockwise.data[i].key;
          const stroke = renderData.clockwise.data[i].stroke;
          const opacity = clockwiseOpacities(instruction.index);
          return (
            <Slice
              instruction={instruction}
              data={renderData.clockwise.data[i]}
              color={clockwiseColor}
              opacity={opacity}
              stroke={stroke}
              radius={radius}
              key={key as string}
              onClick={() => instruction.data.drillTo && onSliceClick?.(instruction.data.drillTo)}
              onMouseEnter={() => onSliceHighlighted(key)}
              onMouseLeave={() => onSliceHighlighted(undefined)}
              highlightedSliceKey={highlightedSlice}
              highlightedSlicePartKey={highlightedSlicePart}
              drillable={!renderData.metadata.drilledAllTheWay}
              showOuterRingOnSliceHighlighted={showOuterRingOnSliceHighlighted}
              sliceThickness={sliceThickness}
              totalWidth={width}
              totalHeight={height}
              scale={scale}
            />
          );
        })}
        {counterClockwiseInstructions?.map((instruction, i) => {
          const key = renderData.counterClockwise.data[i].key;
          const stroke = renderData.counterClockwise.data[i].stroke;
          const opacity = counterClockwiseOpacities(instruction.index);
          return (
            <Slice
              data={renderData.counterClockwise.data[i]}
              instruction={instruction}
              color={counterClockwiseColor}
              opacity={opacity}
              radius={radius}
              stroke={stroke}
              key={key as string}
              onClick={() => instruction.data.drillTo && onSliceClick?.(instruction.data.drillTo)}
              onMouseEnter={() => onSliceHighlighted(key)}
              onMouseLeave={() => onSliceHighlighted(undefined)}
              highlightedSliceKey={highlightedSlice}
              highlightedSlicePartKey={highlightedSlicePart}
              drillable={!renderData.metadata.drilledAllTheWay}
              showOuterRingOnSliceHighlighted={showOuterRingOnSliceHighlighted}
              sliceThickness={sliceThickness}
              totalWidth={width}
              totalHeight={height}
              scale={scale}
            />
          );
        })}
      </g>
      <foreignObject
        height={defaultInnerRadius * 2}
        width={defaultInnerRadius * 2}
        x={(width - 2 * defaultInnerRadius) / 2}
        y={(wantedHeight - 2 * defaultInnerRadius) / 2}
        pointerEvents="none"
        overflow="visible"
      >
        <D3ChartCenter
          renderData={renderData}
          highlightedInfo={highlightedInfo}
          showArrowBack={drilled && !highlightedSlice && !highlightedSlicePart}
          onBackClick={onBackClick}
          onSliceClick={onSliceClick}
          scale={scale}
          highlightedSlice={highlightedSlice}
          highlightedSlicePart={highlightedSlicePart}
        />
      </foreignObject>
    </svg>
  );
};

interface SliceProps<T> {
  instruction: d3.PieArcDatum<ChartSlice<T>>;
  data: ChartSlice<T>;
  color: string;
  opacity: number;
  radius: number;
  stroke?: string;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  highlightedSliceKey: DrillKey<T> | undefined;
  highlightedSlicePartKey: DrillKey<T> | undefined;
  drillable: boolean;
  showOuterRingOnSliceHighlighted?: boolean;
  sliceThickness: number;
  totalWidth: number;
  totalHeight: number;
  scale: number;
}

const Slice = <T,>({
  instruction,
  radius,
  color,
  opacity,
  stroke,
  data,
  onClick,
  onMouseEnter,
  onMouseLeave,
  highlightedSliceKey,
  highlightedSlicePartKey,
  drillable,
  showOuterRingOnSliceHighlighted = true,
  sliceThickness,
  totalWidth,
  totalHeight,
  scale,
}: SliceProps<T>) => {
  const { colors } = useTheme();
  const sliceStroke = colors.gray.main;
  const { startAngle, endAngle } = instruction;

  const hoverAddition = HOVER_ADDITION * scale;
  const outerPieThickness = OUTER_PIE_THICKNESS * scale;

  const partWithinHighlighted =
    highlightedSlicePartKey && data.highlighteableParts && data.highlighteableParts.map.has(highlightedSlicePartKey);
  const sliceHighlighted = highlightedSliceKey === data.key;
  const highlighted = partWithinHighlighted || sliceHighlighted;

  const someoneElseHighlighted =
    (highlightedSliceKey != null || highlightedSlicePartKey != null) &&
    highlightedSliceKey !== data.key &&
    !partWithinHighlighted;

  const sliceInnerRadius = sliceHighlighted ? radius - sliceThickness - hoverAddition : radius - sliceThickness;
  const sliceOuterRadius = sliceHighlighted && !data.highlighteableParts ? radius + outerPieThickness / 2 : radius;

  const arcGenerator = arc()
    .startAngle(startAngle)
    .endAngle(endAngle)
    .innerRadius(sliceInnerRadius)
    .outerRadius(sliceOuterRadius);

  // build the pie and arcGenerator for the outer little pie
  const outerPie = pie<ChartDataPoint<T>>()
    .padAngle(0.005)
    .startAngle(startAngle)
    .endAngle(endAngle)
    .value(d => d.absoluteValue);
  const outerPieInstructions = data.highlighteableParts && outerPie(data.highlighteableParts.array);

  const outerArcGenerator = arc<ChartDataPoint<T>>()
    .innerRadius(radius + 1)
    .outerRadius(radius + outerPieThickness);

  const shouldRenderLabel = Math.abs(endAngle - startAngle) / (2 * Math.PI) > MINIMAL_PIE_SHARE_TO_SHOW_LABEL;

  const highlightedPartData = partWithinHighlighted
    ? data.highlighteableParts!.map.get(highlightedSlicePartKey)
    : undefined;

  const showOuterPie =
    ((sliceHighlighted && showOuterRingOnSliceHighlighted) || partWithinHighlighted) && outerPieInstructions;

  const labelOpacity = someoneElseHighlighted ? 0.3 : 1;
  const pathOpacity = highlighted ? 1 : someoneElseHighlighted ? 0.2 : opacity;

  return (
    <g>
      <D3ChartPath
        data-testid="d3-chart-slice-path"
        //@ts-expect-error key narrowing
        key={data.key}
        //@ts-expect-error d3 typings
        d={arcGenerator(instruction) || undefined}
        cursor={drillable ? 'pointer' : 'cursor'}
        onClick={drillable ? onClick : undefined}
        stroke={stroke || sliceStroke}
        strokeWidth={SLICE_STROKE_WIDTH}
        fill={data.color?.fill || color}
        opacity={pathOpacity}
        onMouseMove={!sliceHighlighted ? onMouseEnter : () => {}}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />

      {showOuterPie &&
        outerPieInstructions.map((instruction, i) => {
          const partData = data.highlighteableParts!.array![i];
          const partHighlighted = partData.key === highlightedSlicePartKey;
          const color = sliceHighlighted || partHighlighted ? partData.color?.fill : '#ffffff10';
          return (
            <path
              //@ts-expect-error d3 typings
              d={outerArcGenerator(instruction) || undefined}
              //@ts-expect-error key narrowing
              key={partData.key}
              fill={color}
              // stroke={color}
            />
          );
        })}
      <D3ChartG opacity={labelOpacity}>
        {shouldRenderLabel &&
          (data.icon ? (
            <IconLabel
              startAngle={startAngle}
              endAngle={endAngle}
              radius={radius}
              data={data as RequiredProperties<ChartSlice<T>, 'icon'>}
              isSomePartHighlighted={highlightedSlicePartKey != null}
              highlightedPartData={highlightedPartData}
              scale={scale}
            />
          ) : (
            <PolylineLabel
              arcGenerator={arcGenerator}
              startAngle={startAngle}
              endAngle={endAngle}
              radius={radius}
              data={data}
              isSomePartHighlighted={highlightedSlicePartKey != null}
              highlightedPartData={highlightedPartData}
              totalWidth={totalWidth}
              totalHeight={totalHeight}
              scale={scale}
            />
          ))}
      </D3ChartG>
    </g>
  );
};

interface PolylineLabelProps<T> {
  arcGenerator: d3.Arc<any, d3.DefaultArcObject>;
  startAngle: number;
  endAngle: number;
  radius: number;
  data: ChartSlice<T>;
  highlightedPartData?: ChartDataPoint<T>;
  isSomePartHighlighted: boolean;
  totalWidth: number;
  totalHeight: number;
  scale: number;
}

const LABEL_SIZE = 0.9;
const MIN_LABEL_SCALE = 0.7;

const PolylineLabel = <T,>({
  arcGenerator,
  startAngle,
  endAngle,
  radius,
  data,
  highlightedPartData,
  isSomePartHighlighted,
  totalWidth,
  totalHeight,
  scale,
}: PolylineLabelProps<T>) => {
  const { colorTextImportant, colorTextSubtle, colors } = useTheme();

  const labelArc = arc()
    .startAngle(startAngle)
    .endAngle(endAngle)
    .innerRadius(radius * POLYLINE_LABEL_ARC_EXTENSION_FACTOR)
    .outerRadius(radius * POLYLINE_LABEL_ARC_EXTENSION_FACTOR);

  // Put together the three points that define the path of the <polyline>
  // @ts-expect-error d3 ts
  const arcCenterPoint = arcGenerator.centroid();
  // @ts-expect-error d3 ts
  const labelArcCenterPoint = labelArc.centroid();
  const endPoint = [...labelArcCenterPoint];
  const onRightHalfOfCircle = startAngle + (endAngle - startAngle) / 2 < Math.PI;
  const endPointExtension = POLYLINE_LABEL_LINE_EXTENSION * (onRightHalfOfCircle ? 1 : -1);
  endPoint[0] = endPoint[0] + endPointExtension;
  const polylinePoints = [arcCenterPoint, labelArcCenterPoint, endPoint].map(([x, y]) => `${x} ${y}`).join(' ');

  // text positioning stuff
  const { above } = getTextPositions(endPoint, onRightHalfOfCircle);

  const labelWidth = onRightHalfOfCircle ? totalWidth / 2 - endPoint[0] : Math.abs(totalWidth / 2 + endPoint[0]);
  const wrapLabelText = totalHeight < 400;
  const flexDirection = wrapLabelText ? (onRightHalfOfCircle ? 'row' : 'row-reverse') : 'column';
  const alignItems = onRightHalfOfCircle || flexDirection !== 'column' ? 'flex-start' : 'flex-end';
  const gap = flexDirection === 'column' ? 'spacingTiny' : 'spacingSmall';

  const labelScale = Math.max(MIN_LABEL_SCALE, scale + (1 - scale) / 2); // make the scale go at half the speed for labels, but still end at the same min point
  const fontSize = `${labelScale * LABEL_SIZE}rem`;

  return (
    <>
      <polyline
        points={polylinePoints}
        stroke={colors.gray['080']}
        strokeWidth="1px"
        fill="transparent"
        pointerEvents="none"
      />
      <circle
        r={POLYLINE_ORIGIN_DOT_RADIUS * scale}
        cx={arcCenterPoint[0]}
        cy={arcCenterPoint[1]}
        fill={colors.gray['100']}
        pointerEvents="none"
      />
      <foreignObject
        x={above.x}
        y={above.y}
        style={{ position: 'relative', transform: onRightHalfOfCircle ? undefined : `translateX(-${labelWidth}px)` }}
        width={labelWidth + 'px'}
        height="40px"
        overflow="visible"
      >
        <Flex
          h="100%"
          mx="spacingSmall"
          overflow="hidden"
          alignItems={alignItems}
          justifyContent="flex-start"
          gap={gap}
          fontSize={fontSize}
          flexDirection={flexDirection}
        >
          <Text
            color={colorTextImportant}
            whiteSpace="nowrap"
            style={{ textOverflow: 'ellipsis' }}
            w={flexDirection === 'column' ? '100%' : ''}
            textAlign={onRightHalfOfCircle ? 'left' : 'right'}
            overflow="hidden"
            // @ts-expect-error key narrowing
            title={data.displayName ?? stringifyKey(data.key)}
          >
            {/* @ts-expect-error key narrowing */}
            {data.displayName ?? stringifyKey(data.key)}
          </Text>

          {highlightedPartData ? (
            <Text color={highlightedPartData.color?.textColor}>
              {portfolioAbbreviation(highlightedPartData.value.toString())}
            </Text>
          ) : (
            !isSomePartHighlighted && <Text color={colorTextSubtle}>{data.percentage}</Text>
          )}
        </Flex>
      </foreignObject>
    </>
  );
};

interface IconLabelProps<T> {
  startAngle: number;
  endAngle: number;
  radius: number;
  data: RequiredProperties<ChartSlice<T>, 'icon'>;
  highlightedPartData?: ChartDataPoint<T>;
  isSomePartHighlighted: boolean;
  scale: number;
}

const IconLabel = <T,>({
  startAngle,
  endAngle,
  radius,
  data,
  highlightedPartData,
  isSomePartHighlighted,
  scale,
}: IconLabelProps<T>) => {
  const { spacingDefault } = useTheme();
  const iconArc = arc()
    .startAngle(startAngle)
    .endAngle(endAngle)
    .innerRadius(radius * 1.15)
    .outerRadius(radius * 1.15);

  // @ts-expect-error d3 ts
  const [iconX, iconY] = iconArc.centroid();

  const onLeftHalfOfCircle = startAngle + (endAngle - startAngle) / 2 > Math.PI;

  const labelScale = Math.max(MIN_LABEL_SCALE, scale + (1 - scale) / 2); // make the scale go at half the speed for labels, but still end at the same min point
  const fontSize = `${labelScale * LABEL_SIZE}rem`;
  const iconSize = data.icon.defaultIconSize * labelScale;
  const leftOrRightPos = onLeftHalfOfCircle
    ? { right: spacingDefault * labelScale }
    : { left: spacingDefault * labelScale };

  return (
    <foreignObject
      x={iconX - iconSize / 2}
      y={iconY - iconSize / 2}
      height={iconSize}
      width={iconSize}
      overflow="visible"
    >
      <HStack gap="spacingDefault" position="relative" fontSize={fontSize}>
        {data.icon.renderIcon(iconSize)}
        <Box
          position="absolute"
          style={{
            ...leftOrRightPos,
            transform: `translate(${onLeftHalfOfCircle ? '-' : ''}${iconSize}px)`,
          }}
        >
          <div style={{ height: `${iconSize}px`, display: 'flex', alignItems: 'center' }}>
            {highlightedPartData ? (
              <Text color={highlightedPartData.color?.textColor}>
                {portfolioAbbreviation(highlightedPartData.value.toString())}
              </Text>
            ) : (
              !isSomePartHighlighted && <Text color="colorTextSubtle">{data.percentage}</Text>
            )}
          </div>
        </Box>
      </HStack>
    </foreignObject>
  );
};

function getTextPositions(endpoint, onRightHalf) {
  const x = endpoint[0];
  const y = endpoint[1];

  const above = { x, y: y - 8 };

  return { above };
}
