import type { MarketAccountTypeEnum, MarketTypeEnum } from './types';

// to be replaced
export enum SubAccountReconCheckpointStatusEnum {
  Reconciled = 'Reconciled',
  Unreconciled = 'Unreconciled',
  Discarded = 'Discarded',
}

export interface SubAccountReconCheckpoint {
  ID: string;
  AccountsKey: string;

  StartTime: string;
  EndTime: string;
  LastUpdateTime: string;
  Asset: string;
  Status: SubAccountReconCheckpointStatusEnum;

  Unmatched: {
    Count: number;
    Amount: string;
  };

  SubAccountDetails: {
    Amount: string;
    SubAccounts: {
      SubAccount: string;
      Amount: string;
      Status: SubAccountReconCheckpointStatusEnum;
      Unmatched: {
        Amount: string;
        Count: number;
      };
    }[];
  };

  MarketAccountDetails?: {
    Amount: string;
    MarketAccounts: {
      MarketAccount: string;
      MarketType: MarketTypeEnum;
      MarketAccountType: MarketAccountTypeEnum;
      Status: SubAccountReconCheckpointStatusEnum;

      Amount: string;
      Unmatched: {
        Count: number;
        Amount: string;
      };
    }[];
  };
}
