import { formatDistanceStrict } from 'date-fns';
import { defineMessages } from 'react-intl';
import type { IntlWithFormatter } from '../../contexts/IntlContext';
import { CustomerTransaction } from '../../types/CustomerBalance';
import { CustomerExecutionReport } from '../../types/CustomerExecutionReport';
import { CustomerQuote } from '../../types/CustomerQuote';
import { CustomerTrade } from '../../types/CustomerTrade';
import { ExecutionReport } from '../../types/ExecutionReport';
import { Loan } from '../../types/Loan';
import { LoanQuote } from '../../types/LoanQuote';
import { LoanTransaction } from '../../types/LoanTransaction';
import { Quote } from '../../types/Quote';
import type { User } from '../../types/User';
import {
  ExecTypeEnum,
  LoanQuoteStatusEnum,
  LoanStatusEnum,
  LoanTransactionStatusEnum,
  OrdStatusEnum,
  PricingModeEnum,
  QuoteStatusEnum,
} from '../../types/types';
import { abbreviateId, formattedDateWithMilliseconds } from '../../utils';
import { CustomerTradeSummary } from '../CustomerTradeSummary';
import { CustomerTransactionLabel, CustomerTransactionSummary } from '../CustomerTransactionSummary';
import { ExecutionReportSummary } from '../ExecutionReportSummary';
import { IconName } from '../Icons';
import { LoanQuoteSummary } from '../LoanQuoteSummary';
import { LoanSummary } from '../LoanSummary';
import { LoanTransactionSummary } from '../LoanTransactionSummary';
import { QuotesSummary } from '../QuotesSummary';
import { SmartSummary } from '../SmartSummary';
import {
  getCustomerTransactionIconName,
  getTradeStatusIconName,
  getTradeStatusIconVariant,
  getTransactionHistoryIconVariant,
} from '../Status';
import type { TimelineItemProps, TimelineItemVariant } from '../Timeline';
import type {
  ExecutionTimelineEntry,
  ExecutionTimelineGroupEntry,
  ExecutionTimelineItemEntry,
  WithTimestamp,
} from './types';

const messages = defineMessages({
  closed: {
    defaultMessage: 'Closed',
    id: 'ExecutionTimeline.closed',
  },
  customer: {
    defaultMessage: 'Customer',
    id: 'ExecutionTimeline.customer',
  },
  customerOrderIdCanceled: {
    defaultMessage: 'Customer order {id} canceled.',
    id: 'ExecutionTimeline.customerOrderIdCanceled',
  },
  customerOrderIdModificationRejected: {
    defaultMessage: 'Customer order #{id} modification rejected.',
    id: 'ExecutionTimeline.customerOrderIdModificationRejected',
  },
  customerOrderIdModified: {
    defaultMessage: 'Customer order #{id} modified.',
    id: 'ExecutionTimeline.customerOrderIdModified',
  },
  customerOrderIdPaused: {
    defaultMessage: 'Customer order #{id} paused.',
    id: 'ExecutionTimeline.customerOrderIdPaused',
  },
  customerOrderIdPauseRequested: {
    defaultMessage: 'Customer order #{id} pause requested.',
    id: 'ExecutionTimeline.customerOrderIdPauseRequested',
  },
  customerOrderIdRejected: {
    defaultMessage: 'Customer order #{id} rejected.',
    id: 'ExecutionTimeline.customerOrderIdRejected',
  },
  customerOrderIdResumed: {
    defaultMessage: 'Customer order #{id} resumed.',
    id: 'ExecutionTimeline.customerOrderIdResumed',
  },
  customerOrderIdResumeRequested: {
    defaultMessage: 'Customer order #{id} resume requested.',
    id: 'ExecutionTimeline.customerOrderIdResumeRequested',
  },
  customerOrderIdSubmitted: {
    defaultMessage: 'Customer order #{id} submitted.',
    id: 'ExecutionTimeline.customerOrderIdSubmitted',
  },
  customerQuoteIdReceived: {
    defaultMessage: 'Customer quote #{id} received.',
    id: 'ExecutionTimeline.customerQuoteIdReceived',
  },
  customerQuoteIdRejected: {
    defaultMessage: 'Customer quote #{id} rejected.',
    id: 'ExecutionTimeline.customerQuoteIdRejected',
  },
  customerQuoteIdCanceled: {
    defaultMessage: 'Customer quote #{id} canceled.',
    id: 'ExecutionTimeline.customerQuoteIdCanceled',
  },
  customerQuoteIdPendingFixing: {
    defaultMessage: 'Customer quote #{id} pending fixing.',
    id: 'ExecutionTimeline.customerQuoteIdPendingFixing',
  },
  customerQuoteIdUpdated: {
    defaultMessage: 'Customer quote #{id} updated.',
    id: 'ExecutionTimeline.customerQuoteIdUpdated',
  },
  fixingAppliedOnCustomerRFQId: {
    defaultMessage: 'Fixing applied on Customer RFQ #{id}.',
    id: 'ExecutionTimeline.fixingAppliedOnCustomerRFQId',
  },
  dealer: {
    defaultMessage: 'Dealer',
    id: 'ExecutionTimeline.dealer',
  },
  newCounterOffer: {
    defaultMessage: 'New counter offer.',
    id: 'ExecutionTimeline.newCounterOffer',
  },
  newFirmRequest: {
    defaultMessage: 'New firm request.',
    id: 'ExecutionTimeline.newFirmRequest',
  },
  newLoan: {
    defaultMessage: 'New loan',
    id: 'ExecutionTimeline.newLoan',
  },
  newOffer: {
    defaultMessage: 'New offer.',
    id: 'ExecutionTimeline.newOffer',
  },
  newRequest: {
    defaultMessage: 'New request.',
    id: 'ExecutionTimeline.newRequest',
  },
  newTypeTransaction: {
    defaultMessage: 'New {type, select, Principal {Principal} other {Collateral}} Transaction',
    id: 'ExecutionTimeline.newTypeTransaction',
  },
  orderIdCanceled: {
    defaultMessage: 'Order #{id} canceled.',
    id: 'ExecutionTimeline.orderIdCanceled',
  },
  orderIdModificationRejected: {
    defaultMessage: 'Order #{id} modification rejected.',
    id: 'ExecutionTimeline.orderIdModificationRejected',
  },
  orderIdModified: {
    defaultMessage: 'Order #{id} modified.',
    id: 'ExecutionTimeline.orderIdModified',
  },
  orderIdPaused: {
    defaultMessage: 'Order #{id} paused.',
    id: 'ExecutionTimeline.orderIdPaused',
  },
  orderIdPauseRequested: {
    defaultMessage: 'Order #{id} pause requested.',
    id: 'ExecutionTimeline.orderIdPauseRequested',
  },
  orderIdRejected: {
    defaultMessage: 'Order #{id} rejected.',
    id: 'ExecutionTimeline.orderIdRejected',
  },
  orderIdResumed: {
    defaultMessage: 'Order #{id} resumed.',
    id: 'ExecutionTimeline.orderIdResumed',
  },
  orderIdResumeRequested: {
    defaultMessage: 'Order #{id} resume requested.',
    id: 'ExecutionTimeline.orderIdResumeRequested',
  },
  orderIdSubmitted: {
    defaultMessage: 'Order #{id} submitted.',
    id: 'ExecutionTimeline.orderIdSubmitted',
  },
  pendingTypeTransfer: {
    defaultMessage: 'Pending {type, select, Principal {Principal} other {Collateral}} Transfer',
    id: 'ExecutionTimeline.pendingTypeTransfer',
  },
  processingTypeTransfer: {
    defaultMessage: 'Processing {type, select, Principal {Principal} other {Collateral}} Transfer',
    id: 'ExecutionTimeline.processingTypeTransfer',
  },
  rfqIdSubmitted: {
    defaultMessage: 'RFQ #{id} submitted.',
    id: 'ExecutionTimeline.rfqIdSubmitted',
  },
  rfqIdRejected: {
    defaultMessage: 'RFQ #{id} rejected.',
    id: 'ExecutionTimeline.rfqIdRejected',
  },
  rfqIdCanceled: {
    defaultMessage: 'RFQ #{id} canceled.',
    id: 'ExecutionTimeline.rfqIdCanceled',
  },
  rfqIdPendingFixing: {
    defaultMessage: 'RFQ #{id} pending fixing.',
    id: 'ExecutionTimeline.rfqIdPendingFixing',
  },
  fixingAppliedOnRFQId: {
    defaultMessage: 'Fixing applied on RFQ #{id}.',
    id: 'ExecutionTimeline.fixingAppliedOnRFQId',
  },
  rfqIdUpdated: {
    defaultMessage: 'Quotes updated on RFQ #{id}.',
    id: 'ExecutionTimeline.rfqIdUpdated',
  },
  receivedError: {
    defaultMessage: 'Received error.',
    id: 'ExecutionTimeline.receivedError',
  },
  requestAccepted: {
    defaultMessage: 'Request accepted.',
    id: 'ExecutionTimeline.requestAccepted',
  },
  requestCanceled: {
    defaultMessage: 'Request canceled.',
    id: 'ExecutionTimeline.requestCanceled',
  },
  requestRejected: {
    defaultMessage: 'Request rejected.',
    id: 'ExecutionTimeline.requestRejected',
  },
  sourceStatusCustomerTrade: {
    defaultMessage:
      '{source, select, Market {Market} User {User} Dealer {Dealer} DealerUser {DealerUser} other { }} {status, select, Confirmed {Confirmed} Pending {Pending} other {Canceled}} Customer Trade.',
    id: 'ExecutionTimeline.sourceStatusCustomerTrade',
  },
  termSheetComplete: {
    defaultMessage: 'termSheetComplete',
    id: 'ExecutionTimeline.termSheetComplete',
  },
  termSheetReadyForSignature: {
    defaultMessage: 'termSheetReadyForSignature',
    id: 'ExecutionTimeline.termSheetReadyForSignature',
  },
  termSheetRequested: {
    defaultMessage: 'termSheetRequested',
    id: 'ExecutionTimeline.termSheetRequested',
  },
  tradedOnCustomerOrderId: {
    defaultMessage: 'Traded on customer order #{id}.',
    id: 'ExecutionTimeline.tradedOnCustomerOrderId',
  },
  tradedOnOrderId: {
    defaultMessage: 'Traded on order #{id}.',
    id: 'ExecutionTimeline.tradedOnOrderId',
  },
  typeTransferCompleted: {
    defaultMessage: '{type, select, Principal {Principal} other {Collateral}} Transfer Completed',
    id: 'ExecutionTimeline.typeTransferCompleted',
  },
  typeTransferRejected: {
    defaultMessage: '{type, select, Principal {Principal} other {Collateral}} Transfer Rejected',
    id: 'ExecutionTimeline.typeTransferRejected',
  },
  typeTransferSubmitted: {
    defaultMessage: '{type, select, Principal {Principal} other {Collateral}} Transfer Submitted',
    id: 'ExecutionTimeline.typeTransferSubmitted',
  },
});

export function getCustomerExecutionReportItem(
  executionReport: CustomerExecutionReport,
  intl: IntlWithFormatter
): Partial<TimelineItemProps> | undefined {
  // We don't seem to get execution reports after an order has been canceled, so have to check OrdStatus instead
  if (executionReport.OrdStatus === OrdStatusEnum.Canceled) {
    return {
      label: intl.formatMessage(messages.customerOrderIdCanceled, { id: abbreviateId(executionReport.OrderID) }),
      icon: IconName.MinusCircle,
      variant: 'primary',
      content: executionReport.Text,
    };
  }

  switch (executionReport.ExecType) {
    case ExecTypeEnum.Rejected:
      return {
        label: intl.formatMessage(messages.customerOrderIdRejected, { id: abbreviateId(executionReport.OrderID) }),
        icon: IconName.ExclamationCircle,
        variant: 'warning',
        content: executionReport.Text,
      };
    case ExecTypeEnum.PendingNew:
      return {
        label: intl.formatMessage(messages.customerOrderIdSubmitted, { id: abbreviateId(executionReport.OrderID) }),
        content: <ExecutionReportSummary executionReport={executionReport} />,
      };
    case ExecTypeEnum.PendingPause:
      return {
        label: intl.formatMessage(messages.customerOrderIdPauseRequested, {
          id: abbreviateId(executionReport.OrderID),
        }),
      };
    case ExecTypeEnum.Paused:
      return {
        label: intl.formatMessage(messages.customerOrderIdPaused, { id: abbreviateId(executionReport.OrderID) }),
        icon: IconName.PauseCircle,
      };
    case ExecTypeEnum.PendingResume:
      return {
        label: intl.formatMessage(messages.customerOrderIdResumeRequested, {
          id: abbreviateId(executionReport.OrderID),
        }),
      };
    case ExecTypeEnum.Resumed:
      return {
        label: intl.formatMessage(messages.customerOrderIdResumed, { id: abbreviateId(executionReport.OrderID) }),
      };
    case ExecTypeEnum.Replaced:
      return {
        label: intl.formatMessage(messages.customerOrderIdModified, { id: abbreviateId(executionReport.OrderID) }),
        content: <ExecutionReportSummary executionReport={executionReport} />,
        variant: 'primary',
        icon: IconName.Pencil,
      };
    case ExecTypeEnum.ReplaceRejected:
      return {
        label: intl.formatMessage(messages.customerOrderIdModificationRejected, {
          id: abbreviateId(executionReport.OrderID),
        }),
        icon: IconName.ExclamationCircle,
        variant: 'warning',
        content: executionReport.Text,
      };
    case ExecTypeEnum.Trade:
      switch (executionReport.OrdStatus) {
        case OrdStatusEnum.Filled:
          return {
            label: intl.formatMessage(messages.tradedOnCustomerOrderId, { id: abbreviateId(executionReport.OrderID) }),
            icon: IconName.CheckCircle,
            variant: 'positive',
            content: <SmartSummary entity={executionReport} type="order" qtyField="LastQty" priceField="LastPx" />,
          };
        default: {
          return {
            label: intl.formatMessage(messages.tradedOnCustomerOrderId, { id: abbreviateId(executionReport.OrderID) }),
            content: <SmartSummary entity={executionReport} type="order" qtyField="LastQty" priceField="LastPx" />,
          };
        }
      }
  }
}

export function getExecutionReportItem(
  executionReport: ExecutionReport,
  intl: IntlWithFormatter,
  prevExecutionReport?: ExecutionReport
): Partial<TimelineItemProps> | undefined {
  // We don't seem to get execution reports after an order has been canceled, so have to check OrdStatus instead
  if (executionReport.OrdStatus === OrdStatusEnum.Canceled) {
    return {
      label: intl.formatMessage(messages.orderIdCanceled, { id: abbreviateId(executionReport.OrderID) }),
      icon: IconName.MinusCircle,
      variant: 'primary',
      content: executionReport.Text,
      initiator: executionReport.RequestUser ?? 'System',
    };
  }

  switch (executionReport.ExecType) {
    case ExecTypeEnum.Rejected:
      return {
        label: intl.formatMessage(messages.orderIdRejected, { id: abbreviateId(executionReport.OrderID) }),
        icon: IconName.ExclamationCircle,
        variant: 'warning',
        content: executionReport.Text,
      };
    case ExecTypeEnum.PendingNew:
      return {
        label: intl.formatMessage(messages.orderIdSubmitted, { id: abbreviateId(executionReport.OrderID) }),
        content:
          executionReport.PricingMode === PricingModeEnum.SpreadToFixing ? (
            <SmartSummary
              entity={executionReport}
              type="order"
              qtyField="OrderQty"
              priceField="LastPx"
              showLastMarket={true}
            />
          ) : (
            <ExecutionReportSummary executionReport={executionReport} />
          ),
        initiator: executionReport.RequestUser,
      };
    case ExecTypeEnum.PendingPause:
      return {
        label: intl.formatMessage(messages.orderIdPauseRequested, { id: abbreviateId(executionReport.OrderID) }),
        initiator: executionReport.RequestUser ?? 'System',
      };
    case ExecTypeEnum.Paused:
      return {
        label: intl.formatMessage(messages.orderIdPaused, { id: abbreviateId(executionReport.OrderID) }),
        icon: IconName.PauseCircle,
        variant: 'default',
      };
    case ExecTypeEnum.PendingResume:
      return {
        label: intl.formatMessage(messages.orderIdResumeRequested, { id: abbreviateId(executionReport.OrderID) }),
        initiator: executionReport.RequestUser ?? 'System',
      };
    case ExecTypeEnum.Resumed:
      return {
        label: intl.formatMessage(messages.orderIdResumed, { id: abbreviateId(executionReport.OrderID) }),
      };
    case ExecTypeEnum.Replaced:
      return {
        label: intl.formatMessage(messages.orderIdModified, { id: abbreviateId(executionReport.OrderID) }),
        content: (
          <ExecutionReportSummary
            showDiff={!!prevExecutionReport}
            executionReport={executionReport}
            prevExecutionReport={prevExecutionReport}
          />
        ),
        variant: 'primary',
        icon: IconName.Pencil,
        initiator: executionReport.RequestUser ?? 'System',
      };
    case ExecTypeEnum.ReplaceRejected:
      return {
        label: intl.formatMessage(messages.orderIdModificationRejected, { id: abbreviateId(executionReport.OrderID) }),
        icon: IconName.ExclamationCircle,
        variant: 'warning',
        content: executionReport.Text,
      };
    case ExecTypeEnum.Triggered:
      return {
        label: `Order ${abbreviateId(executionReport.OrderID)} triggered.`,
        icon: IconName.ChartLine,
        variant: 'default',
        content: executionReport.Text,
      };
    case ExecTypeEnum.Trade:
      switch (executionReport.OrdStatus) {
        case OrdStatusEnum.Filled:
          return {
            label: intl.formatMessage(messages.tradedOnOrderId, { id: abbreviateId(executionReport.OrderID) }),
            icon: IconName.CheckCircle,
            variant: 'positive',
            content: (
              <SmartSummary
                entity={executionReport}
                type="order"
                qtyField="LastQty"
                priceField="LastPx"
                showLastMarket={true}
              />
            ),
          };
        default: {
          return {
            label: intl.formatMessage(messages.tradedOnOrderId, { id: abbreviateId(executionReport.OrderID) }),
            content: (
              <SmartSummary
                entity={executionReport}
                type="order"
                qtyField="LastQty"
                priceField="LastPx"
                showLastMarket={true}
              />
            ),
          };
        }
      }
  }
}

export function getQuoteItem(quote: Quote, intl: IntlWithFormatter): Partial<TimelineItemProps> | undefined {
  if (quote.PricingMode === PricingModeEnum.SpreadToFixing) {
    // We only have history for SpreadToFixing type quotes and customer quotes
    switch (quote.QuoteStatus) {
      case QuoteStatusEnum.PendingNew:
        return {
          label: intl.formatMessage(messages.rfqIdSubmitted, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.SubmitTime,
          content: <QuotesSummary quote={quote} />,
        };
      case QuoteStatusEnum.Canceled:
        return {
          label: intl.formatMessage(messages.rfqIdCanceled, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: <QuotesSummary quote={quote} />,
        };
      case QuoteStatusEnum.Rejected:
        return {
          label: intl.formatMessage(messages.rfqIdRejected, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: <QuotesSummary quote={quote} />,
        };
      case QuoteStatusEnum.Filled:
        return {
          label: intl.formatMessage(messages.fixingAppliedOnRFQId, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: (
            <SmartSummary
              entity={quote}
              type="trade"
              qtyField="TradedQty"
              priceField="TradedPx"
              showFixingDetails={true}
            />
          ),
          icon: IconName.CheckCircle,
          variant: 'positive',
        };
      case QuoteStatusEnum.PendingFix:
        return {
          label: intl.formatMessage(messages.rfqIdPendingFixing, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: <QuotesSummary quote={quote} />,
        };
      case QuoteStatusEnum.Open:
        return {
          label: intl.formatMessage(messages.rfqIdUpdated, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: <QuotesSummary quote={quote} />,
        };
      default:
        return undefined;
    }
  }
  return {
    label: intl.formatMessage(messages.rfqIdUpdated, { id: abbreviateId(quote.RFQID) }),
    timestamp: quote.SubmitTime,
    content: <QuotesSummary quote={quote} />,
  };
}

export function getCustomerQuoteItem(
  quote: CustomerQuote,
  intl: IntlWithFormatter
): Partial<TimelineItemProps> | undefined {
  if (quote.PricingMode === PricingModeEnum.SpreadToFixing) {
    switch (quote.QuoteStatus) {
      case QuoteStatusEnum.PendingNew:
        return {
          label: intl.formatMessage(messages.customerQuoteIdReceived, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.SubmitTime,
          content: <QuotesSummary quote={quote} />,
        };
      case QuoteStatusEnum.Canceled:
        return {
          label: intl.formatMessage(messages.customerQuoteIdCanceled, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: <QuotesSummary quote={quote} />,
        };
      case QuoteStatusEnum.Rejected:
        return {
          label: intl.formatMessage(messages.customerQuoteIdRejected, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: <QuotesSummary quote={quote} />,
        };
      case QuoteStatusEnum.Filled:
        return {
          label: intl.formatMessage(messages.fixingAppliedOnCustomerRFQId, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: (
            <SmartSummary
              entity={quote}
              type="trade"
              qtyField="TradedQty"
              priceField="TradedPx"
              showFixingDetails={true}
            />
          ),
          icon: IconName.CheckCircle,
          variant: 'positive',
        };
      case QuoteStatusEnum.PendingFix:
        return {
          label: intl.formatMessage(messages.customerQuoteIdPendingFixing, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: <QuotesSummary quote={quote} />,
        };
      case QuoteStatusEnum.Open:
        return {
          label: intl.formatMessage(messages.customerQuoteIdUpdated, { id: abbreviateId(quote.RFQID) }),
          timestamp: quote.Timestamp,
          content: <QuotesSummary quote={quote} />,
        };
      default:
        return undefined;
    }
  }
  return {
    label: intl.formatMessage(messages.customerQuoteIdReceived, { id: abbreviateId(quote.RFQID) }),
    timestamp: quote.SubmitTime,
    content: <QuotesSummary quote={quote} />,
  };
}

export function getLoanQuoteItem(
  loanQuote: LoanQuote,
  intl: IntlWithFormatter,
  user?: User
): Partial<TimelineItemProps> | undefined {
  let label = '';
  let icon: IconName | undefined = undefined;
  let variant: TimelineItemVariant | undefined = undefined;

  switch (loanQuote.QuoteStatus) {
    case LoanQuoteStatusEnum.PendingNew:
      label = intl.formatMessage(messages.newRequest);
      variant = 'positive';
      break;
    case LoanQuoteStatusEnum.LenderFirm:
      label = intl.formatMessage(messages.newOffer);
      variant = 'primary';
      break;
    case LoanQuoteStatusEnum.BorrowerFirm:
      if (loanQuote.Revision === 0) {
        label = intl.formatMessage(messages.newFirmRequest);
        variant = 'secondary';
      } else {
        label = intl.formatMessage(messages.newCounterOffer);
        variant = 'secondary';
      }
      break;
    case LoanQuoteStatusEnum.Canceled:
      label = intl.formatMessage(messages.requestCanceled);
      break;
    case LoanQuoteStatusEnum.Error:
      label = intl.formatMessage(messages.receivedError);
      icon = IconName.ExclamationCircle;
      variant = 'warning';
      break;
    case LoanQuoteStatusEnum.Rejected:
      label = intl.formatMessage(messages.requestRejected);
      icon = IconName.ExclamationCircle;
      variant = 'warning';
      break;
    case LoanQuoteStatusEnum.Filled:
      label = intl.formatMessage(messages.requestAccepted);
      icon = IconName.CheckCircle;
      variant = 'primary';
      break;
    case LoanQuoteStatusEnum.TermSheetRequested:
      label = intl.formatMessage(messages.termSheetRequested);
      icon = IconName.Circle;
      variant = 'primary';
      break;
    case LoanQuoteStatusEnum.TermSheetSent:
      label = intl.formatMessage(messages.termSheetReadyForSignature);
      icon = IconName.Circle;
      variant = 'primary';
      break;
    case LoanQuoteStatusEnum.TermSheetAllSigned:
      label = intl.formatMessage(messages.termSheetComplete);
      icon = IconName.CheckCircle;
      variant = 'positive';
      break;
  }

  return {
    label,
    icon,
    variant,
    initiator: loanQuote.User,
    timestamp: loanQuote.Timestamp,
    content: <LoanQuoteSummary loanQuote={loanQuote} showVersion={true} />,
  };
}

export function getLoanItem(loan: Loan, intl: IntlWithFormatter, user?: User): Partial<TimelineItemProps> | undefined {
  let label = '';
  let icon: IconName | undefined = undefined;
  let variant: TimelineItemVariant | undefined = undefined;

  switch (loan.Status) {
    case LoanStatusEnum.Open:
      label = intl.formatMessage(messages.newLoan);
      variant = 'positive';
      break;
    case LoanStatusEnum.Error:
      label = intl.formatMessage(messages.receivedError);
      icon = IconName.ExclamationCircle;
      variant = 'warning';
      break;
    case LoanStatusEnum.Closed:
      label = intl.formatMessage(messages.closed);
      icon = IconName.CheckCircle;
      variant = 'default';
      break;
  }

  return {
    label,
    icon,
    variant,
    initiator: loan.RevisionBy,
    timestamp: loan.Timestamp,
    content: <LoanSummary loan={loan} showVersion={true} />,
  };
}

export function getLoanTransactionItem(
  loanTransaction: LoanTransaction,
  intl: IntlWithFormatter,
  user?: User
): Partial<TimelineItemProps> | undefined {
  let label = '';
  let icon: IconName | undefined = undefined;
  let variant: TimelineItemVariant | undefined = undefined;

  switch (loanTransaction.Status) {
    case LoanTransactionStatusEnum.New:
      label = intl.formatMessage(messages.newTypeTransaction, { type: loanTransaction.Type });
      variant = 'positive';
      break;
    case LoanTransactionStatusEnum.TransferPending:
      label = intl.formatMessage(messages.pendingTypeTransfer, { type: loanTransaction.Type });
      icon = IconName.Circle;
      variant = 'primary';
      break;
    case LoanTransactionStatusEnum.TransferProcessing:
      label = intl.formatMessage(messages.processingTypeTransfer, { type: loanTransaction.Type });
      icon = IconName.Circle;
      variant = 'primary';
      break;
    case LoanTransactionStatusEnum.TransferSubmitted:
      label = intl.formatMessage(messages.typeTransferSubmitted, { type: loanTransaction.Type });
      icon = IconName.Circle;
      variant = 'primary';
      break;
    case LoanTransactionStatusEnum.Completed:
      label = intl.formatMessage(messages.typeTransferCompleted, { type: loanTransaction.Type });
      icon = IconName.CheckCircle;
      variant = 'positive';
      break;
    case LoanTransactionStatusEnum.Rejected:
      label = intl.formatMessage(messages.typeTransferRejected, { type: loanTransaction.Type });
      icon = IconName.ExclamationCircle;
      variant = 'warning';
      break;
    case LoanTransactionStatusEnum.Error:
      label = intl.formatMessage(messages.receivedError);
      label = 'Received error';
      icon = IconName.ExclamationCircle;
      variant = 'warning';
      break;
  }

  if (!label) {
    return;
  }

  return {
    label,
    icon,
    variant,
    initiator: loanTransaction.RevisionBy,
    timestamp: loanTransaction.Timestamp,
    content: <LoanTransactionSummary loanTransaction={loanTransaction} showVersion={true} />,
  };
}

export function getCustomerTradeItem(
  customerTrade: CustomerTrade,
  intl: IntlWithFormatter
): Partial<TimelineItemProps> | undefined {
  return {
    label: intl.formatMessage(messages.sourceStatusCustomerTrade, {
      source: customerTrade.TradeSource ?? '',
      status: customerTrade.TradeStatus,
    }),
    timestamp: customerTrade.TransactTime,
    icon: getTradeStatusIconName(customerTrade.TradeStatus),
    variant: getTradeStatusIconVariant(customerTrade.TradeStatus),
    content: <CustomerTradeSummary entity={customerTrade} />,
  };
}

export function getCustomerTransactionItem(
  customerTransaction: CustomerTransaction,
  intl: IntlWithFormatter
): Partial<TimelineItemProps> | undefined {
  return {
    label: <CustomerTransactionLabel entity={customerTransaction} />,
    timestamp: customerTransaction.Timestamp,
    icon: getCustomerTransactionIconName(customerTransaction.Status),
    variant: getTransactionHistoryIconVariant(customerTransaction.Status),
    content: <CustomerTransactionSummary entity={customerTransaction} />,
  };
}

export function getTimelineItemData<T extends { Timestamp: string }>(
  item: T,
  intl: IntlWithFormatter,
  showInitiatorLabels?: boolean,
  user?: User,
  prevItem?: T
): Partial<TimelineItemProps> | undefined {
  let itemData: Partial<TimelineItemProps> | undefined = undefined;
  if (item instanceof CustomerExecutionReport) {
    itemData = getCustomerExecutionReportItem(item, intl);
  } else if (item instanceof ExecutionReport) {
    itemData = getExecutionReportItem(item, intl, prevItem instanceof ExecutionReport ? prevItem : undefined);
  } else if (item instanceof Quote) {
    itemData = getQuoteItem(item, intl);
  } else if (item instanceof CustomerQuote) {
    itemData = getCustomerQuoteItem(item, intl);
  } else if (item instanceof LoanQuote) {
    itemData = getLoanQuoteItem(item, intl, user);
  } else if (item instanceof Loan) {
    itemData = getLoanItem(item, intl, user);
  } else if (item instanceof LoanTransaction) {
    itemData = getLoanTransactionItem(item, intl, user);
  } else if (item instanceof CustomerTrade) {
    itemData = getCustomerTradeItem(item, intl);
  } else if (item instanceof CustomerTransaction) {
    itemData = getCustomerTransactionItem(item, intl);
  }

  if (itemData != null && itemData.timestamp == null) {
    itemData.timestamp = item.Timestamp;
  }

  if (itemData != null && showInitiatorLabels) {
    if (item instanceof CustomerQuote || item instanceof CustomerExecutionReport) {
      itemData.initiator = intl.formatMessage(messages.customer);
    } else if (item instanceof ExecutionReport || item instanceof Quote) {
      itemData.initiator = intl.formatMessage(messages.dealer);
    }
  }

  return itemData == null ? undefined : itemData;
}

export function getTimestampText(firstTimestamp?: string, currentTimestamp?: string): string {
  if (currentTimestamp == null) {
    return '';
  }
  if (firstTimestamp == null) {
    return formattedDateWithMilliseconds(currentTimestamp) ?? '';
  }

  const timestampDiff = formatDistanceStrict(Sugar.Date.create(currentTimestamp), Sugar.Date.create(firstTimestamp));
  const timestamp = formattedDateWithMilliseconds(currentTimestamp);
  return timestampDiff === '0 seconds' ? timestamp ?? '' : `${timestamp} (${timestampDiff})`;
}

export function groupSimilarTimelineItems<T extends WithTimestamp>(
  sortedItems: ExecutionTimelineItemEntry<T>[]
): ExecutionTimelineEntry<T>[] {
  let currentGroup: ExecutionTimelineGroupEntry<T> | null = null;
  const groupedItems: ExecutionTimelineEntry<T>[] = [];

  // For now we'll just group trades
  for (const item of sortedItems) {
    if (
      (item.rawData instanceof ExecutionReport || item.rawData instanceof CustomerExecutionReport) &&
      item.rawData.ExecType === ExecTypeEnum.Trade &&
      item.rawData.OrdStatus !== OrdStatusEnum.Filled
    ) {
      if (currentGroup == null) {
        currentGroup = {
          type: 'group',
          items: [],
        };
      }
      currentGroup.items.push(item);
    } else {
      if (currentGroup != null) {
        groupedItems.push({
          ...currentGroup,
        });
        currentGroup = null;
      }
      groupedItems.push(item);
    }
  }

  if (currentGroup != null) {
    groupedItems.push(currentGroup);
  }

  return groupedItems;
}

/**
 * Sorts timeline items according to the `Timestamp` of the item.
 * Orders dealer events before customer events when timestamps are identical.
 * @param items
 */
export function sortTimelineItems<T extends WithTimestamp>(
  items: ExecutionTimelineItemEntry<T>[],
  reverse?: boolean
): ExecutionTimelineItemEntry<T>[] {
  const sortedItems = [...items].sort((a, b) => {
    if (a.props.timestamp === b.props.timestamp) {
      if (a.rawData instanceof ExecutionReport && b.rawData instanceof CustomerExecutionReport) {
        return -1;
      }
      if (a.rawData instanceof CustomerExecutionReport && b.rawData instanceof ExecutionReport) {
        return 1;
      }
    }
    return a.props.timestamp.localeCompare(b.props.timestamp);
  });
  return reverse ? sortedItems.reverse() : sortedItems;
}

/*
 * Flatten groups that are supposed to be open
 * @param items
 * @param openedGroups
 */
export function flattenOpenedGroups<T extends WithTimestamp>(
  items: ExecutionTimelineEntry<T>[],
  openedGroups: Set<number>
): ExecutionTimelineEntry<T>[] {
  if (openedGroups.size === 0) {
    return items;
  }
  return items.reduce((acc: ExecutionTimelineEntry<T>[], item, index) => {
    if (openedGroups.has(index) && item.type === 'group') {
      acc = acc.concat(item.items);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
}
