import type { CSSProperties } from 'react';
import { Box } from '../Core';
import { LoaderWrapper, Square, Wrapper } from './styles';

export { LoaderWrapper, Overlay } from './styles';

export enum LoaderSizes {
  XXS = 8,
  TINY = 16,
  SMALL = 24,
  MEDIUM = 32,
  DEFAULT = 40,
}

interface LoaderTalosProps {
  size?: LoaderSizes;
  wrapperStyle?: CSSProperties;
  text?: string;
}

/**
 * The talos logo loader
 */
export const LoaderTalos = ({ size = LoaderSizes.DEFAULT, text, wrapperStyle = {} }: LoaderTalosProps) => (
  <LoaderWrapper style={wrapperStyle} data-testid="loader">
    <LoaderTalosIcon text={text} size={size} />
  </LoaderWrapper>
);

export const LoaderTalosIcon = ({ size = LoaderSizes.DEFAULT, text }: { size: LoaderSizes; text?: string }) => (
  <>
    <Wrapper size={size}>
      <Square />
      <Square />
      <Square />

      <Square />
      <Square />
      <Square />

      <Square />
      <Square />
      <Square />
    </Wrapper>
    {text && <Box mt={size / 2}>{text}</Box>}
  </>
);
