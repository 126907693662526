import {
  ACTION,
  Alert,
  AlertVariants,
  Dialog,
  Divider,
  EMPTY_ARRAY,
  FormControlSizes,
  FormGroup,
  Input,
  MixpanelEvent,
  NotificationVariants,
  SearchSelect,
  Toggle,
  Tooltip,
  getDisplayNameOrNameLabel,
  useDynamicCallback,
  useGlobalToasts,
  useMixpanel,
  type DialogProps,
  type ISubaccount,
} from '@talos/kyoko';
import { useState } from 'react';
import { useRoleAuth } from '../../hooks';
import { useSubAccounts } from '../../providers';
import { useSubAccountCloningVerification } from './useSubAccountCloningVerification';

type CreateSubAccountModalProps = DialogProps & {
  /** Set the model sub account right off the bat. To be used if you're priming from a sub account entity for example. */
  initialModelSubAccount?: ISubaccount;
  onSuccessfulCreation: (newSubAcc: ISubaccount) => void;
};

/**
 * The CreateSubAccountModal helps you create Sub Accounts. It allows you to create brand new sub accounts, but also allows the user to create
 * new sub accounts as clones of already existing ones.
 *
 * **Note** If you are using the initialModelSubAccount flow, you have to also set that as the key of the drawer. This so that
 * the component remounts (clearing all its state and starting fresh) on every new primed model sub account.
 */
export const CreateSubAccountModal = ({
  onSuccessfulCreation,
  initialModelSubAccount,
  ...dialogProps
}: CreateSubAccountModalProps) => {
  const mixpanel = useMixpanel();
  const { tradableSubAccounts, cloneSubAccount, createSubAccount } = useSubAccounts();
  const { add: addToast } = useGlobalToasts();
  const { isAuthorized } = useRoleAuth();

  const onlyCloningAllowed = isAuthorized(ACTION.CLONE_SUB_ACCOUNTS) && !isAuthorized(ACTION.EDIT_SUB_ACCOUNTS);

  // CreateByCloning toggle is initially set to true if either thats all the user can do or if there's an initial model account passed.
  const [createByCloning, setCreateByCloning] = useState((onlyCloningAllowed || !!initialModelSubAccount) ?? false);
  const [modelSubAccount, setModelSubAccount] = useState<ISubaccount | undefined>(initialModelSubAccount);

  const [loading, setLoading] = useState(false);
  const [newName, setNewName] = useState(
    initialModelSubAccount ? `${initialModelSubAccount.DisplayName ?? initialModelSubAccount.Name} copy` : ''
  );

  const { close: closeDialog } = dialogProps;

  const handleConfirm = useDynamicCallback(() => {
    createByCloning ? handleClone() : handleCreate();
  });

  const handleClone = useDynamicCallback(async () => {
    mixpanel.track(MixpanelEvent.CloneSubAccount);
    if (!modelSubAccount) {
      return;
    }

    setLoading(true);
    let clone: ISubaccount | undefined;

    // Using async-await syntax to write the code in correct order with the complexity around error handling here
    // The promise is split into two distinct parts because we want to handle the errors differently in the two steps.
    // Step 1 is to perform the cloning
    try {
      clone = (await cloneSubAccount(modelSubAccount.SubaccountID, newName)).SubAccount;
      if (!clone) {
        // shouldnt happen, mostly for typescript to be happy.
        throw new Error();
      }
    } catch (e: any) {
      setLoading(false);
      addToast({
        variant: NotificationVariants.Negative,
        text: `Failed to create Sub Account: ${e.message}`,
      });

      return;
    }

    // Step 2, verify the cloning. Only proceed once verified
    try {
      await verifyCloning(modelSubAccount, clone);
      addToast({
        variant: NotificationVariants.Positive,
        text: 'Successfully created Sub Account.',
      });
    } catch (e: any) {
      addToast({
        variant: NotificationVariants.Warning,
        text: 'Did not receive the necessary updates to guarantee usability of the newly created Sub Account. Try refreshing your page.',
      });
      setLoading(false);
      // The sub account was created, but verification failed. Since the sub account was _created_, we still close the dialog...
      // at this stage, the user might have to do some manual work...
      closeDialog();
      return;
    }

    // All done
    setNewName('');
    setLoading(false);
    onSuccessfulCreation(clone);
    closeDialog();
  });

  const handleCreate = useDynamicCallback(() => {
    setLoading(true);
    createSubAccount(newName)
      .then(newSubAccount => {
        onSuccessfulCreation(newSubAccount);
        addToast({
          variant: NotificationVariants.Positive,
          text: 'Successfully created Sub Account.',
        });
        closeDialog();
        setNewName('');
      })
      .catch((e: ErrorEvent) => {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Failed to create Sub Account: ${e.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const { verifyCloning } = useSubAccountCloningVerification();

  // A name always has to be provided. The model sub account only has to be selected if we're gonna create by cloning.
  const confirmDisabled = newName.length === 0 || (modelSubAccount == null && createByCloning);

  return (
    <Dialog
      {...dialogProps}
      title="Create Sub Account"
      confirmLabel="Create Sub Account"
      width={480}
      confirmDisabled={confirmDisabled}
      onConfirm={handleConfirm}
      closeOnConfirm={false}
      confirmLoading={loading}
    >
      <FormGroup label="Sub Account Name" mb="spacingMedium">
        <Input
          value={newName}
          onChange={e => setNewName(e.target.value)}
          data-testid="sub-account-duplication-name-input"
        />
      </FormGroup>

      <FormGroup inline label="Copy settings from existing sub account" justifyContent="space-between">
        <Tooltip
          tooltip={onlyCloningAllowed ? 'Your user is only able to clone sub accounts which already exist' : undefined}
        >
          <Toggle
            size={FormControlSizes.Small}
            checked={createByCloning}
            onChange={setCreateByCloning}
            disabled={onlyCloningAllowed}
            data-testid="create-by-cloning-toggle"
          />
        </Tooltip>
      </FormGroup>

      {createByCloning && (
        <>
          <Divider orientation="horizontal" mb="spacingMedium" />

          <Alert variant={AlertVariants.Info} dismissable={false} mb="spacingMedium">
            Your new sub account will inherit the settings from the sub account below including associated rollups and
            permissions.
          </Alert>

          <FormGroup label="Model Sub Account" mb="spacingMedium">
            <SearchSelect
              selection={modelSubAccount}
              options={tradableSubAccounts ?? EMPTY_ARRAY}
              onChange={setModelSubAccount}
              getLabel={getDisplayNameOrNameLabel}
              data-testid="model-sub-account-selector"
            />
          </FormGroup>
        </>
      )}
    </Dialog>
  );
};
