import {
  AllocationValueTypeEnum,
  Dialog,
  Patch,
  TradeStatus,
  abbreviateId,
  logger,
  prepareAllocationsForRequest,
  useDynamicCallback,
  useUserContext,
  type Allocation,
  type DialogProps,
  type Trade,
  type TradeStatusEnum,
  type UseDisclosureReturn,
} from '@talos/kyoko';
import { useModifyDialogValidation } from 'hooks';
import { useCallback, useEffect, useState } from 'react';

import { FormError } from 'components/Form';
import { OMSSubAccounts } from 'components/OMS/OMSSubAccounts';

import { useTheme } from 'styled-components';
import { prepareAllocationOrderForUI } from 'utils/order';
import { useSubAccounts } from '../../../providers';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { ModifyMultipleEntitySubAccountsDialog } from '../ModifyMultipleEntitySubAccounts';
import { CancelDialogWrapper, Row } from '../styles';

type BulkModifyTradeSubAccountDialogProps = DialogProps &
  UseDisclosureReturn & {
    selectedTrades?: Trade[];
  };

export const BulkModifyTradeSubAccountDialog = ({ selectedTrades, ...props }: BulkModifyTradeSubAccountDialogProps) => {
  if (!selectedTrades) {
    return null;
  }

  if (selectedTrades.length === 1) {
    return ModifyTradeSubAccountDialog({ selectedTrade: selectedTrades[0], ...props });
  }

  return <ModifyMultipleEntitySubAccountsDialog selectedEntities={selectedTrades} entityType="trade" {...props} />;
};
type ModifyTradeSubAccountDialogProps = DialogProps &
  UseDisclosureReturn & {
    selectedTrade: Trade;
  };

interface ModifyTradeSubAccountForm {
  tradeID: string;
  subAccount?: string;
  tradeStatus?: TradeStatusEnum;
  currency?: string;
  allocationValueType: AllocationValueTypeEnum;
  subAccountAllocations: Allocation[];
  quantity: string;
}

export const ModifyTradeSubAccountDialog = ({ selectedTrade, ...props }: ModifyTradeSubAccountDialogProps) => {
  const [modifySubAccountError, setModifySubAccountError] = useState<string>();
  const { orgApiEndpoint } = useUserContext();
  const theme = useTheme();
  const { useTradeAllocations } = useTradingSettings();
  const { tradableSubAccounts } = useSubAccounts();
  const [form, setForm] = useState<ModifyTradeSubAccountForm | undefined>();

  useEffect(() => {
    // Copy state off selected trade to form
    // If we are set to use allocations but the trade has no alloc type set, default to percentage immediately.
    if (selectedTrade) {
      const tradeForUI = prepareAllocationOrderForUI(selectedTrade);
      if (!tradeForUI.subAccountAllocations || tradeForUI.subAccountAllocations.length === 0) {
        tradeForUI.subAccountAllocations = [{ subAccount: tradeForUI.SubAccount || '', value: '100' }];
      }
      setForm({
        tradeID: tradeForUI.TradeID,
        subAccount: tradeForUI.SubAccount,
        tradeStatus: tradeForUI.TradeStatus,
        currency: tradeForUI.Currency,
        allocationValueType: tradeForUI.allocationValueType || AllocationValueTypeEnum.Percentage,
        subAccountAllocations: tradeForUI.subAccountAllocations || [],
        quantity: tradeForUI.Quantity,
      });
    }
  }, [selectedTrade]);

  const { close, open } = props;

  const { errors } = useModifyDialogValidation({
    orderOrTrade: {
      ...form,
      subAccountAllocations: form?.subAccountAllocations,
      allocationValueType: form?.allocationValueType,
    },
    mode: 'trade',
  });

  const handleOnSubAccountModifyDialogConfirm = useDynamicCallback(() => {
    if (!form) {
      return;
    }

    form.allocationValueType == null &&
      logger.error(new Error(`allocationValueType is null while preparing allocations`));
    form.subAccountAllocations == null &&
      logger.error(new Error(`subAccountAllocations is null while preparing allocations`));

    const preparedTradeAllocations = prepareAllocationsForRequest({
      subAccountAllocations: form.subAccountAllocations,
      subAccounts: tradableSubAccounts,
      allocationValueType: form?.allocationValueType,
      quantity: form.quantity,
    });

    Patch(orgApiEndpoint ?? '', `/trades/${form.tradeID}`, { Allocation: preparedTradeAllocations })
      .then(() => {
        close();
      })
      .catch((e: ErrorEvent) => {
        setModifySubAccountError(e?.message || 'Something went wrong.');
        open();
      });
  });

  const handleUpdate = useCallback(
    (update: Partial<ModifyTradeSubAccountForm>) => {
      if (!form) {
        return;
      }
      setForm({
        ...form,
        ...update,
      });
    },
    [form]
  );

  if (!form) {
    return null;
  }

  return (
    <Dialog
      {...props}
      onConfirm={handleOnSubAccountModifyDialogConfirm}
      confirmLabel="Update"
      cancelLabel="Cancel"
      confirmDisabled={errors?.subAccountAllocations != null}
      width={300}
    >
      <CancelDialogWrapper>
        {selectedTrade && (
          <Row>
            <h3>
              Trade #<b>{abbreviateId(form.tradeID)}</b>
            </h3>
            <TradeStatus theme={theme} tradeStatus={form.tradeStatus} />
          </Row>
        )}

        {/* Sub Accounts */}
        <OMSSubAccounts
          subAccountAllocations={form.subAccountAllocations}
          onUpdate={handleUpdate}
          quantityCurrency={form.currency}
          useAllocations={useTradeAllocations}
          errors={errors}
          touched={{ subAccountAllocations: true }}
          updateDefaultSubAccountOnChange={false}
        />
        {modifySubAccountError && <FormError style={{ justifyContent: 'flex-end' }}>{modifySubAccountError}</FormError>}
      </CancelDialogWrapper>
    </Dialog>
  );
};
