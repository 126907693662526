import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#303052',

    '000': hsla(240, 0.26, 0.04, 1),
    '010': hsla(240, 0.26, 0.11, 1),
    '020': hsla(240, 0.26, 0.14, 1),
    '030': hsla(240, 0.26, 0.16, 1),
    '040': hsla(240, 0.26, 0.2, 1),
    '050': hsla(240, 0.26, 0.24, 1),
    '060': hsla(240, 0.26, 0.28, 1),
    '070': hsla(240, 0.26, 0.36, 1),
    '080': hsla(240, 0.26, 0.57, 1),
    '090': hsla(240, 0.26, 0.75, 1),
    '100': hsla(240, 0.26, 0.85, 1),
  },

  primary: {
    mute: setAlpha(0.1, '#314A8C'),
    dim: setAlpha(0.4, '#314A8C'),
    default: '#314A8C',
    lighten: '#4B64A6',
  },

  green: {
    mute: setAlpha(0.1, '#56AE84'),
    dim: setAlpha(0.4, '#56AE84'),
    default: '#337E5A',
    lighten: '#56AE84',
  },

  red: {
    mute: setAlpha(0.1, '#922E43'),
    dim: setAlpha(0.4, '#922E43'),
    default: '#922E43',
    lighten: '#D23557',
  },

  yellow: {
    mute: setAlpha(0.1, '#CCAC3E'),
    dim: setAlpha(0.4, '#CCAC3E'),
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: setAlpha(0.1, '#458CC1'),
    dim: setAlpha(0.4, '#458CC1'),
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  orange: {
    mute: 'rgba(255, 120, 0, 0.1)',
    dim: 'rgba(255, 120, 0, 0.4)',
    default: '#FF7800',
    lighten: '#FFA04D',
  },
  purple: darkColors.purple,
};

export const DarkKeyrockNewTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkKRNew',

  fontFamily: "'Manrope', sans-serif",
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  spacingLayout: 8,

  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray.main,

  backgroundInput: colors.gray.main,
});
