import type { IndexKeys } from '@talos/kyoko';
import { useDynamicCallback, useMarketAccountsContext, useMarketsContext } from '@talos/kyoko';
import { DEV_NOGROUP_GROUP } from '../tokens';
import type { DrillKey, MergedBalance } from '../types';

/**
 * This hooks consumes all the necessary data sources and exposes an API which lets the developer
 * fetch a display name for any drill key based on the passed in indexing key for the drill key.
 */
export const useDisplayNameHelper = () => {
  const { marketAccountsByID } = useMarketAccountsContext();
  const { marketsByName } = useMarketsContext();

  const getDisplayName = useDynamicCallback((key: DrillKey<MergedBalance>, indexKey: IndexKeys<MergedBalance>) => {
    switch (indexKey) {
      case 'currency':
        return key;
      case 'market':
        return marketsByName.get(key)?.DisplayName ?? key;
      case 'marketAccountID':
        return marketAccountsByID.get(+key)?.DisplayName ?? key;
      case 'group':
        return key === DEV_NOGROUP_GROUP ? undefined : key;
      default:
        return JSON.stringify(key);
    }
  });

  return { getDisplayName };
};
