import type {
  Column,
  ColumnDef,
  Leaves,
  LedgerUpdateTypeEnum,
  Position,
  PositionUpdateSourceEnum,
  ProductTypeEnum,
  RowGroupsOpenedState,
  SubAccountTypeEnum,
  TabProps,
} from '@talos/kyoko';

export interface BasePositionsTabProps extends TabProps {
  tag?: string;
  defaultFilter: PositionsTableFilter;
  defaultColumns: PositionColumn[];
  defaultRowGroupsOpened?: RowGroupsOpenedState;
  showZeroBalances: boolean;
}

export interface SpotPositionsTabProps extends BasePositionsTabProps {
  tabType: 'Spot';
}

export interface DerivativesPositionsTabProps extends BasePositionsTabProps {
  tabType: 'Derivatives';
}

export interface UnifiedPositionsTabProps extends BasePositionsTabProps {
  tabType: 'Unified';
  includeCash?: boolean;
}

export interface SubAccountPositionsTabProps extends BasePositionsTabProps {
  tabType: 'Sub Accounts';
  includeCash?: boolean;
  hierarchical?: boolean;
}

export type PositionsTabProps =
  | SubAccountPositionsTabProps
  | SpotPositionsTabProps
  | DerivativesPositionsTabProps
  | UnifiedPositionsTabProps;

export type PositionsTabType = 'Derivatives' | 'Spot' | 'Sub Accounts' | 'Unified';
export type ExtraPositionColumns = 'underlying' | 'expiry' | 'instrument' | 'SubAccountType';
export type PositionColumn = Leaves<Position> | ExtraPositionColumns | Partial<ColumnDef<Position>>;
export interface PositionsTableFilter {
  Source?: PositionUpdateSourceEnum;
  AssetTypes?: ProductTypeEnum[];
  SubAccounts?: string[];
  Symbols?: string[];
  SubAccountTypes?: SubAccountTypeEnum[];
  Expiries?: string[];
  MarketAccounts?: string[];
  MarketAccountGroups?: string[];
  MarketAccountTypes?: string[];
}

export function colIDToFilterBuilderKey(id: string): keyof PositionsTableFilter | undefined {
  switch (id as keyof Position | ExtraPositionColumns) {
    case 'SubAccountType':
      return 'SubAccountTypes';
    case 'AssetType':
      return 'AssetTypes';
    case 'Asset':
      return 'Symbols';
    case 'instrument':
      return 'Symbols';
    case 'PositionSource':
      return 'Source';
    case 'SubAccount':
      return 'SubAccounts';
    case 'expiry':
      return 'Expiries';
    case 'MarketAccount':
      return 'MarketAccounts';
    case 'marketAccountGroup':
      return 'MarketAccountGroups';
  }
  return undefined;
}

export type ClonePositionsTabFunc = (
  filter: PositionsTableFilter,
  columns: Column[],
  rowGroupsOpened: RowGroupsOpenedState | undefined,
  showZeroBalances: boolean
) => void;

export enum SubAccountPositionsBlotterMode {
  Spot = 'Spot',
  Derivatives = 'Derivatives',
  DerivativesAdvanced = 'DerivativesAdvanced',
}

export interface UpdatePositionForm {
  updateType?: LedgerUpdateTypeEnum;
  subAccount?: string;
  marketAccount?: string;
  asset?: string;
  amount?: string;
  avgCost?: string;
  avgCostCurrency?: string;
  timestamp?: Date;
  comments?: string;
}
