import { IconButton } from '../Button';
import { Box } from '../Core';
import { FormControlSizes } from '../Form';
import { Icon, IconName } from '../Icons';
import { useNotes } from './NotesProvider';
import { NoteContent, NoteWrapper, Square, Squares } from './styles';
import { NoteVariant, type NoteProps } from './types';

export function Note(props: NoteProps) {
  const { removeNote } = useNotes();
  return (
    <NoteWrapper variant={props.variant}>
      {props.variant === NoteVariant.Announcement && (
        <Squares>
          <Square />
          <Square />
          <Square />
          <Square />
          <Square />
          <div />
          <Square />
          <div />
          <div />
          <div />
          <Square />
          <div />
          <Square />
        </Squares>
      )}
      <NoteContent>
        {props.icon && <Icon icon={props.icon} />}
        <div>{props.text}</div>
      </NoteContent>
      <Box gap="spacingSmall" display="flex" alignItems="center">
        {props.actions}
        {props.closable && (
          <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={() => removeNote(props.id)} ghost />
        )}
      </Box>
    </NoteWrapper>
  );
}
