import Big from 'big.js';
import { useEffect, useState } from 'react';
import { bigMin, toBigWithDefault } from '../../utils';
import { Bar, BarWrapper, Label, MeterWrapper } from './styles';

export type MeterProps = {
  value: number;
  color?: string;
  backgroundColor?: string;
  showLabel?: boolean;
  customLabel?: string;
  animate?: boolean;
  showInitialAnimation?: boolean;
  alignBar?: 'left' | 'right';
  /** Whether or not to round the starting edge of the bar. Defaults to true. */
  roundStartingEdge?: boolean;
  height?: number;
};

function asPercent(value: number | string | Big) {
  const valueBig = toBigWithDefault(value, 0);
  return valueBig.gt(0) ? valueBig.times(100).toNumber() : 0;
}

export const Meter = ({
  value,
  color,
  backgroundColor,
  showLabel = true,
  animate = true,
  showInitialAnimation = true,
  customLabel,
  alignBar = 'left',
  roundStartingEdge = true,
  height,
}: MeterProps) => {
  const [percent, setPercent] = useState<number>(showInitialAnimation ? 0 : asPercent(value));
  useEffect(() => {
    // Allow initial render and then set width to get animation
    if (percent === 0) {
      const timer = setTimeout(() => setPercent(asPercent(value)), 200);
      return () => clearTimeout(timer);
    } else {
      setPercent(asPercent(value));
    }
  }, [value, percent]);

  const roundLeftEdge = alignBar === 'right' || (alignBar === 'left' && roundStartingEdge);
  const roundRightEdge = alignBar === 'left' || (alignBar === 'right' && roundStartingEdge);

  return (
    <MeterWrapper>
      <BarWrapper
        background={backgroundColor}
        roundLeftEdge={roundLeftEdge}
        roundRightEdge={roundRightEdge}
        height={height}
      >
        <Bar
          width={toBigWithDefault(bigMin(percent, 100), 0).toNumber()}
          color={color}
          align={alignBar}
          animate={animate}
        />
      </BarWrapper>
      {!customLabel && showLabel && <Label>{Big(percent).toFixed(2)}%</Label>}
      {customLabel && <Label>{customLabel}</Label>}
    </MeterWrapper>
  );
};
