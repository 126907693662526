import { Box, PortalContextProvider, useIsIntersecting, type BoxProps } from '@talos/kyoko';
import { useRef, type PropsWithChildren } from 'react';

/** Ensures each component is has appropriate dockview setup
 * - Ensure portal context is available (for filters, etc)
 * - Only render if visible (this is due to issues with react-beautiful-dnd - see TODO)
 *
 * TODO: We want to allow the component to at least render (and not unload) when not visible, but it may take
 * refactoring of the built-in tabs component.  May be also useful to add a flag to this to allow components who don't care
 * about DND ot ignore this
 * - this feels like a good suggestion: https://github.com/atlassian/react-beautiful-dnd/issues/1756#issuecomment-933723320
 */
export function DockViewPanelWrapper({ children, className, ...props }: PropsWithChildren<BoxProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsIntersecting(ref);
  return (
    <Box ref={ref} h="100%" w="100%" background="backgroundContent" {...props}>
      {isVisible && <PortalContextProvider>{children}</PortalContextProvider>}
    </Box>
  );
}
