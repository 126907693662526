const N_LEAD_CHARS = 6;
const N_END_CHARS = 6;

// 0xabcdefargaerg35g35h3095h -> 0xabcd...h3095h
export function truncateBlockchainAddress(address: string | null | undefined) {
  if (address == null) {
    return '';
  }

  if (address.length <= N_END_CHARS + N_LEAD_CHARS + 3) {
    return address;
  }

  const lead = address.substring(0, N_LEAD_CHARS);
  const end = address.substring(address.length - N_END_CHARS);
  return `${lead}...${end}`;
}
