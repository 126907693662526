import type { TabGroupSpec } from 'components/FlexibleLayout/types';

export const PORTFOLIO_DOCKVIEW_PANEL_GROUPS: { [key: string]: TabGroupSpec } = [
  {
    type: 'blotters',
    label: 'Blotters',
  },
].reduce((res, group: TabGroupSpec) => {
  res[group.type] = group;
  return res;
}, {});

export type PortfolioViewType = 'portfolio-management' | 'risk' | 'operations';
