import { Tooltip } from 'components/Tooltip';
import { forwardRef, useCallback, useState, type MouseEvent } from 'react';
import styled, { useTheme } from 'styled-components';
import { HStack } from '../Core';
import { ICON_SIZES, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { TabEditor } from './TabEditor';
import { CloseIcon, TabWrapper } from './styles';
import type { TabProps } from './types';

export const Tab = styled(
  forwardRef(
    (
      {
        label,
        prefix,
        suffix,
        editable = false,
        closable = false,
        reorderable = false,
        isSelected,
        onRename,
        onCancel,
        onRemove,
        isAddingTab = false,
        isDragging = false,
        showDot,
        dotVariant = IndicatorDotVariants.Default,
        maxTabLength,
        ...props
      }: TabProps,
      ref
    ) => {
      const [isEditing, setEditing] = useState(isAddingTab);
      const handleRename = useCallback(
        (value: string) => {
          if (value) {
            onRename?.(value);
            setEditing(false);
          }
        },
        [onRename]
      );
      const handleCancel = useCallback(() => {
        onCancel?.();
        setEditing(false);
        if (isAddingTab) {
          onRemove?.();
        }
      }, [onCancel, onRemove, isAddingTab]);

      const handleRemove = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          onRemove?.();
          return false;
        },
        [onRemove]
      );

      const handleAuxClick = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
          if (e.button === 1 && closable) {
            onRemove?.();
          }
          return false;
        },
        [onRemove, closable]
      );

      const theme = useTheme();

      return (
        <TabWrapper
          ref={ref}
          role="tab"
          aria-selected={isSelected}
          isSelected={isSelected}
          isDragging={isDragging}
          onDoubleClick={() => editable && setEditing(true)}
          onAuxClick={handleAuxClick}
          {...props}
        >
          {isEditing ? (
            <TabEditor initialValue={label} onSubmit={handleRename} onCancel={handleCancel} />
          ) : (
            <IndicatorDotWrapper show={showDot} variant={dotVariant}>
              <HStack style={{ lineHeight: '100%' }}>
                <Tooltip tooltip={maxTabLength ? label : undefined}>
                  <HStack style={maxTabLength ? { maxWidth: `${maxTabLength}px` } : undefined}>
                    {!!prefix && prefix}
                    {label && (
                      <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {label}
                      </span>
                    )}
                  </HStack>
                </Tooltip>
                {!!suffix && suffix}
                {closable && (
                  <CloseIcon
                    icon={IconName.Clear}
                    size={ICON_SIZES.SMALL}
                    onClick={handleRemove}
                    color={theme.colorTextDefault}
                  />
                )}
              </HStack>
            </IndicatorDotWrapper>
          )}
        </TabWrapper>
      );
    }
  )
)``;
