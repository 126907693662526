import {
  Box,
  Flex,
  FormControlSizes,
  HStack,
  IconButton,
  IconName,
  Modal,
  type ModalProps,
  Text,
  useDisclosure,
  useDynamicCallback,
  usePortal,
  VStack,
} from '@talos/kyoko';
import { useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { BreakDetails } from '../BreakDetails';
import type { ReconTab } from '../tabs';
import type { BreakDetailsState } from '../types';
import type { ReconAssetRow } from './blotter/rows';
import { SubAccountReconOverviewBlotter } from './blotter/SubAccountReconOverviewBlotter';
import { RECON_OVERVIEW_BLOTTER_PORTAL_ID } from './tokens';

interface ReconOverviewProps {
  tab: ReconTab;
  updateTab: (tab: ReconTab) => void;
}

export const ReconOverview = ({ tab }: ReconOverviewProps) => {
  const detailModal = useDisclosure();
  const [selectedBreak, setSelectedBreak] = useState<ReconAssetRow>();

  const handleViewBreakDetails = useDynamicCallback((assetRow: ReconAssetRow) => {
    setSelectedBreak(assetRow);
    detailModal.open();
  });

  const { setPortalRef: filtersContainerRef } = usePortal(RECON_OVERVIEW_BLOTTER_PORTAL_ID);
  const theme = useTheme();

  return (
    <>
      <VStack h="100%">
        <HStack
          w="100%"
          p="spacingSmall"
          justifyContent="flex-end"
          ref={filtersContainerRef}
          borderBottom={`2px solid ${theme.backgroundBody}`}
          background="colors.gray.main"
        />
        <Box flex="1" w="100%">
          <SubAccountReconOverviewBlotter
            onViewCheckpointDetails={handleViewBreakDetails}
            blotterID={`portfolio/reconciliation/${tab.id}/recon-overviewz`}
          />
        </Box>
      </VStack>
      <BreakDetailsModal selectedBreak={selectedBreak} {...detailModal} />
    </>
  );
};

type BreakDetailsModalProps = {
  selectedBreak: ReconAssetRow | undefined;
} & ModalProps;

const BreakDetailsModal = ({ selectedBreak, ...modalProps }: BreakDetailsModalProps) => {
  const breakDetailsState: BreakDetailsState | undefined = useMemo(() => {
    if (!selectedBreak) {
      return undefined;
    }

    return {
      filter: {},
      checkpointID: selectedBreak.checkpointID,
      subAccounts: selectedBreak.SubAccounts,
      asset: selectedBreak.Asset,
      startTime: selectedBreak.StartTime,
      endTime: selectedBreak.EndTime,
    };
  }, [selectedBreak]);

  const modalContainerRef = useRef<HTMLDivElement>(null);

  if (!breakDetailsState) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      minHeight="90vh"
      maxHeight="90vh"
      minWidth="90vw"
      maxWidth="90vw"
      position="relative"
      data-testid="sub-acc-recon-modal"
      closeOnClickOutside={false} // can lead to bad ux, just use the top-right x button
    >
      <Flex flexDirection="column" ref={modalContainerRef} position="absolute" top="0" bottom="0" right="0" left="0">
        {/* PopperHeader component doesnt exist in master so build our own for now */}
        <HStack
          justifyContent="space-between"
          p="spacingSmall"
          pl="spacingDefault"
          background="backgroundModalHeader"
          borderBottom="2px solid"
          borderColor="backgroundBody"
        >
          <Text color="colorTextImportant">Sub Account Reconciliation</Text>
          <IconButton
            ghost
            color="colorTextSubtle"
            size={FormControlSizes.Small}
            icon={IconName.Close}
            onClick={() => modalProps.close()}
            data-testid="close-dialog-button"
          />
        </HStack>
        <BreakDetails state={breakDetailsState} modalContainerRef={modalContainerRef} />
      </Flex>
    </Modal>
  );
};
