import type {
  ICellEditorParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import Big from 'big.js';
import { get } from 'lodash';

import { getPositionAmount, isFuture, isOption, isPerpetualSwap, isSpot } from '../../../utils';
import { buildSizeAggFunc } from '../aggFuncs/sizeAggFunc';
import { baseColumn } from './baseColumn';
import { amountObjectComparator } from './utils';

import type { Security } from '../../../types/Security';
import type { AgGridSizeProps } from '../../AgGrid/types';
import type { SizeValue } from '../aggFuncs/types';
import type { SizeColumnParams } from './size.types';
import type { ColDefFactory, Column } from './types';

export const size: ColDefFactory<Column<SizeColumnParams>> = column => ({
  type: 'numericColumn',
  width: 150,
  minWidth: 70,
  maxWidth: 240,
  cellEditor: 'amountInput',
  cellEditorParams: (params: ICellEditorParams<unknown, SizeValue>) => ({
    ...column.params,
    value: params.value?.value,
  }),
  cellRenderer: 'sizeColumn',
  aggFunc: column.aggregate ? buildSizeAggFunc(column.params) : undefined,
  cellRendererParams: (params: ICellRendererParams<unknown, SizeValue>): AgGridSizeProps => {
    return { ...column.params, ...params, value: params.value };
  },
  valueSetter: (params: ValueSetterParams) => {
    const field = params.colDef.field;
    if (!field) {
      // Failed to update
      return false;
    }

    const newValue: SizeValue | undefined = params.newValue;
    params.data[field] = newValue?.value;

    return true;
  },
  valueGetter: (props): SizeValue => {
    if (column.params?.valueGetter && typeof column.params.valueGetter === 'function') {
      return column.params.valueGetter(props);
    }

    const { colDef, data, context } = props;

    // Default some column options
    const showCurrency = column.params?.showCurrency ?? true;
    const showCurrencyForNullPosition = column.params?.showCurrencyForNullPosition ?? true;

    let value: string | undefined = colDef.field != null ? get(data, colDef.field) : undefined;

    // Use provided set of currencies unless specified
    const currencies = column.params?.currencies || context.current.currenciesBySymbol;

    const currencyField = column.params?.currencyField;
    // If we do not have currency defined, try finding it.
    let workingCurrency: string | null =
      column.params?.currency || (currencyField != null ? get(data, currencyField) : null);

    let securityInfo: Security | undefined = undefined;
    const securityField = column.params?.securityField;
    if (securityField != null) {
      securityInfo = context.current.securitiesBySymbol?.get(get(data, securityField));
    }

    let currencyInfo = currencies?.get(workingCurrency ?? '');

    // If we don't have securityInfo at this point, treat the currency as a security (derivatives)
    if (securityInfo === undefined) {
      securityInfo =
        currencyInfo == null && workingCurrency != null
          ? context.current.securitiesBySymbol?.get(workingCurrency)
          : undefined;
    }

    if (securityInfo && !isSpot(securityInfo) && column.params?.showInTermsOfContracts) {
      // If the security is spot, and the implementer says that they want to show derivatives in terms of contracts always,
      // we just return the raw value here without a currency and without applying any potential notional multiplier
      return { value: value ?? '', currency: '', increment: undefined };
    }

    const noPositionCurrencyExists = securityInfo && !isSpot(securityInfo) && !securityInfo.PositionCurrency;
    if (noPositionCurrencyExists) {
      if (value !== undefined && (isPerpetualSwap(securityInfo) || isFuture(securityInfo) || isOption(securityInfo))) {
        // Display positions for future and perps with notional multiplier always in base or quote ccy
        const positionAmount = getPositionAmount(value, securityInfo);
        value = positionAmount.value;
        workingCurrency = positionAmount.currency;
        currencyInfo = currencies?.get(workingCurrency ?? '');
      } else if (!showCurrencyForNullPosition) {
        // Don't show currency when security is non Spot and PositionCurrency is undefined/null
        workingCurrency = '';
      }
    }

    const increment = column.params?.increment || currencyInfo?.DefaultIncrement || securityInfo?.DefaultSizeIncrement;
    const decidedCurrency = showCurrency
      ? currencyInfo?.Symbol || securityInfo?.PositionCurrency || workingCurrency
      : undefined;

    return { value: value ?? '', currency: decidedCurrency ?? '', increment };
  },
  // Value parser are used when receiving editing input
  valueParser: ({ oldValue, newValue }: { oldValue: SizeValue; newValue: string }) => {
    const value = newValue?.trim();
    try {
      Big(value);
      return { ...oldValue, value };
    } catch (e) {
      return oldValue;
    }
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, SizeValue>): string => {
    return typeof value.value === 'string' ? value.value : value.value.toFixed();
  },
  ...baseColumn(column),
  headerClass: () => {
    const columnGroupClass = column.columnGroup ? 'ag-custom-column-group' : '';
    return column.params?.align !== 'left' ? `ag-right-aligned-header ${columnGroupClass}` : `${columnGroupClass}`;
  },
  comparator: amountObjectComparator,
});
