import { type Allocation, runValidation, toBigWithDefault, useDynamicCallback } from '@talos/kyoko';
import Big from 'big.js';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { array, object, string } from 'yup';
import type { SchemaLike } from 'yup/lib/types';
import type { ResolveBreakForm } from './types';

export const useResolutionValidation = (form: ResolveBreakForm, breakAmount: string | undefined) => {
  const breakAmountBig = toBigWithDefault(breakAmount, 0);
  const [errors, setErrors] = useState<Partial<Record<keyof ResolveBreakForm, string>>>({});
  const [touched, setTouched] = useState<Partial<Record<keyof ResolveBreakForm, boolean>>>({});

  const setAllTouched = useDynamicCallback(() =>
    setTouched({
      updateType: true,
      subAccountAllocations: true,
      asset: true,
      comments: true,
    } satisfies Record<keyof ResolveBreakForm, boolean>)
  ); // static test to ensure we arent missing any

  useEffect(() => {
    const schema: Partial<Record<keyof ResolveBreakForm, SchemaLike>> = {
      updateType: string().required('Update Type is required'),
      asset: string().required('Asset is required'),
    };

    let subAccountAllocationsSchema = array<Allocation>(
      object({
        subAccount: string()['required']('Please select a Sub Account'),
        value: string().default(''),
      }).typeError('Please select a Sub Account')
    );

    subAccountAllocationsSchema = subAccountAllocationsSchema.min(1, 'Please select a Sub Account');

    subAccountAllocationsSchema = subAccountAllocationsSchema.test(
      'allocations',
      'Total must equal Break amount',
      (arr: Allocation[] | undefined) => {
        if (!arr) {
          return false;
        }

        const value = arr.reduce(
          (workingValue, allocation) => workingValue.plus(toBigWithDefault(allocation.value, 0)),
          Big(0)
        );

        return value.eq(breakAmountBig);
      }
    );

    schema.subAccountAllocations = subAccountAllocationsSchema;

    setErrors(prev => {
      const next = runValidation(object().shape(schema), form);
      return isEqual(prev, next) ? prev : next;
    });
  }, [form, breakAmountBig]);

  return { errors, touched, setTouched, setAllTouched };
};
