import type { ICellEditorParams } from 'ag-grid-community';
import { defineMessages } from 'react-intl';
import type { AgGridSearchSelectDropdownProps } from '../../AgGrid/types';
import { Box } from '../../Core';
import { FormattedMessage } from '../../Intl';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

const messages = defineMessages({
  lookbackPeriod: {
    defaultMessage:
      'Rolling lookback period against which the limit will be checked. When a new order is entered, the system will look back at the orders/volume placed within the specified period.',
    id: 'BlotterTable.columns.lookbackPeriod',
  },
});

const WINDOW_DURATIONS = ['1m', '5m', '15m', '1h', '12h', '24h'];

export const windowDuration: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellEditor: 'searchSelectDropdown',
  cellEditorPopup: true,
  suppressKeyboardEvent: params => params.event.key === 'Enter',
  cellEditorParams: (params: ICellEditorParams) => {
    return {
      ...params,
      useSearchSelectParams: {
        items: WINDOW_DURATIONS,
        getLabel: item => item,
        initialSortByLabel: false,
      },
    } satisfies AgGridSearchSelectDropdownProps<string>;
  },
  headerComponent: 'tooltipHeader',
  headerComponentParams: {
    tooltip: (
      <Box w="250px">
        <FormattedMessage {...messages.lookbackPeriod} />
      </Box>
    ),
  },
});
