import {
  SUB_ACCOUNT_RECON_CHECKPOINT,
  type SubAccountReconCheckpoint,
  type SubscriptionResponse,
  useSubscription,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { map, type Observable } from 'rxjs';
import { type SubAccountReconOverviewRow, subAccReconCheckpointToBlotterRows } from './rows';
import type { SubAccountReconOverviewBlotterFilter } from './types';

type SubAccountReconCheckpointRequest = WebsocketRequest &
  SubAccountReconOverviewBlotterFilter & { CheckpointIDs?: string[] };

export const useSubAccountReconOverviewDataObs = ({
  filter,
  checkpointIDs,
}: {
  filter: SubAccountReconOverviewBlotterFilter;
  checkpointIDs?: string[];
}) => {
  const request: SubAccountReconCheckpointRequest = useMemo(() => {
    return {
      name: SUB_ACCOUNT_RECON_CHECKPOINT,
      tag: 'useSubAccountReconOverviewDataObs',
      CheckpointIDs: checkpointIDs,
      ...filter,
    };
  }, [filter, checkpointIDs]);

  const { data } = useSubscription<SubAccountReconCheckpoint>(request);

  const rows: Observable<SubscriptionResponse<SubAccountReconOverviewRow>> = useMemo(() => {
    return data.pipe(map(message => ({ ...message, data: message.data.flatMap(subAccReconCheckpointToBlotterRows) })));
  }, [data]);

  return { dataObservable: rows };
};
