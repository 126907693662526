import {
  MarketAccountStatusEnum,
  useDynamicCallback,
  type AgGridSearchSelectDropdownProps,
  type Column,
  type CustomerTradingLimit,
  type MarketAccount,
} from '@talos/kyoko';
import type { ICellEditorParams, ValueFormatterParams } from 'ag-grid-community';
import { useCustomerUsers, useCustomersByName } from 'hooks/useCustomer';
import { useCustomerMarketAccounts } from 'providers/CustomerMarketAccountsProvider';
import { useMemo } from 'react';

interface CustomerAccountAutocompleteItem {
  label: string;
  value?: MarketAccount;
  description?: string;
}

export const useCustomerAccountColumn = (): Column => {
  const { customerMarketAccountsByName } = useCustomerMarketAccounts();
  const customersByName = useCustomersByName();
  const customerUsers = useCustomerUsers();

  const getDescription = useDynamicCallback((marketAccountName: string) => {
    const counterparty = customerMarketAccountsByName.get(marketAccountName)?.Counterparty;
    return counterparty ? customersByName?.get(counterparty)?.DisplayName : 'No Associated Customer';
  });

  const cellEditorParams = useDynamicCallback((params: ICellEditorParams) => {
    const entities: MarketAccount[] = [...customerMarketAccountsByName.values()].filter(
      acc => acc.Status === MarketAccountStatusEnum.Active
    );
    const items: CustomerAccountAutocompleteItem[] = entities.map(u => ({
      label: u.DisplayName ?? u.Name,
      value: u,
      description: getDescription(u.Name),
    }));
    items.unshift({
      label: '*',
      value: undefined,
      description: 'All accounts',
    });
    return {
      ...params,
      useSearchSelectParams: {
        items,
        getLabel: (item: CustomerAccountAutocompleteItem) => item.label,
        getDescription: (item: CustomerAccountAutocompleteItem) => item.description ?? '',
      },
      searchPlaceholder: 'Account',
    } satisfies AgGridSearchSelectDropdownProps<CustomerAccountAutocompleteItem>;
  });

  const valueFormatter = (params: ValueFormatterParams<CustomerAccountAutocompleteItem, string>) => {
    if (!params.value) {
      return '*';
    }
    return params.value;
  };

  const valueSetter = useDynamicCallback(
    ({
      newValue,
      data,
    }: {
      newValue: CustomerAccountAutocompleteItem;
      data: { MarketAccount?: string; CustomerUser?: string };
    }) => {
      if (newValue.value == null) {
        data.MarketAccount = undefined;
        return true;
      }
      const foundUser = customerUsers?.find(cu => cu.Email === data.CustomerUser);
      const foundCounterparty = customerMarketAccountsByName?.get(newValue.value.Name);

      if (foundUser?.Counterparty !== foundCounterparty?.Counterparty) {
        // The newly selected Market Account collides with the selected customer user, delete the user selection.
        delete data.CustomerUser;
      }

      if (newValue?.value) {
        data.MarketAccount = newValue.value.Name;
      }

      return true;
    }
  );

  const valueGetter = useDynamicCallback(({ data }: { data: CustomerTradingLimit }) => {
    if (data.MarketAccount) {
      const foundAccount = customerMarketAccountsByName.get(data.MarketAccount);
      return foundAccount?.DisplayName ?? foundAccount?.Name;
    }
  });

  return useMemo(
    () => ({
      type: 'custom',
      id: 'customerAccount',
      field: 'MarketAccount',
      title: 'Market Account',
      params: {
        cellEditor: 'searchSelectDropdown',
        cellEditorPopup: true,
        suppressKeyboardEvent: params => params.event.key === 'Enter',
        cellEditorParams,
        sortable: true,
        editable: ({ data }: { data: CustomerTradingLimit }) => data.Counterparty == null,
        getDescription,
        valueSetter,
        valueFormatter,
        valueGetter,
      },
    }),
    [cellEditorParams, getDescription, valueGetter, valueSetter]
  );
};
