import { useCallback, useMemo, useState } from 'react';

import {
  ACTION,
  Button,
  ButtonVariants,
  FormRowStatus,
  FormTable,
  IconName,
  InstrumentCompositionEnum,
  LocalFilterInput,
  ModeEnum,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  promisesHaveResolved,
  useFormTable,
  useGlobalToasts,
  useSecuritiesContext,
  type Column,
  type CustomerSecurity,
  type FormRow,
  type Security,
} from '@talos/kyoko';
import type { ValueGetterParams } from 'ag-grid-community';
import { useRoleAuth } from 'hooks';
import { compact } from 'lodash';
import { useCustomerSecurities, useCustomerSecuritiesContext } from 'providers';
import { useBlotterState } from 'providers/BlottersContext';
import { useSymbolColumn, useUnderlyingCrossSymbolColumn, useUnifiedLiquidityColumn } from './utils/columns';

const SEARCH_KEYS = ['Symbol', 'Mode'] satisfies (keyof CustomerSecurity)[];

const useCustomerSecurityColumns = (securitiesList: Security[], filteredSecuritiesList: Security[]): Column[] => {
  const symbolColumn = useSymbolColumn(securitiesList, filteredSecuritiesList);
  const underlyingCrossSymbolColumn = useUnderlyingCrossSymbolColumn(securitiesList, filteredSecuritiesList);
  const unifiedLiquidityColumn = useUnifiedLiquidityColumn();
  return useMemo(
    () =>
      compact([
        symbolColumn,
        { type: 'mode', field: 'Mode', title: 'Enable', width: 75 },
        {
          type: 'mode',
          field: 'DefaultPricingRuleMode',
          title: 'Default PR Mode',
          description: 'New Pricing Rules will default to this Mode',
          width: 130,
          suppressMenu: false,
        },
        {
          type: 'mode',
          field: 'PriceViaSyntheticCross',
          title: 'Price via Synthetic Cross',
          description: 'Should an additional pair be used when pricing this symbol',
          width: 130,
          suppressMenu: false,
        },
        unifiedLiquidityColumn,
        {
          ...underlyingCrossSymbolColumn,
          hide: false,
        },
        {
          type: 'text',
          title: 'Size Increment',
          field: 'MinSizeIncrement',
          editable: true,
          description: 'Minimum size increment.',
          width: 140,
        },
        {
          type: 'text',
          title: 'Amount Increment',
          field: 'MinAmtIncrement',
          editable: true,
          description: 'Minimum amount increment.',
          width: 160,
        },
        {
          type: 'text',
          title: 'Price Increment',
          field: 'MinPriceIncrement',
          editable: true,
          description: 'Minimum price increment.',
          width: 130,
        },
        {
          type: 'number',
          field: 'MinimumSize',
          editable: true,
          description: 'Minimum size for orders.',
          width: 130,
        },
        {
          type: 'number',
          field: 'MaximumSize',
          editable: true,
          description: 'Maximum size for orders.',
          width: 130,
        },
        {
          type: 'custom',
          field: 'SizeBuckets',
          params: {
            valueGetter: ({ data }: ValueGetterParams<CustomerSecurity>) => {
              const sizeBuckets = data?.SizeBuckets;
              if (Array.isArray(sizeBuckets)) {
                let string = '';
                // Add spaces between options so you can double click options
                sizeBuckets.forEach((size, i) => (string += `${size}${i !== sizeBuckets.length - 1 ? ', ' : ''}`));
                return string;
              }
              // If already a string, keep it.
              return sizeBuckets;
            },
            valueSetter: ({ data, newValue }) => {
              data.SizeBuckets = newValue
                .replace(/\s+/g, '')
                .split(',')
                .filter(v => v !== '');
              return true;
            },
            cellEditor: 'input',
          },
          editable: true,
          description: 'Size buckets for showing depth, separated by commas.',
          width: 300,
        },
        {
          type: 'text',
          field: 'DisplaySymbol',
          editable: true,
          description: 'Display symbol for the symbol.',
          width: 130,
          hide: true,
        },
        {
          type: 'text',
          field: 'Description',
          editable: true,
          description: 'Description for the symbol.',
          width: 160,
          hide: true,
        },
        { type: 'filler', id: 'filler1' },
        { type: 'remove', id: 'remove', params: { allowOnlyForAddedRows: true } },
      ]),
    [symbolColumn, underlyingCrossSymbolColumn, unifiedLiquidityColumn]
  );
};

export function SecurityMaster() {
  const customerSecurities = useCustomerSecurities();
  const [isSaving, setIsSaving] = useState(false);
  const { isAuthorized } = useRoleAuth();

  const { add: addToast } = useGlobalToasts();
  const { securitiesList } = useSecuritiesContext();
  const customerSecuritiesService = useCustomerSecuritiesContext();

  const filteredSecuritiesList = useMemo(() => {
    return securitiesList.filter(
      security =>
        // Don't allow users to create CustomerSecurities tied to Synthetic Securities
        security.Composition !== InstrumentCompositionEnum.Synthetic &&
        // Block users from making duplicate customer securities (backend will also reject)
        customerSecurities?.find(customerSec => customerSec.Symbol === security.Symbol) === undefined
    );
  }, [customerSecurities, securitiesList]);

  const columns = useCustomerSecurityColumns(securitiesList, filteredSecuritiesList);

  const createCustomerSecurity = useCallback(
    async (row: FormRow<Partial<CustomerSecurity>>) => {
      return customerSecuritiesService
        .createCustomerSecurity(row.data)
        .then(({ data }) => {
          row.setData(data[0]);
          return data[0];
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: e?.toString() || `Could not add customer security.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, customerSecuritiesService]
  );

  const updateCustomerSecurity = useCallback(
    async (row: FormRow<Partial<CustomerSecurity>>) => {
      return customerSecuritiesService
        .updateCustomerSecurity(row.data)
        .then(({ data }) => {
          row.setData(data[0]);
          return data[0];
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: e?.toString() || `Could not update customer security.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, customerSecuritiesService]
  );

  const { filterValueSecurityMaster, setFilterValueSecurityMaster } = useBlotterState();
  const formTable = useFormTable<Partial<CustomerSecurity>>({
    rowID: 'Symbol',
    data: customerSecurities,
    columns: columns,
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
      filterText: filterValueSecurityMaster,
    },
  });

  const handleSave = useCallback(() => {
    setIsSaving(true);
    const rows = formTable.getRows();
    const requests: Promise<any>[] = [];
    for (const row of rows) {
      switch (row.status) {
        case FormRowStatus.Added:
          requests.push(createCustomerSecurity(row));
          break;
        case FormRowStatus.Updated:
          requests.push(updateCustomerSecurity(row));
          break;
        default:
          break;
      }
    }
    Promise.allSettled(requests).then(promises => {
      setIsSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: 'Security Master saved.',
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Security Master could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  }, [addToast, formTable, createCustomerSecurity, updateCustomerSecurity]);

  const handleAddSymbolButton = useCallback(() => {
    formTable.addRow({ Mode: ModeEnum.Enabled, DefaultPricingRuleMode: ModeEnum.Enabled });
  }, [formTable]);

  const headerActions = (
    <PanelActions>
      <LocalFilterInput
        placeholder="Filter by Symbol or Mode"
        value={filterValueSecurityMaster}
        onChange={setFilterValueSecurityMaster}
      />
      <Button
        startIcon={IconName.Plus}
        onClick={handleAddSymbolButton}
        variant={ButtonVariants.Positive}
        disabled={!isAuthorized(ACTION.DEALER_TRADING) || !formTable.isMounted}
      >
        Add Symbol
      </Button>
      <Button
        data-testid="security-master-save"
        variant={ButtonVariants.Primary}
        onClick={handleSave}
        disabled={isSaving || !formTable.isDirty || !isAuthorized(ACTION.DEALER_TRADING)}
      >
        Save
      </Button>
    </PanelActions>
  );

  return (
    <Panel>
      <PanelHeader>
        <h2>Security Master</h2>
        {headerActions}
      </PanelHeader>
      <PanelContent>
        <FormTable {...formTable} />
      </PanelContent>
    </Panel>
  );
}
