import type { ValueFormatterParams } from 'ag-grid-community';
import { isArray } from 'lodash';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const subAccountNames: ColDefFactory<Column> = column => ({
  ...baseColumn(column),

  // ["name1", "name2"] -> "DisplayName1, DisplayName2"
  valueFormatter: ({ value, context }: ValueFormatterParams<unknown, string[]>) => {
    if (!isArray(value)) {
      return '';
    }

    return value
      .map(subAccountName => context.current.subAccountsByName?.get(subAccountName)?.DisplayName ?? subAccountName)
      .join(', ');
  },
});
