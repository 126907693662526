import { lazy, memo, Suspense, useEffect } from 'react';

import { BrowserRouter, Redirect, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  ACTION,
  Dialog,
  HStack,
  LoaderTalos,
  MixpanelEvent,
  MixpanelEventProperty,
  Notes,
  useAuthContext,
  useEventListener,
  useGlobalDialog,
  useMixpanel,
  VStack,
} from '@talos/kyoko';
import { ContextProvider } from 'providers';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { NavigationSidebar } from 'components/NavigationSidebar';
import { NoMatchPage } from 'containers/NoMatchPage';
import { useFeatureFlag, useRoleAuth } from 'hooks';

import { Header } from 'containers/Header';
import { AppwideDrawer } from '../../components/AppwideDrawer';
import { GlobalToasts } from '../../components/GlobalToasts';
import { Unauthorized } from '../Unauthorized';

export const Content = styled.div`
  background: ${({ theme }) => theme.backgroundCard};
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
  gap: ${({ theme }) => theme.spacingTiny}px;
  background: ${({ theme }) => theme.backgroundBody};
`;

const AdminContainer = lazy(() => import('containers/Admin'));
const TradingContainer = lazy(() => import('containers/Trading'));
const PortfolioContainer = lazy(() => import('containers/Portfolio'));
const PortfolioEngineeringContainer = lazy(() => import('containers/PortfolioEngineering'));
const DealerContainer = lazy(() => import('containers/Dealer'));
const AnalyticsContainer = lazy(() => import('containers/Analytics'));
const MonitoringContainer = lazy(() => import('containers/Monitoring'));
const SettingsContainer = lazy(() => import('containers/Settings'));
const OMS = lazy(() => import('components/OMS'));
const MarketDataCardsProvider = lazy(() => import('containers/Trading/MarketDataCardsProvider'));
const PowerSearch = lazy(() => import('../../components/PowerSearch'));

export const Pages: ReturnType<typeof withRouter> = withRouter(
  memo(function Pages() {
    const { isAuthorized } = useRoleAuth();
    const history = useHistory();
    const mixpanel = useMixpanel();

    const path = history?.location?.pathname;
    const section = path.split('/')[1] ?? 'trading';

    useEffect(() => {
      mixpanel.time_event(MixpanelEvent.ViewSection);
      return () => {
        mixpanel.track(MixpanelEvent.ViewSection, { [MixpanelEventProperty.Section]: section });
      };
    }, [section, mixpanel]);

    useEventListener('beforeunload', () => {
      mixpanel.track(MixpanelEvent.ViewSection, { [MixpanelEventProperty.Section]: section });
    });

    const { enableMonitoringBlotters } = useFeatureFlag();
    const { content, ...dialog } = useGlobalDialog();

    return (
      <ErrorBoundary>
        <Dialog {...dialog}>{content}</Dialog>
        <MarketDataCardsProvider>
          <VStack h="100%" alignItems="stretch">
            <PowerSearch />
            <Content>
              <NavigationSidebar />
              <VStack w="100%" h="100%" gap="spacingTiny" overflow="hidden" alignItems="initial">
                <Header />
                <Notes />
                <Suspense fallback={<LoaderTalos />}>
                  <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden" alignItems="initial">
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/trading" />
                      </Route>
                      <Route exact path="/trade">
                        <Redirect to="/trading" />
                      </Route>
                      <Route
                        path="/trade/*"
                        render={({ match }) => {
                          const newPath = match.url.replace('trade', 'trading');
                          return <Redirect to={newPath} />;
                        }}
                      />
                      <Route exact path="/dealer">
                        <Redirect to="/dealer/monitoring" />
                      </Route>
                      <Route exact path="/portfolio-engineering">
                        <Redirect to="/portfolio-engineering/rebalance" />
                      </Route>
                      <Route path="/trading" component={TradingContainer} />
                      {isAuthorized(ACTION.SUBMIT_TRANSFER) && (
                        <Route exact path="/transfers" component={TradingContainer} />
                      )}
                      {isAuthorized(ACTION.VIEW_PORTFOLIO) && (
                        <Route path="/portfolio" component={PortfolioContainer} />
                      )}
                      {isAuthorized(ACTION.EDIT_PORTFOLIO_ENGINEERING) && (
                        <Route path="/portfolio-engineering" component={PortfolioEngineeringContainer} />
                      )}
                      {isAuthorized(ACTION.VIEW_DEALER) && <Route path="/dealer" component={DealerContainer} />}
                      {isAuthorized(ACTION.VIEW_ANALYTICS) && (
                        <Route path="/analytics" component={AnalyticsContainer} />
                      )}
                      <Route path="/settings" component={SettingsContainer} />
                      <Route exact path="/monitoring">
                        <Redirect to="/monitoring/blotters" />
                      </Route>
                      {enableMonitoringBlotters && <Route path="/monitoring" component={MonitoringContainer} />}
                      {isAuthorized(ACTION.VIEW_TALOS_ADMIN_SETTINGS) && (
                        <Route path="/admin" component={AdminContainer} />
                      )}
                      <Route component={NoMatchPage} />
                    </Switch>
                    <AppwideDrawer />
                    <OMS />
                  </HStack>
                </Suspense>
              </VStack>
            </Content>
          </VStack>
          <GlobalToasts />
        </MarketDataCardsProvider>
      </ErrorBoundary>
    );
  })
);

export const Routes = () => {
  const auth = useAuthContext();

  return (
    <BrowserRouter>
      {auth.isAuthenticated && (
        <ContextProvider>
          <Pages />
        </ContextProvider>
      )}
      {!auth.isAuthenticated && <Route path="/unauthorized" component={Unauthorized} />}
    </BrowserRouter>
  );
};
