import { MixpanelEvent } from '@talos/kyoko';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { TradingSettingCheckbox } from './TradingSettingCheckbox';

export function DisplayBBOCheckbox() {
  const { alwaysShowBestBidOffer, setAlwaysShowBestBidOffer } = useTradingSettings();
  const displayMsg = 'Always Display Best Bid/Offer in New Order';
  return (
    <TradingSettingCheckbox
      id="bestBidOffer"
      isEnabled={alwaysShowBestBidOffer}
      onChecked={setAlwaysShowBestBidOffer}
      mixPanelEvent={MixpanelEvent.AlwaysDisplayBestBidOffer}
      displayName={displayMsg}
      displayDescription="When enabled, the New Order form will always display the Best Bid/Offer rate at the top of the form, instead of a Qty based rate."
    />
  );
}
