import { Box } from '@talos/kyoko';
import styled from 'styled-components';

export const ChartCenterCurrencySuffix = styled.span`
  color: ${({ theme }) => theme.colorTextSubtle};
  font-size: 0.8em;
`;

export const D3ChartG = styled.g`
  transition: opacity 200ms;
`;

export const D3ChartPath = styled.path`
  transition: opacity 200ms;
`;

export const DropdownButtonContainer = styled(Box)<{ disabled: boolean }>`
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'pointer' : 'cursor')};
  }
`;
