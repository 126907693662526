import {
  Box,
  Button,
  ButtonVariants,
  Divider,
  FormTable,
  HStack,
  IconName,
  LocalFilterInput,
  Text,
  VStack,
  type ISubaccount,
  type SubAccountPositionLimit,
} from '@talos/kyoko';
import { useState } from 'react';
import { useBlotterState } from '../../../../providers/BlottersContext';
import { ForSubAccountSelector } from '../ForSubAccountSelector';
import { useSubAccountTradingLimitsBlotter } from './useSubAccountTradingLimitsBlotter';

const SEARCH_KEYS = ['MatchAsset', 'SubAccount', 'LimitID', 'Description'] satisfies (keyof SubAccountPositionLimit)[];

export function SubAccountTradingLimits() {
  const [forSubAccount, setForSubAccount] = useState<ISubaccount | undefined>(undefined);

  return (
    <SubAccountTradingLimitsFormTable
      // Reset the form table whenever forSubAccount changes
      key={forSubAccount?.Name}
      forSubAccount={forSubAccount}
      onForSubAccountChange={setForSubAccount}
    />
  );
}

const SubAccountTradingLimitsFormTable = ({
  forSubAccount,
  onForSubAccountChange,
}: {
  forSubAccount: ISubaccount | undefined;
  onForSubAccountChange: (forSubAccount: ISubaccount | undefined) => void;
}) => {
  const { filterValueSubAccountTradingLimits, setFilterValueSubAccountTradingLimits } = useBlotterState();

  const { formTable, isSaving, onAdd, onSave } = useSubAccountTradingLimitsBlotter({
    forSubAccount,
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
      filterText: filterValueSubAccountTradingLimits,
    },
  });

  return (
    <VStack h="100%" w="100%" alignItems="flex-start">
      <VStack w="100%" gap="spacingDefault" alignItems="flex-start" my="spacingDefault">
        <Text>The maximum (or minimum) asset position that can be accumulated in sub account(s).</Text>
        <HStack gap="spacingDefault" justifyContent="flex-end" w="100%">
          <ForSubAccountSelector forSubAccount={forSubAccount} onForSubAccountChange={onForSubAccountChange} />
          <Divider orientation="vertical" />
          <LocalFilterInput
            placeholder="Search..."
            value={filterValueSubAccountTradingLimits}
            onChange={setFilterValueSubAccountTradingLimits}
            width="170px"
          />
          <Divider orientation="vertical" />
          <Button startIcon={IconName.Plus} onClick={onAdd}>
            Add Limit
          </Button>
          <Button variant={ButtonVariants.Primary} onClick={onSave} disabled={isSaving || !formTable.isDirty}>
            Save
          </Button>
        </HStack>
      </VStack>
      <Box h="100%" w="100%">
        <FormTable {...formTable} />
      </Box>
    </VStack>
  );
};
