import { defineMessages } from 'react-intl';
import { AlertVariants } from '../../components/Alert';
import type { ColDefIntl } from '../../components/BlotterTable/columns/types';

const messages = defineMessages({
  high: {
    defaultMessage: 'High',
    id: 'types.WarningSeverity.high',
  },
  low: {
    defaultMessage: 'Low',
    id: 'types.WarningSeverity.low',
  },
  medium: {
    defaultMessage: 'Medium',
    id: 'types.WarningSeverity.medium',
  },
  none: {
    defaultMessage: 'None',
    id: 'types.WarningSeverity.none',
  },
});

type MessageKey = keyof typeof messages;

export enum WarningSeverity {
  NONE,
  LOW,
  MEDIUM,
  HIGH,
}

export const WARNING_SEVERITY_TO_ALERT_VARIANT: Record<WarningSeverity, AlertVariants> = {
  [WarningSeverity.NONE]: AlertVariants.Default,
  [WarningSeverity.LOW]: AlertVariants.Notice,
  [WarningSeverity.MEDIUM]: AlertVariants.Warning,
  [WarningSeverity.HIGH]: AlertVariants.Negative,
};

export const WARNING_SEVERITY_TO_TEXT: Record<WarningSeverity, MessageKey> = {
  [WarningSeverity.NONE]: 'none',
  [WarningSeverity.LOW]: 'low',
  [WarningSeverity.MEDIUM]: 'medium',
  [WarningSeverity.HIGH]: 'high',
};

export const prettyWarningSeverity = (warningSeverity: WarningSeverity, intl: ColDefIntl) =>
  WARNING_SEVERITY_TO_TEXT[warningSeverity]
    ? intl.formatMessage(messages[WARNING_SEVERITY_TO_TEXT[warningSeverity]])
    : '';
