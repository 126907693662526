import { createContext, useContext } from 'react';
import type { AmountObject } from '../utils';

export const UnderlyingPriceContext = createContext<{
  underlyingAmountRecord: Record<string, AmountObject | null>;
  currency: string | null;
}>({
  currency: null,
  underlyingAmountRecord: {},
});

UnderlyingPriceContext.displayName = 'UnderlyingAmountContext';

export function useUnderlyingPriceContext() {
  const context = useContext(UnderlyingPriceContext);
  if (context === undefined) {
    throw new Error('Missing UnderlyingPriceContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
