import {
  Box,
  DateTimePickerSelect,
  FormControlSizes,
  readableDate,
  useDynamicCallback,
  type DateTimePickerSelectProps,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { TreasuryManagementActionType } from '../TreasuryManagementReducer';
import { useMaxDateValue } from '../hooks/useMaxDateValue';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';

const shortcuts: DateTimePickerSelectProps['shortcuts'] = {
  Now: 'Now',
};

function customShortcutResolver(shortcut: string) {
  // We only have one shortcut "Now" and that is effectively clearing the selection
  return null;
}

function getLabel(date: Date | null) {
  return readableDate(date, true);
}

export function SnapshotDateSelector() {
  const theme = useTheme();
  const {
    state: { snapshotDate },
    dispatch,
  } = useTreasuryManagementContext();

  const handleSnapshotDateChange = useDynamicCallback((newDate: Date | null) => {
    dispatch({
      type: TreasuryManagementActionType.SnapshotDateChange,
      payload: {
        snapshotDate: newDate,
      },
    });
  });

  const convertedSnapshotDate = useMemo(() => (snapshotDate == null ? null : new Date(snapshotDate)), [snapshotDate]);

  const maxDateValue = useMaxDateValue();

  return (
    <Box flex="0 0 auto">
      <DateTimePickerSelect
        size={FormControlSizes.Small}
        value={convertedSnapshotDate}
        onChange={handleSnapshotDateChange}
        shortcuts={shortcuts}
        customShortcutResolver={customShortcutResolver}
        getLabel={getLabel}
        timePickerVariant="selector"
        timeSelectorIntervalMinutes={15}
        placeholder="Now"
        placeholderColor={theme.colorTextAttention}
        maxValue={maxDateValue}
      />
    </Box>
  );
}
