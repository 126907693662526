import { useCurrenciesContext, useSyncedRef, type Currency, type CurrencyTagEnum } from '@talos/kyoko';
import { usePortfolioSettings } from 'providers/PortfolioSettingContext';
import { useMemo } from 'react';

/**
 * Returns a filter function that determines whether an asset should be included based on our Include Cash logic
 * @param includeCash - A boolean indicating whether cash assets should be included.
 * @param treatStablecoinsAsCash - A boolean indicating whether stablecoins should be treated as cash assets.
 * @param currenciesBySymbol - A map of currencies indexed by symbol.
 * @returns A filter function that takes an asset symbol as input and returns a boolean indicating whether the asset should be included.
 */
export function getIncludeCashFilter(
  includeCash: boolean,
  treatStablecoinsAsCash: boolean,
  currenciesBySymbol: Map<string, Currency>
): (asset: string) => boolean {
  return (asset: string): boolean => {
    if (includeCash) {
      return true;
    }

    const cashToExclude: CurrencyTagEnum[] = !includeCash
      ? treatStablecoinsAsCash
        ? ['Fiat', 'StableCoin']
        : ['Fiat']
      : [];
    const isExcludedCash = cashToExclude.length
      ? currenciesBySymbol.get(asset)?.Tags?.some(tag => cashToExclude.includes(tag)) ?? false
      : false;
    return !isExcludedCash;
  };
}

/**
 * Returns a filter function where given an asset input, returns a boolean indicating whether the asset should be included based on our Include Cash logic.
 * - Internally recognizes stablecoins as cash if `treatStablecoinsAsCash` is true from User Settings.
 * @returns A filter function that takes an asset symbol as input and returns a boolean indicating whether the asset should be included.
 */
export function useIncludeCashFilterFunc<T>({
  includeCash,
  assetGetter,
}: {
  /** A boolean indicating whether cash assets should be included. */
  includeCash: boolean;
  /** A function that takes an input and returns the asset symbol (memoized internally) */
  assetGetter: (data: T) => string;
}): (asset: T) => boolean {
  const { treatStablecoinsAsCash } = usePortfolioSettings();
  const { currenciesBySymbol } = useCurrenciesContext();
  const assetGetterRef = useSyncedRef(assetGetter);
  return useMemo(() => {
    const includeCashFilter = getIncludeCashFilter(includeCash, treatStablecoinsAsCash, currenciesBySymbol);
    return (asset: T): boolean => includeCashFilter(assetGetterRef.current(asset));
  }, [includeCash, treatStablecoinsAsCash, currenciesBySymbol, assetGetterRef]);
}
