import type { ICellEditorParams, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { get, set } from 'lodash';
import {
  getThresholdTypeDescription,
  prettifyThresholdTypeEnum,
  type SubAccountWindowLimit,
} from '../../../types/SubAccountWindowLimit';
import { ThresholdTypeEnum } from '../../../types/types';
import type { AgGridSearchSelectDropdownProps } from '../../AgGrid/types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const thresholdType: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellEditor: 'searchSelectDropdown',
  cellEditorPopup: true,
  suppressKeyboardEvent: params => params.event.key === 'Enter',
  cellEditorParams: (params: ICellEditorParams) => {
    const options = Object.keys(ThresholdTypeEnum);

    return {
      ...params,
      useSearchSelectParams: {
        items: options,
        getLabel: item => prettifyThresholdTypeEnum(item as ThresholdTypeEnum),
      },
    } satisfies AgGridSearchSelectDropdownProps<string>;
  },
  valueSetter: ({ newValue, data }: ValueSetterParams<Partial<SubAccountWindowLimit>>) => {
    if (data == null || column.field == null) {
      return false;
    }

    set(data, column.field, newValue);

    // We're coupling this column to the SubAccountWindowLimit type for now.
    // If we set the type to NewOrderCount, there should be no asset set. Wipe it.
    if (newValue === ThresholdTypeEnum.NewOrderCount) {
      delete data['ThresholdAsset'];
    }

    return true;
  },
  valueGetter: (params: ValueGetterParams) => {
    if (column.field == null) {
      return undefined;
    }

    return get(params.data, column.field);
  },
  valueFormatter: ({ value }: ValueFormatterParams<Partial<SubAccountWindowLimit>, ThresholdTypeEnum | undefined>) => {
    return value ? prettifyThresholdTypeEnum(value) : value ?? '';
  },
  headerComponent: 'tooltipHeader',
  headerComponentParams: {
    tooltip: (
      <>
        {/* Very bespoke so leaving like this for now */}
        <ul style={{ margin: 0, padding: 0, paddingLeft: 20 }}>
          <li>{getThresholdTypeDescription(ThresholdTypeEnum.NewOrderCount)}</li>
          <li>{getThresholdTypeDescription(ThresholdTypeEnum.NewOrderVolume)}</li>
          <li>{getThresholdTypeDescription(ThresholdTypeEnum.TotalActiveVolume)}</li>
        </ul>
      </>
    ),
  },
});
