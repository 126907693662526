import type { ICellEditorParams, ValueSetterParams } from 'ag-grid-community';
import { set } from 'lodash';
import { PositionSideTypeEnum } from '../../../types/types';
import type { AgGridSearchSelectDropdownProps } from '../../AgGrid/types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

/**
 * An editable column for PositionSideTypeEnums, also referred to as "Direction"s.
 * Can be either "Both", "Long" or "Short".
 */
export const positionDirection: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  field: 'Direction',
  cellEditor: 'searchSelectDropdown',
  cellEditorPopup: true,
  cellEditorParams: (params: ICellEditorParams) => {
    return {
      ...params,
      useSearchSelectParams: {
        items: [PositionSideTypeEnum.Both, PositionSideTypeEnum.Long, PositionSideTypeEnum.Short],
        getLabel: direction => direction,
      },
    } satisfies AgGridSearchSelectDropdownProps<PositionSideTypeEnum>;
  },
  suppressKeyboardEvent: () => true,
  valueSetter: (params: ValueSetterParams<unknown>) => {
    if (params.colDef.field == null || params.data == null) {
      // Not OK -- we need to know what field to set the new value on
      return false;
    }

    const newValue: PositionSideTypeEnum | undefined = params.newValue;
    set(params.data, params.colDef.field, newValue);
    return true;
  },
});
