import { HStack, Tab, TabList, TabSize, Tabs, useMixpanel, useTabs } from '@talos/kyoko';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import type { LayoutOptionTabProps } from './portfolioDashboard.types';
import { getPortfolioViewActions } from './stateManagement/portfolioViewLayoutSlice.hooks';
import { usePortfolioRouting } from './usePortfolioRouting';

const { updateTab } = getPortfolioViewActions();
export const PortfolioControlHeader = () => {
  const dispatch = useAppStateDispatch();
  const history = useHistory();
  const { selectedView, selectedTabIndex, selectedTab } = usePortfolioRouting();
  const tabs = useTabs<LayoutOptionTabProps>({
    initialItems: selectedView.tabs,
    initialSelectedIndex: selectedTabIndex,
  });

  const mixpanel = useMixpanel();
  useEffect(() => {
    mixpanel.track(selectedTab.mixpanelEvent);
  }, [selectedTab, mixpanel]);

  const { setItems } = tabs;
  useEffect(() => {
    setItems(selectedView.tabs);
  }, [setItems, selectedView.tabs]);

  return (
    <HStack justifyContent="start" w="100%" h="40px" background="backgroundContent" px={4}>
      <Tabs
        data-testid="portfolio-dashboard-workspace-tabs"
        style={{ overflow: 'hidden' }}
        {...tabs}
        selectedIndex={selectedTabIndex >= 0 ? selectedTabIndex : 0}
        size={TabSize.Default}
      >
        <TabList>
          {tabs.items.map((tab, index) => (
            <Tab
              key={index}
              {...tab}
              onClick={() => {
                history.push(`/portfolio${selectedView.route}${tab.route}`);
                dispatch(updateTab({ view: selectedView.value, tab }));
              }}
            />
          ))}
        </TabList>
      </Tabs>
    </HStack>
  );
};
