import { IconName, type FilterableProperty } from '@talos/kyoko';
import { useMemo } from 'react';
import { prettifySubAccount, useSubAccounts } from '../../providers/SubAccountsContext';

/**
 * This hook creates the filter property definition for Rollups.
 * This filter will filter on sub accounts type Rollup
 *
 * @returns Filter Property definition to use for Rollup filters
 */
export function useRollupsFilter() {
  const { subAccountsByName, allSubAccountRollups } = useSubAccounts();
  const subAccountsToUse = allSubAccountRollups;

  return useMemo(
    () => ({
      ...({
        key: 'SubAccounts',
        label: 'Rollup',
        icon: IconName.Users,
        control: 'multi-select',
        options: subAccountsToUse?.map(sa => sa.Name) ?? [],
        getOptionLabel: (subAccountName: string) => prettifySubAccount(subAccountName, subAccountsByName),
        dropdownOptions: {
          dropdownWidth: '300px',
          maxHeight: 300,
        },
      } as const satisfies FilterableProperty),
    }),
    [subAccountsToUse, subAccountsByName]
  );
}
