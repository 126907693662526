import {
  FilterClauseType,
  IconName,
  OrdTypeEnum,
  cleanupInitialFilterDateRange,
  filterExcludesOrderStatus,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useMarketAccountGroupsFilter,
  useMarketAccountsContext,
  useOrderStatusFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type MarketOrder,
  type OrdStatusEnum,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys, pick, values } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useIDFilter, useMarketsFilter } from '../../../hooks';
import { useMarketAccountsFilter } from '../../../hooks/filters/useMarketAccountsFilter';

export interface MarketOrderFilter extends DateRangeFilter {
  _statuses?: string[];
  Statuses?: OrdStatusEnum[];
  Markets?: string[];
  OrdTypes?: string[];
  Symbols?: string[];
  ParentOrderID?: string;
  MarketAccounts?: string[];
  MarketAccountGroups?: string[];
}

export interface UseMarketOrderFilterParams<TData extends MarketOrder> {
  source?: 'trading' | 'monitoring' | 'dealer-monitoring';
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
  includeStatusFilter: boolean;
  includeIDFilter: boolean; // Include IdFilter only if blotter table was not already filtered by some Id
}

export function useMarketOrderFilter<TData extends MarketOrder>({
  persistedBlotterTable,
  includeStatusFilter,
  includeIDFilter,
}: UseMarketOrderFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<MarketOrderFilter>((initialFilter as MarketOrderFilter) || {});

  const { marketAccountsByName } = useMarketAccountsContext();

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<MarketOrder>>(
    row => {
      const data = row.data;
      if (filterExistsAndExcludes(filter, 'OrdTypes', data, 'OrdType')) {
        return false;
      }
      if (filterExcludesOrderStatus(filter._statuses, data)) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Markets', data, 'Market')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }

      if (filter.MarketAccountGroups != null && filter.MarketAccountGroups.length > 0) {
        const marketAccountGroup = data?.MarketAccount
          ? marketAccountsByName.get(data.MarketAccount)?.Group
          : undefined;
        if (
          marketAccountGroup == null ||
          !filter.MarketAccountGroups.some(filterGroup => filterGroup === marketAccountGroup)
        ) {
          return false;
        }
      }

      return true;
    },
    [filter, marketAccountsByName]
  );

  const marketsFilter = useMarketsFilter();
  const orderStatusFilter = useOrderStatusFilter();
  const idFilter = useIDFilter();
  const symbolsFilter = useSymbolsFilter();
  const marketAccountsFilter = useMarketAccountsFilter();
  const marketAccountGroupsFilter = useMarketAccountGroupsFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      compact([
        symbolsFilter,
        marketsFilter,
        marketAccountsFilter,
        marketAccountGroupsFilter,
        {
          key: 'OrdTypes',
          label: 'Order Type',
          icon: IconName.CheckCircle,
          options: values(OrdTypeEnum),
          getOptionLabel: (option: string) => option,
        },
        includeStatusFilter ? orderStatusFilter : null,
        includeIDFilter ? { ...idFilter, key: 'ParentOrderID', label: 'Parent Order ID' } : null,
      ]),
    [
      includeStatusFilter,
      marketsFilter,
      orderStatusFilter,
      symbolsFilter,
      idFilter,
      includeIDFilter,
      marketAccountsFilter,
      marketAccountGroupsFilter,
    ]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      keys(filter).forEach(key => {
        if (key === '_start' || key === 'StartDate') {
          return;
        }
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key],
        });
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: MarketOrderFilter = removeEmptyFilters<MarketOrderFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies MarketOrderFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

type DerivedMarketOrderColumns = 'marketAccountGroup';

const colIDToFilterBuilderKeyMap = {
  Symbol: 'Symbols',
  Market: 'Markets',
  MarketAccount: 'MarketAccounts',
  OrdStatus: '_statuses',
  OrdType: 'OrdTypes',
  marketAccountGroup: 'MarketAccountGroups',
} satisfies Partial<Record<keyof MarketOrder & DerivedMarketOrderColumns, keyof MarketOrderFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof MarketOrderFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}
export function onlyServerFilterKeys(filter?: MarketOrderFilter) {
  return filter == null ? filter : pick(filter, ['StartDate', 'EndDate', 'Statuses', 'Symbols', 'ParentOrderID']);
}
