import styled, { css } from 'styled-components';
import { setAlpha } from '../../styles';
import { Box } from '../Core';

import { Icon } from '../Icons/Icon';
import { AlertVariants } from './types';

export const AlertWrapper = styled(Box)<{ isBordered: boolean; variant: AlertVariants }>`
  display: flex;
  justify-content: flex-start;
  flex: 1;

  ${({ isBordered, theme }) =>
    isBordered &&
    css`
      border-radius: 3px;
      border: solid 1px ${theme.colors.gray['050']};
    `}

  ${({ theme }) => css`
    background: ${setAlpha(0.04, theme.colors.gray['100'])};
    border: solid 1px ${theme.colors.gray['050']};
    color: ${theme.colorTextImportant};
  `}

  ${({ variant, theme }) =>
    variant === AlertVariants.Guide &&
    css`
      background: ${setAlpha(0.16, theme.colors.blue.default)};
      border: solid 1px ${setAlpha(0.04, theme.colors.blue.default)};
      color: ${theme.colorTextImportant};
    `} 


  > ${Icon}:last-child {
    cursor: pointer;
  }
`;

AlertWrapper.defaultProps = {
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flex: 1,
  gap: 'spacingDefault',
  p: 'spacingMedium',
  fontSize: 'fontSizeDefault',
};
