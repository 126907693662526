import {
  CurrencyIcon,
  LoaderTalos,
  MixpanelEvent,
  Text,
  VStack,
  getCurrencyColor,
  useDynamicCallback,
  useElementSize,
  useMarketAccountsContext,
  useMixpanel,
  type BoxProps,
  type IndexKeys,
} from '@talos/kyoko';
import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import { showByToIndexingKeys } from '../TreasuryManagementReducer';
import { useDisplayNameHelper } from '../hooks/useDisplayNameHelper';
import { useTreasuryManagementInteractions } from '../providers/TreasuryManagementInteractionsProvider';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';
import type { DrillKey, MergedBalance } from '../types';
import { D3Chart } from './D3Chart/D3Chart';
import type { ChartRing, D3ChartColor } from './D3Chart/types';

import { useChartData } from './useChartData';

const mergedBalanceValueGetter = (dblBalance: MergedBalance) => dblBalance.netEquivalentAmount;
const DEFAULT_ICON_SIZE = 24;

export const D3ChartWrapper = (boxProps: BoxProps) => {
  const theme = useTheme();
  const { elementRef, size } = useElementSize<HTMLDivElement>();
  const { state } = useTreasuryManagementContext();
  const { showBy, flattenedDrillKeys, visualization } = state;
  const { getDisplayName } = useDisplayNameHelper();
  const { goToGroupRow } = useTreasuryManagementInteractions();
  const { marketAccountsByID } = useMarketAccountsContext();
  const mixpanel = useMixpanel();
  const [highlightedSlice, setHighlightedSlice] = useState<DrillKey<MergedBalance> | undefined>(undefined);

  const handleSliceClick = useDynamicCallback((keys: DrillKey<MergedBalance>[]) => {
    mixpanel.track(MixpanelEvent.TreasuryDoughnutSliceClicked);

    let key = keys[keys.length - 1];
    if (showBy === 'counterparty') {
      // Convert market account id to market account name
      const ma = marketAccountsByID.get(+key);
      if (ma == null) {
        return;
      }
      key = ma.Name;
    }

    goToGroupRow?.(key);
  });

  const handleHighlight = useCallback((key: DrillKey<MergedBalance> | undefined) => {
    setHighlightedSlice(key);
  }, []);

  const getColor = useCallback(
    (
      key: DrillKey<MergedBalance>,
      indexKey: IndexKeys<MergedBalance>,
      value: number,
      ring?: ChartRing
    ): D3ChartColor | undefined => {
      if (indexKey === 'currency' && (value > 0 || ring === 'outer')) {
        const color = getCurrencyColor(key as string) ?? theme.colorTextAttention;
        return color ? { textColor: color, fill: color } : undefined;
      }

      if (indexKey === 'marketAccountID' && ring === 'outer') {
        const color = value > 0 ? theme.colorTextAttention : theme.colors.red.lighten;
        return color ? { textColor: color, fill: color } : undefined;
      }

      return undefined;
    },
    [theme]
  );

  const getIcon = useCallback(
    (key: DrillKey<MergedBalance>) => {
      if (!flattenedDrillKeys) {
        return undefined;
      }
      const indexKeys = showByToIndexingKeys(showBy);
      const currentIndexKey = indexKeys[flattenedDrillKeys.length];

      if (currentIndexKey === 'currency') {
        return {
          renderIcon: (size?: number) => (
            <CurrencyIcon currency={key as MergedBalance['currency']} colorful size={size ?? DEFAULT_ICON_SIZE} />
          ),
          defaultIconSize: DEFAULT_ICON_SIZE,
        };
      } else {
        return undefined;
      }
    },
    [flattenedDrillKeys, showBy]
  );

  const renderData = useChartData({
    getValue: mergedBalanceValueGetter,
    getColor,
    getIcon,
    getDisplayName,
    visualization,
  });

  const noData: boolean | undefined = renderData
    ? renderData.clockwise.data.length + renderData.counterClockwise.data.length === 0
    : undefined;

  return (
    // Need nested VStacks here since we want to apply boxProps such as padding to a different element from what the ref is attached to for getting width
    <VStack {...boxProps} w="100%" h="100%">
      <VStack ref={elementRef} w="100%" h="100%" maxHeight="550px">
        {size.clientWidth == null || renderData == null ? (
          <LoaderTalos />
        ) : noData ? (
          <Text color="colorTextAttention" fontSize="fontSizeLarge">
            No balances found
          </Text>
        ) : (
          <D3Chart
            size={size}
            renderData={renderData}
            onSliceHighlighted={handleHighlight}
            highlightedSlice={highlightedSlice}
            highlightedSlicePart={undefined} // no longer used
            showOuterRingOnSliceHighlighted={state.showBy === 'counterparty'}
            onSliceClick={handleSliceClick}
            drilled={flattenedDrillKeys.length > 0}
          />
        )}
      </VStack>
    </VStack>
  );
};
