import {
  ACTION,
  CustomerLedgerUpdateTypeEnum,
  IconName,
  getAgGridColId,
  useDefaultColumns,
  type Column,
  type ColumnDef,
  type CustomerBalance,
} from '@talos/kyoko';
import type { RowNode } from 'ag-grid-community';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useRoleAuth } from '../../../hooks';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../providers';
import { useDisplaySettings } from '../../../providers/DisplaySettingsProvider';
import type { CustomerBalancesColumn } from './types';

interface UseCustomerBalancesColumnsParams {
  defaultColumns: CustomerBalancesColumn[];
  onTransactionPrime: (customerBalance: CustomerBalance, transactionType: CustomerLedgerUpdateTypeEnum) => void;
}

export function useCustomerBalancesColumns({ defaultColumns, onTransactionPrime }: UseCustomerBalancesColumnsParams) {
  const { homeCurrency } = useDisplaySettings();
  const theme = useTheme();
  const { isAuthorized } = useRoleAuth();
  const { getConfig } = useOrgConfiguration();

  const columnDefinitions = useMemo(() => {
    return new Map<string, Column>(
      compact([
        {
          type: 'customer',
          field: 'Counterparty',
          title: 'Customer',
          width: 150,
          enableRowGroup: true,
        },
        {
          type: 'currency',
          field: 'Currency',
          width: 125,
          sortable: true,
          params: {
            colorful: true,
          },
          enableRowGroup: true,
        },
        {
          type: 'marketAccountSourceAccountID',
          field: 'MarketAccount',
          width: 150,
          enableRowGroup: true,
        },
        {
          type: 'size',
          field: 'Equivalent.Amount',
          params: { currencyField: 'Equivalent.Currency', highlightNegative: true },
          title: `Amount (${homeCurrency})`,
          width: 200,
          sortable: true,
          aggregate: true,
        },
        {
          type: 'size',
          field: 'Amount',
          params: { currencyField: 'Currency', highlightNegative: true },
          width: 200,
          sortable: true,
          aggregate: true,
        },
        {
          type: 'size',
          field: 'AvailableAmount',
          title: 'Available Amount',
          params: { currencyField: 'Currency', highlightNegative: true },
          width: 200,
          sortable: true,
          aggregate: true,
        },
        {
          type: 'size',
          field: 'OutstandingBuy',
          title: 'Outstanding Buy',
          params: { currencyField: 'Currency', highlightNegative: true },
          width: 200,
          sortable: true,
          aggregate: true,
        },
        {
          type: 'size',
          field: 'OutstandingSell',
          title: 'Outstanding Sell',
          params: { currencyField: 'Currency', highlightNegative: true },
          width: 200,
          sortable: true,
          aggregate: true,
        },
        {
          type: 'size',
          field: 'PendingWithdrawal',
          title: 'Pending Withdrawal',
          params: { currencyField: 'Currency', highlightNegative: true },
          width: 200,
          sortable: true,
          hide: true,
          aggregate: true,
        },
        {
          type: 'size',
          field: 'UnsettledAmount',
          title: 'Unsettled Amount',
          params: { currencyField: 'Currency', highlightNegative: true },
          width: 200,
          hide: true,
          sortable: true,
          aggregate: true,
        },
        isAuthorized(ACTION.DEALER_TRADING)
          ? {
              type: 'iconButton',
              id: 'deposit-button',
              pinned: 'right',
              width: 50,
              params: {
                alignment: 'rightAligned',
                onClick: ({ node }: { node: RowNode<CustomerBalance> }) => {
                  if (node.data) {
                    onTransactionPrime?.(node.data, CustomerLedgerUpdateTypeEnum.Deposit);
                  }
                },
                icon: IconName.Download,
                title: 'Deposit',
              },
              frozen: true,
              suppressColumnsToolPanel: true,
            }
          : undefined,
        isAuthorized(ACTION.DEALER_TRADING)
          ? {
              type: 'iconButton',
              id: 'withdraw-button',
              pinned: 'right',
              width: 50,
              params: {
                alignment: 'rightAligned',
                onClick: ({ node }: { node: RowNode<CustomerBalance> }) => {
                  if (node.data) {
                    onTransactionPrime?.(node.data, CustomerLedgerUpdateTypeEnum.Withdraw);
                  }
                },
                icon: IconName.Upload,
                title: 'Withdraw',
              },
              frozen: true,
              suppressColumnsToolPanel: true,
            }
          : undefined,
        isAuthorized(ACTION.EDIT_CUSTOMER_BALANCE) || getConfig(OrgConfigurationKey.AllowSetCustomerBalance, false)
          ? {
              type: 'iconButton',
              id: 'set-button',
              pinned: 'right',
              width: 50,
              params: {
                alignment: 'rightAligned',
                onClick: ({ node }: { node: RowNode }) => {
                  if (node.data) {
                    onTransactionPrime?.(node.data, CustomerLedgerUpdateTypeEnum.Set);
                  }
                },
                icon: IconName.Pencil,
                color: theme.backgroundPrimary,
                title: 'Set',
              },
              frozen: true,
              suppressColumnsToolPanel: true,
            }
          : undefined,
      ] satisfies (ColumnDef<CustomerBalance> | undefined)[]).map(c => [getAgGridColId(c), c])
    );
  }, [getConfig, homeCurrency, isAuthorized, theme.backgroundPrimary, onTransactionPrime]);

  const defaultColumnsWithButtons = useMemo(
    () => [...defaultColumns, 'withdraw-button', 'deposit-button', 'set-button'],
    [defaultColumns]
  );

  return useDefaultColumns(defaultColumnsWithButtons, columnDefinitions);
}
