import { IndicatorBadgeSizes, Text, VStack } from '@talos/kyoko';
import { useDisplaySettings } from '../../../../../providers/DisplaySettingsProvider';
import { AsOfDateBadge } from '../../../components/AsOfDateBadge';
import { portfolioAbbreviation } from '../../../portfolioAbbreviation';
import { useTreasuryManagementContext } from '../../providers/TreasuryManagementStateAndTabsProvider';
import { ChartCenterLabel } from './ChartCenterLabel';
import { ChartCenterCurrencySuffix } from './styles';
import type { D3ChartCenterProps } from './types';

// Relative sizes
const BIG_SUM_SIZE = 3.5;
const SUB_SUM_SIZE = 1.5;
const SELECT_SIZE = 1.5;

const BADGE_SCALE_SIZE_THRESHOLD = 0.62;

export const D3ChartCenter = <T,>({ renderData, highlightedInfo, scale }: D3ChartCenterProps<T>) => {
  const { homeCurrency } = useDisplaySettings();
  const {
    state: { snapshotDate },
  } = useTreasuryManagementContext();
  const bigSum = highlightedInfo ? highlightedInfo.sum : renderData.metadata.totalNetSum;

  return (
    <VStack h="100%" w="100%">
      <VStack flex="1" mb="auto" justifyContent="flex-end">
        <ChartCenterLabel renderData={renderData} highlightedInfo={highlightedInfo} labelSize={SELECT_SIZE * scale} />
      </VStack>
      <VStack flex="0">
        <Text
          color={bigSum < 0 ? 'colors.red.lighten' : 'colorTextAttention'}
          weight="fontWeightMedium"
          size={BIG_SUM_SIZE * scale}
        >
          {portfolioAbbreviation(bigSum.toString())}{' '}
          <ChartCenterCurrencySuffix>{homeCurrency}</ChartCenterCurrencySuffix>
        </Text>
      </VStack>
      <VStack flex="1" mt="auto" justifyContent="flex-start" gap="spacingDefault">
        <Text
          color="colorTextSubtle"
          size={SUB_SUM_SIZE * scale}
          style={{ visibility: highlightedInfo ? 'visible' : 'hidden' }}
        >
          / {portfolioAbbreviation(renderData.metadata.totalNetSum.toString())}{' '}
          <ChartCenterCurrencySuffix>{homeCurrency}</ChartCenterCurrencySuffix>
        </Text>
        <AsOfDateBadge
          size={scale < BADGE_SCALE_SIZE_THRESHOLD ? IndicatorBadgeSizes.Small : IndicatorBadgeSizes.Large}
          snapshotDate={snapshotDate}
        />
      </VStack>
    </VStack>
  );
};
