import {
  ACTION,
  BlotterDensity,
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  HStack,
  IconName,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Text,
  isTimeActive,
  useConstant,
  useDrawer,
  usePersistedBlotterTable,
  useRestBlotterTable,
  type Column,
  type ICustomerFIXConnection,
} from '@talos/kyoko';
import type { GridOptions, RowClassParams } from 'ag-grid-community';
import { CustomerFIXConnectionDrawer } from 'components/DetailsDrawer/Customer/CustomerFIXConnectionDrawer';
import { useRoleAuth } from 'hooks';
import { useCallback, useState } from 'react';
import { isActiveRuleColumn } from '../columns';

export function CustomerFIXConnections() {
  const { isAuthorized } = useRoleAuth();
  const [selectedCustomerFIXConnection, setSelectedCustomerFIXConnection] = useState<ICustomerFIXConnection>();

  const columns = useConstant<Column[]>([
    isActiveRuleColumn,
    {
      type: 'text',
      field: 'ConnectionID',
      width: 170,
      sortable: true,
      sort: '+',
      description: 'Unique ID of this connection',
      hide: true,
    },
    {
      type: 'text',
      field: 'Name',
      width: 180,
      sortable: true,
      sort: '-',
      description: 'Name of this connection',
    },
    {
      type: 'text',
      field: 'CustomerUser',
      width: 170,
      description: 'End user associated with this connection',
    },
    {
      type: 'text',
      field: 'ConnectionType',
      width: 200,
    },
    {
      type: 'text',
      field: 'URL',
      title: 'URL',
      width: 130,
    },
    {
      type: 'text',
      field: 'BeginString',
      width: 130,
      description: 'Usually FIX4.4',
    },
    {
      type: 'text',
      field: 'SenderCompID',
      width: 120,
      description: 'SenderCompId for this connection. For your client, this will be the TargetCompID they will use.',
    },
    {
      type: 'text',
      field: 'TargetCompID',
      width: 150,
    },
    {
      type: 'time',
      field: 'StartTime',
      width: 150,
    },
    {
      type: 'time',
      field: 'EndTime',
      width: 150,
    },
  ]);

  const customerFIXConnectionDrawer = useDrawer({
    position: 'relative',
    width: 500,
    placement: 'right',
    closeOnEscape: true,
  });

  const editCustomerFIXConnection = useCallback(
    (fixConnection?: ICustomerFIXConnection) => {
      setSelectedCustomerFIXConnection(fixConnection);
      customerFIXConnectionDrawer.open();
    },
    [customerFIXConnectionDrawer]
  );

  const getRowStyle = (params: RowClassParams<ICustomerFIXConnection>) => {
    return isTimeActive(params.data?.StartTime, params.data?.EndTime) ? undefined : { opacity: '0.8' };
  };

  const persistedTable = usePersistedBlotterTable('dealer/customer-fix-connections', { columns });
  const blotterTable = useRestBlotterTable({
    ...persistedTable,
    request: {
      path: `/organization/customer-fix-connections`,
    },
    rowID: 'TargetCompID',
    density: BlotterDensity.Comfortable,
    ...({
      getRowStyle: getRowStyle,
    } satisfies GridOptions),
  });

  const handleOnSave = useCallback(
    (forceRefresh?: boolean) => {
      blotterTable.refresh(forceRefresh);
      customerFIXConnectionDrawer.close();
    },
    [blotterTable, customerFIXConnectionDrawer]
  );

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <PanelHeader alignItems="flex-start">
          <Box>
            <h2>FIX Connections</h2>
            <Box mt="spacingDefault">
              <Text whiteSpace="break-spaces">CustomerFIX Connection Configuration</Text>
            </Box>
          </Box>
          <PanelActions>
            <Button
              startIcon={IconName.Plus}
              onClick={() => editCustomerFIXConnection()}
              variant={ButtonVariants.Positive}
              disabled={!isAuthorized(ACTION.DEALER_TRADING)}
              data-testid="customer-fix-connections-new-rule-button"
            >
              New FIX Connection
            </Button>
          </PanelActions>
        </PanelHeader>
        <PanelContent>
          <BlotterTable {...blotterTable} />
        </PanelContent>
      </Panel>
      <CustomerFIXConnectionDrawer
        {...customerFIXConnectionDrawer}
        onSave={handleOnSave}
        customerFIXConnection={selectedCustomerFIXConnection}
      />
    </HStack>
  );
}
