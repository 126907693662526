import type { IAggFuncParams } from 'ag-grid-community';
import type Big from 'big.js';

export type PriceValue =
  | {
      /** The price of the asset in units of quoteCurrency */
      price: Big | undefined;
      /** The asset which is being priced */
      asset: string | undefined;
      /** The unit of the price */
      quoteCurrency: string | undefined;
      /** Whether or not we can be aggregated */
      aggregate: boolean;
    }
  | undefined;

const EXIT_AGGREGATE: PriceValue = {
  price: undefined,
  asset: undefined,
  quoteCurrency: undefined,
  aggregate: false,
};

export function priceAggFunc({ values }: IAggFuncParams<unknown, PriceValue>): PriceValue {
  return values.reduce((total, value) => {
    if (!value?.aggregate) {
      return EXIT_AGGREGATE;
    }

    // Check that value is sufficiently populated. If these properties are not defined, we cannot perform an aggregation.
    if (value.asset == null || value.price == null || value.quoteCurrency == null) {
      return EXIT_AGGREGATE;
    }

    // Instantiate total to value, would happen on the first go
    if (!total) {
      return value;
    }

    // If there is no value for whatever reason, continue
    if (!value) {
      return total;
    }

    // Reaching this point means that total is instantiated, and value is defined.

    // If the currencies dont match at this point, exit the aggregation
    if (total.quoteCurrency !== value.quoteCurrency || total.asset !== value.asset) {
      return EXIT_AGGREGATE;
    }

    // total is defined but it wasnt instantiated to have a value, just accept the value
    if (!total.price) {
      return value;
    }

    // total.price is defined, but value.price is not. This is fine since theyre the same currency (no exit) -> continue
    if (!value.price) {
      return total;
    }

    // both total price and value price are defined, lets ensure theyre the same in order to continue. if not -> exit
    if (!total.price.eq(value.price)) {
      return EXIT_AGGREGATE;
    }

    // total and value are the exact same at this point, just proceed with value!
    return value;
  }, undefined as PriceValue);
}
