import { createSelector } from '@reduxjs/toolkit';
import { BaseField, type NumericField } from '../../../../fields';
import type { WLTradingLimitsProps } from '../../../../types';
import type { RootState } from '../types';

export const selectIsFormValid = createSelector(
  (state: RootState) => state.order.form,
  form => {
    if (!form.symbolField.hasValue) {
      return false;
    }
    if (form.viewType.value === 'rfq') {
      // for an rfq, we need symbol, qty, side and market account
      return (
        form.symbolField.hasValue &&
        form.quantityField.hasValue &&
        form.sideField.hasValue &&
        form.marketAccountField.hasValue &&
        form.quantityField.hasError === false
      );
    } else if (form.viewType.value === 'order') {
      const parametersHasError = Object.keys(form.parameters).some(key => {
        const maybeBaseField = form.parameters[key];
        if (maybeBaseField instanceof BaseField) {
          return maybeBaseField.hasInvalidValue;
        }
        return false;
      });

      const principalHasError = Object.keys(form).some(k => {
        const maybeBaseField = form[k];
        if (maybeBaseField instanceof BaseField) {
          return maybeBaseField.hasInvalidValue;
        } else {
          return false;
        }
      });
      return !principalHasError && !parametersHasError;
    }
    return false;
  }
);

export const selectLimitPrice = createSelector(
  (state: RootState) => state.order.form,
  function (form): NumericField | undefined {
    if (form.viewType.value === 'order' && 'LimitPrice' in form.parameters) {
      return form.parameters.LimitPrice as NumericField;
    }
    return undefined;
  }
);

export const selectForm = createSelector(
  (state: RootState) => state.order.form,
  form => form
);

export const selectModifiedOrder = createSelector(
  (state: RootState) => state.order.orderBeingModified,
  orderBeingModified => orderBeingModified
);
export const selectTradingLimitsValidation = createSelector(
  (state: RootState) => state.order.dependencies.tradingLimitsValidation,
  tradingLimitsValidation => tradingLimitsValidation
);

export const selectTradingLimitsArguments = createSelector(
  (state: RootState) => state.order.form.quantityField.value,
  (state: RootState) => state.order.form.orderCurrencyField.value,
  (state: RootState) => state.order.form.symbolField.value?.Symbol,
  (state: RootState) => state.order.form.marketAccountField.value?.SourceAccountID,
  (quantity, currency, symbol, marketAccountName): WLTradingLimitsProps => ({
    quantity,
    currency,
    symbol,
    marketAccountName,
  })
);

export const selectOrderStep = createSelector(
  (state: RootState) => state.order.orderStep,
  orderStep => orderStep
);
export const selectQuoteReqID = createSelector(
  (state: RootState) => state.order.quoteReqID,
  quoteReqID => quoteReqID
);
export const selectQuote = createSelector(
  (state: RootState) => state.order.quote,
  quote => quote
);

export const selectError = createSelector(
  (state: RootState) => state.order.error,
  error => error
);

export const selectSecurity = createSelector(
  (state: RootState) => state.order.form.symbolField.value,
  security => security
);
export const selectSymbol = createSelector(
  (state: RootState) => selectSecurity(state)?.Symbol,
  symbol => symbol
);

export const selectIsFormOpen = createSelector(
  (state: RootState) => state.order.isOpen,
  isOpen => isOpen
);

export const selectFocusedOrderID = createSelector(
  (state: RootState) => state.order.focusedOrderID,
  focusedOrderID => focusedOrderID
);

export const selectOrderViewType = createSelector(
  (state: RootState) => state.order.form.viewType,
  viewType => viewType
);

export const selectBalances = createSelector(
  (state: RootState) => state.order.form.marketAccountField.value,
  (state: RootState) => state.order.form.symbolField.value,
  (state: RootState) => state.order.dependencies.balances,
  (marketAccount, symbol, balances) =>
    new Map(
      (balances || [])
        .filter(
          b =>
            (b.Currency === symbol?.BaseCurrency || b.Currency === symbol?.QuoteCurrency) &&
            // For 0 balances Back-end is sending down empty MarketAccount strings
            (b.MarketAccount === marketAccount?.SourceAccountID || b.MarketAccount === '')
        )
        .map(b => [b.Currency, b])
    )
);
