import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  gray: {
    main: '#DEE2FF',
    '000': hsla(232, 0.48, 0.85, 1),
    '010': hsla(232, 0.48, 0.8, 1),
    '020': hsla(232, 0.48, 0.76, 1),
    '030': hsla(232, 0.48, 0.72, 1),
    '040': hsla(232, 0.48, 0.68, 1),
    '050': hsla(232, 0.48, 0.46, 1),
    '060': hsla(232, 0.48, 0.38, 1),
    '070': hsla(232, 0.48, 0.34, 1),
    '080': hsla(232, 0.48, 0.28, 1),
    '090': hsla(232, 0.48, 0.16, 1),
    '100': hsla(232, 0.48, 0.11, 1),
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    mute: setAlpha(0.1, '#3867ff'),
    dim: setAlpha(0.4, '#3867ff'),
    default: '#3867ff',
    lighten: '#dee2ff',
  },

  green: {
    mute: setAlpha(0.1, '#56AE84'),
    dim: setAlpha(0.4, '#56AE84'),
    default: '#337E5A',
    lighten: '#56AE84',
  },

  red: {
    mute: setAlpha(0.1, '#922E43'),
    dim: setAlpha(0.4, '#922E43'),
    default: '#922E43',
    lighten: '#D23557',
  },

  yellow: {
    mute: setAlpha(0.1, '#CCAC3E'),
    dim: setAlpha(0.4, '#CCAC3E'),
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: setAlpha(0.1, '#458CC1'),
    dim: setAlpha(0.4, '#458CC1'),
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  orange: {
    mute: 'rgba(255, 120, 0, 0.1)',
    dim: 'rgba(255, 120, 0, 0.4)',
    default: '#FF7800',
    lighten: '#FFA04D',
  },
  purple: lightColors.purple,
};

export const LightKeyrockNewTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightKRNew',

  fontFamily: "'Manrope', sans-serif",
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  spacingLayout: 8,

  backgroundInput: colors.gray.main,

  borderColorDivider: colors.gray['050'],

  backgroundPrimaryButton: colors.primary.default,
  backgroundPrimaryButtonHover: setAlpha(0.9, colors.primary.default),
  backgroundPrimaryButtonFocus: setAlpha(0.9, colors.primary.default),
  backgroundPrimaryButtonActive: setAlpha(0.99, colors.primary.default),
  backgroundPrimaryButtonDisabled: setAlpha(0.5, colors.primary.default),
});
