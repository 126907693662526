import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { Button, ButtonVariants, type ButtonProps } from '../../../Button';
import { FormControlSizes } from '../../../Form';
import { IconName } from '../../../Icons';
import { FormattedMessage } from '../../../Intl';
import type { FilterBuilderProps } from '../FilterBuilder';

const messages = defineMessages({
  clearAll: {
    defaultMessage: 'Clear All',
    id: 'Filters.FilterBuilder.clearAll',
  },
});

export type FilterBuilderClearAllButtonProps = Pick<FilterBuilderProps, 'removeAllFilterClauses'> &
  Pick<ButtonProps, 'disabled'> & {
    size?: FormControlSizes;
  };

export const FilterBuilderClearAllButton = ({
  removeAllFilterClauses,
  disabled = false,
  size = FormControlSizes.Small,
}: FilterBuilderClearAllButtonProps) => {
  const handleClearAllClick = useCallback(removeAllFilterClauses, [removeAllFilterClauses]);

  return (
    <Button
      disabled={disabled}
      size={size}
      startIcon={IconName.Clear}
      onClick={handleClearAllClick}
      variant={ButtonVariants.Default}
      data-testid="filter-builder-clear-button"
    >
      <FormattedMessage {...messages.clearAll} />
    </Button>
  );
};
