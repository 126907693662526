import {
  Box,
  ControlPrefix,
  FormControlSizes,
  FuseAutocompleteResult,
  Icon,
  IconName,
  itemIsAutocompleteGroup,
  SearchSelect,
  Tooltip,
  type AutocompleteItemsProp,
  type RenderResultFunc,
} from '@talos/kyoko';
import { useRollupTreeList } from 'hooks';
import { useSubAccounts } from 'providers';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo } from 'react';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../stateManagement/portfolioViewLayoutSlice.hooks';

const renderResult: RenderResultFunc<Item> = (searchResults, disabled) => {
  const renderRollup = () => {
    const level = searchResults.item.item.level;
    return level === 0 ? null : (
      <>
        <Box w={`${(level - 1) * 16}px`} />
        <Box pr="4px">
          <Icon icon={IconName.ChevronRight} />
        </Box>
      </>
    );
  };
  return FuseAutocompleteResult(searchResults, disabled, renderRollup);
};

interface Item {
  key: string;
  itemId: number;
  label: string;
  level: number;
  description: string;
  searchString: string;
  parents: number[];
}

const sortFilterHiererachy: AutocompleteItemsProp<Item>['sortFilterOverride'] = (fullList, searchText) => {
  const fullItemById = new Map(fullList.map(item => [item.item.item.itemId, item]));
  const filteredList = fullList.filter(item =>
    item.item.item.searchString.toLowerCase().includes(searchText.toLowerCase())
  );
  const sortedList = filteredList.sort((fuseA, fuseB) => {
    const a = fuseA.item.item;
    const b = fuseB.item.item;
    const aSearch = a.searchString.toLowerCase();
    const bSearch = b.searchString.toLowerCase();
    const search = searchText.toLowerCase();
    const startWithA = aSearch.startsWith(search);
    const startWithB = bSearch.startsWith(search);
    if (startWithA && !startWithB) {
      return -1;
    }
    if (!startWithA && startWithB) {
      return 1;
    }
    const aIndex = aSearch.indexOf(search) ?? -1;
    const bIndex = bSearch.indexOf(search) ?? -1;
    if (aIndex > -1 || bIndex > -1) {
      return aIndex - bIndex;
    }

    return aSearch.localeCompare(bSearch);
  });

  // build final result with parents
  const finalResult = sortedList.reduce((result, next) => {
    // if the items's parent are not in the list, add them
    const parents = next.item.item.parents;
    const parentItems = parents.map(parentId => fullItemById.get(parentId)).compact();
    const parentItemsToInsert = parentItems.filter(
      item => !result.find(r => r.item.item.itemId === item.item.item.itemId)
    );
    result.push(...parentItemsToInsert);
    result.push(next);

    return result;
  }, [] as typeof sortedList);
  return finalResult;
};

const { changeSelectedPortfolioId } = getPortfolioViewActions();
export const RollupSubAccountSelect = () => {
  const { subAccountsByID } = useSubAccounts();
  const rollupTree = useRollupTreeList();
  const dispatch = useAppStateDispatch();
  const { selectedPortfolioId } = usePortfolioViewStateSelector();
  const options = useMemo(() => {
    const newOptions = (rollupTree ?? [])
      .map<Item | undefined>(hierarchyNode => {
        const subAccountResolved = subAccountsByID.get(hierarchyNode.subAccountId);
        return !subAccountResolved
          ? undefined
          : {
              key: hierarchyNode.key,
              level: hierarchyNode.level,
              itemId: subAccountResolved.SubaccountID,
              label: subAccountResolved.DisplayName,
              description: subAccountResolved.Type,
              searchString: hierarchyNode.searchDescription,
              parents: hierarchyNode.parents,
            };
      })
      .compact();
    return newOptions;
  }, [rollupTree, subAccountsByID]);

  const currentSelection = useMemo(
    () => options.find(option => option.itemId === selectedPortfolioId),
    [options, selectedPortfolioId]
  );

  return (
    <Box w="300px">
      <SearchSelect
        data-testid="rollup-subaccount-select"
        prefix={
          <Tooltip tooltip="Book or Rollup to represent as the selected Portfolio">
            <ControlPrefix>Portfolio:</ControlPrefix>
          </Tooltip>
        }
        placeholder="Select..."
        selection={currentSelection}
        renderResult={renderResult}
        initialSortByLabel={false}
        dropdownWidth="300px"
        size={FormControlSizes.Small}
        getLabel={item => item.label}
        getDescription={item => item.description}
        showDescriptionInButton
        fuseDistance={100}
        disableFuzzyMatching
        sortFilterOverride={sortFilterHiererachy}
        options={options}
        scrollSelectionIntoViewIndex={(scrollItems, item) =>
          scrollItems.findIndex(i => (itemIsAutocompleteGroup(i) ? 0 : i.itemId === item.itemId))
        }
        onChange={selectedItem => {
          selectedItem &&
            dispatch(
              changeSelectedPortfolioId({
                selectedPortfolioId: selectedItem.itemId,
              })
            );
        }}
      />
    </Box>
  );
};
