import { Box, Flex, Grid } from '@talos/kyoko';
import styled, { css } from 'styled-components';

export const DetailsWrapper = styled(Box)`
  scrollbar-gutter: stable;
`;
DetailsWrapper.defaultProps = {
  overflow: 'auto',
  p: 'spacingComfortable',
  pr: 'spacingTiny',
  background: 'backgroundContent',
  flex: 1,
  h: '100%',
};

export const GridItem = styled(Flex)``;
GridItem.defaultProps = {
  flexDirection: 'column',
  gap: 'spacingDefault',
};

export type GridSize = 'sm' | 'md' | 'lg';

export const CustomGridLayout = styled(Grid)<{ size: GridSize; isOrderMultiLeg: boolean }>`
  ${({ size, isOrderMultiLeg }) => {
    switch (size) {
      case 'lg':
        return css`
          grid-template-areas:
            '${`${isOrderMultiLeg ? 'multileg' : 'general'} order timeline`}'
            'general order timeline'
            'general order timeline';
          grid-template-rows: min-content min-content 1fr;
          grid-template-columns: 1fr 1fr 1fr;
        `;

      case 'md':
        return css`
          grid-template-areas:
            '${`${isOrderMultiLeg ? 'multileg multileg' : 'general order'} timeline`}'
            'general order timeline'
            'general order timeline';
          grid-template-rows: min-content min-content 1fr;
          grid-template-columns: 1fr 1fr 1fr;
        `;

      case 'sm':
        return css`
          grid-template-areas:
            '${`${isOrderMultiLeg ? 'multileg' : 'general'} timeline`}'
            'general timeline'
            'order timeline';
          grid-template-rows: min-content min-content 1fr;
          grid-template-columns: 1fr 1fr;
        `;
    }
  }}
`;
CustomGridLayout.defaultProps = {
  gap: 'spacingDefault',
  minHeight: '100%',
};
