import type { ICellRendererParams } from 'ag-grid-community';
import Big from 'big.js';
import { useEffect } from 'react';
import type { MarketDataStatistics } from '../../../hooks';
import { OptionTypeEnum } from '../../../types';
import { EMPTY_OBJECT } from '../../../utils';
import { VStack } from '../../Core';
import { InlineFormattedNumber, NumberVariants } from '../../FormattedNumber';

export type MarkObject = Pick<MarketDataStatistics, 'MarkPrice' | 'MarkPriceIV'>;
export type GridOptionMarkParams = { currency: string | undefined };
export const AG_GRID_OPTION_MARK_KEY = 'optionMark';

export function AgGridOptionMark(params: ICellRendererParams<unknown, MarkObject> & GridOptionMarkParams) {
  const { MarkPriceIV, MarkPrice } = params.value || EMPTY_OBJECT;
  const markPriceCurrency = params.currency;

  useEffect(() => {
    const type = params?.colDef?.field?.startsWith('Call.') ? OptionTypeEnum.Call : OptionTypeEnum.Put;
    params.api.refreshCells({
      columns: [`${type}.BidPrice`, `${type}.OfferPrice`],
      force: true,
      suppressFlash: true,
      rowNodes: [params.node],
    });
  }, [MarkPrice, params.api, params.node, params.colDef]);

  return (
    <VStack alignItems="flex-end">
      <InlineFormattedNumber number={MarkPrice} currency={markPriceCurrency} />
      <InlineFormattedNumber
        variant={NumberVariants.Muted}
        number={MarkPriceIV ? Big(MarkPriceIV).mul(100) : MarkPriceIV}
        currency="%"
        increment="0.01"
      />
    </VStack>
  );
}
