import {
  ACTION,
  Box,
  Button,
  ButtonVariants,
  Flex,
  FormGroup,
  FormRowStatus,
  FormTable,
  IconName,
  ModeEnum,
  MultiSelect,
  NotificationVariants,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  useMarketsContext,
  type Column,
  type UseFormTable,
  type User,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRoleAuth } from '../../../../hooks';
import type { IEditUserDrawerTab } from '../types';
import { useUserSettings } from '../useUserSettings';

type UserMarketSessionLimitRow = {
  LimitID?: string;
  Market: string;
  Limit: string;
  Mode: ModeEnum;
};

interface EditMarketSessionLimitTabProps {
  formTable: UseFormTable<UserMarketSessionLimitRow>;
  onAddMarkets: (newMarkets: string[]) => void;
}

function EditMarketSessionLimitTab({ formTable, onAddMarkets }: EditMarketSessionLimitTabProps) {
  const { marketsList, marketsByName } = useMarketsContext();

  const options = useMemo(() => {
    const rows = formTable.getRows();
    return marketsList.filter(market => !rows.some(row => row.data.Market === market.Name)).map(market => market.Name);
  }, [marketsList, formTable]);

  const [selections, setSelections] = useState<string[]>([]);

  const getLabel = useCallback(
    (marketName: string) => {
      return marketsByName.get(marketName)?.DisplayName ?? marketName;
    },
    [marketsByName]
  );

  return (
    <Flex flexDirection="column" gap="spacingMedium" w="100%" h="100%">
      <Flex flexDirection="column" gap="spacingMedium">
        <FormGroup label="Markets" controlId="markets">
          <Flex gap="spacingSmall" w="100%">
            <Box flex="1">
              <MultiSelect
                selections={selections}
                options={options}
                placeholder="Search a market"
                onChange={selections => setSelections(selections)}
                getLabel={getLabel}
              />
            </Box>
            <Button
              variant={ButtonVariants.Primary}
              startIcon={IconName.Plus}
              disabled={selections.length === 0}
              onClick={() => {
                onAddMarkets(selections);
                setSelections([]);
              }}
            >
              Add
            </Button>
          </Flex>
        </FormGroup>
      </Flex>
      <FormTable promptIfUnsavedChanges={false} background="transparent" {...formTable} />
    </Flex>
  );
}

export function useEditMarketSessionLimitTab({ user }: { user: User }): IEditUserDrawerTab {
  const { add: addToast } = useGlobalToasts();
  const { getMarketSessionLimit, addMarketSessionLimit, updateMarketSessionLimit, removeMarketSessionLimit } =
    useUserSettings();

  const columns = useMemo<Column[]>(
    () =>
      compact([
        {
          sort: '+',
          minWidth: 300,
          width: 300,
          suppressMenu: true,
          sortIndex: 0,
          field: 'Market',
          type: 'market',
        },
        {
          type: 'toggle',
          field: 'Mode',
          title: 'Enabled',
          width: 80,
        },
        {
          type: 'custom',
          field: 'Limit',
          cellEditor: 'input',
          editable: true,
        },
        { type: 'remove', frozen: true, id: 'remove', pinned: 'right', title: '', suppressMenu: true },
      ]),
    []
  );

  const [userMarketSessionLimitRows, setUserMarketSessionLimitRows] = useState<UserMarketSessionLimitRow[]>([]);

  const formTable = useFormTable<UserMarketSessionLimitRow>({
    rowID: 'Market',
    data: userMarketSessionLimitRows,
    columns,
  });

  const resetTable = useDynamicCallback(formTable.reset);

  const loadData = useCallback(() => {
    getMarketSessionLimit(user).then(result => {
      setUserMarketSessionLimitRows(
        result.map(row => ({
          LimitID: row.LimitID,
          Market: row.Market,
          Limit: row.ViewLimit,
          Mode: row.Mode,
        }))
      );
    });
  }, [user, getMarketSessionLimit]);

  useEffect(() => {
    if (!formTable.isDirty) {
      resetTable(userMarketSessionLimitRows);
    }
  }, [formTable.isDirty, resetTable, userMarketSessionLimitRows]);

  const onAddMarkets = useCallback(
    (newMarkets: string[]) => {
      for (const market of newMarkets) {
        formTable.addRow({
          Market: market,
          Mode: ModeEnum.Enabled,
          Limit: '1',
        });
      }
    },
    [formTable]
  );

  const handleSaveChanges = useDynamicCallback(() => {
    if (!formTable.isDirty) {
      return;
    }

    const rows = formTable.getRows();
    const promises: Promise<any>[] = [];
    for (const row of rows) {
      const limit = parseInt(row.data.Limit);
      if (row.status === FormRowStatus.Added && limit > 0) {
        promises.push(addMarketSessionLimit(user, row.data.Market, limit, row.data.Mode));
      }
      if (row.status === FormRowStatus.Removed && row.data.LimitID) {
        promises.push(removeMarketSessionLimit(row.data.LimitID));
      }
      if (row.status === FormRowStatus.Updated && row.data.LimitID && limit > 0) {
        promises.push(updateMarketSessionLimit(row.data.LimitID, limit, row.data.Mode));
      }
    }

    return Promise.all(promises)
      .catch((e: Error) => {
        addToast({
          text: `Failed to update Market Session Limits: ${e.message}`,
          variant: NotificationVariants.Negative,
        });
      })
      .finally(() => {
        resetTable();
        loadData();
      });
  });

  useEffect(() => {
    loadData();
  }, [loadData]);

  const component = useMemo(() => {
    return <EditMarketSessionLimitTab formTable={formTable} onAddMarkets={onAddMarkets} />;
  }, [formTable, onAddMarkets]);

  const { isAuthorized } = useRoleAuth();
  const canEditMarketSessionLimit = isAuthorized(ACTION.EDIT_MARKET_SESSION_LIMIT);

  return {
    name: 'Market Session Limits',
    component,
    viewable: canEditMarketSessionLimit,
    save: handleSaveChanges,
    isDirty: formTable.isDirty,
    renderReasonInput: false,
  };
}
