import { Button, ButtonVariants } from '../Button';
import { FormControlSizes } from '../Form/types';
import { Container } from './styles';

export type QuickOptionsProps<T> = {
  options: {
    label: string;
    value: T;
  }[];
  onOptionClick: (value: T) => void;
  disabled?: boolean;
  tabbable?: boolean;
};

export const QuickOptions = <T,>({
  options,
  onOptionClick,
  disabled = false,
  tabbable = false,
}: QuickOptionsProps<T>) => {
  return (
    <Container>
      {options.map((option, i) => (
        <Button
          key={i}
          data-testid={`QuickOptions-${option.label}`}
          onClick={() => onOptionClick(option.value)}
          variant={ButtonVariants.Default}
          size={FormControlSizes.Tiny}
          disabled={disabled}
          tabIndex={tabbable ? undefined : -1}
        >
          {option.label}
        </Button>
      ))}
    </Container>
  );
};
