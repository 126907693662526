import {
  FilterClauseType,
  IconName,
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  removeEmptyFilters,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type FilterClause,
  type FilterableProperty,
  type MarketAccount,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { isEqual, keys, values } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useMarketAccountsFilter, useMarketsFilter } from '../../../hooks';
import { useConnectionStatusFilter } from '../../../hooks/filters/useConnectionStatusFilter';
import { useMarketTypesFilter } from '../../../hooks/filters/useMarketTypesFilter';
import type { TradingControlsBlotterEntity } from './types';

export interface TradingControlsBlotterTableFilter {
  Markets?: string[];
  MarketAccounts?: string[];
  Status?: string[];
  ConnectionStatuses?: string[];
  MarketTypes?: string[];
  ShowInactiveMarketAccounts?: boolean;
}

interface useTradingControlsFilterProps<TData extends TradingControlsBlotterEntity> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}

const getMarketAccountStatusLabel = (status: string) => {
  switch (status) {
    case MarketAccountStatusEnum.Active:
      return 'Enabled';
    case MarketAccountStatusEnum.Inactive:
      return 'Inactive';
    case MarketAccountStatusEnum.Disabled:
      return 'Disabled';
    default:
      return '';
  }
};

const isMarketAccountTrading = (ma: MarketAccount) => ma.Type === MarketAccountTypeEnum.Trading;

export const useTradingControlsFilter = <TData extends TradingControlsBlotterEntity>(
  props: useTradingControlsFilterProps<TData>
) => {
  const { persistedBlotterTable } = props;
  const { initialFilter, onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<TradingControlsBlotterTableFilter>(() =>
    cleanupInitialFilterDateRange(initialFilter)
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TradingControlsBlotterEntity>>(
    row => {
      const data = row.data;

      if (filterExistsAndExcludes(filter, 'Markets', data, 'Market')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'Name')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Status', data, 'Status')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'ConnectionStatuses', data, 'ConnectionStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'MarketTypes', data, 'MarketType')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const marketsFilter = useMarketsFilter();
  const marketAccountsFilter = useMarketAccountsFilter({ customOptionsFilter: isMarketAccountTrading });
  const connectionStatusFilter = useConnectionStatusFilter();
  const marketTypesFilter = useMarketTypesFilter();
  const filterableProperties: FilterableProperty[] = useMemo(
    () => [
      marketsFilter,
      marketAccountsFilter,
      { ...connectionStatusFilter, key: 'ConnectionStatuses' },
      {
        key: 'Status',
        label: 'Trading',
        control: 'single-select',
        icon: IconName.CheckCircle,
        options: values(MarketAccountStatusEnum),
        getOptionLabel: getMarketAccountStatusLabel,
      },
      { ...marketTypesFilter, key: 'MarketTypes', label: 'Market Type' },
    ],
    [marketsFilter, marketAccountsFilter, connectionStatusFilter, marketTypesFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof TradingControlsBlotterTableFilter)[]).forEach(
        (key: keyof TradingControlsBlotterTableFilter) => {
          clauses.push({
            key: key,
            type: FilterClauseType.INCLUSIVE,
            selections: filter[key] as string[],
          });
        }
      );
    }
    return clauses;
  }, [filter]);

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<TradingControlsBlotterTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as TradingControlsBlotterTableFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;

  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    filterBuilderProps,
  };
};

export function colIDToFilterBuilderKey(id: string): keyof TradingControlsBlotterTableFilter | undefined {
  switch (id as keyof TradingControlsBlotterEntity) {
    case 'Market':
      return 'Markets';
    case 'Name':
      return 'MarketAccounts';
    case 'Status':
      return 'Status';
    case 'ConnectionStatus':
      return 'ConnectionStatuses';
    case 'MarketType':
      return 'MarketTypes';
  }

  return undefined;
}
