import { getAgGridColId, useDefaultColumns, type Column, type ColumnDef, type CustomerTransaction } from '@talos/kyoko';
import { useMemo } from 'react';
import type { CustomerTransactionsColumn } from './types';

export const useCustomerTransactionColumns = (defaultColumns: CustomerTransactionsColumn[]) => {
  const columnDefinitions = useMemo(() => {
    return new Map<string, Column>(
      (
        [
          { type: 'date', field: 'Timestamp', width: 200, sortable: true, sort: '-' },
          { type: 'customer', field: 'Counterparty', title: 'Customer', width: 200 },
          { type: 'marketAccountSourceAccountID', field: 'MarketAccount' },
          { type: 'currency', field: 'Currency', width: 150, params: { colorful: true } },
          { type: 'size', field: 'Quantity', params: { currencyField: 'Currency' }, width: 150 },
          { type: 'transactionType', field: 'TransactionType', width: 150, title: 'Type' },
          { type: 'transferStatus', field: 'Status', width: 225 },
          { type: 'text', field: 'ExternalComments', title: 'External Comment' },
          { type: 'text', field: 'TransactionID', hide: true },
          { type: 'text', field: 'CustomerAddressID', hide: true },
        ] satisfies ColumnDef<CustomerTransaction>[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, []);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};
