import { useCallback, useEffect, type PropsWithChildren, type ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from '../../hooks/usePortal';

export interface PortalProps {
  portalId: string;
}

export const Portal = ({ children, portalId }: PropsWithChildren<PortalProps>) => {
  const { portalRef } = usePortal(portalId);
  // Used for injecting the filters + action items in the parent container
  const PortalCallback = useCallback(
    ({ children }: PropsWithChildren<any>): ReactPortal | null => {
      if (portalRef == null) {
        return null;
      }
      return createPortal(children, portalRef);
    },
    [portalRef]
  );

  return <PortalCallback>{children}</PortalCallback>;
};

export const useTopLevelPortalElement = (portalId: string) => {
  const { portalRef, setPortalRef } = usePortal(portalId);
  useEffect(() => {
    if (!portalRef) {
      let parentElem = document.querySelector<HTMLDivElement>(`#${portalId}`);

      if (!parentElem) {
        parentElem = document.createElement('div');
        parentElem.setAttribute('id', portalId);

        // Simple workaround for preventing custom date filter to close when selecting a date:
        // @see https://www.ag-grid.com/react-grid/component-filter/#custom-filters-containing-a-popup-element
        parentElem.setAttribute('class', 'ag-custom-component-popup');

        document.body.insertBefore(parentElem, document?.body?.lastElementChild?.nextElementSibling ?? null);
      }
      setPortalRef(parentElem);
    }
  }, [portalId, portalRef, setPortalRef]);
};
