import {
  Box,
  HStack,
  LineChart,
  LoaderTalos,
  getCurrencyColor,
  getNavigatorColor,
  logger,
  renderToHTML,
  useConstant,
  useDynamicCallback,
  useHighchartsRef,
  useObservableValue,
  type Currency,
} from '@talos/kyoko';
import { useHomeCurrency } from 'hooks';
import { compact, toNumber } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { debounceTime, tap } from 'rxjs';
import { useTheme, type DefaultTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useSubAccounts } from '../../../../providers';
import { useChartPlotline } from '../../PortfolioChart/useChartPlotline';
import { AS_OF_PLOTLINE_ID, DYNAMIC_CHART_Z_INDEXES, getPointUnderlyingData } from '../../PortfolioChart/utils';
import { RESOLUTION_METADATA, dateRangeFilterToResolution } from '../../SelectableResolution';
import {
  ChartMessageType,
  ChartMessagingProvider,
  useChartDateRangeObs,
  useChartMessaging,
} from '../../providers/ChartMessagingProvider';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceInteractions } from '../providers/PerformanceInteractionsProvider';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import { ALL_SUB_ACCOUNTS_PLACEHOLDER } from '../tokens';
import { isPeriodCustomPeriod, periodToChartDateRange, type CustomPeriod, type Period, type PnLUnit } from '../types';
import { BenchmarkSeriesTooltip } from './BenchmarkSeriesTooltip';
import { DeltaSeriesTooltip } from './DeltaSeriesTooltip';
import { Insights } from './Insights';
import { PerformanceSeriesTooltip } from './PerformanceSeriesTooltip';
import { InsightsContainer } from './styles';
import { BENCHMARK_SERIES_NAME, DELTA_SERIES_NAME, NAVIGATOR_SERIES_NAME, PERFORMANCE_SERIES_NAME } from './tokens';
import { useHistoricalPerformanceData } from './useHistoricalPerformanceData';
import { useLifeToDateStartDate } from './useLifeToDateStartDate';

// Overscroll applied to the chart x axis
// 0 for now since its weird
const OVERSCROLL = 0;

function InnerHistoricalPerformanceChart() {
  const theme = useTheme();
  const homeCurrencyInfo = useHomeCurrency();
  const { chartRef, isLoaded, setChartObject } = useHighchartsRef();
  const { dataLoading, dataResetting, currentDataPointsByTsRef } = useHistoricalPerformanceData({ isLoaded, chartRef });
  const { subAccountsByName } = useSubAccounts();

  const { messenger } = useChartMessaging();

  const {
    state: { snapshotDate, chartRequestDateRange, pnlUnit, showDelta, benchmarks, subAccounts, showInsights, period },
    dispatch,
  } = usePerformanceContext();

  const requestResolution = useMemo(() => {
    return dateRangeFilterToResolution(chartRequestDateRange);
  }, [chartRequestDateRange]);

  const { initialPlotline } = useChartPlotline({ isLoaded, chartRef, snapshotDate, plotlineID: AS_OF_PLOTLINE_ID });

  const handlePointClick = useDynamicCallback((point: Highcharts.Point) => {
    if (chartRef.current == null) {
      return;
    }

    const clickedExistingPlotLinePoint = snapshotDate != null && new Date(snapshotDate).getTime() === point.x;

    dispatch({
      type: PerformanceActionType.SnapshotDateChange,
      payload: {
        // Delete plotline (setting page to show "Now") if you clicked a point which already had a plotline
        snapshotDate: clickedExistingPlotLinePoint ? null : new Date(point.x),
      },
    });
  });

  const getTooltip = useDynamicCallback(
    (data: Highcharts.TooltipFormatterContextObject, tooltip: Highcharts.Tooltip) => {
      // The tooltip is shared so data contains an array of points and we return one tooltip each for these

      if (!data.points) {
        return [];
      }

      const tooltips = data.points.map(point => {
        const pointRawData = getPointUnderlyingData(point.point);

        if (!pointRawData) {
          return '';
        }
        const firstDataPointX = pointRawData[0][0]; // type is [x, y][]

        const maybeSubAccount = subAccounts.length === 1 ? subAccounts[0] : undefined;
        const subAccountLabel = maybeSubAccount
          ? subAccountsByName?.get(maybeSubAccount)?.DisplayName ?? maybeSubAccount
          : ALL_SUB_ACCOUNTS_PLACEHOLDER;

        const positionStat = firstDataPointX
          ? currentDataPointsByTsRef.current.get(toNumber(firstDataPointX))
          : undefined;
        if (!positionStat) {
          logger.error(new Error(`Unable to resolve hovered point to HistoricalPositionStat in Performance chart`), {
            extra: {
              x: firstDataPointX,
            },
          });

          return '';
        }

        if (point.series.name === PERFORMANCE_SERIES_NAME) {
          return renderToHTML(
            <PerformanceSeriesTooltip
              point={point}
              positionStat={positionStat}
              homeCurrencyInfo={homeCurrencyInfo}
              dateFormatter={RESOLUTION_METADATA[requestResolution].dateFormatter}
              plotLineX={snapshotDate ? new Date(snapshotDate).getTime() : undefined}
              pnlUnit={pnlUnit}
              subAccountLabel={subAccountLabel}
            />,
            theme
          );
        }

        if (point.series.name === DELTA_SERIES_NAME) {
          const previousPoint: Highcharts.Point | undefined = getPreviousPoint(point);

          const previousPointFirstX = getPointUnderlyingData(previousPoint)?.[0]?.[0];
          const previousStat = previousPointFirstX
            ? currentDataPointsByTsRef.current.get(previousPointFirstX)
            : undefined;

          return renderToHTML(
            <DeltaSeriesTooltip
              point={point}
              positionStat={positionStat}
              previousPositionStat={previousStat}
              homeCurrencyInfo={homeCurrencyInfo}
            />,
            theme
          );
        }

        if (point.series.name === BENCHMARK_SERIES_NAME) {
          return renderToHTML(<BenchmarkSeriesTooltip point={point} />, theme);
        }

        return '';
      });

      // We are using split tooltips. When we do this, we need to provide a tooltip entry for each given point,
      // as well as html for the bottom x-axis time tooltip in the beginning of the array.
      // What I do here is just return empty html for the x-axis tooltip so it doesnt render.
      return ['', ...tooltips];
    }
  );

  const initialShowDelta = useConstant(showDelta);
  const initialYAxes = useConstant(getYAxes(theme, pnlUnit, showDelta, homeCurrencyInfo));
  const initialXAxis = useConstant(getXAxis(period, initialPlotline));
  const showBenchmarkSeries = pnlUnit === 'percentage' && benchmarks.length === 1;
  const initialShowBenchmarkSeries = useConstant(showBenchmarkSeries);

  const options = useMemo(() => {
    return {
      chart: {
        animation: false,
        zooming: {
          mouseWheel: {
            enabled: true,
          },
        },
        events: {
          click: function (event) {
            if (this.hoverPoint) {
              handlePointClick(this.hoverPoint);
            }
          },
        },
      },
      plotOptions: {
        series: {
          dataGrouping: {
            // Crucial option, this says that when we group we display the values of the first point in each resulting group of points
            // Tooltips etc assume that this is the case, as they look up the x-value of the group (which is the x value of the first point in the group)
            approximation: 'open',
            units: [
              ['minute', [15, 30]],
              ['hour', [1, 2, 3, 4, 6, 12]],
              ['day', [1]],
            ],
            // This setting anchors the first grouped data point to the timestamp (x value) of the first underlying data point.
            // For example, if Highcharts is grouping data on the hour, but our data starts at say 15 minutes past the full hour, this option
            // ensures that the first data point is at 10:15 as opposed to 10:00 (which would be misleading)
            firstAnchor: 'firstPoint',
          },
          point: {
            events: {
              click: function () {
                handlePointClick(this);
              },
            },
          },
        },
      },
      tooltip: {
        split: true,
        useHTML: true,
        outside: true,
        formatter: function (tooltip: Highcharts.Tooltip) {
          return getTooltip(this, tooltip);
        },
      },
      legend: {
        enabled: false,
      },
      xAxis: initialXAxis,
      yAxis: initialYAxes,
      navigator: {
        enabled: true,
        xAxis: {
          labels: {
            format: '{value:%e %b %y}',
          },
          plotLines: initialPlotline,
          overscroll: OVERSCROLL,
        },
        adaptToUpdatedData: false,
        series: [
          {
            name: NAVIGATOR_SERIES_NAME,
            data: [],
            type: 'line',
            color: getNavigatorColor(theme),
          },
        ],
      },
      series: [
        {
          type: 'line',
          data: [],
          animation: false,
          name: PERFORMANCE_SERIES_NAME,
          color: theme.colorDataBlue,

          navigatorOptions: {
            color: theme.colors.gray['070'],
          },
          lineWidth: 2,
          showInNavigator: false,
          zIndex: DYNAMIC_CHART_Z_INDEXES.series,
          states: {
            hover: {
              lineWidth: 2,
            },
            inactive: {
              enabled: false,
            },
          },
          marker: {
            enabled: false,
          },
        },
        {
          type: 'column',
          visible: initialShowDelta,
          name: DELTA_SERIES_NAME,
          data: [],
          yAxis: 1,
          showInNavigator: false,
          // setting inactive to enabled: false makes it so that it doesnt change appearance
          // when you interact with / click on the other series
          states: {
            inactive: {
              enabled: false,
            },
          },
          zones: [
            {
              value: 0,
              color: theme.colors.red.default,
            },
            {
              color: theme.colors.green.default,
            },
          ],
        },
        {
          visible: initialShowBenchmarkSeries,
          type: 'line',
          name: BENCHMARK_SERIES_NAME,
          yAxis: 0,
          animation: false,
          data: [],
          color: getCurrencyColor('BTC'),
          dashStyle: 'Dash',
          showInNavigator: false,
          lineWidth: 2,
          zIndex: DYNAMIC_CHART_Z_INDEXES.series,
          states: {
            hover: {
              lineWidth: 2,
            },
            inactive: {
              enabled: false,
            },
          },
          marker: {
            enabled: false,
          },
        },
      ],
    } satisfies Highcharts.Options;
  }, [
    initialPlotline,
    theme,
    handlePointClick,
    getTooltip,
    initialYAxes,
    initialXAxis,
    initialShowBenchmarkSeries,
    initialShowDelta,
  ]);

  // In these two bottom effects, we mutate the options of our chart through the API instead
  // of just re-computing the options object above since that has other knock-on re-rendering effects
  // which makes charts janky / things disappear... just more difficult to work with. This below is easier.
  useUpdateEffect(() => {
    if (!isLoaded) {
      return;
    }
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    chart.series.find(s => s.name === DELTA_SERIES_NAME)?.setVisible(showDelta);
    chart.update({ yAxis: getYAxes(theme, pnlUnit, showDelta, homeCurrencyInfo) });
  }, [pnlUnit, showDelta, theme, homeCurrencyInfo]);

  // Reflect the change in "showBenchmarkSeries" change in the visibility of the series in the chart itself
  useUpdateEffect(() => {
    if (!isLoaded) {
      return;
    }
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    chart.series.find(serie => serie.name === BENCHMARK_SERIES_NAME)?.setVisible(showBenchmarkSeries);
  }, [showBenchmarkSeries]);

  // Whenever the period changes, we recompute the xAxis to reflect the new selected time period.
  // Most importantly it sets new correct min and max values on the axis so we're showing the new selected time period.
  useUpdateEffect(() => {
    if (!isLoaded) {
      return;
    }
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    chart.update({ xAxis: getXAxis(period) });
  }, [period]);

  //Whenever the xAxis extremes change, we send a message to our neighbors that this has occurred
  const handleXAxisExtremesChange = useDynamicCallback(
    (axis: Highcharts.Axis, event: Highcharts.AxisSetExtremesEventObject) => {
      if (event.dataMax == null && event.dataMin == null) {
        // If there is no data in the chart, we don't care about any of the events here.
        return true;
      }

      // If the selected period has an enddate, then we take the min of the period's end date and the user's zoom.
      // Else, we allow max to be undefined (signifying that we will be receiving live data from BE)
      const selectedPeriodHasEndPoint = !!periodToChartDateRange(period)?.EndDate;
      const max =
        selectedPeriodHasEndPoint && axis.max != null
          ? Math.min(axis.max, event.max)
          : event.max >= event.dataMax
          ? undefined
          : event.max;

      messenger.next({
        type: ChartMessageType.VisibleAreaChanged,
        payload: {
          min: event.min,
          max: max,
        },
      });
    }
  );

  const chartDateRangeObs = useChartDateRangeObs();
  // A notifier observable, which dispatches debounced date range changes to the top-level reducer
  useObservableValue(
    () =>
      chartDateRangeObs.pipe(
        debounceTime(200),
        tap(({ StartDate, EndDate }) => {
          dispatch({
            type: PerformanceActionType.ChartRequestDateRangeChange,
            payload: {
              chartRequestDateRange: {
                StartDate: StartDate,
                EndDate: EndDate,
              },
            },
          });
        })
      ),
    [chartDateRangeObs, dispatch]
  );

  const { setZoomOutChart } = usePerformanceInteractions();
  // Register chart zoom out with the interactions provider
  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
    const zoomOut = () => chart?.zoomOut();
    setZoomOutChart(() => zoomOut);
  }, [chartRef, isLoaded, setZoomOutChart]);

  // When data is resetting, we want to render a big blocking overlay over the entire chart area
  const showLoading = dataResetting;
  // If data is not resetting, just loading more data, show the loading corner icon
  const showLoadingCornerIcon = dataLoading && !showLoading;

  return (
    <>
      <HStack
        w="100%"
        h="100%"
        position="relative"
        alignItems="flex-start"
        py="spacingComfortable"
        px="spacingLarge"
        gap="spacingLarge"
      >
        <Box h="100%" w="100%" position="relative" overflow="hidden" maxHeight="800px">
          <LineChart
            throttleResizing={0}
            onChartCreated={setChartObject}
            isLoading={showLoading}
            showOverlayWhenLoading
            showLoadingCornerIcon={showLoadingCornerIcon}
            options={options}
            onXAxisAfterSetExtremes={handleXAxisExtremesChange}
            showResetZoomButton={false} // we have our own
          />
        </Box>
        {showInsights && (
          <InsightsContainer flex="1" minWidth="230px">
            <Insights chart={chartRef.current} currentDataPointsByTsRef={currentDataPointsByTsRef} />
          </InsightsContainer>
        )}
      </HStack>
    </>
  );
}

export function HistoricalPerformanceChart() {
  const {
    state: { period, chartRequestDateRange },
  } = usePerformanceContext();

  const periodKey = useMemo(() => {
    // Key-ifies a custom period which looks like { StartDate: string, EndDate?: string }
    return isPeriodCustomPeriod(period) ? uuid() : period;
  }, [period]);

  useLifeToDateStartDate();

  // We block on showing anything until we know the StartDate for the selected period
  const loading = chartRequestDateRange.StartDate == null;

  return (
    <ChartMessagingProvider>
      {/* Force a re-mount whenever period changes. The period decides the left and right bounds of the data shown and
      there is no carry-over in state between two periods such as zooms, etc, so a re-mount alleviates a lot of complexity from the 
      chart component itself */}
      {loading ? <LoaderTalos /> : <InnerHistoricalPerformanceChart key={periodKey} />}
    </ChartMessagingProvider>
  );
}

function getYAxisTitle(homeCurrencyInfo: Currency | undefined, pnlUnit: PnLUnit): string {
  return `PnL ${pnlUnit === 'amount' ? homeCurrencyInfo?.Symbol : '%'}`;
}

function getYAxes(theme: DefaultTheme, pnlUnit: PnLUnit, showDelta: boolean, homeCurrencyInfo: Currency | undefined) {
  return compact([
    {
      offset: 0,
      height: showDelta ? '75%' : '100%',
      opposite: false,
      gridLineColor: theme.borderColorChartGridLine,
      gridLineWidth: 1,
      title: {
        text: getYAxisTitle(homeCurrencyInfo, pnlUnit),
        style: {
          color: theme.colorTextDefault,
        },
      },
      // todo olof merge options for multi-axis like this
      labels: {
        style: {
          fontFamily: theme.fontFamily,
          color: theme.colorTextDefault,
          fontSize: `${theme.fontSizeTiny}rem`,
        },
      },
    },
    {
      visible: showDelta,
      offset: 0,
      top: showDelta ? '78%' : '100%',
      height: showDelta ? '22%' : '0%',
      opposite: false,
      gridLineColor: theme.borderColorChartGridLine,
      gridLineWidth: 1,
      title: {
        text: 'Delta',
        style: {
          color: theme.colorTextDefault,
        },
      },
      labels: {
        style: {
          fontFamily: theme.fontFamily,
          color: theme.colorTextDefault,
          fontSize: `${theme.fontSizeTiny}rem`,
        },
      },
    },
  ] satisfies Highcharts.YAxisOptions[]);
}

function getXAxis(
  period: Period | CustomPeriod,
  plotLines?: Highcharts.XAxisPlotLinesOptions[]
): Highcharts.XAxisOptions {
  const { StartDate, EndDate } = periodToChartDateRange(period);

  return {
    ordinal: false,
    minRange: 1000 * 3600 * 4, // 4 hours in milliseconds
    plotLines,
    overscroll: OVERSCROLL, // defines the right-padding so to speak of the line series data points
    min: StartDate ? new Date(StartDate).getTime() : null,
    max: EndDate ? new Date(EndDate).getTime() : null,
  };
}

/**
 * Given the tooltip point input, get the previous point to be used for various comparisons.
 *
 * Highcharts has some serious quirks.
 * For some reason, depending on the zoom level, the previous data point for the hovered point is either 1 or 2 indexes behind the given point.index.
 * I don't know why this is. So there are two cases:
 * points[point.index === point], or points[point.index - 1] === point. So given this, if we want the previous point, we need to double-check things.
 */
function getPreviousPoint(tooltipPoint: Highcharts.TooltipFormatterContextObject): Highcharts.Point | undefined {
  const pointOneIndexBack: Highcharts.Point | undefined = tooltipPoint.series.points[tooltipPoint.point.index - 1];
  if (pointOneIndexBack?.index !== tooltipPoint.point.index) {
    return pointOneIndexBack;
  }

  const pointTwoIndexesBack = tooltipPoint.series.points[tooltipPoint.point.index - 2];
  return pointTwoIndexesBack;
}
