import {
  Box,
  Button,
  ButtonVariants,
  Divider,
  FormTable,
  HStack,
  IconName,
  LocalFilterInput,
  Text,
  VStack,
  type ISubaccount,
  type SubAccountWindowLimit,
} from '@talos/kyoko';
import { useState } from 'react';
import { useBlotterState } from '../../../../providers/BlottersContext';
import { ForSubAccountSelector } from '../ForSubAccountSelector';
import { useSubAccountWindowLimitsBlotter } from './useSubAccountWindowLimitsBlotter';

const SEARCH_KEYS = [
  'MatchAsset',
  'SubAccount',
  'MatchUser',
  'LimitID',
  'Description',
] satisfies (keyof SubAccountWindowLimit)[];

export function SubAccountWindowLimits() {
  const [forSubAccount, setForSubAccount] = useState<ISubaccount | undefined>(undefined);

  return (
    <SubAccountWindowLimitsFormTable
      // Reset the form table whenever forSubAccount changes
      key={forSubAccount?.Name}
      forSubAccount={forSubAccount}
      onForSubAccountChange={setForSubAccount}
    />
  );
}

const SubAccountWindowLimitsFormTable = ({
  forSubAccount,
  onForSubAccountChange,
}: {
  forSubAccount: ISubaccount | undefined;
  onForSubAccountChange: (forSubAccount: ISubaccount | undefined) => void;
}) => {
  const { filterValueSubAccountWindowLimits, setFilterValueSubAccountWindowLimits } = useBlotterState();
  const { formTable, isSaving, onAdd, onSave } = useSubAccountWindowLimitsBlotter({
    forSubAccount,
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
      filterText: filterValueSubAccountWindowLimits,
    },
  });

  return (
    <VStack h="100%" w="100%" alignItems="flex-start">
      <VStack w="100%" gap="spacingDefault" alignItems="flex-start" my="spacingDefault">
        <Text>
          The maximum order count, volume, or total active volume per sub account or user over a defined rolling
          look-back time period.
        </Text>

        <HStack gap="spacingDefault" justifyContent="flex-end" w="100%">
          <ForSubAccountSelector forSubAccount={forSubAccount} onForSubAccountChange={onForSubAccountChange} />
          <Divider orientation="vertical" />
          <LocalFilterInput
            placeholder="Search..."
            value={filterValueSubAccountWindowLimits}
            onChange={setFilterValueSubAccountWindowLimits}
            width="170px"
          />
          <Divider orientation="vertical" />
          <Button startIcon={IconName.Plus} onClick={onAdd}>
            Add Limit
          </Button>
          <Button variant={ButtonVariants.Primary} onClick={onSave} disabled={isSaving || !formTable.isDirty}>
            Save
          </Button>
        </HStack>
      </VStack>
      <Box h="100%" w="100%">
        <FormTable {...formTable} />
      </Box>
    </VStack>
  );
};
