import type { ICellRendererParams } from 'ag-grid-enterprise';
import type { BigSource } from 'big.js';
import { useEffect, useState, type ReactNode } from 'react';
import { useTheme, type DefaultTheme } from 'styled-components';
import { Meter, type MeterProps } from '../Meter';
import { Tooltip } from '../Tooltip';

export type AgGridMeterProps = ICellRendererParams &
  MeterProps & {
    // ag-grid valueGetter can return an object with a value property, in case we need to re-render based on other properties
    value: { value: BigSource; getMeterColor: (params: ICellRendererParams, theme: DefaultTheme) => string };
    alignBar?: 'left' | 'right';
    getMeterFillPercentage?: (params: ICellRendererParams) => string;
    tooltip?: (props: { meterProps: MeterProps } & AgGridMeterProps) => ReactNode | null;
  };
export function AgGridMeter(params: AgGridMeterProps) {
  const { value, showInitialAnimation, alignBar, showLabel, tooltip, getMeterFillPercentage, valueFormatted } = params;
  const theme = useTheme();
  const [color, setColor] = useState<string>(theme.backgroundPrimary);

  useEffect(() => {
    if (value?.getMeterColor) {
      setColor(value.getMeterColor(params, theme) ?? theme.backgroundPrimary);
    } else {
      setColor(theme.backgroundPrimary);
    }
  }, [value?.getMeterColor, params, theme, value]);

  if (value == null) {
    return <span />;
  }

  const label = valueFormatted ?? undefined;

  let meterValue = value;

  if (typeof value === 'object' && 'value' in value) {
    // `value` may be an object with the actual percentage inside it
    // This allows `valueGetter` to return extra properties that may change the color of the meter
    meterValue = value.value;
  }
  if (getMeterFillPercentage) {
    meterValue = getMeterFillPercentage(params);
  }

  if (!meterValue) {
    return <span />;
  }

  const meterProps: MeterProps = {
    value: meterValue,
    customLabel: label,
    showLabel: showLabel,
    showInitialAnimation: showInitialAnimation,
    color: color,
    alignBar: alignBar,
  };

  const maybeTooltip = tooltip?.({ meterProps, ...params });

  if (maybeTooltip) {
    return (
      <Tooltip usePortal tooltip={maybeTooltip} targetStyle={{ width: '100%' }}>
        <Meter {...meterProps} />
      </Tooltip>
    );
  }

  return <Meter {...meterProps} />;
}
