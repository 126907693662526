import {
  useAssetsFilter,
  useCurrenciesFilter,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
} from '@talos/kyoko';
import { createContext, useCallback, useContext, useMemo } from 'react';

import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformancePageProducts } from '../hooks/usePerformancePageProducts';
import type { PerformanceFilter } from '../types';
import { usePerformanceContext } from './PerformanceStateAndTabsProvider';

export const PerformanceFiltersContext = createContext<PerformanceFiltersContextProps | undefined>(undefined);

export type PerformanceFiltersContextProps = {
  filterableProperties: FilterableProperty[];
  onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'];
};

export function usePerformanceFilters() {
  const context = useContext(PerformanceFiltersContext);
  if (context === undefined) {
    throw new Error('Missing PerformanceFiltersContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/**
 * This provider allows anyone in the page to have access to the filterable properties for example
 */
export const PerformanceFiltersProvider = function PerformanceFiltersProvider({ children }) {
  const { dispatch } = usePerformanceContext();

  const productOptions = usePerformancePageProducts();

  const currenciesFilter = useCurrenciesFilter();
  const assetsFilter = useAssetsFilter();

  const filterableProperties = useMemo(() => {
    const filterBase = productOptions.includes('derivatives') ? assetsFilter : currenciesFilter;
    return [{ ...filterBase, label: 'Asset', key: 'Symbols' }];
  }, [currenciesFilter, assetsFilter, productOptions]);

  const onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'] = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      const newFilter: PerformanceFilter = {
        Symbols: filterClausesByPropertyKey.get('Symbols')?.selections,
      };
      dispatch({
        type: PerformanceActionType.FilterChange,
        payload: {
          filter: newFilter,
        },
      });
    },
    [dispatch]
  );

  const value = useMemo(() => {
    return {
      filterableProperties,
      onFilterClausesChanged,
    };
  }, [filterableProperties, onFilterClausesChanged]);

  return <PerformanceFiltersContext.Provider value={value}>{children}</PerformanceFiltersContext.Provider>;
};
