import { isEmpty, uniq } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useDynamicCallback } from '../../../hooks/useDynamicCallback';
import type {
  FilterBuilderSide,
  FilterClause,
  FilterableProperty,
  InitialFilterClause,
  UseFilterBuilderOutput,
  UseFilterBuilderProps,
} from './types';
import { FilterClauseType } from './types';
import { useFilterBuilderRefs } from './useFilterBuilderRefs';

const ListOfFilterClauseTypes: FilterClauseType[] = [FilterClauseType.INCLUSIVE, FilterClauseType.EXCLUSIVE];
export const isFilterClause = (maybeFilterClause: any): maybeFilterClause is FilterClause =>
  maybeFilterClause &&
  Object.entries(maybeFilterClause).length === 3 &&
  typeof maybeFilterClause.key === 'string' &&
  Array.isArray(maybeFilterClause.selections) &&
  ListOfFilterClauseTypes.includes(maybeFilterClause.type);

export const useFilterBuilder = ({
  initialFilterClauses,
  onFilterClausesChanged,
  properties,
}: UseFilterBuilderProps): UseFilterBuilderOutput => {
  const propertiesByKey = useMemo(() => {
    return new Map(properties.map(property => [property.key, property]));
  }, [properties]);

  const handleFilterClausesChanged = useDynamicCallback((filterClausesByPropertyKey: Map<string, FilterClause>) =>
    onFilterClausesChanged?.(filterClausesByPropertyKey, propertiesByKey)
  );

  const [filterClausesByPropertyKey, setFilterClausesByPropertyKey] = useState<Map<string, FilterClause>>(() => {
    const verifiedClauses = verifyFilterClauses(initialFilterClauses, propertiesByKey);

    const nSelectionsBeforeVerification = initialFilterClauses.reduce(
      (count, clause) => count + (clause.selections?.length ?? 0),
      0
    );
    const nSelectionsAfterVerification = [...verifiedClauses.values()].reduce(
      (count, clause) => count + (clause.selections?.length ?? 0),
      0
    );

    // If any selections have been removed we need to inform the parent of this change
    if (nSelectionsBeforeVerification !== nSelectionsAfterVerification) {
      handleFilterClausesChanged(verifiedClauses);
    }

    return verifiedClauses;
  });

  const {
    addPropertyRefs,
    removePropertyRefs,
    removeAllPropertyRefs,
    resetAllPropertyRefs,
    openClause,
    updateSelectionRefs,
    ...filterBuilderRefs
  } = useFilterBuilderRefs(filterClausesByPropertyKey);

  const resetFilterClauses = useCallback(
    (filterClauses: FilterClause[] = []) => {
      const nextFilterClauses = verifyFilterClauses(filterClauses, propertiesByKey);
      setFilterClausesByPropertyKey(nextFilterClauses);
      handleFilterClausesChanged(nextFilterClauses);
      resetAllPropertyRefs(filterClauses);
    },
    [propertiesByKey, resetAllPropertyRefs, handleFilterClausesChanged]
  );

  const filterClauses: FilterClause[] = useMemo(() => {
    return [...filterClausesByPropertyKey.values()];
  }, [filterClausesByPropertyKey]);

  const addFilterClause = useCallback(
    (property: string, selections: string[] = []) => {
      const nextFilterClauses = new Map(filterClausesByPropertyKey).set(property, {
        key: property,
        type: FilterClauseType.INCLUSIVE,
        selections,
      });
      setFilterClausesByPropertyKey(nextFilterClauses);
      handleFilterClausesChanged(nextFilterClauses);
      addPropertyRefs(property);
    },
    [addPropertyRefs, filterClausesByPropertyKey, handleFilterClausesChanged]
  );

  const removeFilterClause = useCallback(
    (property: string) => {
      const nextFilterClauses = new Map(filterClausesByPropertyKey);
      nextFilterClauses.delete(property);
      setFilterClausesByPropertyKey(nextFilterClauses);
      handleFilterClausesChanged(nextFilterClauses);
      removePropertyRefs(property);
    },
    [removePropertyRefs, filterClausesByPropertyKey, handleFilterClausesChanged]
  );

  const removeAllFilterClauses = useCallback(() => {
    resetFilterClauses();
  }, [resetFilterClauses]);

  const replaceFilterClause = useCallback(
    (oldProperty: string, newProperty: string) => {
      // we replace the ones while maintaining order and placement
      // in order to preserve ordering of the map we need to loop over the entries and create a new map from it in that order
      removePropertyRefs(oldProperty);
      addPropertyRefs(newProperty);

      const nextFilterClauses = new Map(
        [...filterClausesByPropertyKey.entries()].map(([property, clause]) => {
          // replace in place
          if (property === oldProperty) {
            return [newProperty, { key: newProperty, type: FilterClauseType.INCLUSIVE, selections: [] }];
          } else {
            // keep as is
            return [property, clause];
          }
        })
      );
      setFilterClausesByPropertyKey(nextFilterClauses);
      handleFilterClausesChanged(nextFilterClauses);
    },
    [removePropertyRefs, addPropertyRefs, filterClausesByPropertyKey, handleFilterClausesChanged]
  );

  // updates the selections for a given clause
  const setFilterClauseSelections = useCallback(
    (property: string, newSelections: string[]) => {
      const currClause = filterClausesByPropertyKey.get(property);
      if (!currClause) {
        return;
      }

      const newClause = { ...currClause, selections: newSelections };
      const nextFilterClauses = new Map(filterClausesByPropertyKey);
      nextFilterClauses.set(property, newClause);
      setFilterClausesByPropertyKey(nextFilterClauses);
      handleFilterClausesChanged(nextFilterClauses);
      updateSelectionRefs(property, newSelections);
    },
    [filterClausesByPropertyKey, handleFilterClausesChanged, updateSelectionRefs]
  );

  const addAndOpenClause = useCallback(
    (
      property: string | undefined,
      side: FilterBuilderSide = 'rhs',
      value: string[] | string | undefined = undefined
    ) => {
      // The value we receive into this function can either be string, string[] or undefined. We normalize it string[] | undefined.
      const valueArr: string[] | undefined = value == null ? undefined : value instanceof Array ? value : [value];

      if (property === undefined) {
        // If we are passing in undefined we want to open "add", so do it
        openClause(undefined);
        return;
      }

      // Otherwise we want to open an explicit property. If it exists, open, if it doesnt exist, add and then open
      const filterableProperty = propertiesByKey.get(property);
      if (!filterableProperty) {
        return;
      }

      const maybeClause = filterClausesByPropertyKey.get(property);
      if (!maybeClause) {
        // there is no clause for this property, we add a new one and attach any selections to it we want
        const maybeSelections = valueArr ? verifyClauseSelections(valueArr, filterableProperty) : [];
        addFilterClause(property, maybeSelections);
      } else if (valueArr) {
        // there already exists a clause for this property, _and_ we want to add some more values to that clause.
        const selections = verifyClauseSelections([...(maybeClause.selections ?? []), ...valueArr], filterableProperty);
        setFilterClauseSelections(property, selections);
      }

      // If a value is passed we chose to not open the dropdown on this function call
      if (valueArr) {
        return;
      }

      openClause(property, side);
    },
    [filterClausesByPropertyKey, openClause, addFilterClause, setFilterClauseSelections, propertiesByKey]
  );

  return {
    filterClauses,
    filterClausesByPropertyKey,
    addFilterClause,
    removeFilterClause,
    removeAllFilterClauses,
    replaceFilterClause,
    resetFilterClauses,
    setFilterClauseSelections,
    propertiesList: properties,
    propertiesByKey,
    addAndOpenClause,
    updateSelectionRefs,
    ...filterBuilderRefs,
  };
};

const verifyFilterClauses = (
  filterClauses: InitialFilterClause[],
  propertiesByKey: Map<string, FilterableProperty>
) => {
  const acceptableFilterClauses: FilterClause[] = filterClauses
    // filter out all the non-supported properties
    .filter(clause => {
      const filterableProperty = propertiesByKey.get(clause.key);
      return !!filterableProperty;
    })
    .flatMap(clause => {
      const filterableProperty = propertiesByKey.get(clause.key)!;

      const acceptableSelections = verifyClauseSelections(clause.selections, filterableProperty);
      if (isEmpty(acceptableSelections)) {
        return [];
      }
      return {
        ...clause,
        selections: acceptableSelections,
      };
    });

  const map = new Map(acceptableFilterClauses.map(clause => [clause.key, clause]));
  return map;
};

const verifyClauseSelections = (selections: string[] | undefined, filterableProperty: FilterableProperty): string[] => {
  if (selections == null) {
    return [];
  }

  if (filterableProperty.control === 'text') {
    if (Array.isArray(selections)) {
      return uniq(selections);
    }

    return [selections];
  }

  // From here downward we apply verification in steps. We have a working array of selections.
  let workingSelections = [...selections];
  if (filterableProperty.control === 'single-select' && workingSelections.length > 1) {
    // If the property is single select and there is more than one element in the array, only accept the first element, ignoring all others
    workingSelections = [workingSelections[0]];
  }

  const clearUnsupportedSelectionsOnInit = filterableProperty.clearUnsupportedSelectionsOnInit ?? true;
  if (!clearUnsupportedSelectionsOnInit) {
    return workingSelections ?? [];
  }

  const optionsAvailable = new Set(filterableProperty.options);
  // Note: `selections` should never be undefined, but if a default filter
  // for a tab is defined with `undefined`, this will throw unless we add this null check.
  // Saves some headaches during development.
  const acceptableSelections = workingSelections?.filter(selection => optionsAvailable.has(selection));
  if (!acceptableSelections?.length) {
    return [];
  }

  const uniqueAcceptableSelections = uniq(acceptableSelections);

  return uniqueAcceptableSelections;
};
