import { MarketDataCardState, type MarketDataCardConfig } from '@talos/kyoko';
import { CardViewEnum } from 'tokens/card';

export const NUM_COLUMNS = 10;
export const DEFAULT_COLUMNS: MarketDataCardConfig[][] = [
  [
    {
      activeSlideIndex: 1,
      id: 'cd33f6b0-5d37-11e9-97e0-bb2afd9bf808',
      availableMarketAccounts: [],
      selectedMarketAccounts: [],
      availableMarkets: ['coinbase', 'gemini'],
      selectedMarkets: ['coinbase', 'gemini'],
      priceIncrement: '0.01',
      sizeBuckets: ['1', '2.5', '5', '10', '100'],
      symbol: 'BTC-USD',
      topOfBookSizeBucket: '1',
      state: MarketDataCardState.UPDATED_CARD,
      view: CardViewEnum.NORMAL,
    },
  ],
  [
    {
      activeSlideIndex: 1,
      id: 'd3273fa0-5d37-11e9-97e0-bb2afd9bf808',
      availableMarketAccounts: [],
      selectedMarketAccounts: [],
      availableMarkets: ['coinbase', 'gemini'],
      selectedMarkets: ['coinbase', 'gemini'],
      priceIncrement: '0.01',
      sizeBuckets: ['10', '25', '50', '100', '1000'],
      symbol: 'ETH-USD',
      topOfBookSizeBucket: '10',
      state: MarketDataCardState.UPDATED_CARD,
      view: CardViewEnum.NORMAL,
    },
  ],
];
