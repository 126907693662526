import { pipe, scan, type Observable, type UnaryFunction } from 'rxjs';
import type { MinimalSubscriptionResponse } from '../types/SubscriptionResponse';
import { NestedMap } from '../utils/nestedMap';

/**
 * An RxJS pipe which scans MinimalSubscriptionResponses of type T into a NestedMap of type T.
 * Currently only supports setting new entries as if they were additive. Does not support any other UpdateActions yet.
 * @param indexKeys The keys passed to the NestedMap which will be used for indexing new entries
 * @returns an Observable of a NestedMap on the other end
 */
export function scanToNestedMap<T>(
  indexKeys: NestedMap<T>['indexKeys']
): UnaryFunction<Observable<MinimalSubscriptionResponse<T>>, Observable<NestedMap<T>>> {
  return pipe(
    scan((nestedMap, update) => {
      if (update.initial) {
        nestedMap.clear();
      }

      for (const datapoint of update.data) {
        nestedMap.setEntry(datapoint);
      }

      return nestedMap;
    }, new NestedMap<T>({ items: [], indexKeys }))
  );
}
