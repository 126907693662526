import * as Sentry from '@sentry/browser';
import { parse } from 'date-fns';
import type React from 'react';
import { memo, useCallback, useEffect, useRef, useState, type FocusEvent, type MouseEvent } from 'react';
import { useTheme } from 'styled-components';

import type { DateFnInput } from '../../utils/date';
import { isValidDateInput } from '../../utils/date';
import { IconButton } from '../Button';
import { FormControlSizes, Input, type FormControlProps } from '../Form';
import { Icon, IconName } from '../Icons';
import { Popover, usePopoverState } from '../Popover';
import { DateTimePickerWrapper } from './styles';

import { defineMessages, useIntl } from 'react-intl';
import { useDynamicCallback } from '../../hooks';
import { DateTimePickerContent, type DateTimePickerContentProps, type SelectionOrigin } from './DateTimePickerContent';
import {
  DEFAULT_DATE_PICKER_EOD,
  DEFAULT_SHORTCUTS,
  getFormatter,
  shouldEmitChange,
  validateMaxDate,
  validateMinDate,
} from './utils';

const messages = defineMessages({
  ariaLabelForClearValueButton: {
    defaultMessage: 'Clear value',
    id: 'DateTimePicker.ariaLabelForClearValueButton',
  },
  ariaLabelForValueInput: {
    defaultMessage: 'Value',
    id: 'DateTimePicker.ariaLabelForValueInput',
  },
});

export type DateTimePickerProps = {
  /** Current value of the date / time picker */
  value: Date | null;
  /** Change event handler */
  onChange: (value: Date | null) => void;
  /** Portalize component: @default: true */
  portalize?: boolean;
} & Omit<FormControlProps<HTMLInputElement>, 'onChange' | 'value'> &
  Pick<
    DateTimePickerContentProps,
    | 'showCalendar'
    | 'showShortcuts'
    | 'showTimePicker'
    | 'showMilliseconds'
    | 'shortcuts'
    | 'timePickerVariant'
    | 'timeSelectorIntervalMinutes'
    | 'customEOD'
    | 'useDaySelectCustomEOD'
    | 'minValue'
    | 'maxValue'
  >;

/**
 * Date / Time Picker component
 */
export const DateTimePicker = memo(function DateTimePicker({
  onChange,
  onBlur,
  onFocus,
  value,
  showCalendar = true,
  showShortcuts = true,
  showTimePicker = true,
  showMilliseconds = false,
  shortcuts = DEFAULT_SHORTCUTS,
  className,
  style,
  disabled,
  minValue,
  maxValue,
  customEOD = DEFAULT_DATE_PICKER_EOD,
  useDaySelectCustomEOD,
  timePickerVariant = 'picker',
  timeSelectorIntervalMinutes = 60,
  portalize = true,
  size,
  ...props
}: DateTimePickerProps) {
  const { spacingSmall } = useTheme();
  const { formatMessage } = useIntl();
  const popover = usePopoverState({
    trigger: '',
    placement: 'bottom-end',
    delay: undefined,
    usePortal: portalize,
    onClickOutside: e => {
      if (
        timePickerVariant === 'selector' &&
        timeSelectorDropdownContentRef &&
        timeSelectorDropdownContentRef.current
      ) {
        const el = e.currentTarget;
        if (el instanceof Node && e.composedPath().includes(timeSelectorDropdownContentRef.current)) {
          // dont close, we're inside of the time selector dropdown
          return;
        }
      }

      close();
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const dateFormatter = useCallback(
    (date: DateFnInput | null) => {
      if (date == null) {
        return '';
      }
      try {
        const formatter = getFormatter(showMilliseconds, showTimePicker);
        return formatter(date);
      } catch (e) {
        return '';
      }
    },
    [showMilliseconds, showTimePicker]
  );

  // Either parses as a Date (without time), or with date + time depending on inputs.
  const dateParser = useCallback(
    (text: string) => {
      let parseString = 'yyyy-MM-dd';
      let pattern = /\d\d\d\d-\d\d-\d\d/;
      if (showTimePicker) {
        parseString += ` HH:mm:ss${showMilliseconds ? '.SSS' : ''}`;
        pattern = showMilliseconds ? /\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d.\d\d\d/ : /\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d/;
      }
      if (text.match(pattern)) {
        return parse(text, parseString, new Date());
      } else {
        return new Date('invalid');
      }
    },
    [showTimePicker, showMilliseconds]
  );

  const [inputValue, setInputValue] = useState<string>(dateFormatter(value) ?? '');

  const inputValueRef = useRef(inputValue);
  useEffect(() => {
    inputValueRef.current = inputValue;
  }, [inputValue]);

  useEffect(() => {
    if (value !== null && !isValidDateInput(value)) {
      Sentry.captureMessage('Received invalid date in date picker', {
        level: 'error',
        extra: {
          inputValue: inputValueRef.current,
          customEOD,
        },
      });
    }
  }, [value, inputValue, customEOD, dateFormatter]);

  const [showClear, setShowClear] = useState(value !== null && isValidDateInput(value));
  const { open, close } = popover;

  useEffect(() => {
    setShowClear(value !== null && isValidDateInput(value));

    // Whenever value changes, update the inputValue to reflect it.
    if (value == null) {
      setInputValue('');
    } else if (isValidDateInput(value)) {
      setInputValue(dateFormatter(value) ?? '');
    }
  }, [value, dateFormatter]);

  const handleInputFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      open();
      if (onFocus != null) {
        onFocus(e);
      }
    },
    [open, onFocus]
  );

  // When blurring the input field, set to the string form of latest valid date (either a date or empty string)
  const handleInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const date = dateParser(e.target.value);
      if (!isValidDateInput(date)) {
        setInputValue(dateFormatter(value) ?? '');
      }

      if (onBlur) {
        onBlur(e);
      }
    },
    [dateFormatter, value, onBlur, dateParser]
  );

  const maybeEmitChange = useCallback(
    (maybeChangedDate: Date | null) => {
      if (maybeChangedDate && minValue) {
        maybeChangedDate = validateMinDate(maybeChangedDate, minValue, showTimePicker);
      }

      if (maybeChangedDate && maxValue) {
        maybeChangedDate = validateMaxDate(maybeChangedDate, maxValue, showTimePicker);
      }

      if (shouldEmitChange(value, maybeChangedDate)) {
        onChange(maybeChangedDate);
      }
    },
    [onChange, value, minValue, maxValue, showTimePicker]
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      if (inputRef.current && inputRef.current.contains(document.activeElement)) {
        close();
        return;
      }
    }
  };

  const handleTabOut = useDynamicCallback(() => {
    inputRef.current?.focus();
    close();
  });

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const text = e.target.value;
      setInputValue(text);

      if (text === '') {
        maybeEmitChange(null);
        return;
      }

      try {
        // This works for 99% of cases, but see link to bug below.
        // For example, 2022-01-01 12:31:5 (missing digit at the end) will pass with "05" seconds when it should have been an invalid date
        // https://github.com/date-fns/date-fns/issues/1924
        const date = dateParser(text);
        if (isValidDateInput(date)) {
          maybeEmitChange(date);
          return;
        }
      } catch (e) {
        // Do nothing
        maybeEmitChange(null);
      }
    },
    [maybeEmitChange, dateParser]
  );

  const handleSelection = useDynamicCallback((date: Date | null, from: SelectionOrigin) => {
    if (isValidDateInput(date)) {
      setInputValue(dateFormatter(date) ?? '');
    }
    maybeEmitChange(date);

    const shouldClose = !showTimePicker || from === 'shortcut';
    if (shouldClose) {
      close();
    }
  });

  const handleClear = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      maybeEmitChange(null);
      setInputValue('');
      return false;
    },
    [maybeEmitChange]
  );

  const timeSelectorDropdownContentRef = useRef<HTMLDivElement>(null);
  const clearDisabled = disabled || !showClear;

  return (
    <DateTimePickerWrapper className={className} style={style} onKeyDown={handleKeyDown}>
      <Popover {...popover}>
        <Input
          {...props}
          size={size}
          onFocus={handleInputFocus}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          value={inputValue}
          disabled={disabled}
          aria-label={formatMessage(messages.ariaLabelForValueInput)}
          ref={inputRef}
          suffix={
            <>
              <IconButton
                ghost
                round
                icon={IconName.Clear}
                onClick={handleClear}
                style={{ visibility: clearDisabled ? 'hidden' : 'visible' }}
                disabled={clearDisabled}
                aria-label={formatMessage(messages.ariaLabelForClearValueButton)}
                size={(size ?? FormControlSizes.Default) - 0.5}
              />
              <Popover {...popover} tabIndex={-1}>
                <Icon icon={IconName.Clock} style={{ marginRight: spacingSmall }} />
                <DateTimePickerContent
                  value={value}
                  showCalendar={showCalendar}
                  showTimePicker={showTimePicker}
                  showShortcuts={showShortcuts}
                  showMilliseconds={showMilliseconds}
                  shortcuts={shortcuts}
                  timePickerVariant={timePickerVariant}
                  timeSelectorIntervalMinutes={timeSelectorIntervalMinutes}
                  timeSelectorDropdownContentRef={timeSelectorDropdownContentRef}
                  onSelection={handleSelection}
                  onTabOut={handleTabOut}
                  minValue={minValue}
                  maxValue={maxValue}
                />
              </Popover>
            </>
          }
        />
      </Popover>
    </DateTimePickerWrapper>
  );
});
