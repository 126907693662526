import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { map, scan, shareReplay, throttleTime } from 'rxjs/operators';

import {
  CUSTOMER_EXECUTION_REPORT,
  CUSTOMER_MAX_ORDER_SIZE_LIMIT,
  CUSTOMER_NEW_ORDER_SINGLE,
  CUSTOMER_QUOTE_CANCEL_REQUEST,
  CUSTOMER_QUOTE_REQUEST,
  CUSTOMER_QUOTE_UPDATE_REQUEST,
  CUSTOMER_TRANSACTION,
  OrdTypeEnum,
  formattedDateForSubscription,
  logger,
  useObservable,
  useObservableValue,
  useSocketClient,
  useStaticSubscription,
  useSubscription,
  type Customer,
  type CustomerQuote,
  type CustomerTradingLimit,
  type CustomerTransaction,
  type CustomerUser,
  type Order,
  type QuoteParameters,
  type SubscriptionResponse,
} from '@talos/kyoko';
import type { LegacyQuote } from 'components/OMS/NewRFQ/Components/Quotes/Quote/types';
import { CustomersContext } from 'providers/CustomersProvider';
import { asyncScheduler, type Observable } from 'rxjs';
import {
  FixedPricingModeEnum,
  type CustomerQuoteResponse,
  type CustomersProviderProps,
  type LockQuotePriceParams,
  type LockQuoteSpreadParams,
} from '../providers/CustomersContext.types';

const THROTTLE_MS = 500;
export function useCustomersContext() {
  const context = useContext(CustomersContext);
  if (context == null) {
    throw new Error('Missing CustomersContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export function useCustomerTradingLimits({ tag }: { tag: string }) {
  const request = useMemo(
    () => ({ name: CUSTOMER_MAX_ORDER_SIZE_LIMIT, tag: `${tag}/useCustomerTradingLimits` }),
    [tag]
  );
  const { data: tradingLimitsSub } = useSubscription<
    SubscriptionResponse<CustomerTradingLimit, typeof CUSTOMER_MAX_ORDER_SIZE_LIMIT>
  >(request, {
    loadAll: true,
  });

  const tradingLimitsObs = useObservable<
    SubscriptionResponse<CustomerTradingLimit, typeof CUSTOMER_MAX_ORDER_SIZE_LIMIT>
  >(() => tradingLimitsSub, [tradingLimitsSub]);

  return useObservableValue(() => tradingLimitsObs, [tradingLimitsObs])?.data;
}

export function useCustomers() {
  const { customers } = useCustomersContext();
  return useObservableValue(() => customers, [customers]);
}

export function useCustomersByName(): Map<string, Customer> | undefined {
  const customers = useCustomers();
  return useMemo(() => {
    if (!customers) {
      return undefined;
    }
    return new Map(customers.map(customer => [customer.Name, customer]));
  }, [customers]);
}

export function useCustomerUsers() {
  const { customerUsers } = useCustomersContext();
  const customersByName = useCustomersByName();

  const listOfUsers = useObservableValue(() => customerUsers, [customerUsers]);

  return useMemo(() => {
    return (
      listOfUsers
        // Some rogue entries have all their required fields removed. Needs back-end clean up.
        // Without a CustomerUserID or Counterparty, we can't make any API calls.
        ?.filter(user => user.Counterparty && user.CustomerUserID)
        ?.sort((a, b) => {
          // Sort by Counterparty, Secondary Sort by DisplayName
          if (a.Counterparty === b.Counterparty) {
            return a.DisplayName.localeCompare(b.DisplayName);
          } else {
            // Prioritize Customer Display Name over Counterparty Name
            const cNameA = customersByName?.get(a.Counterparty)?.DisplayName || a.Counterparty;
            const cNameB = customersByName?.get(b.Counterparty)?.DisplayName || b.Counterparty;
            return cNameA.localeCompare(cNameB);
          }
        })
    );
  }, [customersByName, listOfUsers]);
}

export function useCustomerUsersByID(): Map<string, CustomerUser> | undefined {
  const customerUsers = useCustomerUsers();
  return useMemo(() => {
    if (!customerUsers) {
      return undefined;
    }
    return new Map(customerUsers.map(customerUser => [customerUser.CustomerUserID, customerUser]));
  }, [customerUsers]);
}

export const useCustomerQuotes = ({
  customerQuoteSub,
  quoteReqID,
  tag,
}: {
  customerQuoteSub: Observable<CustomerQuoteResponse>;
  quoteReqID: string | undefined;
  tag: string;
}) => {
  const client = useSocketClient();

  const currentQuoteObs = useObservable<CustomerQuote>(
    () =>
      customerQuoteSub.pipe(
        scan((map, json: any) => {
          if (json.initial) {
            map.clear();
          }
          for (const quote of json.data) {
            if (quote.QuoteReqID === quoteReqID) {
              map.set(quote.QuoteReqID, quote);
            }
          }
          return map;
        }, new Map<string, CustomerQuote>()),
        map((map: Map<string, CustomerQuote>) => [...map.values()]),
        map((quotes: CustomerQuote[]) => (quotes.length ? quotes[0] : ({} as CustomerQuote))),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerQuoteSub, quoteReqID]
  );

  const currentQuote = useObservableValue(() => currentQuoteObs, [currentQuoteObs], {} as CustomerQuote);

  const requestQuote = useCallback(
    ({ counterparty, side, orderQty, quoteReqID, symbol, currency, marketAccount }: Partial<LegacyQuote>) => {
      const data: Partial<CustomerQuote> = {
        Counterparty: counterparty,
        Currency: currency,
        OrderQty: orderQty,
        QuoteReqID: quoteReqID,
        Symbol: symbol,
        MarketAccount: marketAccount,
        Parameters: {
          PublishExpectedHedgePrice: true,
        },
        TransactTime: formattedDateForSubscription(new Date()),
      };
      if (side) {
        data.Side = side;
      }
      client.registerPublication({
        type: CUSTOMER_QUOTE_REQUEST,
        data: [data],
      });
    },
    [client]
  );

  const lockQuotePrice = useCallback(
    ({ quoteReqID, customerBidPrice, customerOfferPrice }: LockQuotePriceParams) => {
      if (!quoteReqID || !currentQuote?.RFQID) {
        logger.error(new Error('Cannot Lock Quote Price without a QuoteReqID or RFQID'));
        return;
      }
      const parameters: Partial<QuoteParameters> = { ...currentQuote?.Parameters };
      // We want to send all parameters from the previous message minus BidSpread and OfferSpread here
      delete parameters.BidSpread;
      delete parameters.OfferSpread;
      const data = {
        RFQID: currentQuote?.RFQID,
        Parameters: {
          ...parameters,
          FixedPricingMode: FixedPricingModeEnum.Price,
          CustomerBidPrice: customerBidPrice,
          CustomerOfferPrice: customerOfferPrice,
        },
        OrigQuoteReqID: currentQuote?.OrigQuoteReqID || currentQuote?.QuoteReqID,
        QuoteReqID: quoteReqID,
        TransactTime: formattedDateForSubscription(new Date()),
      };
      client.registerPublication({
        type: CUSTOMER_QUOTE_UPDATE_REQUEST,
        data: [data],
      });
    },
    [client, currentQuote?.OrigQuoteReqID, currentQuote?.Parameters, currentQuote?.QuoteReqID, currentQuote?.RFQID]
  );

  const lockQuoteSpread = useCallback(
    ({ quoteReqID, bidSpread, offerSpread }: LockQuoteSpreadParams) => {
      if (!quoteReqID || !currentQuote?.RFQID) {
        logger.error(new Error('Cannot Lock Quote Spread without a QuoteReqID or RFQID'));
        return;
      }
      const parameters: Partial<QuoteParameters> = { ...currentQuote?.Parameters };
      // We want to send all parameters from the previous message minus CustomerBidPrice and CustomerOfferPrice here
      delete parameters.CustomerBidPrice;
      delete parameters.CustomerOfferPrice;
      const data = {
        RFQID: currentQuote?.RFQID,
        Parameters: {
          ...parameters,
          FixedPricingMode: FixedPricingModeEnum.Spread,
          BidSpread: bidSpread,
          OfferSpread: offerSpread,
        },
        OrigQuoteReqID: currentQuote?.OrigQuoteReqID || currentQuote?.QuoteReqID,
        QuoteReqID: quoteReqID,
        TransactTime: formattedDateForSubscription(new Date()),
      };
      client.registerPublication({
        type: CUSTOMER_QUOTE_UPDATE_REQUEST,
        data: [data],
      });
    },
    [client, currentQuote?.OrigQuoteReqID, currentQuote?.Parameters, currentQuote?.QuoteReqID, currentQuote?.RFQID]
  );

  const cancelQuote = useCallback(
    (counterparty, rFQID, transactTime = new Date()) => {
      const data = {
        Counterparty: counterparty,
        RFQID: rFQID,
        TransactTime: formattedDateForSubscription(transactTime),
      };
      client.registerPublication({
        type: CUSTOMER_QUOTE_CANCEL_REQUEST,
        data: [data],
      });
    },
    [client]
  ) satisfies CustomersProviderProps['cancelQuote'];

  const acceptQuote = useCallback(
    quote => {
      const {
        counterparty,
        symbol,
        side,
        currency,
        orderQty,
        rFQID,
        price,
        clOrdID,
        marketAccount,
        transactTime = new Date(),
      } = quote;
      if (clOrdID == null || rFQID == null) {
        logger.error(new Error('Cannot accept Customer quote without a ClOrdId or RFQID'));
        return;
      }
      const data = {
        Counterparty: counterparty,
        MarketAccount: marketAccount,
        Symbol: symbol,
        ClOrdID: clOrdID,
        Side: side,
        Currency: currency,
        OrderQty: orderQty,
        OrdType: OrdTypeEnum.RFQ,
        RFQID: rFQID,
        Price: price,
        TransactTime: formattedDateForSubscription(transactTime),
      };
      client.registerPublication({
        type: CUSTOMER_NEW_ORDER_SINGLE,
        data: [data],
      });
    },
    [client]
  );

  const { data: customerExecutionReportsSub } = useStaticSubscription({
    name: CUSTOMER_EXECUTION_REPORT,
    tag: `${tag}/useCustomerQuotes`,
  });

  const customerExecutionReportsByRFQIDObs = useObservable(
    () =>
      customerExecutionReportsSub.pipe(
        scan((quotes: Map<string, Order>, json) => {
          json.initial && quotes.clear();
          const orders: Order[] = json.data;
          orders.forEach(order => {
            if (order.RFQID != null) {
              quotes.set(order.RFQID, order);
            }
          });
          return quotes;
        }, new Map<string, Order>()),
        throttleTime(THROTTLE_MS, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerExecutionReportsSub]
  );

  const returnedVals: CustomersProviderProps = {
    customerExecutionReportsByRFQIDObs,
    requestQuote,
    currentQuote,
    cancelQuote,
    acceptQuote,
    lockQuotePrice,
    lockQuoteSpread,
  };

  return returnedVals;
};

export function useCustomerTransaction({
  clReqID,
  tag,
}: {
  clReqID?: string;
  tag: string;
}): CustomerTransaction | undefined {
  const [request, setRequest] = useState<{ name: string; ClReqID: string; tag: string } | null>(null);

  useEffect(() => {
    if (clReqID == null) {
      setRequest(null);
    } else {
      setRequest({ name: CUSTOMER_TRANSACTION, ClReqID: clReqID, tag: `${tag}/useCustomerTransaction` });
    }
  }, [clReqID, tag]);

  const { data: subscription } = useSubscription<CustomerTransaction>(request);
  const customerTransaction = useObservableValue(
    () => subscription.pipe(map(response => response?.data[0])),
    [subscription]
  );

  return useMemo(() => {
    // Verifying a matching ClReqID ensures that we don't blink incorrect information on the drawer while new data is loading
    if (customerTransaction != null && customerTransaction.ClReqID === clReqID) {
      return customerTransaction;
    }
    return undefined;
  }, [clReqID, customerTransaction]);
}
