import { useEffect, useMemo, useState } from 'react';

export const useMatchMedia = (mediaQuery: string) => {
  const mql = useMemo(
    // window.matchMedia is undefined in tests.
    () => window.matchMedia?.(mediaQuery) as MediaQueryList | undefined,
    [mediaQuery]
  );
  const [match, setMatch] = useState<boolean>(!!mql?.matches);

  useEffect(() => {
    if (mql) {
      const callback = e => {
        setMatch(e.matches);
      };
      mql.addEventListener('change', callback);
      return () => {
        mql.removeEventListener('change', callback);
      };
    }
  }, [mql]);
  return match;
};
