import type { ApiPermissionFilter, ISubaccount, RollupMembership, SubAccount } from '@talos/kyoko';
import { createContext, useContext } from 'react';
import type { SubAccountRollupsContextProps } from './SubAccountRollupsProvider';

export interface SubAccountsContextProps {
  isLoaded: boolean;
  subAccountsEnabled: boolean;

  /** All SubAccounts -- books as well as rollups */
  allSubAccounts: SubAccount[];
  /** Only SubAccounts with Type Book */
  allSubAccountBooks: SubAccount[];
  /** Only SubAccounts with Type Rollup */
  allSubAccountRollups: SubAccount[];
  /** All SubAccounts, of all types, in one map for various forms of .Name resolution */
  subAccountsByName: Map<string, SubAccount>;
  /** All SubAccounts, of all types, in one map for various forms of .SubaccountID resolution */
  subAccountsByID: Map<number, SubAccount>;

  /** The list of tradable sub accounts. Unpopulated if the trading segregation feature is not enabled */
  tradableSubAccounts: SubAccount[] | undefined;
  /** Same as tradableSubAccounts, but a set of the entries' .Names instead */
  tradableSubAccountNamesSet: Set<string>;

  createSubAccount: (name: SubAccount['Name']) => Promise<SubAccount>;
  updateSubAccount: (account: Pick<SubAccount, 'SubaccountID' | 'DisplayName'>) => Promise<SubAccount>;
  deleteSubAccount: (subaccountID: SubAccount['SubaccountID']) => Promise<void>;
  cloneSubAccount: (oldName: string | number, newName: string) => Promise<ClonedSubAccountData>;
  createSubAccountRollup: SubAccountRollupsContextProps['createSubAccountRollup'];
  deleteSubAccountRollup: SubAccountRollupsContextProps['deleteSubAccountRollup'];
  updateSubAccountRollup: SubAccountRollupsContextProps['updateSubAccountRollup'];
}
export const SubAccountsContext = createContext<SubAccountsContextProps | null>(null);
SubAccountsContext.displayName = 'SubAccountsContext';

export function useSubAccounts() {
  const context = useContext(SubAccountsContext);
  if (context == null) {
    throw new Error('Missing SubAccountsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/**
 * Attempts to get the display name for the passed SubAccount.Name from the passed subAccountsByName map.
 * Built to encapsulate some nasty ternary logic away from your components.
 * Returns SubAccount.DisplayName, else SubAccount.Name (passed in), else returns undefined.
 */

export function prettifySubAccount(subAccount: string, subAccountsByName: Map<string, SubAccount> | undefined): string;
export function prettifySubAccount(
  subAccount: string | undefined,
  subAccountsByName: Map<string, SubAccount> | undefined
): string | undefined;
export function prettifySubAccount(
  subAccount: string | undefined,
  subAccountsByName: Map<string, SubAccount> | undefined
): string | undefined {
  return subAccount ? subAccountsByName?.get(subAccount)?.DisplayName ?? subAccount : undefined;
}

export interface ClonedSubAccountData {
  ApiPermissionFilters?: ApiPermissionFilter[];
  SubAccount: ISubaccount;
  RollupMemberships?: RollupMembership[];
}
