import type { Store } from '@reduxjs/toolkit';
import { createContext } from 'react';
import {
  createDispatchHook,
  createSelectorHook,
  createStoreHook,
  type RootStateOrAny,
  type TypedUseSelectorHook,
} from 'react-redux';
import type { CustomerUserDispatch, CustomerUserState } from './store';

export const CustomerUserDetailsContext = createContext<RootStateOrAny>(undefined);

export const useCustomerUserDispatch: () => CustomerUserDispatch = createDispatchHook(CustomerUserDetailsContext);
export const useCustomerUserSelector: TypedUseSelectorHook<CustomerUserState> =
  createSelectorHook(CustomerUserDetailsContext);
export const useCustomerUserStore: () => Store<CustomerUserState> = createStoreHook(CustomerUserDetailsContext);
