import type { RowNode } from 'ag-grid-community';
import { createContext, useContext } from 'react';
import { getAgGridColId, type Column } from './columns';
import type { BlotterState, BlotterTableFilter, BlotterTableSort, ColumnState, RowGroupsOpenedState } from './types';
import { getAllParentsOfNodeInclusive } from './utils';

export const BlotterTableStorage = createContext<BlotterTableStorageContextProps | undefined>(undefined);
BlotterTableStorage.displayName = 'BlotterTableStorage';

export function useBlotterTableStorage() {
  const context = useContext(BlotterTableStorage);
  if (context === undefined) {
    throw new Error('Missing BlotterTableStorageContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export function columnToColumnState(column: Column): ColumnState {
  return {
    id: getAgGridColId(column),
    hide: column.hide,
    width: column.width,
    rowGroup: column.rowGroup,
    rowGroupIndex: column.rowGroupIndex,
  };
}

export function nodeToRowGroupOpenedStateKey(node: RowNode): string {
  return getAllParentsOfNodeInclusive(node)
    .reverse()
    .map(node => node.key)
    .join('__');
}

export interface BlotterTableStorageContextProps {
  getState(blotterID: string): BlotterState | undefined;
  setColumnState(blotterID: string, columns: ColumnState[]): void;
  setSortState(blotterID: string, sort: BlotterTableSort): void;
  setFilterState(blotterID: string, filter?: BlotterTableFilter): void;
  setRowGroupsOpenedState(blotterID: string, rowGroupsOpened: RowGroupsOpenedState);
}
