import type { ReactNode } from 'react';
import styled from 'styled-components';
import { Tooltip } from '../../Tooltip';
import { Label } from '../styles';
import { ToggleSwitch, ToggleSwitchWrapper, ToggleWrapper, type ToggleWrapperProps } from './styles';

export * from './styles';

export const Toggle = styled(({ label, checked, disabled, tooltip, onChange, ...props }: ToggleProps) => {
  return (
    <ToggleWrapper
      data-testid="toggle-button"
      data-checked={checked}
      data-disabled={disabled}
      onClick={() => !disabled && onChange(!checked)}
      disabled={disabled}
      checked={checked}
      {...props}
    >
      {label && (
        <>
          {tooltip ? (
            <Tooltip tooltip={tooltip}>
              <Label tooltip={true}>{label}</Label>
            </Tooltip>
          ) : (
            <Label>{label}</Label>
          )}
        </>
      )}
      <ToggleSwitchWrapper>
        <ToggleSwitch data-testid="toggle-switch" />
      </ToggleSwitchWrapper>
    </ToggleWrapper>
  );
})``;

export interface ToggleProps extends Omit<ToggleWrapperProps, 'onClick'> {
  tooltip?: ReactNode;

  onChange(value: boolean): void;
}
