import { AccordionGroup, Box } from '@talos/kyoko';
import { BlotterContainer } from '../../BlotterContainer';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import type { BaseBlotterProps } from '../types';
import { PortfolioPerformanceBlotter } from './index';

export function PortfolioPerformanceBlotterWrapper() {
  const wrapperContext: BaseBlotterProps = {
    id: `portfolio-performance`,
    label: 'Portfolio Perforamnce Blotter',
    tag: `PortfolioManagement-PortfolioPerformance`,
  };
  const { selectedPortfolioId } = usePortfolioViewStateSelector();

  return (
    <Box w="100%" h="100%" data-testid="portfolio-performance-blotter-wrapper">
      <BlotterContainer>
        <AccordionGroup key={wrapperContext.id}>
          <PortfolioPerformanceBlotter
            blotterID={wrapperContext.tag}
            wrapperContext={wrapperContext}
            subAccountId={selectedPortfolioId}
          />
        </AccordionGroup>
      </BlotterContainer>
    </Box>
  );
}
