import type { NestedMap } from '@talos/kyoko';
import type { Observable } from 'rxjs';
import type { BalancesBlotterShowBy } from '../../../Blotters/BalancesV2/types';
import { useTreasuryManagementBalances } from '../providers/TreasuryManagementBalancesProvider';
import type { MergedBalance } from '../types';

// This hook simply just returns a nested map depending on what ShowBy you are passing in
export const useShowByBalances = (showBy: BalancesBlotterShowBy): Observable<NestedMap<MergedBalance>> => {
  const { balancesByCounterpartyObs, balancesByAssetObs } = useTreasuryManagementBalances();

  return showBy === 'counterparty' ? balancesByCounterpartyObs : balancesByAssetObs;
};
