import {
  Children,
  cloneElement,
  isValidElement,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type CSSProperties,
  type ReactElement,
  type ReactNode,
} from 'react';
import { DragDropContext, Draggable, type DraggableProvided, type DroppableProvided } from 'react-beautiful-dnd';
import styled, { useTheme } from 'styled-components';

import { NavTab } from './NavTab';
import {
  OverflowWrapper,
  RightItems,
  TabBorder,
  TabIndicator,
  TabListWrapper,
  TabsWrapper,
  TabWrapper,
} from './styles';
import { Tab } from './Tab';
import { TabButton } from './TabButton';
import { TabsContext } from './Tabs';

import { TabAppearance } from 'components/Tabs/types';
import { findLast } from 'lodash';
import { defineMessages } from 'react-intl';
import { useMountedState } from 'react-use';
import { v1 as uuid } from 'uuid';
import { useConstant, useDynamicCallback, useElementSize, useIntl } from '../../hooks';
import { modeColor } from '../../styles';
import { StrictModeDroppable } from '../../utils/StrictModeDroppable';
import type { BoxProps } from '../Core';
import { Icon, IconName } from '../Icons';
import { Popover, usePopoverState } from '../Popover';

const messages = defineMessages({
  countMore: {
    defaultMessage: '{count} More',
    id: 'Tabs.countMore',
  },
});

export interface TabListProps extends BoxProps {
  children?: ReactNode;
  rightItems?: ReactNode;
  isBordered?: boolean;
  suppressOverflowList?: boolean;
  renderAddTab?: () => ReactNode;
}

interface DraggableTabProps {
  draggableProvided: DraggableProvided;
  child: ReactElement;
  i: number;
  isDragged: boolean;
  withinOverflowList?: boolean;
}

const overflowButtonSize = 80;

export const TabList = styled(function TabList({
  children,
  rightItems,
  isBordered = false,
  suppressOverflowList = false,
  renderAddTab,
  ...props
}: TabListProps) {
  const {
    selectedIndex,
    onSelect,
    onRename,
    onCancel,
    onRemove,
    onReorder,
    onAdd,
    showAddTab,
    allowClosingLastTab,
    appearance,
    size,
  } = useContext(TabsContext)!;
  const theme = useTheme();
  const itemRefs = useRef<HTMLElement[]>([]);
  const isMounted = useMountedState();
  const [translateXMap, setTranslateXMap] = useState<number[]>(itemRefs.current.map(item => item.offsetLeft));
  const [widthMap, setWidthMap] = useState<number[]>(itemRefs.current.map(item => item.offsetWidth));
  const [isDragging, setIsDragging] = useState(false);
  const numberOfChildren = Children.count(children);
  const { formatMessage } = useIntl();

  const [maxVisible, setMaxVisible] = useState<number>(numberOfChildren);

  const {
    elementRef: containerSizeObserverRef,
    size: { offsetWidth: containerWidth },
  } = useElementSize<HTMLDivElement>({ debounceWait: 100 });

  // This useEffect tracks mounted/unmounted children, and also maintains a ResizeObserver that
  // watches if a child has been resized (due to renaming a tab for example). If so, we need to update
  // the tab indicator width.
  useEffect(() => {
    if (itemRefs.current != null) {
      const observer = new ResizeObserver(() => {
        window.requestAnimationFrame(() => {
          const widthMap = itemRefs.current.map(item => item.offsetWidth);
          const translateXMap = itemRefs.current.map(item => item.offsetLeft);
          if (isMounted()) {
            setWidthMap(widthMap);
            setTranslateXMap(translateXMap);
          }
        });
      });
      for (const itemRef of itemRefs.current) {
        if (itemRef) {
          observer.observe(itemRef);
        }
      }
      return () => observer.disconnect();
    }
  }, [children, isMounted, containerWidth]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const rightItemsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // handle cases where tabs are rendered with empty array initially, we need an initial sensible maxVisible that renders all children
    // only after rendering first time we can measure the element sizes and subsequently calculate what fits and what does not
    if (maxVisible === 0) {
      setMaxVisible(numberOfChildren);
    }
  }, [numberOfChildren, maxVisible]);

  useEffect(() => {
    if (containerRef.current && widthMap.length) {
      // Some usages of tabs (symbol selector) are always rendered but initially hidden due to the way we use downshift
      const isHidden = containerRef.current && window.getComputedStyle(containerRef.current).visibility === 'hidden';
      if (isHidden) {
        return;
      }

      if (suppressOverflowList) {
        setMaxVisible(numberOfChildren);
        return;
      }

      let availableWidth =
        containerRef.current.offsetWidth - (rightItemsRef.current?.offsetWidth || 0) - (showAddTab ? 30 : 0);

      let maxVisible;
      for (maxVisible = 0; maxVisible < numberOfChildren; ++maxVisible) {
        // unfortunately not all tabs have an accurate width tracking, only those on the main tab bar are up to date in widthMap
        // those in the overflow are all sized to the widest tab as to align the close icon, so using 90 as an average
        const nextTabWidth = widthMap[maxVisible] || 90;

        if (availableWidth - nextTabWidth > overflowButtonSize) {
          availableWidth = availableWidth - nextTabWidth;
          continue;
        } else if (maxVisible === numberOfChildren - 1 && nextTabWidth < availableWidth) {
          // if only 1 tab not fitting check if it fits reserved space instead - i.e. don't show "1 more" if the underlying tab fits there
          continue;
        }
        break;
      }

      setMaxVisible(Math.max(maxVisible, 1)); // ensure at least 1 is visible
    }
  }, [
    containerRef,
    rightItemsRef,
    containerWidth,
    widthMap,
    numberOfChildren,
    showAddTab,
    appearance,
    size,
    suppressOverflowList,
  ]);

  const handleClickOutside = useDynamicCallback((e: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
      popover.close();
    }
  });

  const popover = usePopoverState({
    usePortal: true,
    trigger: 'click',
    placement: 'bottom-end',
    isSmall: true,
    delay: undefined,
    onClickOutside: handleClickOutside,
  });

  // Ensure we are positioning TabIndicator on already calculated offset
  // of latest tab as on initial render translateXMap[selectedIndex] will be
  // undefined until useEffect calculating offsets and widths fill execute.
  const translateX = useMemo(
    () => findLast(translateXMap, v => v > 0, selectedIndex) || 0,
    [translateXMap, selectedIndex]
  );

  const droppableId = useConstant(`tab-list-${uuid()}`);
  const overflowDroppableId = useConstant(`overflow-list-${uuid()}`);

  const handleDragEnd = useCallback(
    result => {
      setIsDragging(false);
      if (result?.destination) {
        const isSourceOnOverflow = result.source.droppableId === overflowDroppableId;
        const isDestinationOnOverflow = result.destination.droppableId === overflowDroppableId;
        const isMovingFromListToOverflow = !isSourceOnOverflow && isDestinationOnOverflow;

        const destinationIndex = isMovingFromListToOverflow ? result.destination.index - 1 : result.destination.index;

        if (children?.[destinationIndex].props?.reorderable) {
          onReorder?.(result.source.index, destinationIndex);
        }
      }
    },
    [onReorder, children, overflowDroppableId]
  );

  const getListStyle = (listId: string) => {
    return {
      display: 'flex',
      flexDirection: listId === droppableId ? 'row' : 'column',
      gap: listId === droppableId && appearance === TabAppearance.Underlined ? theme.spacingMedium : 0,
    };
  };

  const DraggableTab = memo(({ draggableProvided, isDragged, child, i, withinOverflowList }: DraggableTabProps) => {
    const draggingStyles = isDragged
      ? {
          background: theme.backgroundContent,
          boxShadow: `0 2px 10px ${modeColor(theme, 'hsla(0, 0%, 0%, 0.1)', theme.colors.black.dim)}`,
        }
      : {};
    const getItemStyle = (draggableStyle): CSSProperties => ({
      display: 'flex',
      userSelect: 'none',
      padding: withinOverflowList ? '0px 8px' : 0,
      background: withinOverflowList && selectedIndex === i ? theme.colors.gray['020'] : 'transparent',
      ...draggingStyles,
      ...draggableStyle,
    });

    return (
      <div
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        style={getItemStyle(draggableProvided.draggableProps.style)}
      >
        {cloneElement(child, {
          ...child.props,
          closable: Children.count(children) === 1 ? allowClosingLastTab && child.props.closable : child.props.closable,
          appearance,
          size,
          key: i,
          'data-testid': child.props['data-testid'] ?? `tab-${i}`,
          ref: element => {
            if (element == null) {
              return;
            }
            itemRefs.current[i] = element;
            return element;
          },
          isDragging,
          onClick: e => {
            onSelect?.(i);
            child.props.onClick?.(e);
          },
          onRename: (name: string) => onRename?.(i, name),
          onCancel: () => onCancel?.(i),
          onRemove: () => onRemove?.(i),
          isSelected: selectedIndex === i,
        })}
      </div>
    );
  });

  // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md
  const renderClone = (provided, snapshot, rubric) => (
    <TabWrapper
      background="backgroundContent"
      boxShadow={`0 1px 5px ${theme.backgroundBody}`}
      appearance={appearance}
      size={size}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
    >
      {children?.[rubric.source.index]?.props?.label}
    </TabWrapper>
  );

  const getDroppable = useDynamicCallback(
    (id: string, direction: string, from: number, to: number, withinOverflowList: boolean) => (
      <StrictModeDroppable droppableId={id} direction={direction} renderClone={renderClone}>
        {(droppableProvided: DroppableProvided) => (
          <div ref={droppableProvided.innerRef} style={getListStyle(id)} {...droppableProvided.droppableProps}>
            {Children.map(children, (child, i) => {
              if (!isValidElement(child) || i < from || i >= to) {
                return null;
              }
              if (child.type === Tab || child.type === NavTab) {
                const id = child.props.id || uuid();
                const isReorderable = child.props.reorderable;
                return (
                  <Draggable tabIndex={-1} isDragDisabled={!isReorderable} key={id} draggableId={id} index={i}>
                    {(draggableProvided, snapshot) => (
                      <DraggableTab
                        isDragged={snapshot.isDragging}
                        draggableProvided={draggableProvided}
                        child={child}
                        i={i}
                        withinOverflowList={withinOverflowList}
                      />
                    )}
                  </Draggable>
                );
              }
              return child;
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    )
  );

  const overflowList = useMemo(() => {
    return maxVisible < numberOfChildren ? (
      <Popover {...popover}>
        <TabButton
          appearance={appearance}
          size={size}
          label={formatMessage(messages.countMore, { count: numberOfChildren - maxVisible })}
          isSelected={popover.isOpen || selectedIndex >= maxVisible}
          suffix={<Icon icon={popover.isOpen ? IconName.ChevronUp : IconName.ChevronDown} />}
        />
        <OverflowWrapper>
          {getDroppable(overflowDroppableId, 'vertical', maxVisible, numberOfChildren, true)}
        </OverflowWrapper>
      </Popover>
    ) : null;
  }, [
    maxVisible,
    numberOfChildren,
    popover,
    appearance,
    size,
    formatMessage,
    selectedIndex,
    getDroppable,
    overflowDroppableId,
  ]);

  // if we are adding new tab and overflow list exists, we must expand it to show
  const lastAddedIndex = useRef(-1);
  const handleAdd = useDynamicCallback(() => {
    lastAddedIndex.current = numberOfChildren;
    onAdd?.();
  });
  useEffect(() => {
    if (maxVisible <= lastAddedIndex.current) {
      popover.open();
    }
    // exclude popover or else you won't be able to close it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfChildren]);

  const handleRef = useDynamicCallback((el: HTMLDivElement) => {
    containerRef.current = el;
    containerSizeObserverRef.current = el;
  });

  return (
    <TabListWrapper {...props} size={size} appearance={appearance} ref={handleRef}>
      <TabsWrapper appearance={appearance}>
        <DragDropContext onDragStart={() => setIsDragging(true)} onDragEnd={handleDragEnd}>
          {getDroppable(droppableId, 'horizontal', 0, maxVisible, false)}
          {overflowList}
        </DragDropContext>
        {showAddTab &&
          (renderAddTab ? (
            renderAddTab()
          ) : (
            <TabButton
              appearance={appearance}
              size={size}
              onClick={handleAdd}
              suffix={<Icon icon={IconName.Plus} />}
              data-testid="add-tab-button"
            />
          ))}
        {rightItems && <RightItems ref={rightItemsRef}>{rightItems}</RightItems>}
      </TabsWrapper>
      {translateXMap.length > 0 && selectedIndex < maxVisible && (
        <TabIndicator
          appearance={appearance}
          isDragging={isDragging}
          translateX={translateX}
          width={widthMap[selectedIndex]}
        />
      )}
      <TabBorder isBordered={isBordered} />
    </TabListWrapper>
  );
})``;
