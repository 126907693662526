import type { IndexKeys } from '@talos/kyoko';
import type { IBalance } from '../../../types';
import { Balance } from '../../../types';

export type MergedBalance = {
  positive: TreasuryBalance | undefined;
  negative: TreasuryBalance | undefined;
  netAmount: number;
  netEquivalentAmount: number;
  currency: string;
  market: string;
  marketAccountID: string;
  group: string;
};
export type MergedBalanceIndexKeys = keyof Pick<MergedBalance, 'currency' | 'group' | 'marketAccountID' | 'market'>;

export type DrillKey<T> = T[IndexKeys<T>];

/**
 * A TreasuryBalance is a filtered version of Balance where the equivalent properties are required.
 * Only used in Treasury management for the donut chart, hence the name.
 */
export class TreasuryBalance extends Balance {
  override Equivalent: NonNullable<Balance['Equivalent']>;
  EquivalentCurrency: string;

  constructor(balance: Balance | IBalance, equivalent: NonNullable<Balance['Equivalent']>, equivalentCurrency: string) {
    super(balance);
    this.Equivalent = equivalent;
    this.EquivalentCurrency = equivalentCurrency;
  }
}

export const CHART_HEADER_SUFFIX_PORTAL_ID = 'chartHeaderSuffixID';
