import type { GridApi, IHeaderParams, ModelUpdatedEvent, RowNode } from 'ag-grid-community';
import { throttle } from 'lodash';
import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from '../../hooks';
import { WarningSeverity } from '../../types/WarningSeverity';
import { WARNING_COLID } from '../BlotterTable/columns/warning.types';
import { HStack } from '../Core';
import { ICON_SIZES, Icon, IconName } from '../Icons';
import { WarningSeverityIcon, prettyWarningSeverityTitle } from '../Icons/WarningSeverityIcon';

export const AgGridWarningHeader = ({ progressSort, column, api }: IHeaderParams) => {
  const intl = useIntl();
  const onSortRequested: React.MouseEventHandler<HTMLDivElement> = useCallback(
    e => {
      progressSort(e.shiftKey);
    },
    [progressSort]
  );
  const [currentSort, setCurrentSort] = useState(() => column.getSort());

  const [warningSeverity, setWarningSeverity] = useState<WarningSeverity>(WarningSeverity.NONE);

  // Updating headers based on warningSeverity if included in column data.
  const onHandleModelUpdate = useRef(
    throttle((e: ModelUpdatedEvent<{ warningSeverity: WarningSeverity }>) => {
      // Because of the throttling here, there is evidence of this api being nulled by the time the callback runs (Blotter instance deleted).
      // We handle this by returning early if the api doesnt exist for whatever reason.
      if (!e.api) {
        return;
      }

      let highestSeverity = WarningSeverity.NONE;
      e.api.forEachNodeAfterFilterAndSort(node => {
        const currWarningSeverity = tryGetWarningSeverityFromNode(e.api, node);
        if (currWarningSeverity && currWarningSeverity > highestSeverity) {
          highestSeverity = currWarningSeverity;
          // Since we found highest possible warning severity, we can quit early.
          if (highestSeverity === WarningSeverity.HIGH) {
            return;
          }
        }
      });
      setWarningSeverity(highestSeverity);
    }, 300)
  );

  useEffect(() => {
    if (!api) {
      return;
    }
    const handleModelUpdated = (e: ModelUpdatedEvent) => onHandleModelUpdate.current && onHandleModelUpdate.current(e);
    api.addEventListener('modelUpdated', handleModelUpdated);
    return () => api.removeEventListener('modelUpdated', handleModelUpdated);
  }, [api]);

  useEffect(() => {
    if (!api) {
      return;
    }
    const columnSortChanged = () => {
      setCurrentSort(column.getSort());
    };
    api.addEventListener('sortChanged', columnSortChanged);
    return () => api.removeEventListener('sortChanged', columnSortChanged);
  }, [api, column]);

  return (
    <HStack h="100%" alignItems="center" justifyContent="center" w="100%" onClick={onSortRequested}>
      {currentSort == null && warningSeverity !== WarningSeverity.NONE && (
        <WarningSeverityIcon severity={warningSeverity} title={prettyWarningSeverityTitle(warningSeverity, intl)} />
      )}
      {currentSort && (
        <Icon icon={currentSort === 'desc' ? IconName.ArrowDown : IconName.ArrowUp} size={ICON_SIZES.MEDIUM} />
      )}
    </HStack>
  );
};

// This interface is to be extended by implementers of the warning column type
export interface WarningValue {
  severity: WarningSeverity;
}

// This function grabs a warningSeverity off of the data on the node if it exists, otherwise tries to grab a severity
// from the value of the node + WARNING_COLID column
export function tryGetWarningSeverityFromNode(api: GridApi, node: RowNode): WarningSeverity | undefined {
  if (node.data?.warningSeverity != null) {
    return node.data.warningSeverity;
  }

  // Try safely to see if we can get a proper .severity off the valueGetter of the column
  const maybeWarningValue: Partial<WarningValue> | undefined = api.getValue(WARNING_COLID, node);
  if (maybeWarningValue != null && maybeWarningValue.severity != null) {
    return maybeWarningValue.severity;
  }

  return undefined;
}
