import type { SubAccountReconMatchStatusEnum, TabProps } from '@talos/kyoko';

export const BREAK_DETAILS_BLOTTER_PORTAL_ID = 'break-details-blotter-portal-id';

export interface BreakDetailsBlotterFilter {
  Statuses?: SubAccountReconMatchStatusEnum[];
}

export interface BreakDetailsBlotterTabProps extends TabProps {
  defaultFilter: Partial<BreakDetailsBlotterFilter>;
}
