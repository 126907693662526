import type { SubAccountReconCheckpoint, SubAccountReconCheckpointStatusEnum, TreeRow } from '@talos/kyoko';
import type {
  ICellRendererFunc,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { compact } from 'lodash';
import type { ReconOverviewBlotterColumnIDs } from './useSubAccountReconOverviewBlotterColumns';

export type SubAccountReconOverviewRow = ReconSubAccountRow | ReconAssetRow | ReconMarketAccountRow;

/**
 * Given a SubAccountReconCheckpoint, constructs the rows necessary for visualising this data type in a tree blotter.
 * The produced structure is:
 * - SubAccountRow
 *   - AssetRow
 *     - MarketAccountRow 1
 *     - MarketAccountRow 2
 *     - ...
 */
export function subAccReconCheckpointToBlotterRows(
  checkpoint: SubAccountReconCheckpoint
): SubAccountReconOverviewRow[] {
  const subAccountLevelRow = new ReconSubAccountRow(
    checkpoint.ID,
    checkpoint.AccountsKey,
    checkpoint.SubAccountDetails.SubAccounts.map(s => s.SubAccount)
  );

  const assetLevelRow = new ReconAssetRow({
    checkpointID: checkpoint.ID,
    accountsKey: checkpoint.AccountsKey,
    asset: checkpoint.Asset,
    subAccountAmount: checkpoint.SubAccountDetails.Amount,
    marketAccountAmount: checkpoint.MarketAccountDetails?.Amount,
    breaks: checkpoint.Unmatched?.Count,
    breakAmount: checkpoint.Unmatched?.Amount,
    status: checkpoint.Status,
    subAccounts: checkpoint.SubAccountDetails.SubAccounts.map(s => s.SubAccount),
    startTime: checkpoint.StartTime,
    endTime: checkpoint.EndTime,
    lastUpdateTime: checkpoint.LastUpdateTime,
  });

  const marketAccountLevelRows = checkpoint.MarketAccountDetails?.MarketAccounts.map(
    mktAccDetail =>
      new ReconMarketAccountRow({
        checkpointID: checkpoint.ID,
        accountsKey: checkpoint.AccountsKey,
        asset: checkpoint.Asset,
        marketAccount: mktAccDetail.MarketAccount,
        marketAccountAmount: mktAccDetail.Amount,
        breaks: mktAccDetail.Unmatched?.Count,
        breakAmount: mktAccDetail.Unmatched?.Amount,
        status: mktAccDetail.Status,
      })
  );

  return [subAccountLevelRow, assetLevelRow, ...compact(marketAccountLevelRows)];
}

export class ReconSubAccountRow implements TreeRow {
  AccountsKey: string;
  /** ID of the checkpoint from which this instance originates */
  checkpointID: string;

  get rowID() {
    return this.AccountsKey;
  }

  get dataPath() {
    return [this.AccountsKey];
  }

  SubAccounts: string[];

  groupColumnValueGetter(params: ValueGetterParams<ReconSubAccountRow>): string[] {
    return this.SubAccounts;
  }

  groupColumnFormattedValueGetter({ value, context }: ValueFormatterParams<ReconSubAccountRow, string[]>): string {
    return value.map(sa => context.current.subAccountsByName?.get(sa)?.DisplayName ?? sa).join(', ');
  }

  groupColumnFilterValueGetter(params: ValueGetterParams) {
    return this.groupColumnValueGetter(params);
  }

  constructor(checkpointID: string, accountsKey: string, subAccounts: string[]) {
    this.AccountsKey = accountsKey;
    this.checkpointID = checkpointID;
    this.SubAccounts = subAccounts;
  }
}

export class ReconAssetRow implements TreeRow {
  AccountsKey: string;
  /** ID of the checkpoint from which this instance originates */
  checkpointID: string;
  SubAccounts: string[];
  Asset: string;
  Status: SubAccountReconCheckpointStatusEnum;
  SubAccountAmount?: string;
  MarketAccountAmount?: string;
  Breaks?: number;
  BreakAmount?: string;
  StartTime: string;
  EndTime: string;
  LastUpdateTime: string;

  get dataPath() {
    return [this.AccountsKey, this.Asset];
  }

  get rowID() {
    return this.dataPath.join('-');
  }

  groupColumnValueGetter(params: ValueGetterParams<ReconAssetRow>) {
    return this.Asset;
  }

  groupColumnFormattedValueGetter({ value }: ValueFormatterParams<ReconAssetRow, string>): string {
    return value;
  }

  groupColumnFilterValueGetter(params: ValueGetterParams<ReconAssetRow>) {
    return this.groupColumnValueGetter(params);
  }

  groupColumnInnerCellRenderer(params: ICellRendererParams<ReconAssetRow, string>) {
    // Try to resolve the Asset column and its cell renderer. If found, use it
    const assetCellRenderer: ICellRendererFunc | undefined = params.columnApi
      .getColumn('Asset' satisfies ReconOverviewBlotterColumnIDs)
      ?.getColDef().cellRenderer;

    if (assetCellRenderer) {
      return assetCellRenderer(params);
    }

    // Else just return the base string value in this case
    return params.value;
  }

  constructor({
    accountsKey,
    checkpointID,
    asset,
    marketAccountAmount,
    subAccountAmount,
    breakAmount,
    breaks,
    status,
    subAccounts,
    startTime,
    endTime,
    lastUpdateTime,
  }: {
    accountsKey: string;
    checkpointID: string;
    asset: string;
    marketAccountAmount?: string;
    subAccountAmount?: string;
    breaks?: number;
    breakAmount?: string;
    status: SubAccountReconCheckpointStatusEnum;
    subAccounts: string[];
    startTime: string;
    endTime: string;
    lastUpdateTime: string;
  }) {
    this.AccountsKey = accountsKey;
    this.checkpointID = checkpointID;
    this.Asset = asset;
    this.SubAccountAmount = subAccountAmount;
    this.MarketAccountAmount = marketAccountAmount;
    this.BreakAmount = breakAmount;
    this.Breaks = breaks;
    this.Status = status;
    this.SubAccounts = subAccounts;
    this.StartTime = startTime;
    this.EndTime = endTime;
    this.LastUpdateTime = lastUpdateTime;
  }
}

export class ReconMarketAccountRow implements TreeRow {
  AccountsKey: string;
  /** ID of the checkpoint from which this instance originates */
  checkpointID: string;
  Asset: string;
  MarketAccount: string;
  MarketAccountAmount: string;
  Status: SubAccountReconCheckpointStatusEnum;
  Breaks?: number;
  BreakAmount?: string;

  get dataPath() {
    return [this.AccountsKey, this.Asset, this.MarketAccount];
  }

  get rowID() {
    return this.dataPath.join('-');
  }

  groupColumnValueGetter(params: ValueGetterParams<ReconMarketAccountRow>) {
    return this.MarketAccount;
  }

  groupColumnFormattedValueGetter(params: ValueFormatterParams<ReconMarketAccountRow, string>) {
    return params.context.current.marketAccountsByName?.get(this.MarketAccount)?.DisplayName ?? this.MarketAccount;
  }

  groupColumnFilterValueGetter(params: ValueGetterParams) {
    return this.groupColumnValueGetter(params);
  }

  constructor({
    accountsKey,
    checkpointID,
    asset,
    marketAccount,
    marketAccountAmount,
    breaks,
    breakAmount,
    status,
  }: {
    accountsKey: string;
    checkpointID: string;
    asset: string;
    marketAccount: string;
    marketAccountAmount: string;
    breaks?: number;
    breakAmount?: string;
    status: SubAccountReconCheckpointStatusEnum;
  }) {
    this.AccountsKey = accountsKey;
    this.checkpointID = checkpointID;
    this.Asset = asset;
    this.MarketAccount = marketAccount;
    this.MarketAccountAmount = marketAccountAmount;
    this.Breaks = breaks;
    this.BreakAmount = breakAmount;
    this.Status = status;
  }
}
