import { Provider } from 'react-redux';
import { CustomerUserDetailsDrawer as CustomerDrawer } from './CustomerUserDetailsDrawer';
import { CustomerUserDetailsContext } from './state/hooks';
import { getCustomerStore } from './state/store';
import type { NewUserDrawerProps } from './useCustomerUserDetailsDrawer';

const customerStore = getCustomerStore();
export function CustomerUserDetailsDrawer(props: NewUserDrawerProps) {
  return (
    <Provider context={CustomerUserDetailsContext} store={customerStore}>
      <CustomerDrawer {...props} key={props.customerUserID} />
    </Provider>
  );
}
