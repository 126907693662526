import {
  ConnectionModeEnum,
  ConnectionStatusIndicator,
  IconName,
  MarketAccountTypeEnum,
  Tooltip,
  connectionStatusText,
  useMarketAccountsContext,
  type Column,
  type MarketCredential,
} from '@talos/kyoko';
import type { ICellRendererParams, RowNode } from 'ag-grid-enterprise';
import { compact } from 'lodash';
import { useMemo } from 'react';
import type { HydratedMarketCredential } from './types';

interface useColumnsProps {
  canEditCredential: boolean;
  handleEdit: (marketCredential: MarketCredential) => void;
}

export const useColumns = ({ canEditCredential, handleEdit }: useColumnsProps) => {
  const { marketAccountsByCredentialIDByName } = useMarketAccountsContext();

  return useMemo<Column[]>(
    () =>
      compact([
        {
          type: 'market',
          field: 'Market',
          sortable: true,
          params: {
            withLogo: true,
          },
        },
        {
          type: 'custom',
          field: 'Label',
          sortable: true,
          params: {
            cellRenderer: (params: ICellRendererParams<HydratedMarketCredential>) => {
              const { data } = params;
              if (!data) {
                return null;
              }
              const marketAccounts = marketAccountsByCredentialIDByName.get(data.CredentialID);
              const tooltip = [...(marketAccounts?.values() ?? [])]
                .filter(marketAccount => marketAccount.Type === MarketAccountTypeEnum.Trading)
                .map(marketAccount => marketAccount.DisplayName)
                .join(', ');

              return (
                <Tooltip placement="right" tooltip={tooltip}>
                  {params.data?.Label}
                </Tooltip>
              );
            },
          },
        },
        {
          type: 'custom',
          field: 'Status',
          params: {
            cellRenderer: (params: ICellRendererParams<HydratedMarketCredential>) => {
              const { data } = params;
              if (!data) {
                return null;
              }
              return (
                <ConnectionStatusIndicator
                  showText={false}
                  status={data.Status}
                  popoverText={connectionStatusText(data.Status)}
                />
              );
            },
          },
        },
        {
          type: 'custom',
          field: 'Mode',
          params: {
            valueFormatter: ({ data }) => (data?.Mode === ConnectionModeEnum.Up ? 'Enabled' : 'Disabled'),
          },
        },
        {
          type: 'text',
          field: 'MarketType',
          title: 'Type',
          sortable: true,
        },
        {
          type: 'text',
          field: 'CredentialID',
          width: 120,
        },
        canEditCredential
          ? {
              id: 'edit',
              type: 'iconButton',
              pinned: 'right',
              width: 60,
              suppressColumnsToolPanel: true,
              params: {
                onClick: ({ node }: { node: RowNode }) => handleEdit(node.data),
                icon: IconName.Pencil,
              },
            }
          : null,
      ]),
    [marketAccountsByCredentialIDByName, canEditCredential, handleEdit]
  );
};
