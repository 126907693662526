import { PnlLookbackEnum, POSITION_SUB_ACCOUNT, useSubscription, type Position } from '@talos/kyoko';
import { customEODToRequestEOD } from 'containers/Blotters/PositionsV3/SubAccounts/useSubAccountPositionsBlotterRequest';
import type { PerfSubAccountPositionsRequest } from 'containers/Portfolio/Performance/types';
import { isEqual } from 'lodash';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo, useRef } from 'react';
import { useGetSubAccountNamesForRequest } from '../../providers/pipes/useGetSubAccountNamesForRequest';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from '../tokens';

/** Retrieve WS subscription to get Position feed for the PMS Perf blotter
 * - key for retrieving pnl lookup data for each position
 */
export const usePMSSubAccountPositionsSub = () => {
  const { selectedPortfolioId, showZeroBalances } = usePortfolioViewStateSelector();
  const getSubAccountNamesForRequest = useGetSubAccountNamesForRequest();

  const { customEOD, homeCurrency } = useDisplaySettings();

  const lastRequest = useRef<PerfSubAccountPositionsRequest | null>(null);
  const request = useMemo(() => {
    const newRequest: PerfSubAccountPositionsRequest | null = selectedPortfolioId
      ? {
          name: POSITION_SUB_ACCOUNT,
          tag: 'portfolio/performance',
          ShowZeroBalances: showZeroBalances,
          EquivalentCurrency: homeCurrency,
          Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
          EndOfDay: customEODToRequestEOD(customEOD),
          PnLLookbacks: [
            PnlLookbackEnum.H24,
            PnlLookbackEnum.D30,
            PnlLookbackEnum.D7,
            PnlLookbackEnum.D365,
            PnlLookbackEnum.MonthToDate,
            PnlLookbackEnum.Today,
            PnlLookbackEnum.WeekToDate,
            PnlLookbackEnum.YearToDate,
          ],
          SubAccounts: getSubAccountNamesForRequest(selectedPortfolioId ?? -1),
        }
      : null;
    return isEqual(lastRequest.current, newRequest) ? lastRequest.current : newRequest;
  }, [customEOD, homeCurrency, showZeroBalances, getSubAccountNamesForRequest, selectedPortfolioId]);

  const { data: subscription } = useSubscription<Position>(request, {
    replay: false,
    loadAll: true,
  });

  return subscription;
};
