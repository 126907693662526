import Big from 'big.js';
import { uniq } from 'lodash';
import { toBigWithDefault } from '../utils';
import type { Allocation } from './Allocation';
import type { LedgerUpdateTypeEnum } from './types';

// To be replaced by backend enum from types.ts once backend is merged
export enum SubAccountReconMatchStatusEnum {
  Unmatched = 'Unmatched',
  UnmatchedSubAccount = 'UnmatchedSubAccount',
  UnmatchedMarketAccount = 'UnmatchedMarketAccount',
  Matched = 'Matched',
  Resolved = 'Resolved',
  Unresolved = 'Unresolved',
}

export class SubAccountReconMatch {
  ID!: string;
  Asset!: string;
  CheckpointID!: string;
  LastUpdateTime!: string;
  EventType!: LedgerUpdateTypeEnum;
  Status!: SubAccountReconMatchStatusEnum;
  TransactTime!: string;
  TradeID!: string;

  /** An array of the sub accounts (name) represented in this ReconMatch */
  subAccounts?: string[];
  /** An array of allocation-per-sub account within this ReconMatch. Denotes quantities. */
  subAccountAllocations?: Allocation[];

  /** An array of allocations to prime the resolution drawer with */
  suggestedAllocationAmendments?: Allocation[];

  get breakAmount() {
    const marketAccountEventAmount = toBigWithDefault(this.MarketAccountEvent?.Amount, 0);
    const subAccountEventsAmount = toBigWithDefault(this.SubAccountEvents?.Amount, 0);
    return subAccountEventsAmount.minus(marketAccountEventAmount).toFixed();
  }

  get hasBreak() {
    return !toBigWithDefault(this.breakAmount, 0).eq(0);
  }

  MarketAccountEvent?: {
    Amount: string;
    AvgCost: string;
    AvgCostCurrency: string;
    MarketAccount: string;
  };

  SubAccountEvents?: {
    Amount: string;
    Events: {
      Amount: string;
      AvgCost: string;
      AvgCostCurrency: string;
      SubAccount: string;
    }[];
  };

  constructor(data: SubAccountReconMatch) {
    Object.assign(this, data);
    this.subAccounts = uniq(this.SubAccountEvents?.Events.map(event => event.SubAccount));

    // Below we convert the SubAccountEvents on this entity into an Allocations array
    if (this.SubAccountEvents) {
      const subAccountAmountsMap = this.SubAccountEvents?.Events.reduce((allocs, event) => {
        const allocForSubAcc: Big = allocs.get(event.SubAccount) ?? Big(0);
        const newValue = allocForSubAcc.plus(toBigWithDefault(event.Amount, 0));
        allocs.set(event.SubAccount, newValue);
        return allocs;
      }, new Map<string, Big>());

      this.subAccountAllocations = [...subAccountAmountsMap.entries()].map(([subAccount, amountBig]) => ({
        subAccount,
        value: amountBig.toFixed(),
      }));

      // Our suggestion is simple: allocate the entire break to the first allocation.
      this.suggestedAllocationAmendments = this.subAccountAllocations.map((alloc, i) => ({
        ...alloc,
        value: i === 0 ? this.breakAmount : '0',
      }));
    }
  }
}
