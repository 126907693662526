import { useCallback, type CSSProperties, type KeyboardEvent, type ReactNode, type RefObject } from 'react';
import { Z_INDEX } from '../../../styles';
import { IconButton } from '../../Button/IconButton/index';
import { Flex, HStack } from '../../Core';
import { Icon } from '../../Icons/Icon';
import { IconName } from '../../Icons/IconNames';
import { Portal, useTopLevelPortalElement } from '../../Portal';
import { Text } from '../../Text';
import type { UseDropdownPopperOutput, UseDropdownPopperProps } from '../Dropdown/useDropdownPopper';
import { FormControlSizes } from '../types';
import { DropdownContext, dropdownContextDefaultValue } from './DropdownContext';
import { DropdownContentWrapper } from './styles';
import { DROPDOWN_ID } from './tokens';

export type DropdownProps = UseDropdownPopperOutput & {
  maxHeight?: number;
  comboboxStyles?: CSSProperties;
  menuStyles?: CSSProperties;
  size?: FormControlSizes;
  style?: CSSProperties;
  id?: string;
  children: ReactNode;
  /**  Whether or not to portalize the dropdown. Portalizing a DOM element places it at the root of the page,
   * meaning that it will be detached from the sibling elements and rendered above.
   * Used when there are annoying issues with overflowing / overlapping elements.
   * Setting this to true can cause positioning issues if the reference element moves around a lot or is animated on entry for example.
   */
  portalize?: boolean;
  isOpen: boolean;
  dropdownContentRef?: RefObject<HTMLDivElement>;

  onTabOut?: (event: KeyboardEvent<HTMLElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLElement>) => void;

  /**
   * Whether or not this dropdown is a parent to other "child" dropdowns within it.
   * This option influences the zIndex applied to make sure that portalized child dropdowns render
   * on top of this parent dropdown.
   */
  isParentDropdown?: boolean;

  headerProps?: DropdownHeaderProps;
};

// Expose a subset of the props for now
// also cast the <T> to any since we're not using it
export type ExposedDropdownProps = Pick<DropdownProps, 'portalize' | 'maxHeight'> &
  Pick<UseDropdownPopperProps, 'dropdownPlacement' | 'dropdownWidth'>;

export function Dropdown({
  menuStyles,
  isOpen,
  size,
  children,
  portalize = false,
  referenceElement,
  dropdownWidth,
  setPopperElement,
  popper,
  dropdownContentRef,
  onTabOut,
  onKeyDown,
  isParentDropdown,
  id,
  dropdownHeight,
  maxHeight,

  headerProps,
}: DropdownProps) {
  // We expose keydown events to parents here through the hooks.
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (onTabOut && event.key === 'Tab') {
        onTabOut(event);
      }

      onKeyDown && onKeyDown(event);
    },
    [onTabOut, onKeyDown]
  );
  useTopLevelPortalElement(DROPDOWN_ID);

  const { attributes, styles } = popper;

  const dropdownContent = (
    <div>
      <DropdownContentWrapper
        onKeyDown={handleKeyDown}
        {...attributes.popper}
        style={{
          ...styles.popper,
          minWidth: referenceElement?.offsetWidth,
          width: dropdownWidth,
          visibility: isOpen ? 'visible' : 'hidden',
          ...menuStyles,
          zIndex: isParentDropdown ? Z_INDEX.parentDropdown : Z_INDEX.dropdown,
        }}
        size={size}
        ref={setPopperElement}
        id={id}
      >
        {/* We add this context here to allow components inside a dropdown to know that they are being rendered inside of a dropdown. 
            Defining this as a context instead of a prop to each component allows us to just do this once. 
            Components then check to see if they are within this context and can use that information to do things such as modify their z-index to render on top of the dropdown.
         */}
        <DropdownContext.Provider value={dropdownContextDefaultValue}>
          <Flex
            flexDirection="column"
            background="backgroundDropdownResults"
            h={dropdownHeight}
            maxHeight={maxHeight}
            ref={dropdownContentRef}
          >
            {headerProps && <DropdownHeader {...headerProps} />}
            {/* the children here is the autocomplete results wrapper from AutocompleteDropdown.tsx */}
            {children}
          </Flex>
        </DropdownContext.Provider>
      </DropdownContentWrapper>
    </div>
  );

  return <>{portalize ? <Portal portalId={DROPDOWN_ID}>{dropdownContent}</Portal> : dropdownContent} </>;
}

export interface DropdownHeaderProps {
  icon?: IconName;
  title?: string;
  suffix?: ReactNode;
  onCloseClicked?: () => void;
}

const DropdownHeader = ({ icon, title, suffix, onCloseClicked }: DropdownHeaderProps) => {
  return (
    <HStack
      gap="spacingComfortable"
      pl="spacingSmall"
      justifyContent="space-between"
      fontSize="fontSizeSm"
      background="backgroundDropdownGroupHeader"
    >
      {/* Icon, Title */}
      <HStack gap="spacingSmall">
        {icon && <Icon icon={icon} />}
        {title && <Text>{title}</Text>}
      </HStack>

      <HStack gap="spacingSmall">
        {suffix}
        {onCloseClicked && (
          <IconButton
            ghost
            color="colorTextSubtle"
            size={FormControlSizes.Small}
            icon={IconName.Close}
            onClick={onCloseClicked}
          />
        )}
      </HStack>
    </HStack>
  );
};
