import {
  Dialog,
  FormGroup,
  Input,
  NotificationVariants,
  logger,
  useDynamicCallback,
  useGlobalToasts,
  type ISubaccount,
  type UseDisclosureReturn,
} from '@talos/kyoko';
import { useSubAccounts } from 'providers';
import { useCallback, useState, type KeyboardEventHandler } from 'react';

interface NewSubAccountRollupDialogProps {
  dialog: UseDisclosureReturn;
  onRollupCreated: (rollup: ISubaccount) => void;
}

export function NewSubAccountRollupDialog({ dialog, onRollupCreated }: NewSubAccountRollupDialogProps) {
  const { add: addToast } = useGlobalToasts();
  const { createSubAccountRollup } = useSubAccounts();
  const [newRollupName, setNewRollupName] = useState<string>('');

  const handleAddNewSubAccount = useDynamicCallback(() => {
    createSubAccountRollup(newRollupName)
      .then(newlyCreatedSubAccount => {
        setNewRollupName('');
        addToast({
          text: <span>Sub account rollup {newRollupName} created.</span>,
          variant: NotificationVariants.Positive,
        });
        onRollupCreated(newlyCreatedSubAccount);
        dialog.close();
      })
      .catch(e => {
        logger.error(e);
        addToast({
          text: `Could not create sub account rollup${e?.message ? `: ${e.message}` : ''}.`,
          variant: NotificationVariants.Negative,
        });
      });
  });

  const handleOnKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleAddNewSubAccount();
      }
    },
    [handleAddNewSubAccount]
  );

  return (
    <Dialog
      {...dialog}
      width={520}
      title="New Sub Account Rollup"
      showClose
      onConfirm={handleAddNewSubAccount}
      confirmLabel="Create"
    >
      <FormGroup label="Sub Account Rollup Name">
        <Input onChange={e => setNewRollupName(e.target.value)} value={newRollupName} onKeyDown={handleOnKeyDown} />
      </FormGroup>
    </Dialog>
  );
}
