import { memo, useEffect } from 'react';

import {
  Button,
  ButtonVariants,
  Flex,
  FormTable,
  IconName,
  NotificationVariants,
  PanelActions,
  VStack,
  copyText,
  useConstant,
  useFormTable,
  useGlobalToasts,
  type Column,
  type CustomerUserApiKey,
} from '@talos/kyoko';
import type { RowNode } from 'ag-grid-enterprise';
import { deleteApiKey, updateApiKey } from '../state/apiKeys/apiKeysSlice';
import { useCustomerUserDispatch, useCustomerUserSelector } from '../state/hooks';
import { useCustomerApiKeys } from './useCustomerApiKeys';

const getApiSecret = (data: CustomerUserApiKey) => {
  return data.ApiSecret || '*****';
};

export const CustomerApiKeysFormTable = memo(() => {
  const { add: addToast } = useGlobalToasts();
  const { apiKeys, dirtyKeys, deletedApiKeys, createAPIKey, fetchApiKeys } = useCustomerApiKeys();
  const dispatch = useCustomerUserDispatch();
  const customerUserId = useCustomerUserSelector(state => state.userDetails.customerUserId);

  const columns = useConstant<Column[]>([
    { type: 'date', field: 'Timestamp', width: 150, sort: '-' },
    { type: 'text', field: 'ApiKey', title: 'API Key', width: 130 },
    {
      type: 'custom',
      field: 'ApiSecret',
      title: 'API Secret',
      width: 220,
      params: {
        valueGetter: ({ data }: { data: CustomerUserApiKey }) => getApiSecret(data),
      },
    },
    { type: 'text', field: 'WhitelistedIps', title: 'Whitelisted IPs', editable: true, width: 130 },
    { type: 'filler', id: 'filler' },
    {
      type: 'iconButton',
      id: 'copy',
      params: {
        icon: IconName.ClipboardCopy,
        onClick: ({ node }: { node: RowNode }) => {
          const rowData: CustomerUserApiKey = node.data;
          copyText(`USER:${rowData.CustomerUserID}
KEY:${rowData.ApiKey}
SECRET:${getApiSecret(rowData)}`);
          addToast({
            text: `Details copied to clipboard.`,
            variant: NotificationVariants.Default,
          });
        },
      },
      width: 40,
    },
    {
      type: 'remove',
      id: 'remove',
      params: {
        onClick: ({ node }: { node: RowNode<CustomerUserApiKey> }) => {
          node.data && dispatch(deleteApiKey(node.data));
        },
      },
    },
  ]);

  const formTable = useFormTable<CustomerUserApiKey>({
    rowID: 'CustomerUserApiKeyID',
    data: apiKeys,
    dirtyRowIds: dirtyKeys,
    deletedRowIds: deletedApiKeys,
    columns,
    onChange: row => {
      dispatch(updateApiKey(row.data));
    },
  });

  const { reset } = formTable;
  useEffect(() => {
    reset(apiKeys, dirtyKeys, deletedApiKeys);
  }, [reset, apiKeys, dirtyKeys, deletedApiKeys]);

  useEffect(() => {
    if (customerUserId) {
      fetchApiKeys(customerUserId);
    }
  }, [customerUserId, fetchApiKeys]);

  const headerActions = (
    <PanelActions>
      <Button startIcon={IconName.Plus} variant={ButtonVariants.Positive} onClick={() => createAPIKey()}>
        Generate API Key
      </Button>
    </PanelActions>
  );

  return (
    <VStack alignItems="stretch" h="100%">
      <Flex justifyContent="flex-end" mt="spacingMedium" mb="spacingMedium" alignItems="baseline">
        {headerActions}
      </Flex>
      <FormTable {...formTable} />
    </VStack>
  );
});
