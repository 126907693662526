import {
  ACTION,
  BlotterTableExtrasMenu,
  Button,
  ButtonVariants,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  Toggle,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useMixpanel,
} from '@talos/kyoko';

import { useRoleAuth } from '../../../hooks';

import { OMSView } from 'components/OMS/OMSView';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo, type SetStateAction } from 'react';
import { openView } from '../../../components/OMS/OMSSlice';
import type { BlotterTableOrderFilter } from './useOrderFilter';

type OrderOptionsProps = {
  changeFilter: (action: SetStateAction<BlotterTableOrderFilter>) => void;
  filter: BlotterTableOrderFilter;
  handleExport: () => void;
  handleCloneTab?: () => void;
  tabType: 'FilteredOrders' | 'OpenOrders';
};

export function OrderOptions({ filter, changeFilter, handleCloneTab, handleExport, tabType }: OrderOptionsProps) {
  const mixpanel = useMixpanel();

  const { isAuthorized } = useRoleAuth();
  const dispatch = useAppStateDispatch();

  const handleImport = () => {
    mixpanel.track(MixpanelEvent.OpenOrderImportForm);
    dispatch(openView(OMSView.OrderImport));
  };

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleOnApiOrdersChange = useDynamicCallback((value: boolean) => {
    mixpanel.track(MixpanelEvent.ApiOrdersToggle, { [MixpanelEventProperty.Enabled]: value });
    changeFilter(curr => ({ ...curr, HideApiCalls: !value }));
  });

  const handleOnCloneTab: typeof handleCloneTab = useMemo(
    () =>
      handleCloneTab
        ? () => {
            mixpanel.track(MixpanelEvent.CloneTab);
            return handleCloneTab();
          }
        : undefined,
    [handleCloneTab, mixpanel]
  );

  // API orders toggle isn't hooked up to the orders provider which populated the OpenOrders blotter, so hide it
  const showAPIOrdersToggle = tabType !== 'OpenOrders';

  return (
    <div>
      <BlotterTableExtrasMenu {...extrasMenuPopover}>
        {showAPIOrdersToggle && (
          <Toggle
            label="API Orders"
            size={FormControlSizes.Small}
            tooltip="Include orders submitted through the API in the results."
            checked={!filter.HideApiCalls}
            onChange={handleOnApiOrdersChange}
          />
        )}
        {handleOnCloneTab && (
          <Button
            startIcon={IconName.Duplicate}
            variant={ButtonVariants.Default}
            size={FormControlSizes.Small}
            onClick={handleOnCloneTab}
          >
            Clone Tab
          </Button>
        )}
        {isAuthorized(ACTION.SUBMIT_ORDER) && (
          <Button
            startIcon={IconName.DocumentDownload}
            variant={ButtonVariants.Default}
            size={FormControlSizes.Small}
            onClick={() => {
              extrasMenuPopover.close();
              handleImport();
            }}
          >
            Import Orders
          </Button>
        )}
        <Button
          startIcon={IconName.DocumentUpload}
          variant={ButtonVariants.Default}
          size={FormControlSizes.Small}
          onClick={handleExport}
        >
          Export
        </Button>
      </BlotterTableExtrasMenu>
    </div>
  );
}
