import {
  getAllParentsForSubAccount,
  isAssetSpotSecurity,
  stringColumnComparator,
  type Asset,
  type Currency,
  type ISubaccount,
  type Security,
} from '@talos/kyoko';
import type { RowNode } from 'ag-grid-community';
import { compact } from 'lodash';
import { useSubAccountRollupMemberships, useSubAccounts } from 'providers';
import { useMemo } from 'react';

/** For our purposes, a "SubAccountLimit" is just the minimal needed properties on the limit to be able to be used in this hook */
interface SubAccountLimit {
  SubAccount?: string; // undefined means wildcard
  ThresholdAsset?: string;
  MatchAsset?: string;
}

export const LIMIT_CURRENCIES = ['BTC', 'USD', 'EUR'];
export const LIMIT_CURRENCIES_MAP = new Map(LIMIT_CURRENCIES.map(lc => [lc, { Symbol: lc, Description: '' }]));

// For our two types of sub account limit creation screens, we want to allow the user to set their MatchAsset to be the ThresholdAsset.
// This can be anything, and it works on the BE because the conversion is just 1:1 of course.
// However whenever we change the MatchAsset, we have to make sure that we don't leave a bad straggling ThresholdAsset behind.
export function assetColumnCustomDataModifier(data: Partial<SubAccountLimit>) {
  if (data.ThresholdAsset == null) {
    return;
  }

  if (!LIMIT_CURRENCIES_MAP.has(data.ThresholdAsset) && !(data.ThresholdAsset === data.MatchAsset)) {
    // Delete the ThresholdAsset field if it is not in the set of our base accepted threshold assets, and is not the selected MatchAsset
    delete data['ThresholdAsset'];
  }
}

export function customSubAccountLimitComparator(
  valueA: Asset | undefined,
  valueB: Asset | undefined,
  nodeA: RowNode<SubAccountLimit>,
  nodeB: RowNode<SubAccountLimit>
) {
  const symbolComparisonResult = stringColumnComparator(valueA?.Symbol, valueB?.Symbol);

  // If the two symbols are not the same, we return this sorting result
  if (symbolComparisonResult !== 0) {
    return symbolComparisonResult;
  }

  // If the Symbol is the same, sort by SubAccount. You can add more secondary-tertiary... sorts in the array below if needed.
  const { data: dataA } = nodeA;
  const { data: dataB } = nodeB;
  for (const key of ['SubAccount'] satisfies (keyof SubAccountLimit)[]) {
    const result = stringColumnComparator(dataA?.[key], dataB?.[key]);
    if (result !== 0) {
      return result;
    }
  }
  // These rows are the same
  return 0;
}

/**
 * This hook computes and returns a list of limits for a given SubAccount, all its parents, and any potential wildcard limits.
 * It also returns some intermediate state which might help the implementer
 *
 * Essentially, the hook answers the question: "Give me all limits _relevant_ for this SubAccount".
 */
export const useSubAccountLimitsForSubAccount = <T extends SubAccountLimit>({
  limitsList,
  forSubAccount,
}: {
  limitsList: T[] | undefined;
  forSubAccount?: ISubaccount;
}) => {
  const { subAccountsByName, subAccountsByID } = useSubAccounts();
  const { rollupMembershipsByChildParent } = useSubAccountRollupMemberships();

  const forSubAccountParentNamesSet = useMemo(() => {
    if (forSubAccount == null || subAccountsByName == null || subAccountsByID == null) {
      return undefined;
    }

    // I dont like that the backend uses sub account ids for rollup memberships but names for everything else...
    const allParentIDs = getAllParentsForSubAccount(forSubAccount.SubaccountID, rollupMembershipsByChildParent);
    const allParentNamesList = compact([...allParentIDs].map(subAccountID => subAccountsByID.get(subAccountID)?.Name));
    const allParentNamesSet = new Set(allParentNamesList);

    return allParentNamesSet;
  }, [rollupMembershipsByChildParent, forSubAccount, subAccountsByName, subAccountsByID]);

  const forSubAccountParentLimits = useMemo(() => {
    if (forSubAccountParentNamesSet == null || limitsList == null) {
      return undefined;
    }

    return limitsList.filter(
      limit =>
        // Include if we're either wildcard, or the selected sub acc itself, or any of our parents
        limit.SubAccount === undefined ||
        limit.SubAccount === forSubAccount?.Name ||
        forSubAccountParentNamesSet.has(limit.SubAccount)
    );
  }, [forSubAccountParentNamesSet, limitsList, forSubAccount]);

  return { forSubAccountParentNamesSet, forSubAccountParentLimits };
};

interface UseSubAccountOptionsForSubAccount {
  forSubAccount?: ISubaccount;
}

/**
 * Defines the logic for getting the options when wanting to create sub account limits relevant to a specific sub account ("forSubAccount")
 * When forSubAccount is provided, will return a list including the forSubAccount and all its parents.
 * When forSubAccount is undefined, will simply return undefined.
 */
export const useSubAccountOptionsForSubAccount = ({ forSubAccount }: UseSubAccountOptionsForSubAccount) => {
  const { subAccountsByID } = useSubAccounts();
  const { rollupMembershipsByChildParent } = useSubAccountRollupMemberships();

  const options = useMemo(() => {
    if (forSubAccount == null) {
      return undefined;
    }

    if (subAccountsByID == null) {
      return [forSubAccount];
    }

    const allParentIDs = getAllParentsForSubAccount(forSubAccount.SubaccountID, rollupMembershipsByChildParent);
    const parents = compact([...allParentIDs].map(id => subAccountsByID.get(id)));
    return [forSubAccount, ...parents];
  }, [forSubAccount, rollupMembershipsByChildParent, subAccountsByID]);

  return options;
};

export function subAccountLimitsMatchAssetFilter(asset: { currency?: Currency; security?: Security }): boolean {
  return !isAssetSpotSecurity(asset);
}
