import type { IHeaderParams } from 'ag-grid-community';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDynamicCallback } from '../../hooks';
import { HStack } from '../Core';
import { ICON_SIZES, Icon, IconName } from '../Icons';

interface AgGridGroupToggleHeaderProps extends IHeaderParams {
  columnsInGroup: string[];
}

export const AgGridGroupToggleHeader = ({
  progressSort,
  column,
  api,
  displayName,
  columnsInGroup = [],
  columnApi,
}: AgGridGroupToggleHeaderProps) => {
  const [allColumnsInGroupCollapsed, setAllColumnsInGroupCollapsed] = useState(false);

  const updateColumnCollapsed = useDynamicCallback(() => {
    const allColumns = columnApi.getAllGridColumns();
    const isAllCollapsed = columnsInGroup.every(id => {
      const column = allColumns.find(c => c.getColId() === id);
      if (column) {
        return !column.isVisible();
      }
      return true;
    });
    setAllColumnsInGroupCollapsed(isAllCollapsed);
  });

  useEffect(() => {
    updateColumnCollapsed();
  }, [updateColumnCollapsed]);

  const onSortRequested: React.MouseEventHandler<HTMLDivElement> = useCallback(
    e => {
      progressSort(e.shiftKey);
    },
    [progressSort]
  );
  const [currentSort, setCurrentSort] = useState(() => column.getSort());

  const onHandleColumnVisibilityChange = useDynamicCallback(() => {
    updateColumnCollapsed();
  });

  const handleToggle = useDynamicCallback((ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    columnApi.setColumnsVisible(columnsInGroup, allColumnsInGroupCollapsed);
  });

  useEffect(() => {
    if (!api) {
      return;
    }
    api.addEventListener('columnVisible', onHandleColumnVisibilityChange);
    return () => api.removeEventListener('columnVisible', onHandleColumnVisibilityChange);
  }, [api, onHandleColumnVisibilityChange]);

  useEffect(() => {
    if (!api) {
      return;
    }
    const columnSortChanged = () => {
      setCurrentSort(column.getSort());
    };
    api.addEventListener('sortChanged', columnSortChanged);
    return () => api.removeEventListener('sortChanged', columnSortChanged);
  }, [api, column]);

  return (
    <HStack
      h="100%"
      alignItems="center"
      w="100%"
      justifyContent="flex-start"
      onClick={onSortRequested}
      gap="spacingTiny"
    >
      {displayName}
      <HStack px="spacingComfortable" py="spacingSmall" onClick={handleToggle}>
        <Icon
          icon={allColumnsInGroupCollapsed ? IconName.Columns : IconName.ExpandLeft}
          size={ICON_SIZES.SMALL}
          data-testid="group-toggle-header-button"
        />
      </HStack>
      {currentSort && (
        <Icon icon={currentSort === 'desc' ? IconName.ArrowDown : IconName.ArrowUp} size={ICON_SIZES.SMALL} />
      )}
    </HStack>
  );
};
