import { useSyncedRef } from '@talos/kyoko';
import { usePortfolioRouting } from 'containers/Portfolio/PortfolioManagement/usePortfolioRouting';
import type { DockviewApi, DockviewReadyEvent, SerializedDockview } from 'dockview';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react';

type DockViewProviderContextType = {
  dockViewApi: DockviewApi | undefined;
  handleReady: (event: DockviewReadyEvent) => void;
  restoreLayout: () => void;
};

const DockViewProviderContext = createContext<DockViewProviderContextType | undefined>(undefined);

export type DockViewProviderType<TViewType extends string> = {
  view: TViewType;
  tabLabel: string;
  getLayoutForView: (view: TViewType, tabLabel: string, defaultIfUndefined?: boolean) => SerializedDockview;
  getDefaultLayoutForView: (view: TViewType, tabLabel: string) => SerializedDockview;
  updateLayout: (arg: { view: TViewType; userLayout: SerializedDockview }) => void;
};

export function DockViewProvider<TViewType extends string>({
  children,
  view,
  tabLabel,
  getLayoutForView,
  getDefaultLayoutForView,
  updateLayout,
}: PropsWithChildren<DockViewProviderType<TViewType>>) {
  const [api, setApi] = useState<DockviewApi>();
  const isDockViewReadyRef = useRef(false);
  const { selectedTab } = usePortfolioRouting();

  const handleReady = useCallback(
    (event: DockviewReadyEvent) => {
      isDockViewReadyRef.current = true;
      setApi(event.api);

      const currentLayout = getCurrentLayoutOrDefault<TViewType>(
        view,
        tabLabel,
        getLayoutForView,
        getDefaultLayoutForView
      );
      event.api.fromJSON(currentLayout);
      //applyLayoutConstraints(GROUP_CONSTRAINTS, event.api, true);
    },
    [getDefaultLayoutForView, getLayoutForView, tabLabel, view]
  );

  // DockView's API state changes under the hood,
  // so we need to keep a ref to the current API to avoid useDynamicCallback
  const currentApi = useSyncedRef(api);

  const updateDockViewLayout = useCallback(() => {
    if (currentApi.current && isDockViewReadyRef.current) {
      const currentLayout = getCurrentLayoutOrDefault<TViewType>(
        view,
        tabLabel,
        getLayoutForView,
        getDefaultLayoutForView
      );
      currentApi.current.fromJSON(currentLayout);
    }
  }, [currentApi, getDefaultLayoutForView, getLayoutForView, tabLabel, view]);

  const lastTab = useRef(selectedTab);
  useEffect(() => {
    if (lastTab.current !== selectedTab) {
      lastTab.current = selectedTab;
      updateDockViewLayout();
    }
  }, [selectedTab, updateDockViewLayout]);

  useEffect(() => {
    if (!api) {
      return;
    }

    const disposables = [
      api.onDidLayoutChange(event => {
        setTimeout(() => {
          updateLayout({
            view,
            userLayout: api.toJSON(),
          });
        });
      }),
    ];

    return () => disposables.forEach(disposable => disposable.dispose());
  }, [api, updateLayout, view]);

  const restoreLayout = useCallback(() => {
    const defaultLayout = getDefaultLayoutForView(view, tabLabel);
    api?.fromJSON(defaultLayout);
    updateLayout({ view, userLayout: defaultLayout });
  }, [api, getDefaultLayoutForView, tabLabel, updateLayout, view]);

  const contextValue = useMemo((): DockViewProviderContextType => {
    return {
      dockViewApi: api,
      handleReady,
      restoreLayout,
    };
  }, [api, handleReady, restoreLayout]);

  return <DockViewProviderContext.Provider value={contextValue}>{children}</DockViewProviderContext.Provider>;
}

export const useDockViewProvider = () => {
  const context = useContext(DockViewProviderContext);
  if (!context) {
    throw new Error('useDockViewProvider must be used within a DockViewProvider');
  }
  return context;
};
export default DockViewProvider;
function getCurrentLayoutOrDefault<TViewType extends string>(
  view: TViewType,
  tabLabel: string,
  getLayoutForViewTab: DockViewProviderType<TViewType>['getLayoutForView'],
  getDefaultLayoutForView: DockViewProviderType<TViewType>['getDefaultLayoutForView']
) {
  let currentLayout = getLayoutForViewTab(view, tabLabel);
  if (!currentLayout) {
    currentLayout = getDefaultLayoutForView(view, tabLabel);
  }
  return currentLayout;
}
