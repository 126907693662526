import {
  EMPTY_OBJECT,
  FilterClauseType,
  removeEmptyFilters,
  useCurrenciesFilter,
  type BlotterTableFilter,
  type FilterClause,
  type FilterableProperty,
  type Leaves,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useMarketAccountsFilter } from '../../../../../hooks';
import type { SubAccountReconOverviewRow } from './rows';
import type { SubAccountReconOverviewBlotterFilter } from './types';

export interface UseSubAccountReconOverviewBlotterFilterParams {
  persistedBlotterTable: UsePersistedBlotterTable<SubAccountReconOverviewRow>;
}

export function useSubAccountReconOverviewBlotterFilter({
  persistedBlotterTable,
}: UseSubAccountReconOverviewBlotterFilterParams) {
  const { initialFilter, onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<SubAccountReconOverviewBlotterFilter>(initialFilter ?? EMPTY_OBJECT);

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const marketAccountsFilter = useMarketAccountsFilter();
  const currenciesFilter = useCurrenciesFilter();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof SubAccountReconOverviewBlotterFilter>>([
        marketAccountsFilter,
        { ...currenciesFilter, key: 'Assets' },
      ]),
    [marketAccountsFilter, currenciesFilter]
  );

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<SubAccountReconOverviewBlotterFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as SubAccountReconOverviewBlotterFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof SubAccountReconOverviewBlotterFilter)[]).forEach(
        (key: keyof SubAccountReconOverviewBlotterFilter) => {
          clauses.push({
            key: key,
            type: FilterClauseType.INCLUSIVE,
            selections: filter[key] as string[],
          });
        }
      );
    }
    return clauses;
  }, [filter]);

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    onFilterClausesChanged: handleFilterClausesChanged,
  };
}

export function colIDToFilterBuilderKey(id: string): keyof SubAccountReconOverviewBlotterFilter | undefined {
  switch (id as Leaves<SubAccountReconOverviewRow>) {
    case 'MarketAccount':
      return 'MarketAccounts';
    case 'SubAccounts':
      return 'SubAccounts';
    case 'Asset':
      return 'Assets';
  }

  return undefined;
}
