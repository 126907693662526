import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  Divider,
  FormControlSizes,
  IconName,
  MaxRecordsReachedWarning,
  MixpanelEvent,
  SummaryLine,
  Tooltip,
  TradeSettleStatusEnum,
  abbreviateId,
  createCSVFileName,
  filterByCellValueMenuItem,
  getRowNodesToOperateOn,
  selectAll,
  useBlotterTable,
  useBlotterTableExtrasMenu,
  useDateRangeFilter,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  type BlotterTableRow,
} from '@talos/kyoko';
import type {
  SettleableTrade,
  TradeSettlementColumn,
  TradeSettlementFilter,
  TradeSettlementInternalFilter,
} from './types';

import type { GetContextMenuItems, GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { compact, uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { useCustomersByName } from '../../../../../hooks/useCustomer';
import { useDisplaySettings } from '../../../../../providers/DisplaySettingsProvider';
import { useCustomerSettlementDrawer } from '../CustomerSettlementDrawer/useCustomerSettlementDrawer';
import { useSettlementMonitoringFilters } from '../providers/SettlementMonitoringFiltersProvider';
import { useSettlementMonitoringInteractions } from '../providers/SettlementMonitoringInteractionsProvider';
import { TRADE_SETTLEMENT_BLOTTER_PORTAL_ID } from '../tokens';
import type { SettlementMonitoringFilter } from '../types';
import { useTradeSettlementBlotterObs } from './useTradeSettlementBlotterObs';
import { useTradeSettlementColumns } from './useTradeSettlementColumns';
import { useTradeSettlementFilter } from './useTradeSettlementFilter';

const ENTITY_SEARCH_KEYS: (keyof SettleableTrade)[] = [
  'Counterparty',
  'MarketAccount',
  'Symbol',
  'TradeID',
  'SettleReportID',
];

interface TradeSettlementBlotterParams {
  blotterID: string;
  defaultInternalFilter?: TradeSettlementInternalFilter;
  externalFilter?: SettlementMonitoringFilter;
  defaultColumns: TradeSettlementColumn[];
}

export const TradeSettlementBlotter = ({
  blotterID,
  defaultInternalFilter,
  externalFilter,
  defaultColumns: defaultBlotterColumns,
}: TradeSettlementBlotterParams) => {
  const { homeCurrency } = useDisplaySettings();
  const customersByName = useCustomersByName();
  const mixpanel = useMixpanel();
  const defaultColumns = useTradeSettlementColumns({ defaultColumns: defaultBlotterColumns });
  const [selectedTrades, setSelectedTrades] = useState<SettleableTrade[]>([]);

  const persisted = usePersistedBlotterTable(blotterID, {
    columns: defaultColumns,
    filter: defaultInternalFilter,
  });

  const filtering = useTradeSettlementFilter({
    initialInternalFilter: persisted.initialFilter,
    saveInternalFilter: persisted.onFilterChanged,
    externalFilter,
  });

  const { clientSideFilter, changeFilter } = filtering;

  const { handleClickJson, jsonModal } = useJsonModal();
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const { filterableProperties } = useSettlementMonitoringFilters();
  const { openClause } = useSettlementMonitoringInteractions();
  const { openCustomerSettlementDrawer } = useCustomerSettlementDrawer();

  const getContextMenuItems: GetContextMenuItems<SettleableTrade> = useDynamicCallback(
    (params: GetContextMenuItemsParams<SettleableTrade>) => {
      const items: (MenuItemDef | 'separator')[] = [
        selectAll(params, mixpanel),
        'separator',
        ...getDefaultContextMenuItems(params),
      ];

      const rightClickedTrade = params.node?.data;
      if (rightClickedTrade != null) {
        items.unshift('separator');
        items.unshift({
          name: 'Show JSON',
          action: () => handleClickJson(rightClickedTrade),
          icon: `<i class="ag-icon ${IconName.Braces}"/>`,
        });
      }

      if (openClause) {
        items.unshift(
          ...filterByCellValueMenuItem({
            params,
            filterableProperties,
            openClause,
            colIDToFilterBuilderKey,
            mixpanel,
          })
        );
      }

      items.unshift('separator');

      const isRightClickedTradeUnsettled = rightClickedTrade?.SettleStatus === TradeSettleStatusEnum.Unsettled;

      // Below here we prep the different settlement options for use.
      // Settlement based on the order id of the right-clicked trade
      if (rightClickedTrade && isRightClickedTradeUnsettled && rightClickedTrade.OrderID != null) {
        items.unshift({
          name: `Settle all trades for OrderID ${abbreviateId(rightClickedTrade.OrderID)}`,
          action: () => {
            openCustomerSettlementDrawer({ type: 'OrderIDs', OrderIDs: [rightClickedTrade.OrderID] });
          },
          icon: `<i class="ag-icon ${IconName.CheckCircle}"/>`,
        });
      }

      // Settlement based on the counterparty of the right-clicked trade
      const counterpartyName = rightClickedTrade?.Counterparty;
      if (rightClickedTrade && isRightClickedTradeUnsettled && counterpartyName != null) {
        const counterparty = customersByName?.get(counterpartyName);
        items.unshift({
          name: `Settle all trades for Counterparty ${counterparty?.DisplayName ?? counterpartyName}`,
          action: () => {
            openCustomerSettlementDrawer({ type: 'Counterparty', Counterparty: counterpartyName });
          },
          icon: `<i class="ag-icon ${IconName.CheckCircle}"/>`,
        });
      }

      const settleableSelections = compact(
        getRowNodesToOperateOn(params)
          .map(node => node.data)
          .filter(st => st?.SettleStatus === TradeSettleStatusEnum.Unsettled)
      );

      // Settlement based on the selected settleable trades
      if (canSettleSelectedTrades(settleableSelections).canSettle) {
        items.unshift({
          name: `Settle ${settleableSelections.length} ${settleableSelections.length > 1 ? 'trades' : 'trade'}`,
          action: () => {
            openCustomerSettlementDrawer({ type: 'TradeIDs', settleableTrades: settleableSelections });
          },
          icon: `<i class="ag-icon ${IconName.CheckCircle}"/>`,
        });
      }

      return compact(items);
    }
  );

  const handleRowSelectionChanged = useDynamicCallback((selectedRows: BlotterTableRow<SettleableTrade>[]) => {
    setSelectedTrades(compact(selectedRows.map(row => row.data)));
  });

  const serverFilter = useMemo(() => onlyServerFilterKeys(filtering.filter), [filtering.filter]);
  const { dataObservable, limitReachedState, raisePaginationLimits } = useTradeSettlementBlotterObs({
    serverFilter,
  });

  const blotterTable = useBlotterTable({
    dataObservable,
    sort: '-TransactTime',
    columns: persisted.columns,
    clientLocalFilter: clientSideFilter,
    rowID: 'TradeID' satisfies keyof SettleableTrade,
    rowSelection: 'multiple',
    getContextMenuItems,
    onSortChanged: persisted.onSortChanged,
    onColumnsChanged: persisted.onColumnsChanged,
    onRowSelectionChanged: handleRowSelectionChanged,
    quickSearchParams: {
      entitySearchKeys: ENTITY_SEARCH_KEYS,
    },
  });

  const dateRangeFilter = useDateRangeFilter(filtering.filter, changeFilter);

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Customer Trades',
      }),
    });
    extrasMenuPopover.close();
  });

  const handleSettleClick = useDynamicCallback(() => {
    openCustomerSettlementDrawer({
      type: 'TradeIDs',
      settleableTrades: selectedTrades.filter(trade => trade.SettleStatus === TradeSettleStatusEnum.Unsettled),
    });
  });

  const { canSettle, reason: cannotSettleReason } = canSettleSelectedTrades(selectedTrades);

  return (
    <>
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        portalId={TRADE_SETTLEMENT_BLOTTER_PORTAL_ID}
        {...dateRangeFilter}
        suffix={
          <>
            <Tooltip tooltip={cannotSettleReason}>
              <Button
                size={FormControlSizes.Small}
                disabled={!canSettle}
                onClick={handleSettleClick}
                startIcon={IconName.CheckCircle}
                data-testid="settle-selected-trades-button"
                variant={ButtonVariants.Primary}
              >
                Settle Trades
              </Button>
            </Tooltip>
            <Divider orientation="vertical" mx="spacingSmall" />
            <BlotterTableExtrasMenu {...extrasMenuPopover}>
              <Button startIcon={IconName.DocumentUpload} size={FormControlSizes.Small} onClick={handleExport}>
                Export
              </Button>
            </BlotterTableExtrasMenu>
          </>
        }
      />
      <MaxRecordsReachedWarning
        showLimitReached={!!limitReachedState?.lastRecord}
        earliestTimestamp={limitReachedState?.lastRecord?.Timestamp}
        recordsReceived={limitReachedState?.recordsReceived}
        onRaiseLimitClicked={raisePaginationLimits}
      />
      <BlotterTable {...blotterTable} />
      <SummaryLine
        homeCurrency={homeCurrency}
        rows={selectedTrades} // all trades, not just the settleable ones!
        getAmount={getSettleableTradeAmount}
        getQuantity={getSettleableTradeQuantity}
      />
      {jsonModal}
    </>
  );
};

function colIDToFilterBuilderKey(colID: string): keyof TradeSettlementFilter | undefined {
  switch (colID as keyof SettleableTrade) {
    case 'SettleStatus':
      return 'Statuses';
    case 'MarketAccount':
      return 'MarketAccounts';
    // symbol filtering is disabled
    // case 'Symbol':
    //   return 'Symbols';
  }

  return undefined;
}

function canSettleSelectedTrades(trades: SettleableTrade[]): { canSettle: boolean; reason?: string } {
  // You can settle the list of selected trades if there's at least one selected trade that's unsettled, and
  // if all selected trades are within the same counterparty
  const atLeastOneUnsettledTrade = trades.some(trade => trade.SettleStatus === TradeSettleStatusEnum.Unsettled);

  if (!atLeastOneUnsettledTrade) {
    return {
      canSettle: false,
      reason: 'No unsettled trades are selected',
    };
  }

  const allTradesSameCpty = uniq(trades.map(trade => trade.Counterparty)).length === 1;
  if (!allTradesSameCpty) {
    return {
      canSettle: false,
      reason: 'Only trades from the same counterparty can be bulk settled.',
    };
  }

  return {
    canSettle: true,
  };
}

function onlyServerFilterKeys(filter: TradeSettlementFilter | undefined): TradeSettlementFilter {
  return {
    StartDate: filter?.StartDate,
    EndDate: filter?.EndDate,
  };
}

function getSettleableTradeAmount(t: SettleableTrade) {
  return t.Amount;
}

function getSettleableTradeQuantity(t: SettleableTrade) {
  return t.Quantity;
}
