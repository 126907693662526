import { useDisplaySettings } from 'providers/AppConfigProvider';
import { useEffect, useMemo, useState } from 'react';
import { filter, map, shareReplay } from 'rxjs/operators';

import {
  ConnectionStatusEnum,
  DepthTypeEnum,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  FeeModeEnum,
  LiquidityTypeEnum,
  MARKET_DATA_SNAPSHOT,
  MarketAccountTypeEnum,
  SettleValueTypeEnum,
  TRADE_DIRECTION,
  allowContracts,
  toBigWithDefault,
  useMarketAccountsContext,
  useMarketsContext,
  useObservable,
  useSecurity,
  useSubscription,
  type MarketDataSnapshot,
  type MarketDataSnapshotRequest,
  type SubscriptionResponse,
  type TradeDirection,
  type TradeDirectionRequest,
  type UnifiedLiquidityEnum,
} from '@talos/kyoko';
import { compact, concat, flatMap } from 'lodash';
import { iif, of } from 'rxjs';

interface MarketDataSnapshotParams {
  currency: string | undefined;
  baseCurrency?: string | undefined;
  depth?: number | null;
  includeTradeDirection?: boolean;
  markets?: string[];
  marketAccounts?: string[];
  priceIncrement: string | undefined;
  quantity?: string;
  sizeBuckets?: string[] | null;
  throttle?: string;
  symbol: string | undefined;
  tag: string;
  unifiedLiquidity?: UnifiedLiquidityEnum;
  // Used for converting from quote currency to base currency and then to contracts
  // e.g. assuming contract size of 100, 90k USD ~ 2 BTC ~ 200 contracts
  // We pass the rate in rather than calling useCurrencyConversionRateValue here as to avoid making potentially loads of
  // redundant requests from market cards, since only use case is the order form
  conversionRate?: string;
}

export function useMarketDataSnapshot({
  currency,
  baseCurrency = currency,
  depth,
  includeTradeDirection,
  priceIncrement,
  quantity: inputQuantity,
  sizeBuckets,
  symbol,
  tag,
  unifiedLiquidity,
  conversionRate,
  throttle,
  ...params
}: MarketDataSnapshotParams) {
  const { showAllInPrices, showFirmLiquidity } = useDisplaySettings();
  const [request, setRequest] = useState<(MarketDataSnapshotRequest | TradeDirectionRequest)[] | null>(null);
  const security = useSecurity(symbol);
  const allowQtyInContracts = allowContracts(security);

  let quantity = inputQuantity;

  if (allowQtyInContracts && currency && security) {
    // https://talostrading.atlassian.net/browse/UI-3908
    // Another UI hack that we do because the subscription parameters does not support Currency directly
    // And instead relies on UI converting between Base/Quote ccy or Contract to the correct bucket size
    // If we are dealing with a security that allows contract but we are not entering the quantity in contracts
    // we must convert the quantity to contracts and use size buckets or we'd get wrong spreads back
    if (security.SettleValueType === SettleValueTypeEnum.Regular) {
      if (currency === security.PositionCurrency || currency === security.BaseCurrency) {
        quantity = toBigWithDefault(inputQuantity, 0)
          .div(security.NotionalMultiplier || 1)
          .toFixed();
      } else {
        const approxBase = toBigWithDefault(inputQuantity, 0).div(conversionRate || 1);
        const approxContract = toBigWithDefault(approxBase, 0).div(security.NotionalMultiplier || 1);
        quantity = approxContract.round().toFixed();
      }
    } else {
      if (currency === security.PositionCurrency || currency === security.BaseCurrency) {
        const approxBase = toBigWithDefault(inputQuantity, 0).mul(conversionRate || 1);
        const approxContract = toBigWithDefault(approxBase, 0).div(security.NotionalMultiplier || 1);
        quantity = approxContract.round().toFixed();
      } else {
        quantity = toBigWithDefault(inputQuantity, 0)
          .div(security.NotionalMultiplier || 1)
          .toFixed();
      }
    }
  }

  const { marketAccountsByName, marketAccountsByMarket } = useMarketAccountsContext();
  const marketAccounts = useMemo(
    () => params.marketAccounts?.filter(m => marketAccountsByName.get(m)?.Type === MarketAccountTypeEnum.Trading) ?? [],
    [params.marketAccounts, marketAccountsByName]
  );

  const { marketsByName } = useMarketsContext();

  const markets = useMemo(
    () => params.markets?.filter(m => marketsByName.has(m)) ?? [],
    [marketsByName, params.markets]
  );

  const securityMarketAccountsAndMarkets = useMemo(
    () =>
      !security
        ? new Set()
        : new Set(
            concat(
              compact(flatMap(security.Markets, market => marketAccountsByMarket.get(market)?.map(ma => ma.Name))),
              security.Markets || []
            )
          ),
    [marketAccountsByMarket, security]
  );

  useEffect(() => {
    if (
      security != null &&
      ((marketAccounts && marketAccounts.length) || (markets && markets.length)) &&
      priceIncrement != null &&
      (depth || quantity || sizeBuckets)
    ) {
      const marketDataRequest: MarketDataSnapshotRequest = {
        name: MARKET_DATA_SNAPSHOT,
        DepthType: DepthTypeEnum.Price,
        Markets: markets,
        PriceIncrement: priceIncrement,
        Symbol: security.Symbol,
        FeeMode: showAllInPrices ? FeeModeEnum.Taker : undefined,
        LiquidityType: showFirmLiquidity ? LiquidityTypeEnum.Firm : LiquidityTypeEnum.Indicative,
        tag: tag,
        UnifiedLiquidity: unifiedLiquidity,
        Throttle: throttle,
      };
      if ((markets && markets.length) || (marketAccounts && marketAccounts.length)) {
        // TODO: Cleanup
        // Note: the backend wants Markets to contain either the name of the markets to subscribe to and / or the name of the market accounts to subscribe to.
        //   Markets included with a market account to the same market should be ignored.
        marketDataRequest.Markets = [...(markets ?? []), ...(marketAccounts ?? [])];
      }
      if (depth != null) {
        marketDataRequest.Depth = depth;
      } else {
        if (!allowQtyInContracts && currency && baseCurrency && currency !== baseCurrency) {
          marketDataRequest.AmountBuckets = compact([quantity]);
        } else {
          marketDataRequest.SizeBuckets = quantity ? [quantity] : sizeBuckets ?? undefined;
        }
      }

      // Trade direction does not work with markets/accs not included under the security
      // So we need to remove them from the list for the subscription
      // This results in: Trade direction for unified liquidity is equal to the symbol at the markets available
      const tradeDirectionMarkets = [...markets, ...marketAccounts].filter(marketOrAccount =>
        securityMarketAccountsAndMarkets.has(marketOrAccount)
      );

      setRequest(
        compact([
          marketDataRequest,
          includeTradeDirection &&
            // Trade direction might require a market array, if not specified, not worthwhile sending subscription?
            tradeDirectionMarkets.length > 0 && {
              name: TRADE_DIRECTION,
              Symbol: security.Symbol,
              Markets: tradeDirectionMarkets,
              tag: tag,
            },
        ])
      );
    } else {
      return setRequest(null);
    }
  }, [
    baseCurrency,
    currency,
    priceIncrement,
    security,
    depth,
    tag,
    sizeBuckets,
    quantity,
    showAllInPrices,
    showFirmLiquidity,
    includeTradeDirection,
    throttle,
    marketAccounts,
    markets,
    unifiedLiquidity,
    marketAccountsByMarket,
    securityMarketAccountsAndMarkets,
    allowQtyInContracts,
  ]);

  const {
    data: subscription,
    error,
    isLoading,
  } = useSubscription<SubscriptionResponse<MarketDataSnapshot | TradeDirection>>(request);

  const marketDataErrors = useObservable(
    () =>
      error.pipe(
        map(json => json?.error),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [error]
  );

  // Similar to the kyoko/useMarketDataSnapshot where the response object isn't wrapped, needed by some kyoko components
  const rawMarketDataSnapshot = useObservable(
    () =>
      subscription.pipe(
        filter(
          (json): json is SubscriptionResponse<MarketDataSnapshot> =>
            json.type === MARKET_DATA_SNAPSHOT && (json.data[0]['Bids'] || json.data[0]['Offers'])
        ),
        map(json => json.data[0]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const unstaleRawMarketDataSnapshot = useObservable<MarketDataSnapshot>(
    () =>
      iif(
        () => request === null && symbol !== undefined,
        // If the request is null we are no longer interested in data.
        // so to avoid showing stale data, we return an empty object
        // the check for symbol is for sanity.
        of({
          Bids: EMPTY_ARRAY,
          Offers: EMPTY_ARRAY,
          Status: ConnectionStatusEnum.Offline,
          Symbol: symbol!,
          Markets: EMPTY_OBJECT,
          DepthType: DepthTypeEnum.Price,
        } satisfies MarketDataSnapshot),
        rawMarketDataSnapshot.pipe(filter(snapshot => snapshot.Symbol === symbol))
      ),
    [request, rawMarketDataSnapshot, symbol]
  );

  const marketDataSnapshots = useObservable(
    () =>
      unstaleRawMarketDataSnapshot.pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [unstaleRawMarketDataSnapshot]
  );

  const tradeDirections = useObservable(
    () =>
      subscription.pipe(
        filter((json): json is SubscriptionResponse<TradeDirection> => json.type === TRADE_DIRECTION),
        map(json => json.data[0]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  return {
    marketDataSnapshots,
    rawMarketDataSnapshot: unstaleRawMarketDataSnapshot,
    tradeDirections,
    marketDataErrors,
    isLoading,
  };
}
