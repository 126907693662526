import { MultiSelect } from 'components/Form/MultiSelect';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import type { AgGridMultiSelectDropdownProps } from './types';

export const AgGridMultiSelectDropdown = forwardRef(function AgGridMultiSelectDropdown<T>(
  { multiSelectProps, portalize = false, ...cellEditorParams }: AgGridMultiSelectDropdownProps<T>,
  ref
) {
  const [value, setValue] = useState(cellEditorParams.value);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
    };
  });

  const handleChange = useDynamicCallback((newValue: T[] | undefined) => {
    setValue(newValue);
  });

  return (
    <MultiSelectWrapper data-testid="multi-select">
      <MultiSelect {...multiSelectProps} selections={value} onChange={handleChange} portalize={portalize} autoOpen />
    </MultiSelectWrapper>
  );
});

const MultiSelectWrapper = styled.div`
  margin-top: 3px;
  background: ${({ theme }) => theme.backgroundDropdownInput};
`;
