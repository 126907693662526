import {
  DecisionStatusEnum,
  OrderStatus,
  Text,
  abbreviateId,
  isOrder,
  type ExecutionReport,
  type MarketOrder,
  type Order,
} from '@talos/kyoko';
import { Row } from './styles';

interface SelectedOrdersListProps {
  selectedOrders: (Order | ExecutionReport | MarketOrder)[];
}

export const SelectedOrdersList = ({ selectedOrders }: SelectedOrdersListProps) => {
  if (!selectedOrders?.length) {
    return null;
  }

  return (
    <>
      {selectedOrders.map((selectedOrder, idx, arr) => {
        const id = (selectedOrder as MarketOrder).OID ?? `#${abbreviateId(selectedOrder.OrderID)}`;
        if (idx < 5) {
          return (
            <Row key={id}>
              <Text>{id}</Text>
              <OrderStatus
                ordStatus={selectedOrder.OrdStatus}
                decisionStatus={isOrder(selectedOrder) ? selectedOrder?.DecisionStatus : DecisionStatusEnum.Active}
                cumQty={selectedOrder.CumQty}
                orderQty={selectedOrder.OrderQty}
                pricingMode={isOrder(selectedOrder) ? selectedOrder.PricingMode : undefined}
                title={selectedOrder.Text}
              />
            </Row>
          );
        }
        if (idx === arr.length - 1) {
          return <Row key={selectedOrder.OrderID}>+ {arr.length - 5} Orders</Row>;
        }
        return null;
      })}
    </>
  );
};
