import type { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { useTheme } from 'styled-components';
import type { Customer } from '../../../types/Customer';
import type { AgGridSearchSelectDropdownProps } from '../../AgGrid/types';
import { Box, HStack } from '../../Core';
import { Text } from '../../Text';
import { getBlotterTableContextFromNode } from '../utils';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';
import { stringColumnComparator } from './utils';

export type CounterpartyColumnParams = {
  /** Provide a custom set of customers to use for populating the cell editor options and valueFormatter display name resolution */
  customers?: Customer[];
  /** Whether or not to show an icon prefix for the counterparty  */
  showIconPrefix?: boolean;
  /** If true, on changing the value of this column, will set data.MarketAccount to "" */
  resetsMarketAccount?: boolean;
} & Pick<AgGridSearchSelectDropdownProps<string>['useSearchSelectParams'], 'isItemDisabled'>;

export const counterparty: ColDefFactory<Column<CounterpartyColumnParams>> = column => ({
  ...baseColumn(column),
  valueFormatter: ({ value, context }) => {
    const counterparties = column?.params?.customers ?? context.current.customers ?? [];
    return counterparties.find(counterparty => counterparty.Name === value)?.DisplayName || value;
  },
  cellRenderer: ({ valueFormatted }: ICellRendererParams) => {
    if (valueFormatted == null) {
      return undefined;
    }

    return (
      <HStack>
        {column.params?.showIconPrefix && <CircleIconFromLabel label={valueFormatted} />}
        {valueFormatted}
      </HStack>
    );
  },
  cellEditor: 'searchSelectDropdown',
  cellEditorPopup: true,
  suppressKeyboardEvent: params => params.event.key === 'Enter',
  cellEditorParams: (params: ICellEditorParams) => {
    const counterparties = column?.params?.customers ?? params.context.current.customers ?? [];
    const counterpartiesMap = new Map<string, any>(counterparties.map(cp => [cp.Name, cp]));
    return {
      ...params,
      useSearchSelectParams: {
        items: counterparties.map(counterparty => counterparty.Name),
        getLabel: cpName => counterpartiesMap?.get(cpName)?.DisplayName ?? '',
        isItemDisabled: column?.params?.isItemDisabled,
      },
    } satisfies AgGridSearchSelectDropdownProps<string>;
  },
  valueSetter: params => {
    if (column.field) {
      params.data[column.field] = params.newValue;
      if (column.params?.resetsMarketAccount) {
        // Reset MarketAccount field
        params.data.MarketAccount = '';
      }
      return true;
    }
    return false;
  },
  comparator: (valueA: string | undefined, valueB: string | undefined, nodeA) => {
    const context = getBlotterTableContextFromNode(nodeA);
    const customersByName = context?.customersByName;

    if (!valueA || !valueB || !customersByName) {
      return stringColumnComparator(valueA, valueB);
    }

    const displayA = customersByName.get(valueA)?.DisplayName || valueA;
    const displayB = customersByName.get(valueB)?.DisplayName || valueB;
    return displayA.localeCompare(displayB);
  },
});

const CircleIconFromLabel = ({ label }: { label: string }) => {
  const { baseSize } = useTheme();
  return (
    <Box
      background="colorTextAttention"
      display="flex"
      borderRadius="50%"
      p="spacingSmall"
      h={`${baseSize * 1.5}px`}
      w={`${baseSize * 1.5}px`}
      alignItems="center"
      justifyContent="center"
      mr="spacingDefault"
    >
      <Text color="colors.gray['010']" weight={600} fontSize="fontSizeLarge">
        {label.substring(0, 1).toUpperCase()}
      </Text>
    </Box>
  );
};
