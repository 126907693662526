import { useCallback, useEffect, useState } from 'react';

import {
  ACTION,
  AllocationValueTypeEnum,
  Dialog,
  OrderStatus,
  Patch,
  abbreviateId,
  logger,
  prepareAllocationsForRequest,
  useDynamicCallback,
  useUserContext,
  type Allocation,
  type DecisionStatusEnum,
  type DialogProps,
  type OrdStatusEnum,
  type Order,
  type PricingModeEnum,
  type UseDisclosureReturn,
} from '@talos/kyoko';

import { FormError } from 'components/Form';
import { OMSSubAccounts } from 'components/OMS/OMSSubAccounts';
import { useModifyDialogValidation, useRoleAuth } from 'hooks';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { prepareAllocationOrderForUI } from 'utils/order';
import { useTradingSettings } from '../../../../providers/TradingSettingsContext';
import { ModifyMultipleEntitySubAccountsDialog } from '../../ModifyMultipleEntitySubAccounts';
import { CancelDialogWrapper, Row } from './styles';

type BulkModifySubAccountDialogProps = DialogProps &
  UseDisclosureReturn & {
    selectedOrders?: Order[];
  };

export const BulkModifySubAccountDialog = ({ selectedOrders, ...props }: BulkModifySubAccountDialogProps) => {
  if (!selectedOrders) {
    return null;
  }

  if (selectedOrders.length === 1) {
    const selectedOrder = selectedOrders[0];
    return <ModifySubAccountDialog key={selectedOrder.OrderID} selectedOrder={selectedOrder} {...props} />;
  }

  return <ModifyMultipleEntitySubAccountsDialog selectedEntities={selectedOrders} entityType="order" {...props} />;
};

type ModifySubAccountDialogProps = DialogProps &
  UseDisclosureReturn & {
    selectedOrder?: Order;
  };

// This is intentionally just the parts of the order this form cares about and not the full OMSForm
interface OMSSubAccountForm {
  orderID: string;
  ordStatus: OrdStatusEnum;
  decisionStatus: DecisionStatusEnum;
  cumQty: string;
  text?: string;
  currency: string;
  orderQty: string;
  pricingMode?: PricingModeEnum;
  subAccount?: string;
  allocationValueType: AllocationValueTypeEnum;
  subAccountAllocations: Allocation[];
}

export const ModifySubAccountDialog = ({ selectedOrder, ...props }: ModifySubAccountDialogProps) => {
  const [modifySubAccountError, setModifySubAccountError] = useState<string | undefined>();
  const { tradableSubAccounts } = useSubAccounts();
  const { orgApiEndpoint } = useUserContext();
  const { useTradeAllocations } = useTradingSettings();
  const [form, setForm] = useState<OMSSubAccountForm | undefined>();
  const { isAuthorized } = useRoleAuth();

  useEffect(() => {
    if (!selectedOrder || !props.isOpen) {
      return;
    }

    const orderForUI = prepareAllocationOrderForUI(selectedOrder);
    if (!orderForUI.subAccountAllocations || orderForUI.subAccountAllocations.length === 0) {
      orderForUI.subAccountAllocations = [{ subAccount: orderForUI.SubAccount || '', value: '100' }];
    }
    setForm({
      orderID: orderForUI.OrderID,
      ordStatus: orderForUI.OrdStatus,
      decisionStatus: orderForUI.DecisionStatus,
      currency: orderForUI.Currency || '',
      orderQty: orderForUI.OrderQty,
      cumQty: orderForUI.CumQty,
      text: orderForUI.Text,
      subAccount: orderForUI.SubAccount,
      pricingMode: orderForUI.PricingMode,
      allocationValueType: orderForUI.allocationValueType || AllocationValueTypeEnum.Percentage,
      subAccountAllocations: orderForUI.subAccountAllocations || [],
    });
  }, [selectedOrder, props.isOpen]);

  const { open, close } = props;

  const handleAllocationUpdate = useCallback(
    (update: Partial<OMSSubAccountForm>) => {
      if (!form) {
        return;
      }
      setForm({
        ...form,
        ...update,
      });
    },
    [form]
  );

  const { errors } = useModifyDialogValidation({
    orderOrTrade: {
      ...form,
      subAccountAllocations: form?.subAccountAllocations,
      allocationValueType: form?.allocationValueType,
    },
    mode: 'order',
  });

  const handleOnSubAccountModifyDialogConfirm = useDynamicCallback(() => {
    if (!form) {
      return;
    }

    form.allocationValueType == null &&
      logger.error(new Error(`allocationValueType is null while preparing allocations`));
    form.subAccountAllocations == null &&
      logger.error(new Error(`subAccountAllocations is null while preparing allocations`));

    const preparedTradeAllocations = prepareAllocationsForRequest({
      subAccountAllocations: form.subAccountAllocations,
      subAccounts: tradableSubAccounts,
      allocationValueType: form.allocationValueType,
      quantity: form.orderQty,
    });

    Patch(orgApiEndpoint ?? '', `/orders/${form.orderID}`, { Allocation: preparedTradeAllocations })
      .then(() => {
        close();
      })
      .catch((e: ErrorEvent) => {
        setModifySubAccountError(e?.message || 'Something went wrong.');
        open();
      });
  });

  return (
    <Dialog
      {...props}
      onConfirm={handleOnSubAccountModifyDialogConfirm}
      confirmLabel="Update"
      cancelLabel="Cancel"
      confirmDisabled={errors?.subAccountAllocations != null || !isAuthorized(ACTION.SUBMIT_ORDER)}
      width={300}
    >
      {form && (
        <CancelDialogWrapper>
          <Row>
            <h3>
              Order #<b>{abbreviateId(form.orderID)}</b>
            </h3>
            <OrderStatus
              ordStatus={form.ordStatus}
              decisionStatus={form.decisionStatus}
              cumQty={form.cumQty}
              orderQty={form.orderQty}
              pricingMode={form.pricingMode}
              text={form.text}
            />
          </Row>

          {/* Sub Accounts */}
          <OMSSubAccounts
            subAccountAllocations={form.subAccountAllocations}
            onUpdate={handleAllocationUpdate}
            allocationValueType={form.allocationValueType}
            quantityCurrency={form.currency}
            useAllocations={useTradeAllocations}
            errors={errors}
            touched={{ subAccountAllocations: true }}
            updateDefaultSubAccountOnChange={false}
          />
          {modifySubAccountError && (
            <FormError style={{ justifyContent: 'flex-end' }}>{modifySubAccountError}</FormError>
          )}
        </CancelDialogWrapper>
      )}
    </Dialog>
  );
};
