import {
  Card,
  CurrencyIcon,
  Divider,
  HStack,
  InlineFormattedNumber,
  NumberVariants,
  Text,
  useMarketAccountsContext,
  VStack,
  type SubAccountReconMatch,
} from '@talos/kyoko';
import { useMemo, type ReactNode } from 'react';
import { useSubAccounts } from '../../../../../providers';

export const ReconMatchSummary = ({ match }: { match: SubAccountReconMatch }) => {
  const { marketAccountsByName } = useMarketAccountsContext();
  const { subAccountsByName } = useSubAccounts();

  const subAccountsLabel = useMemo(() => {
    if (!match.subAccounts) {
      return undefined;
    }

    return match.subAccounts.map(sa => subAccountsByName.get(sa)?.DisplayName ?? sa).join(', ');
  }, [match.subAccounts, subAccountsByName]);

  const marketAccount = match.MarketAccountEvent?.MarketAccount;
  const marketAccountLabel = marketAccount
    ? marketAccountsByName.get(marketAccount)?.DisplayName ?? marketAccount
    : undefined;

  const breakVariant = match.hasBreak ? NumberVariants.Warning : undefined;
  const subAccountsPlural = match.subAccounts != null && match.subAccounts.length > 1;

  return (
    <Card w="100%" data-testid="break-summary-card">
      <VStack w="100%" gap="spacingDefault">
        <Row>
          <Text color="colorTextImportant">{match.EventType}</Text>
          <HStack gap="spacingSmall">
            <CurrencyIcon currency={match.Asset} />
            <Text>{match.Asset}</Text>
          </HStack>
        </Row>

        <Row>
          <Text>Break</Text>
          <InlineFormattedNumber number={match.breakAmount} currency={match.Asset} variant={breakVariant} />
        </Row>

        <Divider orientation="horizontal" />

        <Row>
          <Text>{subAccountsPlural ? 'Sub Accounts' : 'Sub Account'}</Text>
          <TextOrMissingValue text={subAccountsLabel} />
        </Row>

        <Row>
          <Text>Market Account</Text>
          <TextOrMissingValue text={marketAccountLabel} />
        </Row>
      </VStack>
    </Card>
  );
};

const Row = ({ children }: { children: ReactNode }) => {
  return (
    <HStack fontSize="fontSizeSmall" w="100%" gap="spacingDefault" justifyContent="space-between">
      {children}
    </HStack>
  );
};

const TextOrMissingValue = ({ text }: { text: string | undefined }) => {
  if (!text) {
    return <Text color="colorTextWarning">Missing Value</Text>;
  }

  return <Text color="colorTextImportant">{text}</Text>;
};
