import { logger } from './logger';

export * from './array';
export * from './bufferedDispatcher';
export * from './composeComponents';
export * from './connectionStatus';
export * from './cookies';
export * from './copyText';
export * from './creditAmount';
export * from './currency';
export * from './customerMarketAccounts';
export * from './date';
export * from './empty';
export * from './env';
export * from './feeMode';
export * from './fileExport';
export * from './getCreditUsage';
export * from './http';
export * from './intl';
export * from './isOrderComplete';
export * from './isOrderPaused';
export * from './isOrderPending';
export * from './isReactNode';
export * from './logger';
export * from './marketColor';
export * from './mergedNestedMap';
export * from './microseconds';
export * from './multileg';
export * from './nestedMap';
export * from './notional';
export * from './number';
export * from './order';
export * from './prettyPriceProtection';
export * from './pricingMode';
export * from './random';
export * from './relativeSize';
export * from './render';
export * from './replaceTokens';
export * from './rollupMembershipUtils';
export * from './security';
export * from './setIntersection';
export * from './setNativeValue';
export * from './string';
export * from './timeUnits';
export * from './truncateBlockchainAddress';
export * from './types';
export * from './url';
export * from './user';
export * from './validation';
export * from './wait';
export * from './ws';

export function abbreviateId(id?: string) {
  return id?.substring(0, 4);
}

export const tryParseJSON = (value: string, logFailure = true) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    if (logFailure) {
      logger.error(e as Error, { extra: { value } });
    }
    return value;
  }
};
