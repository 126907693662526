import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  IconName,
  TRADE_ALLOCATION,
  WSBlotterTableMaxRecordsReachedWarning,
  removeEmptyFilters,
  useAccordionFilterBuilder,
  useDateRangeFilter,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type FilterClause,
  type ITradeAllocation,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { useCallback, useEffect, useMemo } from 'react';
import { columns } from './columns';
import { extractAllocationsPipe } from './extractAllocationsPipe';
import type { TradeAllocationsBlotterTableProps } from './types';
import { onlyServerFilterKeys, useTradeAllocationFilter } from './useTradeAllocationFilter';
import { useTradeAllocationMenu } from './useTradeAllocationMenu';

const ENTITY_SEARCH_KEYS: (keyof ITradeAllocation)[] = [
  'TradeID',
  'Symbol',
  'MarketAccount',
  'User',
  'TradeStatus',
  'Counterparty',
  'Group',
  'Comments',
  'TradeSource',
];

export function TradeAllocationsBlotterTable({
  orderID,
  blotterID,
  blotterPersistID = blotterID,
  defaultColumns = columns,
  defaultFilter,
  defaultSort = '-TransactTime',
  persistFilter,
  persistColumns,
  persistSort,
  initialIsOpen,
  onCloneTab,
  showCloneTab,
}: TradeAllocationsBlotterTableProps) {
  const { getConfig } = useOrgConfiguration();

  const persisted = usePersistedBlotterTable(blotterPersistID, {
    columns: defaultColumns,
    filter: defaultFilter,
    sort: defaultSort,
    persistColumns,
    persistFilter,
    persistSort,
  });

  const tradeAllocationFilter = useTradeAllocationFilter({
    initialFilter: persisted.initialFilter,
    saveFilter: persisted.onFilterChanged,
  });
  const { clientSideFilter, changeFilter, filterableProperties, initialFilterClauses } = tradeAllocationFilter;

  // Forward the parts of the filter being sent to the backend
  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters({
          ...curr,
          MarketAccounts: filterClausesByPropertyKey.get('MarketAccounts')?.selections,
          Sides: filterClausesByPropertyKey.get('Sides')?.selections,
          Statuses: filterClausesByPropertyKey.get('Statuses')?.selections,
          Symbols: filterClausesByPropertyKey.get('Symbols')?.selections,
          Users: filterClausesByPropertyKey.get('Users')?.selections,
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const accordionFilterBuilder = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps: {
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    },
  });

  const {
    getContextMenuItems,
    column: menuColumn,
    dialogs,
  } = useTradeAllocationMenu({
    filterableProperties,
    openClause: accordionFilterBuilder.openClause,
  });

  const columns = useMemo(() => [...persisted.columns, menuColumn], [persisted.columns, menuColumn]);

  // This call owns the true filter state
  const blotterTable = useWsBlotterTable({
    initialRequest: {
      name: TRADE_ALLOCATION,
      tag: blotterID,
      Throttle: '1s',
      ...(orderID != null && { OrderID: orderID }),
    },
    initialFilter: onlyServerFilterKeys(tradeAllocationFilter.filter),
    initialSort: persisted.initialSort,
    rowID: 'id',
    rowSelection: 'multiple',
    clientLocalFilter: clientSideFilter,
    pipe: extractAllocationsPipe,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    columns,
    onColumnsChanged: persisted.onColumnsChanged,
    onSortChanged: persisted.onSortChanged,
    getContextMenuItems,
    quickSearchParams: {
      entitySearchKeys: ENTITY_SEARCH_KEYS,
    },
  });

  const dateRangeFilter = useDateRangeFilter(tradeAllocationFilter.filter, changeFilter);

  /**
   * When the configured filter changes (state owned by useOrderFilter), tell WSBlotterTable about
   * the server keys of the filter but not the locally evaluated keys as they might break the backend.
   */
  useEffect(() => {
    if (tradeAllocationFilter.filter) {
      const serverFilter = onlyServerFilterKeys(tradeAllocationFilter.filter);
      if (!isEqual(blotterTable.filter, serverFilter)) {
        blotterTable.onFilterChanged(serverFilter);
      }
    }
  }, [blotterTable, tradeAllocationFilter.filter]);

  const handleCloneTab = useCallback(() => {
    onCloneTab?.(tradeAllocationFilter.filter, blotterTable.getColumns());
  }, [blotterTable, tradeAllocationFilter.filter, onCloneTab]);

  return (
    <>
      <BlotterTableFilters
        {...accordionFilterBuilder}
        {...dateRangeFilter}
        {...blotterTable.blotterTableFiltersProps}
        prefix={
          showCloneTab && (
            <Button
              startIcon={IconName.Duplicate}
              variant={ButtonVariants.Default}
              size={FormControlSizes.Small}
              onClick={handleCloneTab}
            >
              Clone Tab
            </Button>
          )
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.Timestamp} />
      <BlotterTable {...blotterTable} />
      {dialogs}
    </>
  );
}
