import {
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  MixpanelSourceProvider,
  useDynamicCallback,
  useMixpanel,
} from '@talos/kyoko';
import { ResolveReconMismatchesDrawerContent } from '../components/ResolveReconMismatches/ResolveReconMismatchesDrawerContent';
import { AppwideDrawerContentType, useAppwideDrawerContext } from '../providers/AppwideDrawerProvider';

export const useResolveReconMismatchesDrawer = () => {
  const { openDrawer } = useAppwideDrawerContext();
  const mixpanel = useMixpanel();

  const openResolveReconMismatchesDrawer = useDynamicCallback((mismatchIDs: string[], source?: MixpanelEventSource) => {
    mixpanel.track(MixpanelEvent.OpenReconMismatchResolutionDrawer, {
      [MixpanelEventProperty.Source]: source,
      [MixpanelEventProperty.Amount]: mismatchIDs.length,
    });

    openDrawer({
      type: AppwideDrawerContentType.ResolveReconMismatches,
      title: 'Resolve Mismatch',
      renderContent: () => (
        <MixpanelSourceProvider value={MixpanelEventSource.ReconMismatchesResolutionDrawer}>
          <ResolveReconMismatchesDrawerContent mismatchIDs={mismatchIDs} />,
        </MixpanelSourceProvider>
      ),
    });
  });

  return { openResolveReconMismatchesDrawer };
};
