import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ACTION,
  Box,
  Button,
  ButtonVariants,
  CustomerRolePillWrapper,
  CustomerUser,
  FormTable,
  HStack,
  IconName,
  LocalFilterInput,
  ModeEnum,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  useDrawer,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  useObservableValue,
  type Column,
  type MarketAccount,
} from '@talos/kyoko';
import type { RowNode } from 'ag-grid-enterprise';
import { CustomerUserDetailsDrawer } from 'components/DetailsDrawer/Customer';
import { useFeatureFlag, useRoleAuth } from 'hooks';
import { useCustomerUsers, useCustomers, useCustomersContext } from 'hooks/useCustomer';
import { entries } from 'lodash';
import { useCustomerMarketAccounts } from 'providers';
import { useBlotterState } from 'providers/AppConfigProvider';
import { usePricingRules } from 'providers/PricingRulesProvider';

const SEARCH_KEYS = ['Counterparty', 'DisplayName', 'Email', 'ExternalID', 'Roles'] satisfies (keyof CustomerUser)[];
const READ_ORDERS = 'read::orders';
const WRITE_ORDERS = 'write::orders';

export const CustomerUsers = () => {
  const { filterValueCustomerUsers, setFilterValueCustomerUsers } = useBlotterState();

  const { isAuthorized } = useRoleAuth();
  const isAdmin = isAuthorized(ACTION.DEALER_TRADING);

  const customers = useCustomers();
  const customerUsers = useCustomerUsers();
  const [selectedCustomerUser, setSelectedCustomerUser] = useState<CustomerUser | undefined>();
  const { pricingRules } = usePricingRules();
  const { add: addToast } = useGlobalToasts();

  useEffect(() => {
    formTable.reset(customerUsers);
    // do not include formTable in dependencies because it is not stable and will go into an infinite rendering loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUsers]);

  const customerUserDetailsDrawer = useDrawer({
    position: 'relative',
    width: 650,
    placement: 'right',
  });

  const customersService = useCustomersContext();
  const customerRoles = useObservableValue(() => customersService.customerRoles, [customersService.customerRoles]);
  const customerRolesByName = useMemo(() => new Map(customerRoles?.map(role => [role.Name, role])), [customerRoles]);
  // root Customer records have a TradeSubAccount and HedgeSubAccount field, whereas the CustomerUser records don't
  const customerIsDisabledByCounterparty = useMemo<Map<string, boolean>>(() => {
    return (
      pricingRules
        ?.filter(rule => !!rule.TradeSubAccount)
        ?.reduce((acc, rule) => {
          acc.set(rule.Counterparty, rule.Mode === ModeEnum.Disabled);
          return acc;
        }, new Map()) ?? new Map()
    );
  }, [pricingRules]);

  const updateCustomerMode = useDynamicCallback((customerUserId: string, customerName: string, enabled: boolean) => {
    return customersService
      .updateCustomerUser({ CustomerUserID: customerUserId, Mode: enabled ? ModeEnum.Enabled : ModeEnum.Disabled })
      .then(() => {
        addToast({
          text: `${customerName} ${enabled ? 'enabled' : 'disabled'}`,
          variant: NotificationVariants.Positive,
        });
      })
      .catch((e: ErrorEvent) => {
        addToast({
          text: `Could not ${enabled ? 'enabled' : 'disabled'} ${customerName}: ${e?.toString()}`,
          variant: NotificationVariants.Negative,
        });
        formTable.reset(customerUsers); // revert back to the actual value since the update failed
      });
  });

  const addEditCustomerUser = useCallback(
    (customerUser?: CustomerUser) => {
      setSelectedCustomerUser(customerUser);
      customerUserDetailsDrawer.open();
    },
    [customerUserDetailsDrawer]
  );

  const { customerMarketAccountsByName } = useCustomerMarketAccounts();
  const { enableCustomerAccountRestrictions } = useFeatureFlag();

  const columns = useMemo<Column[]>(
    () => [
      {
        type: 'modeWithMessage',
        width: 100,
        field: 'Mode',
        title: 'Enabled',

        hide: !isAdmin,
        hideInColumnsToolPanel: !isAdmin,
        params: {
          cellRendererParams: ({ data }) => {
            return {
              checked: data?.Mode === ModeEnum.Enabled,
              tooltip:
                data?.Mode === ModeEnum.Enabled && customerIsDisabledByCounterparty.get(data?.Counterparty) ? (
                  <Box textAlign="center">
                    Customer is currently disabled
                    <br />
                    under Customers and Pricing
                  </Box>
                ) : null,
              onChange: (value: boolean) => {
                updateCustomerMode(data.CustomerUserID, data.DisplayName, value);
              },
            };
          },
        },
      },
      {
        type: 'counterparty',
        field: 'Counterparty',
        title: 'Customer',
        params: { customers },
      },
      { type: 'text', field: 'DisplayName', title: 'Name', width: 125 },
      {
        type: 'email',
        field: 'Email',
        width: 250,
      },
      {
        type: 'custom',
        title: 'Roles',
        colId: 'Roles',
        width: 200,
        params: {
          valueGetter: ({ data }: { data: CustomerUser }) => {
            return (data.Roles ?? []).map(customerRole => {
              return typeof customerRole === 'string'
                ? customerRolesByName?.get(customerRole)?.DisplayName
                : customerRole.DisplayName;
            });
          },
          cellRenderer: ({ value, context }: { value: string[]; context: any }) => {
            return (
              <HStack gap="spacingSmall" justifyContent="flex-start">
                {value.map(role => (
                  <CustomerRolePillWrapper key={role} theme={context.current.theme}>
                    {role}
                  </CustomerRolePillWrapper>
                ))}
              </HStack>
            );
          },
          tooltipValueGetter: ({ value }: { value: string[] }) => {
            return value.join(', ');
          },
        },
      },
      { type: 'text', field: 'ExternalID', width: 250 },
      {
        type: 'custom',
        title: 'View Accounts',
        colId: 'ViewAccounts',
        hide: !enableCustomerAccountRestrictions,
        width: 250,
        params: {
          valueGetter: ({ data }: { data: CustomerUser }) => {
            return getPermissionedAccounts(data, READ_ORDERS, customerMarketAccountsByName);
          },
        },
      },
      {
        type: 'custom',
        title: 'Trade Accounts',
        colId: 'TradeAccounts',
        hide: !enableCustomerAccountRestrictions,
        width: 250,
        params: {
          valueGetter: ({ data }: { data: CustomerUser }) => {
            return getPermissionedAccounts(data, WRITE_ORDERS, customerMarketAccountsByName);
          },
        },
      },
      { type: 'filler', id: 'filler1' },
      {
        type: 'iconButton',
        id: 'edit-button',
        pinned: 'right',
        width: 40,
        hide: !isAdmin,
        suppressColumnsToolPanel: !isAdmin,
        params: {
          onClick: ({ node }: { node: RowNode }) => {
            addEditCustomerUser(new CustomerUser(node.data));
          },
          icon: IconName.Pencil,
        },
      },
    ],
    [
      customers,
      customerRolesByName,
      enableCustomerAccountRestrictions,
      customerMarketAccountsByName,
      customerIsDisabledByCounterparty,
      addEditCustomerUser,
      isAdmin,
      updateCustomerMode,
    ]
  );

  // look into whether we should change this to useBlotterTable and <BlotterTable /> like in Settings/Users instead
  const formTable = useFormTable<CustomerUser>({
    rowID: 'Email',
    data: customerUsers ?? [],
    columns: columns ?? [],
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
      filterText: filterValueCustomerUsers,
    },
  });

  const headerActions = (
    <PanelActions>
      <LocalFilterInput
        placeholder="Filter by Customer, Name, or Email"
        value={filterValueCustomerUsers}
        onChange={setFilterValueCustomerUsers}
      />
      {isAdmin && (
        <>
          <Button
            startIcon={IconName.Plus}
            onClick={() => addEditCustomerUser()}
            variant={ButtonVariants.Positive}
            disabled={!isAuthorized(ACTION.DEALER_TRADING)}
            data-testid="add-user-button"
          >
            Add User
          </Button>
        </>
      )}
    </PanelActions>
  );

  if (customerUsers == null || customers == null) {
    return null;
  }
  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <PanelHeader>
          <h2>Customer Users</h2>
          {headerActions}
        </PanelHeader>
        <PanelContent>
          <FormTable {...formTable} onRowDoubleClicked={params => addEditCustomerUser(new CustomerUser(params.data))} />
        </PanelContent>
      </Panel>
      <CustomerUserDetailsDrawer
        {...customerUserDetailsDrawer}
        customerUserID={selectedCustomerUser?.CustomerUserID}
        // reset selected user as drawer is still effectively mounted
        onClose={() => setSelectedCustomerUser(undefined)}
        data-testid="customer-user-details-drawer"
      />
    </HStack>
  );
};

function getPermissionedAccounts(user: CustomerUser, permission: string, accountMap: Map<string, MarketAccount>) {
  const accountPerms = entries(user.MarketAccountAuthorizations).filter(([_, perms]) => perms.includes(permission));

  if (accountPerms.length === 0) {
    return 'All';
  }

  return accountPerms.map(([account, _]) => accountMap.get(account)?.SourceAccountID || account).join(', ');
}
